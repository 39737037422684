import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import SelectBox from "../SelectBox";
import CustomTag from "../../offers/CreateOffers/CustomTag";
import ModalAddSkills from "./ModalAddSkills";
import ModalSaved from "../../myPortal/Modals/ModalSavedChanges";
import ModalDeletePortfolio from "./ModalDeletePortfolio";
import ModalAddMultimedia from "./ModalAddMultimedia";
import ModalAddPortfolio from "./ModalAddPortfolio";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { GetUserPortfolioReducer } from "../../../../redux/actions/pages/myPortal/portfolio/GetPortfolio";
import { GetSoftSkillsReducer } from "../../../../redux/actions/common/softSkills/SoftSkills";
import { DeletePortfolioReducer } from "../../../../redux/actions/pages/myPortal/portfolio/DeletePortfolio";
import { changeDefaultPortfolio, updateArchivo } from '../../../../redux/actions/pages/myPortal/portfolio/PatchPortfolio';
import IconPdf from "../../../../assets/icons/iconpdf.svg";
import IconWord from "../../../../assets/icons/iconword.svg";

interface ModalEditPortfolioProps {
  visible: boolean;
  onClose: () => void;
  skills: string[];
  archivos_portafolio: any[];
  projectId: number | null;
}

const ModalEditPortfolio: React.FC<ModalEditPortfolioProps> = ({
  visible,
  onClose,
  skills: initialSkills = [],
  archivos_portafolio,
  projectId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_userPortfolio, rex_loading, rex_error } = useSelector(
    (state: RootState) => state.getPortfolio
  );
  const { rex_softSkills } = useSelector(
    (state: RootState) => state.softSkills
  );
  const { rex_deleting, rex_deleteError } = useSelector(
    (state: RootState) => state.deletePortfolio
  );

  const [projectName, setProjectName] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [skills, setSkills] = useState<string[]>(initialSkills);
  const [searchTerm, setSearchTerm] = useState("");
  const [typingTimeout, setTypingTimeout] = useState<number | undefined>(
    undefined
  );
  const [selectedSkills, setSelectedSkills] = useState<{id: string; name:string }[]>([]);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null); // Estado para el ID del archivo


  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [isDeleteSkillModalVisible, setIsDeleteSkillModalVisible] =
    useState(false);
  const [isAddWorkModalVisible, setIsAddWorkModalVisible] = useState(false);
  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [isAddMultimediaModalVisible, setIsAddMultimediaModalVisible] =
    useState(false);
  const [isDeletePortfolioModalVisible, setIsDeletePortfolioModalVisible] =
    useState(false);

  const [selectedFileTitle, setSelectedFileTitle] = useState<string | null>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<{ titulo: string; archivo: string | File; id: string } | null>(null);
  const handleFileTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFileTitle(e.target.value); // Update the title in state
  };
  

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProjectName(e.target.value);
  const handleProjectTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProjectTitle(e.target.value);
  const handleProjectDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => setProjectDescription(e.target.value);
  const handleProjectLinkChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProjectLink(e.target.value);

  const handleAddSkills = (newSkills: { id: string; name: string }[]) => {
    setSelectedSkills((prevSkills) => {
      const uniqueNewSkills = newSkills.filter(
        (newSkill) => !prevSkills.some((skill) => skill.id === newSkill.id)
      );
      return [...prevSkills, ...uniqueNewSkills];
    });
    handleAddSkillModalClose();
  };

  const [attachedFiles, setAttachedFiles] = useState<
    Array<{ titulo: string; archivo: string | File }>
  >([]);

  // Luego, modifiquemos la función handleSaveFile
  const handleSaveFile = async (file: File, name: string) => {
    try {
      const formData = new FormData();
      formData.append('titulo', name);
      formData.append('archivoFile', file);
  
      if (selectedFileId) {
        // Actualizar archivo existente
        const updateResult = await dispatch(updateArchivo(selectedFileId, formData));
  
        if (updateResult.error) {
          throw new Error('Error al actualizar el archivo');
        }
  
        // Refrescar los datos del portafolio después de actualizar
        dispatch(GetUserPortfolioReducer());
        message.success('Archivo actualizado con éxito');
      } else {
        // Agregar nuevo archivo (descomentar la siguiente línea para permitir agregar archivos)
        // const addResult = await dispatch(AddPortfolioFileReducer(projectId, formData));
  
        // if (addResult.error) {
        //   throw new Error('Error al agregar el archivo');
        // }
  
        // Refrescar los datos del portafolio después de agregar
        dispatch(GetUserPortfolioReducer());
        message.success('Archivo agregado con éxito');
      }
  
      // Cerrar el modal después de agregar o actualizar
      setIsAddMultimediaModalVisible(false);
    } catch (error) {
      message.error(selectedFileId ? 'Error al actualizar el archivo' : 'Error al agregar el archivo');
    }
  };
  
  

  const handleDeletePortfolio = async () => {
    if (projectId) {
      const result = await dispatch(DeletePortfolioReducer(projectId));
      if (result.success) {
        setIsDeletePortfolioModalVisible(false);
        onClose();
        dispatch(GetUserPortfolioReducer());
      } else {
        console.error("Failed to delete portfolio:", result.error);
      }
    }
  };

  useEffect(() => {
    setSkills(initialSkills || []);
  }, [initialSkills]);

  const handleDeleteClick = () => {
    setIsDeletePortfolioModalVisible(true);
  };

  useEffect(() => {
    if (projectId && rex_userPortfolio) {
      const selectedProject = rex_userPortfolio.find(
        (project) => project.id === projectId
      );
      if (selectedProject) {
        setProjectName(selectedProject.nombre || "");
        setProjectDescription(selectedProject.descripcion || "");
        setProjectLink(selectedProject.url || "");
        setProjectTitle(selectedProject.titulo || "");

        // Cargar archivos adjuntos si existen
        console.log(
          "Archivos del portafolio:",
          selectedProject.archivos_portafolio
        ); // Verificar archivos
        setAttachedFiles(selectedProject.archivos_portafolio || []);

        if (selectedProject.soft_skills_portafolio) {
          setSelectedSkills(
            selectedProject.soft_skills_portafolio.map((skill: any) => ({
              id: skill.soft_skills.id.toString(), // Asegúrate de que el ID sea un string
              name: skill.soft_skills.soft_skill,  // Cambiamos 'nombre' por 'soft_skill'
            }))
          );
        }
         else {
          setSelectedSkills([]); // Si no hay habilidades, asegurarse de que esté vacío
        }
  
        setSelectedSkills(
          selectedProject.soft_skills_portafolio.map((skill: any) => ({
            id: skill.soft_skills.id.toString(),
            name: skill.soft_skills.soft_skill,
          }))
        );
      }
    }
  }, [projectId, rex_userPortfolio]);

  const handleFileClick = (file: any) => {
    setSelectedFileTitle(file.titulo); // Assuming 'titulo' is the title
    setSelectedFile(file.archivo); // Assuming 'archivo' is the file itself
    setSelectedFileId(file.id); // Establecer el ID del archivo seleccionado
    setIsAddMultimediaModalVisible(true); // Mostrar modal
  };

  const renderFileIcon = (fileName: string, file: any) => {
    const extension = fileName.split(".").pop()?.toLowerCase();
  
    return (
      <div onClick={() => handleFileClick(file)} className="cursor-pointer">
        {extension === "pdf" ? (
          <img src={IconPdf} alt="PDF" className="w-[40px] h-[40px] mr-2" />
        ) : extension === "doc" || extension === "docx" ? (
          <img src={IconWord} alt="Word" className="w-[40px] h-[40px] mr-2" />
        ) : (
          <img
            src={`${process.env.REACT_APP_API_BASE_URL_EXACT}/${fileName}`}
            alt="Imagen"
            className="w-24 h-24 object-cover mr-2"
          />
        )}
      </div>
    );
  };
  

  const handleSaveChanges = async () => {
    // Verificar que projectId no es null
    if (projectId === null) {
      console.error('El projectId no puede ser null');
      return; // Detenemos la ejecución si no hay projectId
    }
    
  
    // Crear el objeto con los datos que vamos a actualizar
    const updateData = {
      nombre: projectName,
      titulo: projectTitle,
      descripcion: projectDescription,
      url: projectLink,
      // Extraer solo los IDs de las habilidades seleccionadas
      softSkillsIds: selectedSkills.map(skill => parseInt(skill.id)), // Asegurarse de que sean números
    };
  
    // Intentar realizar la actualización del portafolio
    try {
      const result = await dispatch(changeDefaultPortfolio(String(projectId), updateData));
  
      // Verificar si la respuesta contiene errores
      if (result.error) {
        console.error('Error al actualizar el portafolio:', result.error);
      } else {
        // Si la actualización es exitosa, mostramos el modal secundario y refrescamos los datos del portafolio
        setIsSecondModalVisible(true);
        dispatch(GetUserPortfolioReducer()); // Refrescamos la lista de portafolios
        onClose(); // Cerramos el modal principal
      }
    } catch (error) {
      // Manejar cualquier error inesperado
      console.error('Ocurrió un error durante la actualización:', error);
    }
  };
  
  

  const handleAddWork = () => {
    setIsAddWorkModalVisible(true);
  };

  const handleAddSkillModalClose = () => {
    setIsAddSkillModalVisible(false);
  };

  const handleAddMultimedia = () => {
    setIsAddMultimediaModalVisible(true);
  };

  const handleSkillSelect = (value: string) => {
    const selectedSkill = rex_softSkills.find((skill: { id: number; soft_skill: string }) => skill.soft_skill === value);

    if (selectedSkill && !selectedSkills.some(skill => skill.id === selectedSkill.id.toString())) {
      setSelectedSkills((prevSkills) => [...prevSkills, { id: selectedSkill.id.toString(), name: selectedSkill.soft_skill }]);
    }
  };

  // Remover habilidad seleccionada
  const handleSkillRemove = (skillId: string) => {
    setSelectedSkills((prevSkills) => prevSkills.filter((item) => item.id !== skillId));
  };

  const handleSearchSkills = (search: string) => {
    setSearchTerm(search);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = window.setTimeout(async () => {
      await dispatch(GetSoftSkillsReducer(search, 1, 5));
    }, 500);

    setTypingTimeout(timeoutId);
  };

  return (
    <>
      <Modal
        open={
          visible &&
          !isAddSkillModalVisible &&
          !isAddMultimediaModalVisible &&
          !isDeletePortfolioModalVisible
        }
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{
          borderRadius: "12px",
          border: "1px solid #E1E1E2",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mb-[46px] mt-[21px]">
          <h3 className="mt-[16px] text-heading-md font-bold">
            Editar proyecto
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[68px] mt-[43px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">
            Título
          </span>
          <InputC
            placeholder="Nombre del proyecto"
            value={projectTitle}
            onChange={handleProjectTitleChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md text-[#757575] font-normal">
            Descripción del proyecto
          </span>
          <Input.TextArea
            rows={5}
            value={projectDescription}
            onChange={handleProjectDescriptionChange}
            className="w-full 
                border
                my-[8px]
                border-[#D9D9D9] 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-[#D9D9D9]
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-[#757575]
                rounded-[8px] 
                transition-all 
                duration-200 
                text-[#757575]
                font-normal
                text-body-md"
            style={{ height: 80 }}
          />

          <div className="flex items-center justify-between mt-[27px]">
            <span className="text-body-md font-normal text-[#5F5F5F]">
              Aptitudes (Opcional)
            </span>
          </div>
          
        <SelectBox
          placeholder="Seleccionar habilidades"
          options={rex_softSkills.map((skill) => ({
            value: skill.soft_skill,
            label: skill.soft_skill,
          }))}
          onSearch={handleSearchSkills}
          onChange={handleSkillSelect}
          className="Input-Filter-Employment w-full mt-[5px] rounded-[12px] custom-input-company px-1"
          
        />
        <div className="flex flex-wrap mt-4">
  {selectedSkills.map((skill) => (
    <CustomTag
      key={skill.id}
      text={skill.name} // Mostramos el nombre de la habilidad
      onClose={() => handleSkillRemove(skill.id)}
    />
  ))}
</div>


          <div className="flex items-center justify-between mb-[24px] mt-[15px]">
            <span className="text-body-md font-normal text-[#5F5F5F]">
              Archivos adjuntos ({archivos_portafolio.length}/3)
            </span>
            <Button
              className="text-blue3 font-semibold border border-white w-[218px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
              onClick={() => setIsAddMultimediaModalVisible(true)}
              disabled={archivos_portafolio.length >= 3}
            >
              <PlusOutlined />
              Añadir Contenido multimedia
            </Button>
          </div>

          {archivos_portafolio.length > 0 && (
  <div className="mt-[10px] mb-[24px]">
    <ul className="flex flex-wrap gap-4 mt-4">
      {archivos_portafolio.map((file, index) => (
        <li
          key={index}
          className="flex flex-col items-center cursor-pointer"
          onClick={() => handleFileClick(file)}  // Aquí usamos handleFileClick
        >
          {renderFileIcon(file.nombre_archivo, file)} {/* Pass the file object */}
          <span className="mt-2 text-body-md font-normal text-[#5F5F5F]">
            {file.titulo} {/* Use the file title */}
          </span>
        </li>
      ))}
    </ul>
  </div>
)}

        </div>

        <div className="mx-[68px] mt-[20px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">
            Enlace (Opcional)
          </span>
          <InputC
            placeholder="Nombre del proyecto"
            value={projectLink}
            onChange={handleProjectLinkChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />
        </div>
        <div className="flex mx-[86px] justify-center mt-[34px]">
          <Button
            className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
            onClick={handleDeleteClick}
          >
            Eliminar
          </Button>
          <Button
            className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
            onClick={handleSaveChanges}
          >
            Guardar
          </Button>
        </div>
      </Modal>

      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />

     {/* <ModalAddSkills
        visible={isAddSkillModalVisible}
        onClose={handleAddSkillModalClose}
        existingSkills={skills}
        onAdd={handleAddSkills}
      />*/}

      <ModalDeletePortfolio
        visible={isDeletePortfolioModalVisible}
        onClose={() => setIsDeletePortfolioModalVisible(false)}
        onConfirmDelete={handleDeletePortfolio}
        projectTitle={projectTitle}
      />

      <ModalAddPortfolio
        visible={isAddWorkModalVisible}
        onClose={() => setIsAddWorkModalVisible(false)}
      />

<ModalAddMultimedia
  visible={isAddMultimediaModalVisible}
  onClose={() => {
    setIsAddMultimediaModalVisible(false);
    setSelectedFileId(null); // Limpiar el ID seleccionado al cerrar
  }}
  onSave={handleSaveFile}
  portfolioId={selectedFileId} // Asegúrate de que esto sea el ID del archivo
/>

    </>
  );
};

export default ModalEditPortfolio;
