import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  GET_POSTULACION_BY_ID_REQUEST,
  GET_POSTULACION_BY_ID_SUCCESS,
  GET_POSTULACION_BY_ID_FAILURE,
  GetPostulacionByIdActionTypes,
  Postulacion
} from '../../../../../constants/pages/offers/portulation/GetPortulationId';
import FetchWithIP from '../../../utils/FetchHeaders';

// Action Creators
export const getPostulacionByIdRequestReducer = (): GetPostulacionByIdActionTypes => ({
  type: GET_POSTULACION_BY_ID_REQUEST,
});

export const getPostulacionByIdSuccessReducer = (data: Postulacion): GetPostulacionByIdActionTypes => ({
  type: GET_POSTULACION_BY_ID_SUCCESS,
  payload: { data },
});

export const getPostulacionByIdFailureReducer = (error: string): GetPostulacionByIdActionTypes => ({
  type: GET_POSTULACION_BY_ID_FAILURE,
  payload: error,
});

// Async Action
export const getPostulacionById = (
  id: number
): ThunkAction<Promise<any>, RootState, unknown, GetPostulacionByIdActionTypes> => async (dispatch) => {
  dispatch(getPostulacionByIdRequestReducer());

  try {
    const response = await FetchWithIP(`postulaciones/getByPostulacionId/${id}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    
    // Validate response data
    if (!responseData || !responseData.data) {
      throw new Error('Datos de postulación no encontrados');
    }

    dispatch(getPostulacionByIdSuccessReducer(responseData.data));
    return responseData; 
  } catch (error: any) {
    const errorMessage = error.message || 'Error al obtener los datos de la postulación';
    dispatch(getPostulacionByIdFailureReducer(errorMessage));
    return { error: errorMessage };
  }
};