import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Divider, notification } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { EyeFilled } from "@ant-design/icons";
import IconRecommendations from "../../../../assets/icons/recommendations.svg";
import "../../../../components/styles/pages/principalNav/PrincipalNav.css";
import KillerQuestionItem from "../../../../components/pages/offers/KillerQuestions/KillerQuestionItem";
import QuestionModal from "../../../../components/pages/offers/KillerQuestions/ModalOffers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { CreateKillerQuestionReducer } from "../../../../redux/actions/offers/CreateOffers";
import { UpdateKillersQuestionsReducer } from "../../../../redux/actions/offers/EditKillerQuestions";
import { GetOfferDetailReducer } from "../../../../redux/actions/pages/offers/offer/GetOffer";
import {DeleteOfferSaveReducer} from "../../../../redux/actions/offers/DeleteOfferSave";
import { deleteOfferReducer } from "../../../../redux/actions/pages/company/DeleteOffers";
import ModalCrearOferta from "../../../modals/ModalCrearOferta";
import ModalCancelOferta from "../../../modals/ModalCancelOferta";

const { Text } = Typography;

interface KillerInfo {
  isKiller: boolean;
  minValue: number;
  maxValue: number;
  killer_question_id: number;
}
interface Question {
  tipo_pregunta_id: number;
  question?: string;
  options: any[];
  min: number;
  id?: number;
  killerInfo?: KillerInfo;
  has_condition?: boolean; // Add this line
  max?: number; // Add this line
}

const KillerQuestions: React.FC = () => {
  const navigate = useNavigate();
  const { idOffer, relatedTo } = useParams<{ idOffer: string; relatedTo: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModalCrearOferta, setShowModalCrearOferta] = useState(false);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [listQuestions, setListQuestions] = useState<Question[]>([
    { tipo_pregunta_id: 1, question: "¿?", options: [], min: 0 },
  ]);
  const [loading, setLoading] = useState(true);

  const rex_offer_detail = useSelector((state: RootState) => state.getOfferID.rex_offer_detail);
  const { rex_published_offer } = useSelector(({ paquetesUsuarios }: any) => paquetesUsuarios);
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");

  useEffect(() => {
    if (mode === "create-offer-related" && !relatedTo) {
      notification.error({ message: "Error: No se proporcionó la oferta relacionada" });
      navigate("/offers"); // Redirige a una página de fallback
      return;
    }
  
    if ((mode === "edit" || mode === "create-offer-related") && (idOffer || relatedTo)) {
      const offerId = mode === "edit" ? idOffer : relatedTo;
  
      dispatch(GetOfferDetailReducer(parseInt(offerId!)))
        .then((response: any) => {
        })
        .catch((error: any) => {
          console.error("Error al cargar detalles de la oferta:", error);
        });
    } else {
      setLoading(false);
      
    }

  }, [idOffer, relatedTo, dispatch, mode]);

  useEffect(() => {
    // Configurar preguntas en modo editar o crear oferta relacionada
    if ((mode === "edit" || mode === "create-offer-related") && rex_offer_detail?.killers_questions) {

      setListQuestions(
        rex_offer_detail.killers_questions.map((question: any) => ({
          id: question.id, 
          tipo_pregunta_id: question.tipo_pregunta_id,
          question: question.pregunta,
          options: question.detalle_killers.map((detalle: any) => detalle.detalle),
          min: question.condiciones?.[0]?.minimo || 0,
          max: question.condiciones?.[0]?.maximo || 0,
          has_condition: !!question.condiciones?.length, 
        }))
      );
    } else if (mode === "create") {
      // Reiniciar preguntas para crear una nueva oferta
      setListQuestions([{ tipo_pregunta_id: 1, question: "¿?", options: [], min: 0 }]);
    }
    setLoading(false);
  }, [rex_offer_detail, mode]);

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleSubmit = async (data: { save_only: boolean }) => {
    const validQuestions = listQuestions.filter(
      (question) => question.question && question.question.trim() !== ""
    );
  
    const offerId = parseInt(idOffer || relatedTo || "0");
    if (isNaN(offerId)) {
      notification.error({ message: "ID de oferta no disponible o inválido" });
      return;
    }
  
    try {
      const processedQuestions = validQuestions.map((q) => ({
        tipo_pregunta_id: parseInt(String(q.tipo_pregunta_id)),
        question: q.question?.trim() || "",
        options: Array.isArray(q.options) ? q.options : [],
        has_condition: q.killerInfo?.isKiller ?? q.has_condition, // Indica si tiene condición
        min: q.killerInfo?.isKiller ? q.killerInfo.minValue : q.has_condition ? q.min : undefined, // Preserva `min` si ya existía
        max: q.killerInfo?.isKiller ? 0 : q.has_condition ? 0 : undefined, // Siempre establece `max` en 0 si la condición está activada
      }));
      
      const createOrUpdatePayload = {
        oferta_id: offerId,
        questions: processedQuestions,
        save_only: data.save_only,
      };
  
      let response;
  
      if (mode === "create" || mode === "create-offer-related") {
        response = await dispatch(CreateKillerQuestionReducer(createOrUpdatePayload));
      } else {
        response = await dispatch(UpdateKillersQuestionsReducer(offerId, createOrUpdatePayload.questions));
      }
  
      if (response.respuesta) {
        notification.success({ message: response.mensaje });
        navigate("/offers/open-offers");
      } else {
        notification.warning({ message: response.mensaje || "No se pudo procesar las preguntas" });
      }
    } catch (error) {
      console.error("Error al procesar las preguntas:", error);
      notification.error({ message: "Error al guardar los cambios" });
    }
  };
  

  const handleAddQuestion = () => {
    setListQuestions([
      ...listQuestions,
      { tipo_pregunta_id: 1, question: "¿?", options: [], min: 0 },
    ]);
  };

  const handleDeleteQuestion = (index: number) => {
    const updatedQuestions = listQuestions.filter((_, i) => i !== index);
    setListQuestions(updatedQuestions);
  };

  const handleQuestionChange = (
    index: number, 
    field: string, 
    value: any, 
    killerInfo?: KillerInfo // Explicitly type the parameter
  ) => {
    const updatedQuestions = [...listQuestions];
    
    if (field === 'killerInfo' && killerInfo) {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        killerInfo: killerInfo
      };
    } else {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [field]: value,
        question: field === "question" ? value : updatedQuestions[index].question
      };
    }
    
    setListQuestions(updatedQuestions);
  };

  if (loading) return <p>Cargando...</p>;

  const handleLoadQuestionsFromModal = (loadedQuestions: any[]) => {
  
    const newQuestions = loadedQuestions.map(q => {
      return {
        tipo_pregunta_id: q.tipo_pregunta_id,
        question: q.pregunta,
        options: 
          q.detalle_killers?.map((detalle: any) => detalle.detalle) || 
          q.options || 
          [],
        min: q.min || 0,
      };
    });
  
    setListQuestions([
      ...listQuestions,
      ...newQuestions
    ]);

  };
  const handleCancelCreation = () => {
    // Mostrar el modal de confirmación
    setIsModalConfirmVisible(true);
  };
  

  const handleConfirmCancel  = () => {
    const offerId = parseInt(idOffer || relatedTo || "0");
  
    if (isNaN(offerId)) {
      notification.error({ message: "ID de oferta no disponible o inválido" });
      return;
    }
    dispatch(deleteOfferReducer(offerId));
    navigate("/offers/open-offers");
    setIsModalConfirmVisible(false); 
  };
  


  return (
    <div className="bg-white pr-6">
      <Row justify="space-between" align="middle" className="mb-6">
        <Col>
          <h1 className="font-extralight text-5xl font-sans text-blue3">Killer Questions</h1>
        </Col>
        <Col className="mt-[13px]">
          <Button className="text-blue3 pl-[30px] border principal-nav-notify-button font-semibold mr-[8px] text-body-sm w-[227px] h-[35px]">
            Formular preguntas con IA
            <img src={IconRecommendations} className="icon-merere w-[30px]" alt="Icon" />
          </Button>
          <Button
            className="text-blue3 border principal-nav-notify-button font-semibold text-body-sm w-[227px] h-[35px]"
            onClick={showModal}
          >
            Cargar preguntas de otra oferta
          </Button>
          <QuestionModal 
        visible={isModalVisible} 
        onClose={closeModal} 
        onLoadQuestions={handleLoadQuestionsFromModal}
      />
        </Col>
      </Row>

      {listQuestions.map((question, index) => (
        <div key={index}>
          <KillerQuestionItem
            number={index + 1}
            title={question.question || ""}
            options={question.options}
            changeOptions={(val: any) => handleQuestionChange(index, "options", val)}
            placeholder={question.question || ""}
            defaultSelectedOption={question.tipo_pregunta_id}
            changeTitle={(value: string) => handleQuestionChange(index, "question", value)}
            changeType={(val: number) => handleQuestionChange(index, "tipo_pregunta_id", val)}
            deleteQuestion={() => handleDeleteQuestion(index)}
            changeKillerInfo={(killerInfo: KillerInfo) => 
              handleQuestionChange(index, 'killerInfo', null, killerInfo)
            }
            hasCondition={question.has_condition} // Nuevo campo
            min={question.min} // Campo para la condición
            max={question.max} // Campo para la condición
            changeCondition={(field: string, value: number | boolean) => 
              handleQuestionChange(index, field, value)
            }
          />
        </div>
      ))}

      <Divider />
      

      <Button className="bg-blue3 principal-nav-notify-button2 h-[32px] text-white rounded-[4px]" onClick={handleAddQuestion}>
        + Nueva Pregunta
      </Button>

      <Row className="justify-end mb-[24px]">
        <Col>
          <Button className="bg-white text-[#5F5F5F] border border-white w-[310px] mr-[8px] principal-nav-notify-button0"
                  onClick={handleCancelCreation}>
            Cancelar creación de oferta de empleo
          </Button>
        </Col>
        <Col>
          <Button
            className="text-blue3 w-[98px] border principal-nav-notify-button mr-[8px] text-body-md"
            onClick={() => {
              handleSubmit({ save_only: true });
            }}
          >
            Guardar
          </Button>
        </Col>
        <Col>
        <Button
          className="text-blue3 w-[98px] border principal-nav-notify-button mr-[8px] text-body-md"
          onClick={() => navigate(-1)} // Redirigir a la página anterior
        >
          Volver
        </Button>

        </Col>
        <Col>
          <Button
            className="bg-blue3 text-white w-[251px] principal-nav-notify-button2"
            onClick={() => {
              if (rex_published_offer.status) {
                handleSubmit({ save_only: false });
              } else {
                setShowModalCrearOferta(true);
              }
            }}
          >
            <EyeFilled className="text-[24px]" />{" "}
            {mode === "edit" ? "Guardar Cambios" : "Vista previa y finalizar"}
          </Button>
        </Col>
      </Row>
      <ModalCrearOferta
        showModal={showModalCrearOferta}
        setShowModal={() => setShowModalCrearOferta(false)}
        onOk={() => handleSubmit({ save_only: false })}
      />

      <ModalCancelOferta
        showModal={isModalConfirmVisible}
        setShowModal={setIsModalConfirmVisible}
        onOk={handleConfirmCancel} // Pasar la función de confirmación al modal
      />

    </div>
    
  );
  
};

export default KillerQuestions;
