import React from 'react'
import IconClosed from "../../assets/icons/IconClosed.svg";
import { Button, Col, Input, Modal, Row } from 'antd';
import { useSelector } from 'react-redux';

const styleModal = {
	border: '2px solid #006497',
	borderRadius: '8px',
	overflow: 'hidden',
	padding: '0px',
}

const ModalGuardarBusqueda: React.FC<{
  showModal: boolean;
  setShowModal: any;
  onOk: any,
  setNameSearch:any,
  nameSearch:string
}> = ({
  showModal,
  setShowModal,
  onOk,
  setNameSearch,
  nameSearch
}) => {

    const {
      rex_loading
    } = useSelector(({ savedSearches }: any) => savedSearches)

    return (
      <Modal
        visible={showModal}
        closable={false}
        footer={null}
        width={550}
        style={styleModal}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={() => setShowModal(false)}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16, 24]} className='text-center mt-7'>
          <Col span={24}>
            <p className="font-bold text-2xl font-sans">Guardar búsqueda</p>
          </Col>
          <Col span={24}>
            <Input
              className='font-sans border border-blue3 rounded-[4px]'
              value={nameSearch}
              onChange={(e)=> setNameSearch(e.target.value)}
              placeholder='Nombre de busqueda'
            />
          </Col>
        </Row>
        <Row className='flex justify-center mb-5'>
          <div className="flex justify-center mx-[114px] mt-[38px]">
            <Button
              onClick={() => setShowModal(false)}
              className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
            >
              Cancelar
            </Button>
            <Button
              loading={rex_loading}
              onClick={onOk}
              className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
            >
              Guardar
            </Button>
          </div>
        </Row>
      </Modal>
    )
  }

export default ModalGuardarBusqueda