import React, { useState, useEffect } from "react";
import { Table, Skeleton, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { GetPostulacionesByOfertaReducer } from '../../../../redux/actions/pages/offers/offersProfile/GetOffersProfile';
import { DetailSoftSkillsOfferFormat } from '../../../../constants/pages/searchCV/GetSearchCV';
import CheckboxC from "../../../checkbox/CheckboxProps";
import RatingStar from "../../searchCV/RatingStar";
import InfiniteScroll from "react-infinite-scroll-component";
import { experienciaTexto } from "../../../../utils/CalculateTimeExp";
import ModalSoftSkills from "./ModalSoftSkills";
interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: any, record: any) => JSX.Element | string;
}

interface UserSoftSkillsDto {
  nivel: any;
  soft_skills: {
    soft_skill: string
  }
}

interface OfferSoftSkillsDto {
  nivel: any;
  soft_skills_oferta: {
    porcentaje: number
    soft_skills: {
      soft_skill: string
      soft_skill_c: string
    }
  }[]
}

interface UserDto {
  soft_skills_usuarios: UserSoftSkillsDto[]
}

interface FilterParams {
  educations?: string[];
  keywords?: string[];
  ratings?: number[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
  experiences?: number[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  educationsUsers?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  experiencia_nula?: number[];
  modalidad_remoto?: string[];
  activeSearch?:boolean;
  jornada?: string[];
  excluirEmpresa?: string[]
}

interface DataType {
  id: string;
  nombre: string;
  valoraciones: number;
  numReviews: number;
  aptitudes: {
    role: string;
    language: string;
    level: string;
  };
  softSkills: string;
  ubicacion: string;
  estudios: string;
  experiencia: string;
  rol: string;
}

interface OffersTableProps {
  offerId: number;
  filters: FilterParams;
}


const SkeletonRow = () => (
  <div className="skeleton-row" style={{ display: 'flex', padding: '12px 0', borderBottom: '1px solid #f0f0f0' }}>
    <div style={{ width: '30px' }}><Skeleton.Button active size="small" style={{ width: 20, height: 20 }} /></div>
    <div style={{ width: '20%' }}>
      <Skeleton.Input active size="small" style={{ width: '100%', marginBottom: 4 }} />
      <Skeleton.Input active size="small" style={{ width: '60%' }} />
    </div>
    <div style={{ width: '15%' }}>
      <Skeleton.Input active size="small" style={{ width: '80%' }} />
    </div>
    <div style={{ width: '20%' }}>
      <Skeleton.Input active size="small" style={{ width: '90%', marginBottom: 4 }} />
      <Skeleton.Input active size="small" style={{ width: '70%' }} />
    </div>
    <div style={{ width: '10%' }}>
      <Skeleton.Input active size="small" style={{ width: '100%' }} />
    </div>
    <div style={{ width: '10%' }}>
      <Skeleton.Input active size="small" style={{ width: '100%' }} />
    </div>
    <div style={{ width: '15%' }}>
      <Skeleton.Input active size="small" style={{ width: '90%' }} />
    </div>
    <div style={{ width: '10%' }}>
      <Skeleton.Input active size="small" style={{ width: '80%' }} />
    </div>
  </div>
);

const OffersTable: React.FC<OffersTableProps> = ({ offerId, filters }) => {

  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [modalData, setModalData] = useState<any>(null);  
  const {
    postulaciones,
    rex_total,
    rex_limit,
    error
  } = useSelector((state: RootState) => state.getOffersProfile);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setLoading(true);
  }, [filters])


  useEffect(() => {
    if (postulaciones.length === 0 || page === 1) {
      setLoading(true);
      const selectedRatings = filters.ratings && filters.ratings.length > 0 ? filters.ratings : undefined
      const selectedExperienciaLaboral = filters.experienciaLaboral && filters.experienciaLaboral?.length > 0 ? filters.experienciaLaboral : undefined
      const selectedSoftSkills = filters.softSkills && filters.softSkills?.length > 0 ? filters.softSkills : undefined
      const selectedPaises = filters.paises && filters.paises?.length > 0 ? filters.paises : undefined
      const selectedCiudades = filters.ciudades && filters.ciudades?.length > 0 ? filters.ciudades : undefined
      const selectedCompanies = filters.companies && filters.companies?.length > 0 ? filters.companies : undefined
      const selectedExperiences = filters.experiences && filters.experiences?.length > 0 ? filters.experiences : undefined
      const selectedEducations = filters.educations && filters.educations?.length > 0 ? filters.educations : undefined
      const selectedSectors = filters.sectors && filters.sectors?.length > 0 ? filters.sectors : undefined
      const selectedValoracionesMax = filters.valoraciones_usuariosmax ? filters.valoraciones_usuariosmax : undefined
      const selectedExperienciaNula = filters.experiencia_nula && filters.experiencia_nula.length > 0 ? filters.experiencia_nula : undefined
      const selectedModalidadRemoto = filters.modalidad_remoto && filters.modalidad_remoto?.length > 0 ? filters.modalidad_remoto : undefined
      const selectedExcluirExperienciaLaboral = filters.excluirExperienciaLaboral && filters.excluirExperienciaLaboral?.length > 0 ? filters.excluirExperienciaLaboral : undefined
      const activeSearch = filters.activeSearch
      const selectedJornada = filters.jornada && filters.jornada?.length > 0 ? filters.jornada : undefined
      const selectedExcluirEmpresa = filters.excluirEmpresa && filters.excluirEmpresa?.length > 0 ? filters.excluirEmpresa : undefined

      dispatch(GetPostulacionesByOfertaReducer(
        offerId,
        1,
        10,
        selectedRatings,
        selectedExperienciaLaboral,
        selectedSoftSkills,
        selectedPaises,
        selectedCiudades,
        selectedCompanies,
        selectedExperiences,
        selectedEducations,
        selectedSectors,
        selectedValoracionesMax,
        selectedExperienciaNula,
        selectedModalidadRemoto,
        selectedExcluirExperienciaLaboral,
        activeSearch,
        selectedJornada,
        selectedExcluirEmpresa
      )).then(() => {
        setLoading(false);
      })
    }
  }, [filters, page, rex_limit])

  const handleSoftSkillsClick = (record: any) => {
  
    // Prepara los datos para el modal
    const userSoftSkills = {
      soft_skills_usuarios: record.softSkillsData?.soft_skills_usuarios || []
    };
  
    const ofertaSoftSkills = {
      soft_skills_oferta: record.softSkillsData?.soft_skills_oferta || []
    };
  
    // Importante: establece el modalData
    setModalData({
      ofertas: ofertaSoftSkills, 
      usuarios: userSoftSkills,
      candidateName: record.nombre 
    });
  
    // Establece los soft skills seleccionados
    setSelectedUserSoftSkills({ ofertas: ofertaSoftSkills, usuarios: userSoftSkills });
  
    // Muestra el modal
    setIsModalVisible(true);
  };
  


  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);
    setTimeout(() => {
      const selectedRatings = filters.ratings && filters.ratings.length > 0 ? filters.ratings : undefined
      const selectedExperienciaLaboral = filters.experienciaLaboral && filters.experienciaLaboral?.length > 0 ? filters.experienciaLaboral : undefined
      const selectedSoftSkills = filters.softSkills && filters.softSkills?.length > 0 ? filters.softSkills : undefined
      const selectedPaises = filters.paises && filters.paises?.length > 0 ? filters.paises : undefined
      const selectedCiudades = filters.ciudades && filters.ciudades?.length > 0 ? filters.ciudades : undefined
      const selectedCompanies = filters.companies && filters.companies?.length > 0 ? filters.companies : undefined
      const selectedExperiences = filters.experiences && filters.experiences?.length > 0 ? filters.experiences : undefined
      const selectedEducations = filters.educations && filters.educations?.length > 0 ? filters.educations : undefined
      const selectedSectors = filters.sectors && filters.sectors?.length > 0 ? filters.sectors : undefined
      const selectedValoracionesMax = filters.valoraciones_usuariosmax ? filters.valoraciones_usuariosmax : undefined
      const selectedExperienciaNula = filters.experiencia_nula && filters.experiencia_nula.length > 0 ? filters.experiencia_nula : undefined
      const selectedModalidadRemoto = filters.modalidad_remoto && filters.modalidad_remoto?.length > 0 ? filters.modalidad_remoto : undefined
      const selectedExcluirExperienciaLaboral = filters.excluirExperienciaLaboral && filters.excluirExperienciaLaboral?.length > 0 ? filters.excluirExperienciaLaboral : undefined
      const activeSearch = filters.activeSearch
      const selectedJornada = filters.jornada && filters.jornada?.length > 0 ? filters.jornada : undefined
      const selectedExcluirEmpresa = filters.excluirEmpresa && filters.excluirEmpresa?.length > 0 ? filters.excluirEmpresa : undefined

      dispatch(GetPostulacionesByOfertaReducer(
        offerId,
        nextPage,
        rex_limit,
        selectedRatings,
        selectedExperienciaLaboral,
        selectedSoftSkills,
        selectedPaises,
        selectedCiudades,
        selectedCompanies,
        selectedExperiences,
        selectedEducations,
        selectedSectors,
        selectedValoracionesMax,
        selectedExperienciaNula,
        selectedModalidadRemoto,
        selectedExcluirExperienciaLaboral,
        activeSearch,
        selectedJornada,
        selectedExcluirEmpresa
      )).then(() => {
        setPage(nextPage);
        setLoadingMoreData(false);
        if (nextPage >= totalPages) {
          setHasMore(false);
        }
      })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (postulaciones?.length == rex_total) {
        setHasMore(false)
      }
    }
  }, [rex_total, postulaciones])

  const navigate = useNavigate();

  const handleCandidateClick = (candidateId: number) => {
    if (offerId && candidateId) {
      navigate(`/offers/${offerId}/candidate/${candidateId}`);
    } else {
      console.error("ID del candidato o de la oferta no definidos");
    }
  };
 
  const [selectedUserSoftSkills, setSelectedUserSoftSkills] = useState<any | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const columnsWithCheckbox: Column[] = [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text, record) => (
        <CheckboxC value={0} children={undefined} />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div className="font-normal text-caption text-green32">
              {record.nombre}
            </div>
            <div className="font-bold text-body-sm text-green32">
              {record.rol}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Valoraciones",
      dataIndex: "valoraciones",
      key: "valoraciones",
      render: (text, record) => {
        const roundedRating = Number(record?.valoraciones?.toFixed(1) ?? 0);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <RatingStar value={roundedRating} />
            <span className="font-bold text-body-sm ml-[4px]">
              {roundedRating}
            </span>
            <span className="font-normal text-body-sm text-green32 ml-[4px]">
              ({record.numReviews})
            </span>
          </div>
        );
      },
    },
    {
      title: "Aptitudes",
      dataIndex: "aptitudes",
      key: "aptitudes",
      render: (aptitudes) => (
        <div className="font-medium text-caption text-green32">
          {aptitudes.map((aptitud: string, index: number) => (
            index < 1 && (
              <div key={index}>{aptitud}</div>
            )
          ))}
        </div>
      ),
    },
    {
      title: "Soft Skills",
      dataIndex: "softSkills",
      key: "softSkills",
      render: (_, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation(); // Detiene la propagación del evento
            handleSoftSkillsClick(record);
          }}
          className="cursor-pointer text-caption text-blue3"
        >
          Ver Gráficas
        </div>
      ),
    },  
    {
      title: "Ubicación",
      dataIndex: "ubicacion",
      key: "ubicacion",
      render: (text) => (
        <div className="font-normal text-body-sm text-green32">{text}</div>
      ),
    },
    {
      title: "Estudios",
      dataIndex: "estudios",
      key: "estudios",
      render: (educacion) => (
        <div className="font-medium text-body-sm text-green32">
          {educacion.map((carrera: string, index: number) => (
            index < 1 && (
              <div key={index}>{carrera}</div>
            )
          ))}
        </div>
      ),
    },
    {
      title: "Experiencia",
      dataIndex: "experiencia",
      key: "experiencia",
      render: (text) => (
        <div className="font-medium text-body-sm text-green32">{text}</div>
      ),
    },
  ];

  const data: DataType[] = postulaciones.map((postulacion: any) => ({
    id: postulacion.usuarios.id.toString(),
    nombre: `${postulacion.usuarios.personas.nombre} ${postulacion.usuarios.personas.apellido_paterno} ${postulacion.usuarios.personas.apellido_materno}`,
    valoraciones: postulacion.usuarios.promedioValoraciones,
    numReviews: postulacion.usuarios.valoraciones_usuarios?.length || 0,
    aptitudes: postulacion.usuarios.aptitudes_usuarios.map((apt: any) => `${apt.aptitudes.aptitud}`),
    softSkills: "Ver Gráfica",
    ubicacion: postulacion.usuarios.ubicacion,
    estudios: postulacion.usuarios.educacion_usuarios.map((carrera: any) => `${carrera.carrera}`),
    experiencia: experienciaTexto(postulacion.usuarios.meses_experiencia), 
    rol: postulacion.usuarios.cargo || "Rol no especificado",
    softSkillsData: {
      soft_skills_usuarios: postulacion.usuarios.soft_skills_usuarios || [],
      soft_skills_oferta: postulacion.ofertas.soft_skills_oferta || []
    }
  }));

  if (loading) return <div>  {[...Array(5)].map((_, index) => (
    <SkeletonRow key={index} />
  ))}</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={hasMore && data.length >= rex_limit}
        loader={
          hasMore && data.length >= rex_limit ? ( // Solo muestra el Skeleton si `hasMore` es `true` y hay más resultados para cargar
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            </div>
          ) : null
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Ya no hay más datos</b>
          </p>
        }
        scrollThreshold={0.95}
      >
        <React.Fragment >
          <Table
            columns={columnsWithCheckbox}
            dataSource={data}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record) => ({
              onClick: () => handleCandidateClick(Number(record.id)),
            })}
            className="offer-table cursor-pointer"
          />
        </React.Fragment>

      </InfiniteScroll>
      <style>{`
        .offer-table .ant-table-thead > tr > th {
          background-color: white;
          color: #5f5f5f;
          font-weight: normal;
          font-size: 12px;
          border-bottom: 1px solid #5F5F5F;
        }

        .offer-table .ant-table-thead > tr > th,
        .offer-table .ant-table-tbody > tr > td {
          padding: 12px;
          border-right: none;
        }
      `}</style>
      {
        isModalVisible && (
          <ModalSoftSkills
          visible={isModalVisible}
          onClose={handleCloseModal}
          data={modalData}// Asegúrate de pasar los datos correctos aquí
          selectedUserSoftSkills={selectedUserSoftSkills}  // Pasar el prop de soft skills de usuario
          candidateName={modalData.candidateName}
        />
        
            )
      }
    
    </>
  );
};

export default OffersTable;