import React from 'react';
import { Card, Typography } from 'antd';
import "tailwindcss/tailwind.css";
import CustomRadio from '../../components/pages/myPortal/ButtonRdio';
import IconEdit from '../../assets/icons/EditP.svg';
import IconPdf from '../../assets/icons/iconpdf.svg';

const { Text } = Typography;

interface CardCurriculumProps {
  fileName: string;
  jobTitle: string;
  description: string;
  className?: string;
  onEdit?: () => void;
  showCustomRadio?: boolean;
  showEditIcon?: boolean;
  isSelected?: boolean;
  onSelect?: () => void;
  onClick?:any;
  isBlocked? : boolean
}

// CardCurriculum.tsx
const CardCurriculum: React.FC<CardCurriculumProps> = ({
  fileName,
  jobTitle,
  description,
  className = '',
  onEdit,
  showCustomRadio = true,
  showEditIcon = false,
  isSelected = false,
  onSelect,
  onClick,
  isBlocked = false
}) => {
  // Función que previene la propagación del clic en el CustomRadio
  const handleRadioClick = (e: React.MouseEvent) => {
    e.stopPropagation();  // Evita que el clic en el radio active otros eventos como abrir el modal
    onSelect?.();  // Ejecuta la función onSelect (si está disponible)
  };

  return (
    <Card
      onClick={()=>{
        if(!isBlocked){
          onClick()
        }
      }}
      className={`h-32 p-4 shadow-md rounded-none hover:shadow-lg transition-shadow flex relative ${className} ${isBlocked?'cursor-not-allowed':''}`}
      bordered={false}
      bodyStyle={{ padding: 0, display: 'flex', alignItems: 'center' }}
    >
      {showEditIcon && (
        <div className="absolute top-2 right-2 cursor-pointer" onClick={onEdit}>
          <img src={IconEdit} alt="Edit Icon" className="w-5 h-5" />
        </div>
      )}

      {showCustomRadio && (
        <div className="flex-shrink-0 -ml-4 mb-[68px]" onClick={handleRadioClick}>
          <CustomRadio value={isSelected ? "Selected" : "Not Selected"} />
        </div>
      )}

      <div className="ml-[10px] mt-1">
        <span 
          className={`text-body-md font-bold mb-4 text-[16px] max-w-[160px] overflow-hidden text-ellipsis whitespace-nowrap block ${isBlocked?'blur-sm opacity-90':''}`}
          title={fileName}
        >
          {fileName}
        </span>
        <Text className={`block text-[#52525B] font-medium text-[14px] mb-4 ${isBlocked?'blur-sm opacity-90':''}`}>{jobTitle}</Text>
        <Text className={`block text-[#52525B] font-medium text-[14px] ${isBlocked?'blur-sm opacity-90':''}`}>{description}</Text>
        <div
          style={{
            position: 'absolute',
            right: "10px",
            bottom: "10px"
          }}  
        >
          <img src={IconPdf} />
        </div>
      </div>
    </Card>
  );
};

export default CardCurriculum;