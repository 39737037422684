// src/actions/pages/offers/editKillerQuestionsActions.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  UPDATE_KILLER_QUESTIONS_REQUEST,
  UPDATE_KILLER_QUESTIONS_SUCCESS,
  UPDATE_KILLER_QUESTIONS_FAILURE,
  UpdateKillerQuestionsActionTypes,
} from '../../../constants/offers/EditKillerQuestions';
import FetchWithIP from '../utils/FetchHeaders';
import { GetCreditsUserReducer } from '../common/paquetesUsuarios/PaquetesUsuarios';

export const UpdateKillersQuestionsReducer = (
  oferta_id: number,
  values: any[]
): ThunkAction<Promise<any>, RootState, unknown, UpdateKillerQuestionsActionTypes> => async (dispatch) => {
  dispatch({ type: UPDATE_KILLER_QUESTIONS_REQUEST });

  try {
    const response = await FetchWithIP(`killers-questions/update-by-oferta/${oferta_id}`, {
      method: 'PATCH' },
      (values)
      
 // Enviar directamente el array de preguntas
   );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Error HTTP: ${response.status}`);
    }

    const data = await response.json();
    dispatch(GetCreditsUserReducer())
    dispatch({ type: UPDATE_KILLER_QUESTIONS_SUCCESS, payload: data });
    return data;
  } catch (error: any) {
    console.error('Error en UpdateKillersQuestionsReducer:', error);
    dispatch({ type: UPDATE_KILLER_QUESTIONS_FAILURE, payload: error.message });
    throw error;
  }
};
