import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import LoginP from "../../assets/img/login/login2.svg";
import "../../components/styles/pages/login/Login.css";
import CardEmployment from "../../components/pages/login/CardLoginEmployment";
import CardCandidate from "../../components/pages/login/CardLoginCandidate";

const Register: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div style={{ height: "100vh" }}>
      <Row className="login-container" style={{ height: "100%" }}>
        <Col
          xxl={13} xl={13} md={13}
          // className="login-image"
          // style={{ backgroundColor: "#f0f2f5" }}
          className="sm:hidden sm:bg-red-400 lg:flex"
          style={{
            backgroundColor: "#f0f2f5",
            backgroundImage: "url(" + LoginP + ")",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          {/* <img
            src={LoginP}
            alt="Login"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          /> */}
        </Col>
        <Col
          xxl={11} xl={11} md={11}
          className="py-[60px] px-[40px] md:px-[70px]"
          style={{
            // flexDirection: "column",
            // justifyContent: "flex-start",
          }}
        >
          <Row className="mb-4 justify-end items-center">
            <Col
              xxl={24} xl={24} md={24} className="w-full"
              style={{ justifyItems: 'right' }}
            >
              <h1
                className="font-semibold text-heading-x1 flex"
              >
                <span className="font-sans text-lg font-bold text-[#808080]">¿Ya tienes cuenta?</span>{" "}
                <div
                  className="font-sans text-heading-x1 ml-2 cursor-pointer text-blue3 font-bold"
                  onClick={() => navigate("/login")}
                // to="/login"
                >
                  Inicia sesión
                </div>
              </h1>
            </Col>

            <Col
              xxl={24} xl={24} md={24}
              className="w-full mt-16 mb-11"
            >
              <div>
                <h1 className="text-[45px] font-normal text-[#00476D] font-roboto">
                  Unete a Merēre
                </h1>
                <h1 className="text-body-xl max-w-[560px] leading-[28px] font-roboto">
                  En Merēre, creemos que quienes mejor desempeñan su trabajo merecen
                  encontrarlo más rápidamente.
                </h1>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <div
            >
              <Col span={24} className="">
                <CardCandidate />
              </Col>
              <Col span={24} className="mt-16">
                <CardEmployment />
              </Col>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
