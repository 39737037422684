import { Button, Col, Divider, Input, message, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Sliders from "../../../components/pages/offers/CreateOffers/Slider";
import { ProfileSoftSkill } from '../../../constants/offers/skills/ProfileSkills';
import { AppDispatch } from '../../../redux/store/store';
import { UpdateProfileSkillReducer } from '../../../redux/actions/offers/skills/ProfileSkills';

interface ModalEditarPerfilProps {
	openModal: boolean;
	setOpenModal: any;
}

const styleModal = {
	border: '2px solid #006497',
	borderRadius: '8px',
	overflow: 'hidden',
	padding: '0px',
}

const ModalEditarPerfilSoftSkill: React.FC<ModalEditarPerfilProps> = ({ openModal, setOpenModal }) => {

	const [dataSoftSkills, setDataSoftSkills] = useState<ProfileSoftSkill>()
	const [openModalInput, setOpenModalInput] = useState<boolean>(false)

	const {
		rex_loading,
		rex_profile_skill
	} = useSelector(({ profileSkills }: any) => profileSkills)

	const dispatch = useDispatch<AppDispatch>()

	const initializeSoftSkills = () => {
		setDataSoftSkills(rex_profile_skill)
	}

	const onCloseModal = () => {
		setOpenModal(false)
	}

	const onChangeValue = (value: number, index: number) => {
		setDataSoftSkills((prevState: any) => {
			const updatedDetalles = [...prevState.detalles_preferencias_soft_skills];
			updatedDetalles[index] = {
				...updatedDetalles[index],
				nivel: value,
			}
			return {
				...prevState,
				detalles_preferencias_soft_skills: updatedDetalles,
			}
		})
	}

	const editProfileSoftSkills = async () => {
		if (dataSoftSkills) {
			const respuesta = await dispatch(UpdateProfileSkillReducer(
				dataSoftSkills.id,
				{
					nombre: dataSoftSkills.nombre,
					data: dataSoftSkills.detalles_preferencias_soft_skills
				}
			))
			if (respuesta) {
				message.success('Perfil de Soft Skill editado')
				setOpenModalInput(false)
				setOpenModal(false)
			} else {
				message.error('Error, operacion no realizada')
			}
		}
	}

	useEffect(() => {
		initializeSoftSkills()
	}, [rex_profile_skill])

	return (
		<div>
			<Modal
				open={openModalInput}
				closable={false}
				width={550}
				footer={null}
				style={styleModal}
			>
				<div className='mb-5'>
					<h2 className='text-body-md font-sans mt-2 mb-1'>Nombre de perfil Soft Skills:</h2>
					<Input
						className='font-sans border border-blue3 rounded-[4px]'
						value={dataSoftSkills?.nombre}
						onChange={(e) => {
							if (dataSoftSkills) {
								setDataSoftSkills({ ...dataSoftSkills, nombre: e.target.value })
							}
						}}
					/>
				</div>
				<Row className='flex justify-center'>
					<Col>
						<Button
							onClick={() => setOpenModalInput(false)}
							className="mr-2 principal-nav-notify-button h-[36px] w-[68px] border rounded-[4px] font-sans font-semibold text-sm">
							Cerrar
						</Button>
					</Col>
					<Col>
						<Button
							className='bg-blue3 text-white h-[36px] border rounded-[4px] principal-nav-notify-button2 font-sans font-semibold text-sm'
							onClick={editProfileSoftSkills}
							loading={rex_loading}
						>
							Confirmar
						</Button>
					</Col>
				</Row>
			</Modal>
			<Modal
				title={null}
				open={openModal}
				onCancel={onCloseModal}
				width={584}
				footer={null}
				centered={true}
				maskClosable={false}
				closable={false}
				style={styleModal}
			>
				<div className='pt-[35px] px-[24px]'>
					<h2 className="text-heading-md font-bold font-sans text-black2">
						Edición de perfil de soft skill
					</h2>
					<p className="text-body-md font-sans mt-2">
						Edita tu perfil ideal para esta oferta
					</p>
					<Divider className="bg-black mb-8" />

					<div className="w-full h-auto pb-4">
						{
							dataSoftSkills?.detalles_preferencias_soft_skills.map((apt: any, index: number) => (
								<Sliders
									key={index}
									minLabel={apt.soft_skills.soft_skill}
									maxLabel={apt.soft_skills.soft_skill_c}
									defaultValue={apt.nivel}
									skillId={index}
									onChange={(value) => {
										onChangeValue(value, index)
									}}
									hasValue={true}
								/>
							))
						}
					</div>

					<Row gutter={[12, 12]} className='flex justify-center md:justify-between mt-8 mb-3'>
						<Col>
							<Button className="text-blue3 border border-white h-[36px] principal-nav-notify-buttonS ml-0 shadow-none font-sans font-semibold text-sm"
								onClick={initializeSoftSkills}
							>
								Resetear todos los parámetros
							</Button>
						</Col>
						<Col className='w-full md:w-auto'>
							<Row className='flex justify-center'>
								<Col>
									<Button
										onClick={onCloseModal}
										className="mr-2 principal-nav-notify-button h-[36px] w-[68px] border rounded-[4px] font-sans font-semibold text-sm">
										Cerrar
									</Button>
								</Col>
								<Col>
									<Button
										className='bg-blue3 text-white h-[36px] border rounded-[4px] principal-nav-notify-button2 font-sans font-semibold text-sm'
										onClick={() => {
											if (dataSoftSkills) {
												setDataSoftSkills({ ...dataSoftSkills, nombre: rex_profile_skill.nombre })
												setOpenModalInput(true)
											}
										}}
										loading={rex_loading}
									>
										Confirmar
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Modal>
		</div>
	)
}

export default ModalEditarPerfilSoftSkill