// Constants
export const GET_POSTULACION_BY_ID_REQUEST = 'GET_POSTULACION_BY_ID_REQUEST';
export const GET_POSTULACION_BY_ID_SUCCESS = 'GET_POSTULACION_BY_ID_SUCCESS';
export const GET_POSTULACION_BY_ID_FAILURE = 'GET_POSTULACION_BY_ID_FAILURE';

// Detailed interface for Postulacion
export interface Postulacion {
  id: number;
  usuarios: {
    id: number;
    usuario: string;
    imagen: string | null;
    ubicacion: string;
    cargo: string;
    personas: {
      nombre: string;
      apellido_paterno: string;
      apellido_materno?: string;
    };
    soft_skills_usuarios: Array<{
      soft_skills: {
        soft_skill: string;
      };
      nivel: number;
      porcentaje: number;
    }>;
    experiencias_laborales_usuarios: Array<{
      cargo: string;
      nombre_empresa: string;
      fecha_inicio: string;
      fecha_fin: string | null;
    }>;
    educacion_usuarios: Array<{
      carrera: string;
      nombre_centro_educativo: string;
      fecha_inicio: string;
      fecha_final: string | null;
    }>;
    cvs_usuarios: Array<{
      cv: string;
      nombre: string;
    }>;
    aptitudes_usuarios: Array<{
      aptitudes: {
        aptitud: string;
      };
    }>;
    promedioValoraciones: number;
  };
  ofertas: {
    soft_skills_oferta: Array<{
      soft_skills: {
        soft_skill: string;
      };
      porcentaje: number;
    }>;
  };
}

// Action Types
export interface GetPostulacionByIdRequestAction {
  type: typeof GET_POSTULACION_BY_ID_REQUEST;
}

export interface GetPostulacionByIdSuccessAction {
  type: typeof GET_POSTULACION_BY_ID_SUCCESS;
  payload: {
    data: Postulacion;
  };
}

export interface GetPostulacionByIdFailureAction {
  type: typeof GET_POSTULACION_BY_ID_FAILURE;
  payload: string;
}

export type GetPostulacionByIdActionTypes =
  | GetPostulacionByIdRequestAction
  | GetPostulacionByIdSuccessAction
  | GetPostulacionByIdFailureAction;