import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VALIDATE_RESET_CODE_REQUEST,
  VALIDATE_RESET_CODE_SUCCESS,
  VALIDATE_RESET_CODE_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  ForgotPasswordActionTypes,
  ValidateResetCodeActionTypes,
  ResetPasswordActionTypes,
} from '../../../constants/auth/PasswordReset';
import FetchWithIP from '../utils/FetchHeaders';

// Forgot Password Actions
export const forgotPasswordRequestReducer = (): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_REQUEST,
});

export const forgotPasswordSuccessReducer = (message: string): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: { message },
});

export const forgotPasswordFailureReducer = (error: string): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const ForgotPasswordReducer = (
  email: string
): ThunkAction<Promise<any>, RootState, unknown, ForgotPasswordActionTypes> => async (dispatch) => {
  dispatch(forgotPasswordRequestReducer());

  try {
    const response = await FetchWithIP('auth/forgot-password', {
      method: 'POST',
    }, { email });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(forgotPasswordSuccessReducer(responseData.message));
    return responseData;
  } catch (error: any) {
    dispatch(forgotPasswordFailureReducer(error.message || 'Error al enviar el código'));
    return { error: error.message };
  }
};

// Validate Reset Code Actions
export const validateResetCodeRequestReducer = (): ValidateResetCodeActionTypes => ({
  type: VALIDATE_RESET_CODE_REQUEST,
});

export const validateResetCodeSuccessReducer = (message: string): ValidateResetCodeActionTypes => ({
  type: VALIDATE_RESET_CODE_SUCCESS,
  payload: { message },
});

export const validateResetCodeFailureReducer = (error: string): ValidateResetCodeActionTypes => ({
  type: VALIDATE_RESET_CODE_FAILURE,
  payload: error,
});

export const ValidateResetCodeReducer = (
  codigo_verificacion: number
): ThunkAction<Promise<any>, RootState, unknown, ValidateResetCodeActionTypes> => async (dispatch) => {
  dispatch(validateResetCodeRequestReducer());

  try {
    const response = await FetchWithIP('auth/validate-reset-code', {
      method: 'POST',
    }, { codigo_verificacion });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(validateResetCodeSuccessReducer(responseData.message));
    return responseData;
  } catch (error: any) {
    dispatch(validateResetCodeFailureReducer(error.message || 'Error al validar el código'));
    return { error: error.message };
  }
};

// Reset Password Actions
export const resetPasswordRequestReducer = (): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccessReducer = (message: string): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { message },
});

export const resetPasswordFailureReducer = (error: string): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

export const ResetPasswordReducer = (
  codigo_verificacion: number,
  nueva_contrasena: string
): ThunkAction<Promise<any>, RootState, unknown, ResetPasswordActionTypes> => async (dispatch) => {
  dispatch(resetPasswordRequestReducer());

  try {
    const response = await FetchWithIP('auth/reset-password', {
      method: 'POST',
    }, { codigo_verificacion, nueva_contrasena });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(resetPasswordSuccessReducer(responseData.message));
    return responseData;
  } catch (error: any) {
    dispatch(resetPasswordFailureReducer(error.message || 'Error al restablecer la contraseña'));
    return { error: error.message };
  }
};
