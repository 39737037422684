export const FETCH_GET_APPLICATIONS_REQUEST = 'FETCH_GET_APPLICATIONS_REQUEST';
export const FETCH_GET_APPLICATIONS_SUCCESS = 'FETCH_GET_APPLICATIONS_SUCCESS';
export const FETCH_GET_APPLICATIONS_FAILURE = 'FETCH_GET_APPLICATIONS_FAILURE';
export const SET_FILTER_PARAMS_APLICATIONS = 'SET_FILTER_PARAMS_APLICATIONS';
export const SET_FILTER_PARAMS_APLICATIONS_TWO = 'SET_FILTER_PARAMS_APLICATIONS_TWO';


export interface FetchAplicationsRequestAction {
    type: typeof FETCH_GET_APPLICATIONS_REQUEST;
  }

export interface FetchAplicationsSuccessAction {
    type: typeof FETCH_GET_APPLICATIONS_SUCCESS;
    payload: {
      data: any[]
      meta: { total: number; limit: number; page: number }; 
    };
  }

export interface FetchAplicationsFailureAction {
    type: typeof FETCH_GET_APPLICATIONS_FAILURE;
    payload: string;
  }

interface SetFilterParamsAplicationsAction {
    type: typeof SET_FILTER_PARAMS_APLICATIONS;
    payload: any; 
}

interface SetFilterParamsAplicationsTwoAction {
  type: typeof SET_FILTER_PARAMS_APLICATIONS_TWO;
  payload: any; 
}


export type AplicationsActionTypes =
  | FetchAplicationsRequestAction
  | FetchAplicationsSuccessAction
  | FetchAplicationsFailureAction
  | SetFilterParamsAplicationsAction
  | SetFilterParamsAplicationsTwoAction;