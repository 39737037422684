import { Modal } from 'antd';
import React from 'react';

const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const ModalVerCv: React.FC<{
  nombre: string;
  showModal: boolean;
  setShowModal: any;
  cv: string;
}> = ({ nombre, showModal, setShowModal, cv }) => {
  return (
    <Modal
      title={"CV de " + nombre}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={null} // Esto elimina los botones del modal
      width={"80%"}
    >
      <iframe
        src={API_BASE_URL_EXACT && API_BASE_URL_EXACT + cv}
        width={"100%"}
        height={"650px"}
      />
    </Modal>
  );
};

export default ModalVerCv;
