import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import FetchWithIP from '../../../utils/FetchHeaders';
import {
  GET_USER_RATINGS_BY_TOKEN_REQUEST,
  GET_USER_RATINGS_BY_TOKEN_SUCCESS,
  GET_USER_RATINGS_BY_TOKEN_FAILURE,
  GetUserRatingsByTokenActionTypes,
  UserRatingByToken,
  GET_USER_RATINGS_BY_ID_REQUEST,
  GET_USER_RATINGS_BY_ID_SUCCESS,
  GET_USER_RATINGS_BY_ID_FAILURE,
  GetUserRatingsByIdActionTypes,
} from '../../../../../constants/pages/myPortal/ratings/GetRatingPortal';

export const getUserRatingsByTokenRequest = (): GetUserRatingsByTokenActionTypes => ({
  type: GET_USER_RATINGS_BY_TOKEN_REQUEST
});

export const getUserRatingsByTokenSuccess = (data: UserRatingByToken[]): GetUserRatingsByTokenActionTypes => ({
  type: GET_USER_RATINGS_BY_TOKEN_SUCCESS,
  payload: data
});

export const getUserRatingsByTokenFailure = (error: string): GetUserRatingsByTokenActionTypes => ({
  type: GET_USER_RATINGS_BY_TOKEN_FAILURE,
  payload: error
});

export const getUserRatingsByIdRequest = (): GetUserRatingsByIdActionTypes => ({
  type: GET_USER_RATINGS_BY_ID_REQUEST,
});

export const getUserRatingsByIdSuccess = (data: UserRatingByToken[]): GetUserRatingsByIdActionTypes => ({
  type: GET_USER_RATINGS_BY_ID_SUCCESS,
  payload: data,
});

export const getUserRatingsByIdFailure = (error: string): GetUserRatingsByIdActionTypes => ({
  type: GET_USER_RATINGS_BY_ID_FAILURE,
  payload: error,
});

export const GetUserRatingsByTokenReducer = (
  limit: number = 20,
  sortOrder: "asc" | "desc" = "desc",   // Parámetero para definir el orden de clasificación
  sortColumn: string = "createdAt"      // Parámetero para definir la columna por la que se ordena
): ThunkAction<Promise<void>, RootState, unknown, GetUserRatingsByTokenActionTypes> => async (dispatch) => {
  dispatch(getUserRatingsByTokenRequest());

  try {
    // Construimos la URL con los parámetros limit, sortOrder y sortColumn
    const url = `valoraciones-usuarios/findByToken?limit=${limit}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`;

    const response = await FetchWithIP(url, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    dispatch(getUserRatingsByTokenSuccess(data.data));
  } catch (error: any) {
    dispatch(getUserRatingsByTokenFailure(error.message || 'Ha ocurrido un error al obtener las valoraciones'));
  }
};


export const GetUserRatingsByIdReducer = (
  userId: number
): ThunkAction<Promise<{ data: { valoracion: string; valoraciones_usuarios: any[] } }>, RootState, unknown, GetUserRatingsByIdActionTypes> => async (dispatch) => {
  dispatch(getUserRatingsByIdRequest());

  try {
    const response = await FetchWithIP(`valoraciones-usuarios/findByUser/${userId}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    dispatch(getUserRatingsByIdSuccess(data.data));
    return data; // Devuelve los datos incluyendo valoraciones_usuarios
  } catch (error: any) {
    dispatch(getUserRatingsByIdFailure(error.message || 'Ha ocurrido un error al obtener las valoraciones por usuario'));
    throw error;
  }
};

