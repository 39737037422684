import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_SAVE_PROFILE_SKILLS_FAILURE,
  FETCH_SAVE_PROFILE_SKILLS_REQUEST,
  FETCH_SAVE_PROFILE_SKILLS_SUCCESS,
  FETCH_PROFILES_SKILLS_REQUEST,
	FETCH_PROFILES_SKILLS_SUCCESS,
	FETCH_PROFILES_SKILLS_FAILURE,
  DELETE_PROFILE_SKILLS_REQUEST,
  DELETE_PROFILE_SKILLS_SUCCESS,
  DELETE_ROFILE_SKILLS_FAILURE,
  ProfileSkillsActionTypes,
  FETCH_PROFILE_SKILL_REQUEST,
  FETCH_PROFILE_SKILL_SUCCESS,
  FETCH_PROFILE_SKILL_FAILURE,
  UPDATE_PROFILE_SKILL_REQUEST,
	UPDATE_PROFILE_SKILL_SUCCESS,
	UPDATE_ROFILE_SKILL_FAILURE,
  ProfileSoftSkill
} from '../../../../constants/offers/skills/ProfileSkills';
import FetchWithIP from '../../utils/FetchHeaders';

interface ProfileSkills {
  id: number,
  soft_skill: string,
  value: number
}

interface DataProfileSkills {
  nombre: string
  data: ProfileSkills[]
}
export const fetchProfileSkillRequestReducer = (): ProfileSkillsActionTypes => ({
  type: FETCH_PROFILE_SKILL_REQUEST,
})

export const fetchProfileSkillSuccessReducer = (data:ProfileSoftSkill): ProfileSkillsActionTypes => ({
  type: FETCH_PROFILE_SKILL_SUCCESS,
  payload: data
})

export const fetchProfileSkillFailureReducer = (error: string): ProfileSkillsActionTypes => ({
  type: FETCH_PROFILE_SKILL_FAILURE,
  payload: error,
})

export const fetchSaveProfileSkillsRequestReducer = (): ProfileSkillsActionTypes => ({
  type: FETCH_SAVE_PROFILE_SKILLS_REQUEST,
})

export const fetchSaveProfileSkillsSuccessReducer = (): ProfileSkillsActionTypes => ({
  type: FETCH_SAVE_PROFILE_SKILLS_SUCCESS,
})

export const fetchSaveProfileSkillsFailureReducer = (error: string): ProfileSkillsActionTypes => ({
  type: FETCH_SAVE_PROFILE_SKILLS_FAILURE,
  payload: error,
})

export const fetchProfilesSkillsRequestReducer = (): ProfileSkillsActionTypes => ({
  type: FETCH_PROFILES_SKILLS_REQUEST,
})

export const fetchProfilesSkillsSuccessReducer = (data:[]): ProfileSkillsActionTypes => ({
  type: FETCH_PROFILES_SKILLS_SUCCESS,
  payload: data
})

export const fetchProfilesSkillsFailureReducer = (error: string): ProfileSkillsActionTypes => ({
  type: FETCH_PROFILES_SKILLS_FAILURE,
  payload: error,
});

export const DeleteProfileSkillsRequestReducer = (): ProfileSkillsActionTypes => ({
  type: DELETE_PROFILE_SKILLS_REQUEST,
})

export const DeleteProfileSkillsSuccessReducer = (): ProfileSkillsActionTypes => ({
  type: DELETE_PROFILE_SKILLS_SUCCESS,
})

export const DeleteProfileSkillsFailureReducer = (error: string): ProfileSkillsActionTypes => ({
  type: DELETE_ROFILE_SKILLS_FAILURE,
  payload: error,
})

export const UpdateProfileSkillRequestReducer = (): ProfileSkillsActionTypes => ({
  type: UPDATE_PROFILE_SKILL_REQUEST,
})

export const UpdateProfileSkillSuccessReducer = (): ProfileSkillsActionTypes => ({
  type: UPDATE_PROFILE_SKILL_SUCCESS,
})

export const UpdateProfileSkillFailureReducer = (error: string): ProfileSkillsActionTypes => ({
  type: UPDATE_ROFILE_SKILL_FAILURE,
  payload: error,
})

export const FetchProfileSkillReducer = (id:number): ThunkAction<Promise<any>, RootState, unknown, ProfileSkillsActionTypes> => async (dispatch) => {
  dispatch(fetchProfileSkillRequestReducer());
  
  try {
    const response = await FetchWithIP(`soft-skills-preferencias/${id}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.respuesta) {
      dispatch(fetchProfileSkillSuccessReducer(responseData.data))
      return true
    }
  } catch (error) {
    dispatch(fetchProfileSkillFailureReducer('Error al guardar la informacion'));
    return false
  }
}


export const DeleteProfileSkillsReducer = (id:number): ThunkAction<Promise<any>, RootState, unknown, ProfileSkillsActionTypes> => async (dispatch) => {
  dispatch(DeleteProfileSkillsRequestReducer());

  try {
    const response = await FetchWithIP(`soft-skills-preferencias/${id}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.respuesta) {
      dispatch(DeleteProfileSkillsSuccessReducer())
      dispatch(GetProfilesSkillsReducer())
      return true
    }
  } catch (error) {
    dispatch(DeleteProfileSkillsFailureReducer('Error al guardar la informacion'));
    return false
  }
}

export const UpdateProfileSkillReducer = (id:number, data:{}): ThunkAction<Promise<any>, RootState, unknown, ProfileSkillsActionTypes> => async (dispatch) => {
  dispatch(UpdateProfileSkillRequestReducer());
  
  try {
    const response = await FetchWithIP(`soft-skills-preferencias/${id}`, {
      method: 'PATCH',
    }, data);

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.respuesta) {
      dispatch(UpdateProfileSkillSuccessReducer())
      dispatch(GetProfilesSkillsReducer())
      return true
    }
  } catch (error) {
    dispatch(UpdateProfileSkillFailureReducer('Error al guardar la informacion'));
    return false
  }
}

export const SaveProfileSkillsReducer = (data: DataProfileSkills): ThunkAction<Promise<any>, RootState, unknown, ProfileSkillsActionTypes> => async (dispatch) => {
  dispatch(fetchSaveProfileSkillsRequestReducer());

  try {
    const response = await FetchWithIP('soft-skills-preferencias', {
      method: 'POST',
    }, data);

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.respuesta) {
      dispatch(fetchSaveProfileSkillsSuccessReducer())
      return true

    } else {
      dispatch(fetchSaveProfileSkillsFailureReducer('Error al guardar la informacion'));
      return false
    }

  } catch (error) {
    dispatch(fetchSaveProfileSkillsFailureReducer('Error al guardar la informacion'));
    return false
  }
}

export const GetProfilesSkillsReducer = (): ThunkAction<Promise<any>, RootState, unknown, ProfileSkillsActionTypes> => async (dispatch) => {
  dispatch(fetchProfilesSkillsRequestReducer());

  try {
    const response = await FetchWithIP('soft-skills-preferencias', {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.respuesta) {
      dispatch(fetchProfilesSkillsSuccessReducer(responseData.data))
    } else {
      dispatch(fetchSaveProfileSkillsFailureReducer('Error al guardar la informacion'));
    }

  } catch (error) {
    dispatch(fetchProfilesSkillsFailureReducer('Error al guardar la informacion'));
  }
}
