import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import logo from '../../../assets/logo/logo.png';
import IconNotification from '../../../assets/icons/notification.svg';
import '../../styles/pages/principalNav/PrincipalNav.css';
import Notifications from '../../../pages/notifications/NotificationsRoute';
import ControlPanel from '../../../pages/controlPanel/ControlPanel';
import SearchCV from '../../../pages/searchCV/SearchCV';
import Offers from '../../../pages/offers/Offers';
import ValuationModal from '../../../components/pages/modals/ModalQR';  // Importa el modal
import PortalCompany from '../../../pages/myPortal/PortalCompany';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { GetCreditsUserReducer } from '../../../redux/actions/common/paquetesUsuarios/PaquetesUsuarios';


const HeaderOffers = () => {
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false); // Estado para controlar la visibilidad del modal
  const { rex_user } = useSelector(({ auth }: any) => auth);

  const dispatch = useDispatch<AppDispatch>()

  const isActive = (path: any) => location.pathname.startsWith(path);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(GetCreditsUserReducer())
  }, [])

  return (
    <div className="principal-nav2">
      <header className="principal-nav-header">
        <div className="flex justify-center">
          <div className="w-full 2x2:w-[1330px]">
            <Row gutter={[16, 16]} justify="space-between" align="middle" className="principal-nav-header-row mb-4">

              <Col className="principal-nav-logo-col">
                <Link to="/home">
                  <img src={logo} alt="Merere Logo" className="principal-nav-logo" />
                </Link>
              </Col>

              <Col className="principal-nav-links-col justify-center">
                <nav className="principal-nav-nav2">
                  <Link to="/controlPanel" className={`principal-nav-link text-center ${isActive('/controlPanel') ? 'active' : ''}`}>
                    Panel de Control
                  </Link>
                  <Link to="/searchCV" className={`principal-nav-link text-center ${isActive('/searchCV') ? 'active' : ''}`}>
                    Buscar CV
                  </Link>
                  <Link to="/offers" className={`principal-nav-link text-center ${isActive('/offers') || location.pathname.startsWith('/offer') ? 'active' : ''}`}>
                    Ofertas
                  </Link>

                  <Link to="/PortalCompany" className={`principal-nav-link text-center ${isActive('/PortalCompany') ? 'active' : ''}`}>
                    Mi Portal
                  </Link>

                  <Link to="/memberships" className={`principal-nav-link text-center ${isActive('/memberships') ? 'active' : ''}`}>
                    Precios
                  </Link>
                </nav>
              </Col>

              <Col className="principal-nav-action-buttons-col w-full justify-center xl:w-auto  xl:justify-end">
                <Row justify="end" className="principal-nav-action-buttons">
                  <Col>
                    <Link to="/notifications/all">
                      <Button className="principal-nav-bell-button-1 !w-[44px] h-[44px]" icon={<img src={IconNotification} alt="Notification" />} />
                    </Link>
                  </Col>
                  <Col>
                    <Button
                      className="principal-nav-notify-button"
                      onClick={showModal}  // Abre el modal al hacer clic
                    >
                      Permite que te valoren
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </header>

      <Routes>
        <Route path="/controlPanel" element={<ControlPanel />} />
        {/* <Route path="/searchCV" element={<SearchCV />} /> */}
        <Route path="/offers" element={<Offers />} />
        <Route path="/PortalCompany" element={<PortalCompany />} />
      </Routes>

      {/* Modal para "Permite que te valoren" */}

      <ValuationModal
        visible={isModalVisible}
        onClose={closeModal}
        link={rex_user?.link_valoracion}
        tipoUsuarioId={rex_user?.tipo_usuario_id} // Adjust this based on your logic
      />

    </div>
  );
};

export default HeaderOffers;
