import React from 'react'
import { Button } from 'antd';
import '../../styles/components/cards/CardStats.css'

interface CardEmpleoProps {
  text: string | number;
  iconButton?: JSX.Element;
  customClassName?: string;
}

const CardStats: React.FC<CardEmpleoProps> = ({
  text,
  iconButton = null,
  customClassName = ''
}) => {
  return (
    <Button
      icon={iconButton ? iconButton : null}
      className={`button-stats font-medium text-xs rounded-full px-2 font-sans text-wrap py-[2px] ${customClassName}`}
    >{text}</Button>
  )
}

export default CardStats