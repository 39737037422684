import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Input, Skeleton, Dropdown, Menu, Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetSavedOffersReducer } from "../../../redux/actions/pages/company/SavedOffers"; // Acción para obtener ofertas guardadas
import { deleteOfferReducer } from "../../../redux/actions/pages/company/DeleteOffers";
import { draftOfferReducer } from "../../../redux/actions/pages/company/DraftOffer";
import { RootState, AppDispatch } from "../../../redux/store/store";
import { finalizeOfferReducer } from "../../../redux/actions/pages/company/FinalizeOfferReducer";
import CardOffers from "../../../components/pages/offers/CardOffers";
import { GetOffersReducer } from "../../../redux/actions/pages/company/Offers";
import { useLocation } from 'react-router-dom';
import { searchSavedOffersAction } from "../../../redux/actions/pages/company/SearchSaveOffers";
import IconClosed from "../../../assets/icons/IconClosed.svg";

interface SavedOffersProps {
  searchInitiated: boolean;
}

const SavedOffers: React.FC<SavedOffersProps> = ({ searchInitiated }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [modalType, setModalType] = useState<string | null>(null);

  const searchTxt = queryParams.get('cargo')

  // const { rex_savedoffers: offers, rex_loading: loading, rex_error: error } = useSelector(
  //   (state: RootState) => state.savedOffers
  // );
  const { rex_saved_offers: searchResults, rex_loading: searchLoading } = useSelector(
    (state: RootState) => state.searchSavedOffers
  );

  const { rex_offers: offers, rex_loading: loading, rex_error: error } = useSelector((state: RootState) => state.offers);

  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(searchSavedOffersAction(searchTxt ? searchTxt : '', 1, 10)); // Búsqueda de ofertas guardadas
  }, [dispatch, searchTxt]);


  const showDeleteModal = (id: number) => {
    setSelectedOfferId(id);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = async () => {
    if (selectedOfferId !== null) {
      await dispatch(deleteOfferReducer(selectedOfferId));
      setIsDeleteModalVisible(false);
      dispatch(searchSavedOffersAction(searchTxt ? searchTxt : '', 1, 10));
    }
  };
  const showModal = (type: string, id: number) => {
    setSelectedOfferId(id);
    setModalType(type);
    setIsModalVisible(true); // Muestra el modal
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const getModalTitle = () => {
    switch (modalType) {
      case "edit":
        return "Confirmar edición";
      case "create-related":
        return "Confirmar creación de oferta relacionada";
      case "delete":
        return "Confirmar eliminación";
      case "finalize":
        return "Confirmar finalización";
      case "draft":
        return "Confirmar mover a borrador";
      default:
        return "Confirmar acción";
    }
  };

  const getModalContent = () => {
    switch (modalType) {
      case "edit":
        return "¿Estás seguro de que deseas editar esta oferta?";
      case "create-related":
        return "¿Estás seguro de que deseas crear una oferta relacionada?";
      case "delete":
        return "¿Estás seguro de que deseas eliminar esta oferta?";
      case "finalize":
        return "¿Estás seguro de que deseas finalizar esta oferta?";
      case "draft":
        return "¿Estás seguro de que deseas mover esta oferta a borrador?";
      default:
        return "¿Estás seguro de que deseas realizar esta acción?";
    }
  };

  const handleModalOk = async () => {
    if (selectedOfferId !== null) {
      switch (modalType) {
        case "delete":
          await dispatch(deleteOfferReducer(selectedOfferId));
          break;
        case "finalize":
          await dispatch(finalizeOfferReducer(selectedOfferId));
          break;
        case "draft":
          await dispatch(draftOfferReducer(selectedOfferId));
          break;
        case "edit":
          navigate(`/offer-edit/${selectedOfferId}`);
          break;
        case "create-related":
          console.log(`Crear oferta relacionada con ${selectedOfferId}`);
          break;
      }
      setIsModalVisible(false);
      dispatch(GetOffersReducer(1, 10)); // Actualizar la lista después de realizar una acción
    }
  };

  const handleMenuClick = (key: string, offerId: number) => {
    showModal(key, offerId);
  };


  const menu = (offerId: number) => (
    <Menu
      onClick={({ key }) => handleMenuClick(key, offerId)}
      items={[
        { label: "Editar oferta", key: "edit" },
        { label: "Crear oferta relacionada", key: "create-related" },
        { label: "Eliminar oferta", key: "delete" },
        { label: "Finalizar oferta", key: "finalize" }
      ]}
    />
  );

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24}>
          <div className="pt-3">
            <h2 className="text-heading-md font-bold mb-4">
              {searchTxt ? "Resultados de búsqueda" : "Listado de ofertas guardadas"}
            </h2>
            {error && <p>Error al cargar las ofertas: {error}</p>}
            {loading ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : offers.length === 0 ? (
              <p>No hay ofertas guardadas disponibles.</p>
            ) : (
              offers.map((offer: any, index: number) => (
                <div key={index} className="pb-[8px]" style={{ position: "relative" }}>
                  <CardOffers
                    id={offer.id}
                    title={offer.cargo}
                    subtitle={offer.descripcion}
                    location={`${offer.ubi_provincia}, ${offer.ubi_poblacion}`}
                    activeDate={offer.createdAt}
                    inscritos={offer.inscritos || 0}
                    seleccionados={offer.seleccionados || 0}
                    descartados={offer.descartados || 0}
                    loading={loading}
                    onClick={() => navigate(`/offer/${offer.id}`)}
                    onDelete={() => showDeleteModal(offer.id)}
                  />
                  {/* Dropdown Menu */}
                  <div style={{ position: "absolute", right: 20, top: 20 }}>
                    <Dropdown overlay={menu(offer.id)} trigger={['click']}>
                      <Button
                        type="text"
                        style={{
                          padding: 0,
                          color: "#006497",
                          fontSize: "20px",
                          fontWeight: "bold",
                          lineHeight: "1"
                        }}
                        icon={<MoreOutlined rotate={90} />}
                      />
                    </Dropdown>
                  </div>
                </div>
              ))
            )}
          </div>
        </Col>
      </Row>

      <Modal
        visible={isModalVisible}
        closable={false}
        footer={null}
        width={800}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={handleModalCancel}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16, 24]} className='text-center mt-7'>
          <Col span={24}>
            <p className="font-bold text-2xl font-sans">{getModalTitle()}</p>
          </Col>
          <Col span={24}>
            <p className="font-sans font-medium text-sm">{getModalContent()}</p>
          </Col>
        </Row>
        <Row className='flex justify-center mb-5'>
          <div className="flex justify-center mx-[114px] mt-[38px]">
            <Button
              onClick={handleModalCancel}
              className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleModalOk}
              className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
            >
              Aceptar
            </Button>
          </div>
        </Row>
      </Modal>
    </div>
  );
};

export default SavedOffers;