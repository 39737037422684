import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import axios from 'axios';
import {
  FETCH_EMPLOYMENTS_REQUEST,
  FETCH_EMPLOYMENTS_SUCCESS,
  FETCH_EMPLOYMENTS_FAILURE,
  FETCH_GET_OFFER_REQUEST,
  FETCH_GET_OFFER_FAILURE,
  FETCH_GET_OFFER_SUCCESS,
  SET_FILTER_PARAMS_EMPLOYMENTS,
  EmploymentsActionTypes

} from '../../../constants/pages/Employment';
import FetchWithIP from '../utils/FetchHeaders';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchEmploymentsRequest = (): EmploymentsActionTypes => ({
  type: FETCH_EMPLOYMENTS_REQUEST,
});

export const fetchEmploymentSuccess = (data: any[], meta: { total: number; limit: number; page: number }): EmploymentsActionTypes => ({
  type: FETCH_EMPLOYMENTS_SUCCESS,
  payload: { data, meta },
});

export const fetchEmploymentFailure = (error: string): EmploymentsActionTypes => ({
  type: FETCH_EMPLOYMENTS_FAILURE,
  payload: error,
});


export const setFilterParamsEmployment = (filters: any): EmploymentsActionTypes => ({
  type: SET_FILTER_PARAMS_EMPLOYMENTS,
  payload: filters,
});

export const FetchEmploymentsReducer = (
  reset = true,
  page?: number,
  limit?: number,
  cargoFilter?: string,
  valoraciones_empresasArray?: number[],
  killersQuestionsEmpty?: boolean,
  cargo?: string,
  sector?: string,
  pais?: string,
  ciudad?: string,
): ThunkAction<Promise<any>, RootState, unknown, EmploymentsActionTypes> => async (dispatch) => {
  if (reset) dispatch(fetchEmploymentsRequest());

  const queryParams = new URLSearchParams({
    sortOrder: 'desc',
    sortColumn: 'id',
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
    ...(cargo !== undefined && { cargo: cargo.toString() }),
    ...(sector !== undefined && { sector: sector.toString() }),
    ...(pais !== undefined && { ubi_provincia: pais.toString() }),
    ...(ciudad !== undefined && { ubi_poblacion: ciudad.toString() }),
    // ...(cargoFilter !== undefined && { cargoFilter: cargoFilter?.toString() }),
    ...(cargoFilter ? { cargoFilter: cargoFilter.toString() } : {}),
  }).toString();

  try {
    if (valoraciones_empresasArray) {
      dispatch(setFilterParamsEmployment(valoraciones_empresasArray));
    }
    if (killersQuestionsEmpty) {
      dispatch(setFilterParamsEmployment(killersQuestionsEmpty));
    }
    if (pais) {
      dispatch(setFilterParamsEmployment(pais));
    }
    if (sector) {
      dispatch(setFilterParamsEmployment(sector));
    }

    const response = await FetchWithIP(`ofertas/all?${queryParams}`, {
      method: 'POST'
    }, {
      valoraciones_empresasArray,
      killersQuestionsEmpty
    }
    )

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data && responseData.data.length > 0) {
      const { total, limit, page } = responseData.meta;
      dispatch(fetchEmploymentSuccess(
        responseData.data, { total, limit, page }));
    } else {      
      dispatch(fetchEmploymentSuccess(
        [], { total: 0, limit: 10, page: 1 }));

      throw new Error('Datos vacíos en la respuesta');
    }

    return true
  } catch (error: any) {
    dispatch(fetchEmploymentFailure(error.message || 'Error al cargar '));
    return { error: error.message };
  }

};

export const GetOfferReducer = (oferta_id: number): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch: Dispatch, getState) => {
  const {
    rex_user
  } = getState().auth

  dispatch({ type: FETCH_GET_OFFER_REQUEST });

  let usuario_id = 0;
  if (rex_user) usuario_id = rex_user.id;

  try {
    const response = await FetchWithIP(`ofertas/${oferta_id}/byUser`, {
      method: 'GET'
    }).then(res => res.json());
    dispatch({
      type: FETCH_GET_OFFER_SUCCESS,
      payload: response.data
    });

  } catch (error) {
    dispatch({ type: FETCH_GET_OFFER_FAILURE, error });
    console.error('Error al obtener las ofertas', error);
  }
};