import { 
    FETCH_USER_SEARCHES_REQUEST,
    FETCH_USER_SEARCHES_SUCCESS,
    FETCH_USER_SEARCHES_FAILURE,
    UserSearchesActionTypes,
    userSearchesDto
} from "../../../../constants/common/userSearches/UserSearches";

interface UserSearchesState {
    rex_loading: boolean;
    rex_user_searches: userSearchesDto[];
    rex_error: string | null;
}

const initialState: UserSearchesState = {
    rex_loading: false,
    rex_user_searches: [],
    rex_error: null,
}

const UserSearchesReducer = (state = initialState, action: UserSearchesActionTypes): UserSearchesState => {
  switch (action.type) {
    case FETCH_USER_SEARCHES_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case FETCH_USER_SEARCHES_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_user_searches: action.payload.data,
        rex_error: null,
      };
    case FETCH_USER_SEARCHES_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };
    default:
      return state;
  }
};

export default UserSearchesReducer;
