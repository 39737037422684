// Thunk para eliminar un registro de educación
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import FetchWithIP from '../../../utils/FetchHeaders';
import {
    DELETE_EDUCATION_REQUEST,
    DELETE_EDUCATION_SUCCESS,
    DELETE_EDUCATION_FAILURE,
    DeleteEducationActionTypes,
  } from '../../../../../constants/pages/myPortal/education/DeleteEducation';

export const deleteEducationRequestReducer = (): DeleteEducationActionTypes => ({
    type: DELETE_EDUCATION_REQUEST,
  });
  
  export const deleteEducationSuccessReducer = (id: number): DeleteEducationActionTypes => ({
    type: DELETE_EDUCATION_SUCCESS,
    payload: { id },
  });
  
  export const deleteEducationFailureReducer = (error: string): DeleteEducationActionTypes => ({
    type: DELETE_EDUCATION_FAILURE,
    payload: error,
  });


export const DeleteEducationReducer = (
  id: number
): ThunkAction<Promise<any>, RootState, unknown, DeleteEducationActionTypes> => async (dispatch) => {
  dispatch(deleteEducationRequestReducer());

  try {
    const response = await FetchWithIP(`educacion-usuarios/${id}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    dispatch(deleteEducationSuccessReducer(id));
    return { payload: { id } };
  } catch (error: any) {
    dispatch(deleteEducationFailureReducer(error.message || 'Error al eliminar la educación'));
    return { error: error.message };
  }
};
