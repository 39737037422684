import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  SEARCH_CLOSED_OFFERS_REQUEST,
  SEARCH_CLOSED_OFFERS_SUCCESS,
  SEARCH_CLOSED_OFFERS_FAILURE,
  ClosedOfferActionTypes,
} from '../../../../constants/company/SearchFinishedOffers';
import FetchWithIP from '../../utils/FetchHeaders';
import { fetchOffersSuccessReducer } from './Offers';

// Action Creators para la búsqueda
export const searchClosedOffersRequest = (): ClosedOfferActionTypes => ({
  type: SEARCH_CLOSED_OFFERS_REQUEST,
});

export const searchClosedOffersSuccess = (data: any[], meta: { total: number; limit: number; page: number }): ClosedOfferActionTypes => ({
  type: SEARCH_CLOSED_OFFERS_SUCCESS,
  payload: { data, meta },
});

export const searchClosedOffersFailure = (error: string): ClosedOfferActionTypes => ({
  type: SEARCH_CLOSED_OFFERS_FAILURE,
  payload: error,
});

// Thunk para buscar ofertas finalizadas por cargo
export const searchClosedOffersAction = (cargo: string, page: number = 1, limit: number = 10): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(searchClosedOffersRequest());
  try {
    const response = await FetchWithIP(`ofertas/findAllStateFinalizadoToken?cargo=${cargo}&page=${page}&limit=${limit}&sortOrder=desc&sortColumn=id`, { method: 'GET' });
    if (!response.ok) throw new Error(`Error HTTP: ${response.status}`);

    const responseData = await response.json();
    if (Array.isArray(responseData.data)) {
      const { total, limit, page } = responseData.meta;
      dispatch(fetchOffersSuccessReducer(
        responseData.data,
        responseData.meta
      ));
    } else {
      throw new Error('Los datos obtenidos no son un array');
    }
  } catch (error) {
    dispatch(searchClosedOffersFailure('Error al buscar ofertas finalizadas'));
  }
};
