import React, { useState } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import ValuationModal from '../../../components/pages/modals/ModalValoraPerfil';
import CustomLegend from '../offers/offersProfile/Legend';
import RatingBlue from '../../../components/rating/RatingBlue';
import { DetailSoftSkillsOfferFormat } from '../../../constants/pages/searchCV/GetSearchCV';
import { Col, Row } from 'antd';

interface Skill {
  subject: string;
  A: number;
  B: number;
}

interface FinalistInfoProps {
  imageUrl: string;
  nombre: string;
  puesto: string;
  ubicacion: string;
  habilidades: string[];
  descripcion: string;
  radarData: Skill[];
  isProfileUnlocked: boolean;
  valoracionGeneral: string;
  totalValoraciones: number;
  soft_skills: {}
}

interface UserSoftSkillsDto {
  nivel: any;
  soft_skills: {
    soft_skill: string
  }
}

const defaultSoftSkills = [
  { subject: 'Adaptable', A: 0, B: 0, fullMark: 10 },
  { subject: 'Consistente', A: 0, B: 0, fullMark: 10 },
  { subject: 'Detallista', A: 0, B: 0, fullMark: 10 },
  { subject: 'Innovador', A: 0, B: 0, fullMark: 10 },
  { subject: 'Metódico', A: 0, B: 0, fullMark: 10 },
  { subject: 'Proactivo', A: 0, B: 0, fullMark: 10 },
  { subject: 'Resiliente', A: 0, B: 0, fullMark: 10 },
  { subject: 'Visionario', A: 0, B: 0, fullMark: 10 },
];

const FinalistInfo: React.FC<FinalistInfoProps> = ({
  imageUrl,
  nombre,
  puesto,
  ubicacion,
  habilidades = [],  // Usamos un array vacío como valor por defecto
  descripcion,
  radarData = [],
  isProfileUnlocked,
  valoracionGeneral,
  totalValoraciones,
  soft_skills
}) => {

  const [isModalVisible, setIsModalVisible] = useState(false); // Estado para el modal


  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const dataGraphSoftSkills = (data: any) => {

    const dataFormated = data.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index: number) => {
      const findSubjectUser = data.soft_skills_usuario.find((usr: UserSoftSkillsDto) => usr.soft_skills.soft_skill == item.soft_skills.soft_skill)
      return [
        {
          subject: item.soft_skills.soft_skill,
          A: item.porcentaje > 0 ? item.porcentaje / 10 : 0,
          B: findSubjectUser ? findSubjectUser.nivel : 0,
          fullMark: 10,
          index: index
        },
        {
          subject: item.soft_skills.soft_skill_c,
          A: 10 - (item.porcentaje > 0 ? item.porcentaje / 10 : 0),
          B: findSubjectUser ? 10 - (findSubjectUser.nivel) : 0,
          fullMark: 10,
          index: index
        }
      ]
    })

    if (dataFormated.length == 0) {
      return defaultSoftSkills
    } else {
      return dataFormated.sort((a: any, b: any) => {
        if (a.subject < b.subject) return -1
        if (a.subject > b.subject) return 1
        return 0
      })
    }
  }

  return (
    <Row className="flex justify-between items-start p-6">
      <Col xs={24} md={14}>
        <div className="flex items-center mb-4">
          <img
            src={imageUrl}
            alt="Perfil"
            className="w-20 h-20  mr-4"
          />
          <div>
            <h2 className="text-[18px] font-normal">{nombre}</h2>
            <p className="text-[16px] text-gray-500 font-bold">{puesto}</p>
            <p className="text-[16px] font-medium text-gray-500">{ubicacion}</p>
          </div>
        </div>

        <div className="mt-2">
          <h3 className="font-bold text-[18px] mb-2">Habilidades</h3>
          <div className="flex flex-wrap gap-2">
            {habilidades.map((habilidad, index) => (
              <span
                key={index}
                className="bg-[#FCFCFC] border border-[#E1E1E2] rounded-full px-3 py-1 text-[16px] font-semibold text-black"
              >
                {habilidad}
              </span>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <h3 className="font-bold text-[18px] mb-2 mt-8">Sobre mí</h3>
          <p className="text-gray-700 font-medium text-[16px]">
            {descripcion}
          </p>
        </div>

        <div className="md:mt-20 -mb-5 flex items-center">
          <span className="mr-2 text-[18px] font-bold">Valoraciones</span>
          <div className="font-bold text-[18px] mr-[15px]">
            <RatingBlue
              filledStars={parseFloat(valoracionGeneral)}// Usa la calificación adecuada
              filledStarSize={{ width: '19px', height: '19px', marginRight: '2px', marginTop: '2px' }}
              emptyStarSize={{ width: '24px', height: '24px', marginLeft: '-2px' }}
            />
          </div>
          <span className="ml-2 font-normal text-[18px] text-blue3">{totalValoraciones} valoraciones</span> 
        </div>
      </Col>

      {/* Sección derecha - Gráfico de habilidades */}
      <Col xs={24} md={10}>
        <h3 className="font-bold text-lg text-right">Soft skills</h3>

        <div className="mt-6 relative">
          <ResponsiveContainer width="100%" height={340}>
            <RadarChart cx="50%" cy="40%" outerRadius="60%" data={dataGraphSoftSkills(soft_skills)}>
              <PolarGrid />
              <PolarAngleAxis
                dataKey="subject"
                tick={{ fontSize: 12, fill: '#000', fontWeight: 'bold' }}
              />
              <PolarRadiusAxis angle={30} tickCount={11} domain={[0, 10]} />
              <Radar name="Perfil del Candidato" dataKey="B" stroke="#006497" fill="transparent" />
              <Radar name="Perfil de la oferta" dataKey="A" stroke="#FDBCB4" fill="transparent" />
              <Legend content={<CustomLegend showOffer={true} />} />
              <Tooltip />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </Col>

      {/* Modal para "Valorar candidato" */}
      <ValuationModal
        visible={isModalVisible}
        onClose={closeModal}
        entityName={nombre}
        entityType="candidate"
        linkVal='PENDIENTE'
      />
    </Row>
  );
};

export default FinalistInfo;
