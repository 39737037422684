import {
  FETCH_CANCEL_APPLICATION,
  FETCH_FAILED_CANCEL_APPLICATION,
  FETCH_SUCCESS_CANCEL_APPLICATION,
  CancelApplicationActionTypes
} from "../../../constants/applications/CancelApplication";

interface CancelApplicationState {
  rex_loading: boolean;
  rex_failed: boolean;
}

const INIT_STATE: CancelApplicationState = {
  rex_loading: false,
  rex_failed: false,
};
const CancelApplicationReducer = (state = INIT_STATE, action: CancelApplicationActionTypes): CancelApplicationState => {
  switch (action.type) {
    case FETCH_CANCEL_APPLICATION: {
      return {
        ...state,
        rex_loading: true
      };
    }
    case FETCH_FAILED_CANCEL_APPLICATION: {
      return {
        ...state,
        rex_loading: false,
        rex_failed: true,
      }
    }
    case FETCH_SUCCESS_CANCEL_APPLICATION: {
      return {
        ...state,
        rex_loading: false,
      }
    }
    default:
      return state;
  }
}


export default CancelApplicationReducer;