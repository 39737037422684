import React, { useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import IconCandidate from '../../../assets/icons/IconCandidate.svg';
import IconCandidateWhite from '../../../assets/icons/IconCandidateWhite.svg';
import IconArrow from '../../../assets/icons/IconArrow.svg';
import IconArrowWhite from '../../../assets/icons/IconArrowWhite.svg';
import '../../../styles/pages/login/CardLoginCandidate.css'

const CardCandidate: React.FC = () => {
  const navigate = useNavigate();

  const [hoverCard, setHoverCard] = useState(false)

  const handleClick = () => {
    navigate("/registerCandidate"); // Redirige a la vista del candidato
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <Card
        className={
          `shadow-md rounded-[12px] py-[1px] px-[20px] h-[127px] bg-white border-none card-register-candidate`
        }
        onMouseEnter={() => setHoverCard(true)}
        onMouseLeave={() => setHoverCard(false)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={hoverCard ? IconCandidateWhite : IconCandidate} alt="Candidate Icon" className="mr-4 w-[26px]"
            />
            <div className="max-w-[360px]">
              <h1 className={`font-sans font-semibold text-heading-sm text-black`}>
                Soy Candidato
              </h1>
              <p className={`font-sans text-body-sm font-medium text-black2`}>
                Por fin una plataforma que permite que tus referencias te ayuden a encontrar empleo.
              </p>
            </div>
          </div>
          <img
            src={hoverCard ? IconArrowWhite : IconArrow}
            alt="Arrow Icon" className={`text-gray-500 hover:text-white`}
          />
        </div>
      </Card>
    </div>
  );
};

export default CardCandidate;
