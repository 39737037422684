import { Button, Col, Input, Row, Radio, notification, Tooltip, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowLeft from "../../../assets/icons/arrowLeft.svg";
import Print from "../../../assets/icons/print.svg";
import LogoStripe from '../../../assets/img/Checkout/LogoStripe.png'
import IconEdit from '../../../assets/icons/EditP.svg'
import IconClosed from '../../../assets/icons/IconClosed.svg'
import Check from "../../../assets/icons/check.svg";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { InfoCircleOutlined } from '@ant-design/icons';
import { checkoutPackSelectedReducer, CheckoutReducer } from '../../../redux/actions/common/checkout/Checkout';
import { UpdateEmpresasReducer } from '../../../redux/actions/common/company/Company';

import { loadStripe } from '@stripe/stripe-js';

const ConfirmationCheckout = () => {

	const navigate = useNavigate()
	const dispatch = useDispatch<AppDispatch>()

	const {
		rex_data,
	} = useSelector(({ checkoutReducer }: any) => checkoutReducer);

	const { paquete_id } = useParams<{ paquete_id: string }>();
	const [paymentOption, setPaymentOption] = useState(0)
	const [editTaxData, setEditTaxData] = useState(false)
	const [editbillingData, setEditBillingData] = useState(false)
	const [dataTax, setDataTax] = useState({
		identificacion_fiscal: '',
		razon_social: ''
	})

	const [dataBilling, setDataBilling] = useState({
		direccion: '',
		email_facturacion: ''
	})

	const onChange = (e: any) => {
		setPaymentOption(e.target.value);
	}

	const payPack = () => {
		if (paymentOption == 0) {
			return notification.error({ message: "Seleccione el metodo de pago" });
		}
		if (paymentOption == 3) {
			dispatch(checkoutPackSelectedReducer(rex_data.pack))
			return navigate(`/checkout/payment`, { state: { pack: rex_data.pack } });
		} else {
			return notification.error({ message: "Metodo de pago no disponible" });
		}
	}

	const onChangeTax = (e: any) => {
		setDataTax({
			...dataTax,
			[e.target.name]: e.target.value
		})
	}

	const onChangeBilling = (e: any) => {
		setDataBilling({
			...dataBilling,
			[e.target.name]: e.target.value
		})
	}

	const updateData = async (type: string) => {
		const data = type == 'tax' ? dataTax : dataBilling
		const responseData = await dispatch(UpdateEmpresasReducer(rex_data.user.id, data));

		if (responseData.respuesta) {
			message.success('Datos actualizados')
			if (type == 'tax') {
				setEditTaxData(false)
			} else {
				setEditBillingData(false)
			}
		} else {
			message.error('Ha ocurrido un error al actualizar los datos')
		}

		getDataCheckout()
	}

	const getDataCheckout = () => {
		if (paquete_id)
			dispatch(CheckoutReducer(paquete_id))
	}

	useEffect(() => {
		getDataCheckout()
	}, [])

	return (
		<>
			<Row className='flex justify-center'>
				<Col xs={20} md={21}>
					<div className="pt-8 ">
						<div className="flex items-center mb-4">
							<img
								src={ArrowLeft}
								alt="Regresar"
								className="cursor-pointer mr-2"
								onClick={() => navigate(-1)}
							/>
							<h2 className="text-xl font-bold border-b border-[#A1A1AA] w-full pb-1 font-sans text-blue3">Confirmación y pago</h2>
						</div>
					</div>
				</Col>
			</Row>
			<Row className='flex justify-center font-sans'>
				<Col span={20}>
					<Row gutter={[64, 12]} >
						<Col span={24}>
							<div className='flex justify-between'>
								<div className='font-bold text-xl mb-4'>Lorem ipsum</div>
								<div
									className='flex items-center cursor-pointer'
									onClick={() => console.log("Imprimir")}
								>
									<img width={20} className='mr-3' src={Print} />
									<div className='font-sans'>Imprimir comprobante</div>
								</div>
							</div>
						</Col>
						<Col xs={24} md={14} className='flex flex-col gap-4 md:pb-5'>
							<div className='bg-[#FBFDFE] border border-blue4 rounded-md p-6'>
								<div className='flex justify-between border-b border-[#E1E1E2] pb-4'>
									<div className='font-bold font-sans w-full'>Datos fiscales</div>
									<div>
										{
											editTaxData
												? <div className='flex'>
													<img width={25} className='cursor-pointer' onClick={() => updateData('tax')} src={Check} />
													<img width={25} className='cursor-pointer' onClick={() => {
														setEditTaxData(false)
													}} src={IconClosed} />
												</div>
												: <img
													src={IconEdit}
													alt="Editar"
													onClick={() => {
														setEditTaxData(true)
														setDataTax({
															...dataTax,
															identificacion_fiscal: rex_data.user.identificacion_fiscal,
															razon_social: rex_data.user.razon_social
														})
													}}
													className="inline-block text-sky-blue0 pl-3 cursor-pointer"
												/>
										}

									</div>
								</div>
								<div className={`pt-4 flex flex-col ${editTaxData && 'gap-1'}`}>
									<div className='font-sans'>Identificación fiscal (CIF o NIF):{' '}
										{
											editTaxData
												? <Input className='w-full md:w-1/3' name='identificacion_fiscal' onChange={onChangeTax} value={dataTax.identificacion_fiscal} placeholder='Identificación fiscal (CIF o NIF)' />
												: <span className='text-slate-500'>{rex_data.user.identificacion_fiscal}</span>
										}
									</div>
									<div className='font-sans'>Razón social:{' '}
										{
											editTaxData
												? <Input className='w-full md:w-1/3' name='razon_social' onChange={onChangeTax} value={dataTax.razon_social} placeholder='Razón social' />
												: <span className='text-slate-500'>{rex_data.user.razon_social}</span>
										}
									</div>
								</div>
							</div>
							<div className='bg-[#FBFDFE] border border-blue4 rounded-md p-6'>
								<div className='flex justify-between border-b border-[#E1E1E2] pb-4'>
									<div className='font-bold font-sans'>Datos de facturación</div>
									<div>
										{
											editbillingData
												? <div className='flex'>
													<img width={25} className='cursor-pointer' onClick={() => updateData('billing')} src={Check} />
													<img width={25} className='cursor-pointer' onClick={() => {
														setEditBillingData(false)
													}} src={IconClosed} />
												</div>
												: <img
													src={IconEdit}
													alt="Editar"
													onClick={() => {
														setEditBillingData(true)
														setDataBilling({
															...dataBilling,
															direccion: rex_data.user.direccion,
															email_facturacion: rex_data.user.email_facturacion
														})
													}}
													className="inline-block text-sky-blue0 pl-3 cursor-pointer"
												/>
										}

									</div>
								</div>
								<div className={`pt-4 flex flex-col ${editbillingData && 'gap-1'}`}>
									<div className='font-sans'>Dirección:{' '}
										{
											editbillingData
												? <Input name='direccion' onChange={onChangeBilling} value={dataBilling.direccion} className='w-full 2x2:w-1/3' placeholder='Dirección' />
												: <span className='text-slate-500'>{rex_data.user.direccion}</span>
										}
									</div>
									<div className='font-sans'>Persona de contacto:{' '}
										{
											editbillingData
												? <Input className='w-full 2x2:w-1/3' disabled value={`${rex_data.user.usuarios.personas.nombre} ${rex_data.user.usuarios.personas.apellido_paterno}`} />
												: <span className='text-slate-500'>{`${rex_data.user.usuarios.personas.nombre} ${rex_data.user.usuarios.personas.apellido_paterno}`}</span>
										}
									</div>
									<div className='font-sans'>Email:{' '}
										{
											editbillingData
												? <Input
													className='w-full 2x2:w-1/3' value={dataBilling.email_facturacion}
													name='email_facturacion' onChange={onChangeBilling}
													suffix={
														<Tooltip title="Recuerda que este email puede ser distinto al email con el que te registraste">
															<InfoCircleOutlined
																style={{
																	color: 'rgba(0,0,0,.45)',
																}}
															/>
														</Tooltip>
													}
												/>
												: <span className='text-slate-500'>{rex_data.user.email_facturacion}</span>
										}
									</div>
								</div>
							</div>
							<div className='bg-[#FBFDFE] border border-blue4 rounded-md p-6'>
								<div className='font-bold font-sans border-b border-[#E1E1E2] pb-4'>Código de descuento</div>
								<div className='flex flex-col 2xl:flex-row gap-3 pt-4'>
									<div><Input className='h-[36px] rounded-xl min-w-[264px]' /></div>
									<div><Button className='bg-blue3 text-white principal-nav-notify-button2 h-[36px] rounded-[4px]'>Aplicar codigo</Button></div>
								</div>
								<div className='text-slate-500 mt-2'>{'Lorem Ipsum'}</div>
							</div>
							<div className='bg-[#FBFDFE] border border-blue4 rounded-md p-6'>
								<div className='font-bold font-sans border-b border-[#E1E1E2] pb-4'>Medio de pago</div>
								<div className='pt-4'>
									<Radio.Group onChange={onChange} value={paymentOption} className='flex flex-col gap-4'>
										<Radio value={1}>
											<div>
												<div className='font-bold font-sans'>Tarjeta de crédito</div>
												<div className='text-slate-500'>{'Lorem ipsum'}</div>
											</div>
										</Radio>
										<Radio value={2}>
											<div>
												<div className='font-bold font-sans'>Transferencia bancaria</div>
												<div className='text-slate-500'>{'Lorem ipsum'}</div>
											</div>
										</Radio>
										<Radio value={3}>
											<div>
												<div className='font-bold'><img src={LogoStripe} alt='logo stripe' /></div>
												<div className='font-sans text-slate-500'>{'Lorem ipsum'}</div>
											</div>
										</Radio>
									</Radio.Group>
								</div>
							</div>

							<div className='flex justify-center'>
								<Button
									onClick={payPack} 
									className='bg-blue3 text-white principal-nav-notify-button2 h-[36px] w-auto rounded-[4px]'
								>Pagar ahora</Button>
							</div>
						</Col>
						<Col xs={24} md={10} xxl={6} className='pb-6'>
							<div className='bg-[#FBFDFE] border border-blue4 rounded-md p-6'>
								<div className='font-bold font-sans border-b border-[#E1E1E2] pb-4'>Resumen de compra</div>
								<div className='flex flex-col pt-4 border-b border-[#E1E1E2] pb-4 gap-2'>
									<div className='flex justify-between font-sans'>
										<div>Numero de compra</div>
										<div className='text-slate-500'>{rex_data.pack.idCompra}</div>
									</div>
									<div className='flex justify-between font-sans'>
										<div className='text-blue3'>{rex_data.pack.paquete}</div>
										<div className='text-slate-500'>{rex_data.pack.precio}€</div>
									</div>
									<div>
										{
											rex_data.pack.caracteristicas_paquetes.map((car: any, index: number) => (
												<div key={index} className='flex'><img src={Check} alt="check" className="mr-3" />{car.descripcion}</div>
											))
										}
									</div>
								</div>
								<div className='flex flex-col text-end pt-4'>
									<div className='font-sans'>Productos: <span className='text-slate-500'>{`${rex_data.pack.precio}€`}</span></div>
									<div className='font-sans'>IVA 21%: <span className='text-slate-500'>{`${rex_data.pack.precio * 0.21}€`}</span></div>
									<div className='font-sans text-blue3'>Total: {`${rex_data.pack.precio * 1.21}€`}</div>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}

export default ConfirmationCheckout