// src/constants/pages/company/PaqueteUsuarios.ts

export const ADD_PAQUETE_USUARIO_REQUEST = 'ADD_PAQUETE_USUARIO_REQUEST';
export const ADD_PAQUETE_USUARIO_SUCCESS = 'ADD_PAQUETE_USUARIO_SUCCESS';
export const ADD_PAQUETE_USUARIO_FAILURE = 'ADD_PAQUETE_USUARIO_FAILURE';

// Tipos de las acciones
interface AddPaqueteUsuarioRequestAction {
  type: typeof ADD_PAQUETE_USUARIO_REQUEST;
}

interface AddPaqueteUsuarioSuccessAction {
  type: typeof ADD_PAQUETE_USUARIO_SUCCESS;
  payload: {
    data: any; // Datos del paquete de usuario agregado
  };
}

interface AddPaqueteUsuarioFailureAction {
  type: typeof ADD_PAQUETE_USUARIO_FAILURE;
  payload: string; // Error en caso de fallo
}

// Exportamos los tipos de las acciones
export type PaqueteUsuarioActionTypes =
  | AddPaqueteUsuarioRequestAction
  | AddPaqueteUsuarioSuccessAction
  | AddPaqueteUsuarioFailureAction;
