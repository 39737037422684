import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Affix, Button } from 'antd';
import CardEmpleo from '../../../components/cards/CardEmployment';
import Information from '../../../components/pages/employment/recommendations/Information';
import AlertFilter from '../../../components/pages/employment/recommendations/AlertFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FetchEmploymentsReducer, GetOfferReducer } from '../../../redux/actions/offers/GetOffers';
import { AppDispatch } from '../../../redux/store/store';
import { SaveOfferReducer } from '../../../redux/actions/offers/SaveOffer';

interface FilterParams {
  ratings?: number[];
  solicitudesRapidas?: boolean;
}

const CardEmpleoExample: React.FC = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [formattedData, setFormattedData] = useState<{ allProperties: any, informationProps: any }[]>([]);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { rex_user } = useSelector(({ auth }: any) => auth);
  const CargoRecomendation = rex_user.cargo

  const dispatch: AppDispatch = useDispatch();
  const {
    rex_data: rawCardsData,
    rex_loading,
    rex_error,
    rex_meta,
    rex_total,
    rex_limit,
    rex_loading_offer,
    rex_data_offer
  } = useSelector(({ getOffers }: any) => getOffers);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    ratings: [],
    solicitudesRapidas: false
  });

  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));
    setPage(1);
    setHasMore(true);
    setLoading(true);
  };

  const handleResetFilters = () => {
    console.log('Reseteando filtros');
    setFilterParams({
      ratings: [],
      solicitudesRapidas: false
    });

    console.log('Filtros reseteados:', {
      ratings: [],
      solicitudesRapidas: false
    });

    setPage(1);
    setHasMore(true);
    setLoading(true);
    dispatch(FetchEmploymentsReducer(true, 1, rex_limit, CargoRecomendation));
  };


  useEffect(() => {
    if (formattedData?.length === 0 || page === 1) {
      setLoading(true);
      const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined
      const selectedSolicitudesRapidas = filterParams.solicitudesRapidas ? filterParams.solicitudesRapidas : undefined
      dispatch(FetchEmploymentsReducer(
        true,
        1,
        rex_limit,
        CargoRecomendation,
        selectedRatings,
        selectedSolicitudesRapidas,

      )).then(() => {
        setLoading(false);
      });
    }
  }, [rex_limit, page, filterParams]);

  useEffect(() => {
    if (rawCardsData && rawCardsData.length > 0) {
      const newData = rawCardsData?.map((job: any) => ({
        exampleProps: {
          companyName: job.empresa_id ? job.empresas.empresa : 'Empresa desconocida',
          jobTitle: job.cargo || 'Título desconocido',
          location: job.ubi_poblacion || 'Ubicación no disponible',
          salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
          schedule: job.jornada_laboral || 'Horario no especificado',
          ratings: job.empresas._count.valoraciones_empresas + ' valoraciones',
          activeOffers: job.empresas._count.ofertas + ' ofertas activas',
          followers: job.empresas._count.empresa_seguida + ' Seguidores',
          description: job.descripcion || 'Descripción no disponible',
          postedTime: job.updatedAt && !isNaN(new Date(job.updatedAt).getTime())
            ? `Subida hace ${Math.floor((Date.now() - new Date(job.updatedAt).getTime()) / (1000 * 60 * 60))}h`
            : 'Fecha no disponible',
          applied: false,
          createdAt: job.createdAt
        },
        informationProps: {
          id: job.id,
          title: job.cargo || 'Título desconocido',
          company: job.empresa_id ? job.empresas.empresa : 'Empresa desconocida',
          location: job.ubi_poblacion || 'Ubicación no disponible',
          employmentType: job.jornada_laboral || 'Tipo de jornada no disponible',
          salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
          comments: 0, // Este es estático
          applicantsCount: job._count.postulaciones + ' Inscritos', // Este es estático
          introText: job.empresas.descripcion || '',
          requirements: [
            {
              title: "Estudios mínimos",
              requirement: job.estudios_minimos,
            },
            {
              title: "Años de experiencia",
              requirement: job.anios_experiencia == 0 ? "Sin experiencia" : job.anios_experiencia,
            }
          ],
          responsibilities: [
            job.descripcion || 'Descripción no disponible'
          ],
          extraText: [
            '' // Estático
          ],
          extraText2: [],
          postedTime: job.updatedAt && !isNaN(new Date(job.updatedAt).getTime())
            ? `Subida hace ${Math.floor((Date.now() - new Date(job.updatedAt).getTime()) / (1000 * 60 * 60))}h`
            : 'Fecha no disponible',
          createdAt: job.createdAt
        },
        postulaciones_guardadas: job.postulaciones_guardadas,
        allProperties: job
      }));
      setFormattedData(newData)
    } else {
      setFormattedData([])
    }

  }, [rawCardsData])

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);

    setTimeout(() => {
      const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined
      const selectedSolicitudesRapidas = filterParams.solicitudesRapidas ? filterParams.solicitudesRapidas : undefined
      dispatch(FetchEmploymentsReducer(
        false,
        nextPage,
        rex_limit,
        CargoRecomendation,
        selectedRatings,
        selectedSolicitudesRapidas
      )).then(() => {
        setPage(nextPage);
        setLoadingMoreData(false);
        if (nextPage >= totalPages) {
          setHasMore(false);
        }
      })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  const handleCardClick = (index: number) => {
    // setLoadingInfo(true);

    setSelectedCardIndex(index);
    dispatch(GetOfferReducer(rawCardsData[index].id))
    // setLoadingInfo(false);
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index]
      }));
    }
  };

  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (formattedData?.length == rex_total) {
        setHasMore(false)
      }
    }
  }, [rex_total, formattedData])


  return (
    <div
      className='p-[20px] mb-5'
    >
      <AlertFilter
        onFilterChange={handleFilterChange}
        newOffersCount={rex_meta?.total ?? 0}
        onQuickApplyClick={() => alert('Solicitud rápida aplicada')}
        onRatingsClick={(value: number) => { alert(`Valoraciones: ${value} aplicadas`) }}
        onProvinceClick={(value: string) => alert(`Provincia: ${value} seleccionada`)}
        onCreateAlertClick={() => alert('Nueva alerta creada')}
        newOffersText="Nuevas ofertas que se adaptan a ti"
        createAlertText="Crear nueva alerta"
        filterButtonText="Todas las Ofertas"
        onFilterClick={handleResetFilters}
        quickApplyButtonText="Solicitudes rápidas"
        ratingsDefaultText="Valoraciones"
        onResetFilters={() => {
          console.log('Filtros reseteados en componente padre');
        }}
        provinceDefaultText="Provincia"
        ratingsOptions={[
          { value: 5, label: '5 Estrellas' },
          { value: 4, label: '4 Estrellas' },
          { value: 3, label: '3 Estrellas' },
          { value: 2, label: '2 Estrellas' },
          { value: 1, label: '1 Estrella' },
          { value: 0, label: '0 Estrellas' },
        ]}
        provinceOptions={[
          { value: 'madrid', label: 'Madrid' },
          { value: 'barcelona', label: 'Barcelona' },
          { value: 'valencia', label: 'Valencia' },
        ]}
      />


      <Row gutter={[16, 16]} className='mb-2'>
        <Col xs={24} md={10} style={{ padding: 0, height: '100%' }}>
          <InfiniteScroll
            className='w-full pt-[2px] md:pl-[2px] pl-[8px] pr-[8px] box-border'
            dataLength={formattedData ? formattedData.length : 0}
            next={fetchMoreData}
            hasMore={hasMore && rex_total >= rex_limit}
            loader={
              <div style={{ marginBottom: '16px' }}>
                <Skeleton
                  active
                  avatar={{ shape: 'square', size: 'large' }}
                  paragraph={{ rows: 3 }}
                />
              </div>
            }
            endMessage={<p>No hay más ofertas para mostrar</p>}
          >
            {formattedData?.map((card: any, index: number) => (
              <div
                key={index}
                className={
                  `card-container mb-[16px] rounded-lg
                  ${hoveredCardIndex === index
                    ? 'hovered'
                    : ''
                  } 
                  ${selectedCardIndex === index
                    ? 'selected md:shadow-shadow1'
                    : hoveredCardIndex === index
                      ? 'shadow-shadow1'
                      : 'shadow-shadow2'
                  } transition-shadow duration-300 ease-in-out`
                }
                onClick={() => {
                  handleCardClick(index)
                }}
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div className='hidden md:flex'>
                  <CardEmpleo
                    currentStep={0}
                    idCard={card.allProperties.id}
                    {...card.exampleProps}
                    index={index}
                    indexSelected={selectedCardIndex}
                    // applied={!!appliedCards[index]}
                    applied={card.allProperties.inscritoOfertaByUser}
                    styleType={card?.allProperties.ofertaGuardadaByUser ? "type3" : "default"}
                    saveOffer={false}
                    avatar={card?.allProperties?.empresas?.logo}
                  />
                </div>
                <div className='flex md:hidden'>
                  {selectedCardIndex === index
                    ? <Information
                      saved={rex_data_offer?.guardada}
                      applied={formattedData[selectedCardIndex].allProperties.inscritoOfertaByUser}
                      onApply={function (): void {
                      }}
                      onSave={async () => {
                        await dispatch(SaveOfferReducer(rawCardsData[selectedCardIndex].id))
                        dispatch(FetchEmploymentsReducer(true, page, rex_limit, CargoRecomendation));
                      }}
                      loading={rex_loading_offer}
                      {...formattedData[selectedCardIndex].informationProps}
                      isSavedOffer={formattedData[selectedCardIndex].allProperties.ofertaGuardadaByUser}
                      sector={formattedData[selectedCardIndex].allProperties.sectores.sector}
                      aptitudes={formattedData[selectedCardIndex].allProperties.aptitudes_oferta}
                      avatar={formattedData[selectedCardIndex].allProperties.empresas?.logo}
                    />
                    : <CardEmpleo
                      currentStep={0}
                      index={index}
                      idCard={card.allProperties.id}
                      indexSelected={selectedCardIndex}
                      {...card.exampleProps}
                      // applied={!!appliedCards[index]}
                      applied={card.allProperties.inscritoOfertaByUser}
                      styleType={card?.allProperties.ofertaGuardadaByUser ? "type3" : "default"}
                      saveOffer={false}
                    />

                  }
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </Col>
        <Col xs={0} md={14} style={{ paddingLeft: '8px' }}>
          {selectedCardIndex !== null && formattedData && formattedData.length > 0 ? (
            <Affix offsetTop={140}>
              <Information
                saved={rex_data_offer?.guardada}
                applied={formattedData[selectedCardIndex].allProperties.inscritoOfertaByUser}
                onApply={function (): void {
                }}
                onSave={async () => {
                  await dispatch(SaveOfferReducer(rawCardsData[selectedCardIndex].id))
                  dispatch(FetchEmploymentsReducer(true, page, rex_limit, CargoRecomendation));
                }}
                loading={rex_loading_offer}
                isSavedOffer={formattedData[selectedCardIndex].allProperties.ofertaGuardadaByUser}
                {...formattedData[selectedCardIndex].informationProps}
                sector={formattedData[selectedCardIndex].allProperties.sectores.sector}
                aptitudes={formattedData[selectedCardIndex].allProperties.aptitudes_oferta}
                avatar={formattedData[selectedCardIndex].allProperties.empresas?.logo}
              />
            </Affix>
          ) : (
            <div
              className='py-[12px] px-[16px] rounded-md border border-solid border-gray5'
            >
              <p>Selecciona una oferta para ver más detalles</p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardEmpleoExample;