import {
  GET_POSTULACION_BY_ID_REQUEST,
  GET_POSTULACION_BY_ID_SUCCESS,
  GET_POSTULACION_BY_ID_FAILURE,
  GetPostulacionByIdActionTypes,
  Postulacion
} from '../../../../../constants/pages/offers/portulation/GetPortulationId';

// State Interface
interface GetPostulacionByIdState {
  rex_loading: boolean;
  rex_postulacion: Postulacion[];
  rex_error: string | null;
}

// Initial State
const initialState: GetPostulacionByIdState = {
  rex_loading: false,
  rex_postulacion: [],
  rex_error: null,
};

// Reducer
export const getPostulacionByIdReducer = (
  state = initialState,
  action: GetPostulacionByIdActionTypes
): GetPostulacionByIdState => {
  switch (action.type) {
    case GET_POSTULACION_BY_ID_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null,
      };
    case GET_POSTULACION_BY_ID_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_postulacion: Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data],
        rex_error: null,
      };
    case GET_POSTULACION_BY_ID_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
        rex_postulacion: [],
      };
    default:
      return state;
  }
};

export default getPostulacionByIdReducer;