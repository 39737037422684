import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import { Action, Dispatch } from 'redux';
import {
  FETCH_FAILED_SIGNUP_OFFER,
  FETCH_SIGNUP_OFFER,
  FETCH_SUCCESS_SIGNUP_OFFER
} from '../../../constants/offers/SignUpOffer';
import FetchWithIP from '../utils/FetchHeaders';

export const SignUpOfferReducer = (
  offerId: number,
  cvUsuarioId: number | null,
  portafolioUsuarioIds: number[] | null,
  dataForm: any
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch: Dispatch, getState) => {

  dispatch({ type: FETCH_SIGNUP_OFFER });

  try {
    const data = await FetchWithIP('postulaciones/createOrPostulacion', {
      method: 'POST'
    }, {
      "oferta_id": offerId,
      "cv_usuario_id": cvUsuarioId,
      "portafolio_ids": portafolioUsuarioIds,
      "dataForm": dataForm
    }).then(res => res.json());

    dispatch({
      type: FETCH_SUCCESS_SIGNUP_OFFER,
      payload: data
    });

    return data;
  } catch (error) {
    dispatch({
      type: FETCH_FAILED_SIGNUP_OFFER
    });
    console.error("Error en la API:", error); // Muestra el error completo
    throw error;
  }
};

