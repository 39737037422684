// src/constants/pages/offers/editKillerQuestions.ts

export const UPDATE_KILLER_QUESTIONS_REQUEST = 'UPDATE_KILLER_QUESTIONS_REQUEST';
export const UPDATE_KILLER_QUESTIONS_SUCCESS = 'UPDATE_KILLER_QUESTIONS_SUCCESS';
export const UPDATE_KILLER_QUESTIONS_FAILURE = 'UPDATE_KILLER_QUESTIONS_FAILURE';

interface UpdateKillerQuestionsRequestAction {
  type: typeof UPDATE_KILLER_QUESTIONS_REQUEST;
}

interface UpdateKillerQuestionsSuccessAction {
  type: typeof UPDATE_KILLER_QUESTIONS_SUCCESS;
  payload: any; // Puede ser el resultado de la actualización
}

interface UpdateKillerQuestionsFailureAction {
  type: typeof UPDATE_KILLER_QUESTIONS_FAILURE;
  payload: string;
}

export type UpdateKillerQuestionsActionTypes =
  | UpdateKillerQuestionsRequestAction
  | UpdateKillerQuestionsSuccessAction
  | UpdateKillerQuestionsFailureAction;
