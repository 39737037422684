// src/actions/UpdateSearchStatusActions.ts
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  UPDATE_SEARCH_STATUS_REQUEST,
  UPDATE_SEARCH_STATUS_SUCCESS,
  UPDATE_SEARCH_STATUS_FAILURE,
  UpdateSearchStatusActionTypes,
} from '../../../../../constants/pages/myPortal/searchStatus/UpdateSearchStatus';
import FetchWithIP from '../../../utils/FetchHeaders';

export const updateSearchStatusRequest = (): UpdateSearchStatusActionTypes => ({
  type: UPDATE_SEARCH_STATUS_REQUEST,
});

export const updateSearchStatusSuccess = (data: any): UpdateSearchStatusActionTypes => ({
  type: UPDATE_SEARCH_STATUS_SUCCESS,
  payload: { data },
});

export const updateSearchStatusFailure = (error: string): UpdateSearchStatusActionTypes => ({
  type: UPDATE_SEARCH_STATUS_FAILURE,
  payload: error,
});

export const updateSearchStatus = (busquedaActiva: boolean): ThunkAction<Promise<any>, RootState, unknown, UpdateSearchStatusActionTypes> => async (dispatch) => {
  dispatch(updateSearchStatusRequest());

  try {
    const response = await FetchWithIP('auth/update-busqueda-activa', {
      method: 'PATCH'},
      { busqueda_activa: busquedaActiva },
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(updateSearchStatusSuccess(responseData.data));
    return responseData; // Retornamos la respuesta para manejarla en el frontend
  } catch (error: any) {
    dispatch(updateSearchStatusFailure(error.message || 'Error al actualizar el estado de búsqueda'));
    return { error: error.message };
  }
};
