// src/constants/pages/myPortal/profile/UpdateSearchStatus.ts

export const UPDATE_SEARCH_STATUS_REQUEST = 'UPDATE_SEARCH_STATUS_REQUEST';
export const UPDATE_SEARCH_STATUS_SUCCESS = 'UPDATE_SEARCH_STATUS_SUCCESS';
export const UPDATE_SEARCH_STATUS_FAILURE = 'UPDATE_SEARCH_STATUS_FAILURE';

// Tipos de las acciones
interface UpdateSearchStatusRequestAction {
  type: typeof UPDATE_SEARCH_STATUS_REQUEST;
}

interface UpdateSearchStatusSuccessAction {
  type: typeof UPDATE_SEARCH_STATUS_SUCCESS;
  payload: {
    data: any; // Aquí irían los datos de respuesta (que pueden incluir los campos actualizados)
  };
}

interface UpdateSearchStatusFailureAction {
  type: typeof UPDATE_SEARCH_STATUS_FAILURE;
  payload: string; // Error message
}

export type UpdateSearchStatusActionTypes =
  | UpdateSearchStatusRequestAction
  | UpdateSearchStatusSuccessAction
  | UpdateSearchStatusFailureAction;
