export const FETCH_CIUDADES_REQUEST = 'FETCH_CIUDADES_REQUEST';
export const FETCH_CIUDADES_SUCCESS = 'FETCH_CIUDADES_SUCCESS';
export const FETCH_CIUDADES_FAILURE = 'FETCH_CIUDADES_FAILURE';

interface FetchCiudadesRequestAction {
  type: typeof FETCH_CIUDADES_REQUEST;
}

interface FetchCiudadesSuccessAction {
  type: typeof FETCH_CIUDADES_SUCCESS;
  payload: {
    data: any[]; // Datos de los sectores
    // meta?: { total: number; limit: number; page: number }; // Información adicional de paginación (opcional)
  };
}

interface FetchCiudadesFailureAction {
  type: typeof FETCH_CIUDADES_FAILURE;
  payload: string;
}

export type CiudadesActionTypes = 
  | FetchCiudadesRequestAction
  | FetchCiudadesSuccessAction
  | FetchCiudadesFailureAction;