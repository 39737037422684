export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const VALIDATE_RESET_CODE_REQUEST = 'VALIDATE_RESET_CODE_REQUEST';
export const VALIDATE_RESET_CODE_SUCCESS = 'VALIDATE_RESET_CODE_SUCCESS';
export const VALIDATE_RESET_CODE_FAILURE = 'VALIDATE_RESET_CODE_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// Tipos de acciones
interface ForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_REQUEST;
}

interface ForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_SUCCESS;
  payload: {
    message: string;
  };
}

interface ForgotPasswordFailureAction {
  type: typeof FORGOT_PASSWORD_FAILURE;
  payload: string;
}

interface ValidateResetCodeRequestAction {
  type: typeof VALIDATE_RESET_CODE_REQUEST;
}

interface ValidateResetCodeSuccessAction {
  type: typeof VALIDATE_RESET_CODE_SUCCESS;
  payload: {
    message: string;
  };
}

interface ValidateResetCodeFailureAction {
  type: typeof VALIDATE_RESET_CODE_FAILURE;
  payload: string;
}

interface ResetPasswordRequestAction {
  type: typeof RESET_PASSWORD_REQUEST;
}

interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: {
    message: string;
  };
}

interface ResetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE;
  payload: string;
}

// Exportamos los tipos de las acciones
export type ForgotPasswordActionTypes =
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction;

export type ValidateResetCodeActionTypes =
  | ValidateResetCodeRequestAction
  | ValidateResetCodeSuccessAction
  | ValidateResetCodeFailureAction;

export type ResetPasswordActionTypes =
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction;
