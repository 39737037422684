import { Col, Modal, Row } from 'antd'
import React from 'react'
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const ModalVerPortfolio: React.FC<{
	nombre: string;
	showModal: boolean;
	setShowModal: any;
	data: any[];
}> = ({
	nombre,
	showModal,
	setShowModal,
	data
}) => {

	const renderFileIcon = (fileName?: string) => {
    const extension = fileName?.split('.').pop()?.toLowerCase();
    if (extension === 'pdf' || extension === 'doc' || extension === 'docx') {
      return (
				<iframe
					src={fileName}
					style={{
						height:'650px',
						width:'100%'

					}}
				/>
			)
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension || '')) {
      return (
        <img
          src={`${fileName}`}
          alt="Imagen"
          className="w-max h-max"
        />
      );
    } else {
      return <span className="mr-2">Archivo</span>;
    }
  };

		return (
			<Modal
				title={"Portfolio de " + nombre}
				visible={showModal}
				onCancel={() => setShowModal(!showModal)}
				footer={null} 
				width={"80%"}

			>
				<Row gutter={[16,16]}>
						{
							data.map((dat:any)=> (
								<Col className='w-max flex justify-center items-center' xs={24} md={12} lg={8}>
									{renderFileIcon(API_BASE_URL_EXACT && API_BASE_URL_EXACT + dat.archivo)}
								</Col>
							))
						}
				</Row>
			</Modal>
		)
	}

export default ModalVerPortfolio