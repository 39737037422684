import React, { useState, ChangeEvent } from 'react';
import { Card, Typography } from 'antd';
import CheckboxC from "../../components/checkbox/CheckboxProps";
import IconEdit from "../../assets/icons/EditP.svg";
import IconPdf from '../../assets/icons/iconpdf.svg';
import IconWord from '../../assets/icons/iconword.svg';
import "tailwindcss/tailwind.css";

const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;
const { Title, Text } = Typography;

interface CardPortfolioProps {
  icon?: React.ReactNode;
  projectName: string;
  projectDescription: string;
  projectDetails: string;
  skills: any[];  // Soft skills como un array de objetos
  website: string;
  showCheckbox?: boolean;
  showEditIcon?: boolean;
  onEditClick?: () => void;
  isSelected?: boolean;
  onSelect?: (id: number, isChecked: boolean) => void;
  archivos_portafolio: any[]; // Archivos como un array de objetos
  onClick?: () => void;
  userPortfolio: any;
  onViewPortfolio?: () => void;
  isBlocked?: boolean
}

const CardPortfolio: React.FC<CardPortfolioProps> = ({
  icon = <CheckboxC />,
  projectName,
  projectDescription,
  projectDetails,
  skills = [],
  website,
  showCheckbox = true,
  showEditIcon = false,
  onEditClick,
  onSelect,
  archivos_portafolio = [],
  userPortfolio,
  onViewPortfolio,
  isBlocked = false
}) => {

  const [showModalPortfolio, setShowModalPortfolio] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const renderFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return <img src={IconPdf} alt="PDF" className="w-6 h-6 mr-2" />;
    } else if (extension === 'doc' || extension === 'docx') {
      return <img src={IconWord} alt="Word" className="w-6 h-6 mr-2" />;
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension || '')) {
      return (
        <img
          src={`${API_BASE_URL_EXACT}/${fileName}`}
          alt="Imagen"
          className="w-24 h-24 object-cover mr-2"
        />
      );
    } else {
      return <span className="mr-2">Archivo</span>; // O un ícono genérico
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
    if (onSelect) {
      onSelect(userPortfolio.id, e.target.checked); // Informar al componente padre sobre la selección
    }
  };


  // Abrir el modal solo si el checkbox está marcado

  const handleCardClick = () => {
    // Si showCheckbox es true, permitir abrir el modal sin importar el estado del checkbox
    if (showCheckbox && onViewPortfolio) {
      onViewPortfolio();
    }
    // Si showCheckbox es false, también permitir abrir el modal
    if (!showCheckbox && onViewPortfolio) {
      onViewPortfolio();
    }
  };

  return (
    <>
      <Card
        className={`w-full h-auto p-6 shadow-xl rounded-none transition-shadow hover:shadow-2xl relative ${isBlocked ? 'cursor-not-allowed' : ''}`}
        bordered={false}
        bodyStyle={{ padding: 0 }}
        onClick={handleCardClick}  // Solo abre el modal si el checkbox está marcado
      >
        <div className="flex items-start mb-3 mt-3">
          {showCheckbox && (
            <div className="mr-2" onClick={(e) => e.stopPropagation()}>  {/* Evitar que el clic en el checkbox abra el modal */}
              <CheckboxC
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          )}
          <div>
            <Title level={4} className={`-mt-5 font-bold text-[16px] ${isBlocked ? 'blur-sm opacity-90' : ''}`}>
              {projectName}
            </Title>
            <Text className={`block text-[#52525B] font-bold ${isBlocked ? 'blur-sm opacity-90' : ''}`}>
              {projectDescription}
            </Text>
            <Text className={`block text-[14px] text-[#52525B] font-medium ${isBlocked ? 'blur-sm opacity-90' : ''}`}>
              {projectDetails}
            </Text>

            {/* Mostrar las habilidades */}
            <Text className="block text-[#52525B] font-bold mb-2">Mis Aptitudes:</Text>
            <ul className="list-disc ml-6 mb-4">
              {skills.map((skill: any) => (
                <li key={skill.id} className={`text-[#52525B] font-medium text-[14px] ${isBlocked ? 'blur-sm opacity-90' : ''}`}>
                  {skill.soft_skills.soft_skill}
                </li>
              ))}
            </ul>

            {/* Mostrar los archivos del portafolio */}
            <Text className="block text-[#52525B] font-bold mb-2">Mis Archivos:</Text>
            <div className="flex flex-wrap gap-2">
              {archivos_portafolio.map((archivo: any) => (
                <div key={archivo.id} className="flex items-center">
                  <li> {renderFileIcon(archivo.nombre_archivo)}
                    <span className={`text-[#52525B] font-medium ${isBlocked ? 'blur-sm opacity-90' : ''}`}>
                      {archivo.titulo}
                    </span>
                  </li>
                </div>
              ))}
            </div>

            {/* Enlace al sitio web */}
            <a
              href={website}
              className="text-[#52525B] font-medium hover:underline"
              target="_blank"
              rel="noopener noreferrer"
              style={{ position: 'absolute', bottom: '15px' }}
            >
              {website}
            </a>
          </div>

          {
            showEditIcon && (
              <img
                src={IconEdit}
                alt="Edit"
                className="absolute top-3 right-3 w-6 h-6 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();  // Detener la propagación del evento para evitar que se dispare el `onClick` del Card
                  if (onEditClick) {
                    onEditClick();  // Llamar a la función onEditClick si está definida
                  }
                }}
              />
            )
          }

        </div>
      </Card>

      <style>{`
        .ant-card:not(.ant-card-bordered) {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.2);
          transition: box-shadow 0.3s ease-in-out;
        }
        .ant-card:hover:not(.ant-card-bordered) {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 14px 28px rgba(0, 0, 0, 0.25);
        }
      `}</style>
    </>
  );
};

export default CardPortfolio;
