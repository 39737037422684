import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  FETCH_GET_OFFER_SAVE_REQUEST,
  FETCH_GET_OFFER_SAVE_SUCCESS,
  FETCH_GET_OFFER_SAVE_FAILURE,
  SET_FILTER_PARAMS_OFFER_SAVE,
  OfferSaveActionTypes
} from '../../../constants/offers/GetOfferSave';
import FetchWithIP from '../utils/FetchHeaders';

export const fetchOfferSaveRequest = (): OfferSaveActionTypes => ({
  type: FETCH_GET_OFFER_SAVE_REQUEST,
});

export const fetchOfferSaveSuccess = (data: any[],  meta: { total: number; limit: number; page: number }): OfferSaveActionTypes => ({
  type: FETCH_GET_OFFER_SAVE_SUCCESS,
  payload: { data, meta },
});

export const fetchOfferSaveFailure = (error: string): OfferSaveActionTypes => ({
  type: FETCH_GET_OFFER_SAVE_FAILURE,
  payload: error,
});


export const setFilterParamsOfferSave = (filters: any): OfferSaveActionTypes => ({
  type: SET_FILTER_PARAMS_OFFER_SAVE,
  payload: filters,
});

export const GetOfferSaveReducer = (
  page?:number,
  limit?: number,
  pais?: string,
  ciudad?: string,
  sector?: string,
  cargo?: string
): ThunkAction<Promise<any>,RootState,unknown,OfferSaveActionTypes> => async (dispatch) => {

  dispatch(fetchOfferSaveRequest());

  const queryParams = new URLSearchParams({
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
    ...(pais ?  { ubi_provincia: pais.toString() }: {}),
    ...(ciudad ? { ubi_poblacion: ciudad.toString() }: {}),
    ...(sector ?  { sector: sector.toString() }: {}),
    ...(cargo ?   { cargo: cargo.toString() }: {}),
  }).toString();

  try {

    const locationFilter = [pais, ciudad, sector,cargo].filter(Boolean).join(', ');

     if (locationFilter) {
          dispatch(setFilterParamsOfferSave({ location: locationFilter }));
        } else {
        }

    const response = await FetchWithIP(`postulaciones-guardadas/getAllByUserToken?${queryParams}`,
      {
        method: 'GET',
      }
    )
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
        const responseData = await response.json();

        const employmentData = responseData.data.map((offer: any) => ({
          id: offer.ofertas.id,
          empresa_id: offer.ofertas.empresa_id,
          cargo: offer.ofertas.cargo,
          descripcion: offer.ofertas.descripcion,
          ubi_poblacion: offer.ofertas.ubi_poblacion,
          sal_max: offer.ofertas.sal_max,
          sal_min: offer.ofertas.sal_min,
          jornada_laboral: offer.ofertas.jornada_laboral,
          createdAt: offer.createdAt,
          updatedAt: offer.updatedAt,
          postulaciones_guardadas: offer.postulaciones_guardadas,
          empresa: offer.ofertas.empresas.empresa,
          descripcion_empresa: offer.ofertas.empresas.descripcion,
          estudios_minimos: offer.ofertas.estudios_minimos,
          anios_experiencia: offer.ofertas.anios_experiencia,
          num_inscritos: offer.ofertas._count.postulaciones,
          sector: offer.ofertas.sectores.sector,
          aptitudes: offer.ofertas.aptitudes_oferta,
          logo: offer.ofertas.empresas?.logo ? offer.ofertas.empresas?.logo : null,
          rating: offer.ofertas.empresas._count.valoraciones_empresas + ' valoraciones',
          ofertas_activas: offer.ofertas.empresas._count.ofertas + ' ofertas activas',
          seguidores: offer.ofertas.empresas._count.empresa_seguida + ' Seguidores',
          inscrito: offer.ofertas.inscritoOfertaByUser
        }));

        if (responseData && responseData.data) {
              const { total, limit, page } = responseData.meta;
              dispatch(fetchOfferSaveSuccess(
                employmentData, 
                { total, limit, page}
              ));
            } else {
              throw new Error('Datos vacíos en la respuesta');
            }
        
            return true
        // dispatch({
        //   type: FETCH_GET_OFFER_SAVE_SUCCESS,
        //   payload: {
        //     data: employmentData,
        //     meta: response.meta
        //   }
        // });

      // }).catch((error) => {
      //   console.log(error)
      // });

  } catch (error: any) {
    dispatch(fetchOfferSaveFailure(error.message || 'Error al cargar '));
    return { error: error.message };
  }
};
