import React from 'react'
import { Col, Modal, Row } from 'antd'
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import IconClosed from '../../../assets/icons/IconClosed.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import '../../../styles/pages/searchCV/ModalSoftSkills.css'

type DataSoftSkill = {
  subject?: string;
  [key: string]: string | number | undefined;
  idxSubject?: number,
  A?: number;
  B?: number;
  C?: number;
  D?: number;
  E?: number;
  F?: number;
  G?: number;
  H?: number;
  I?: number;
  J?: number;
  fullMark: number;
};

interface ValuationModalProps {
  visible: boolean;
  onClose: () => void;
	data: DataSoftSkill[]
}

const ModalSoftSkills: React.FC<ValuationModalProps> = ({
  visible,
  onClose,
	data=[]
}) => {

	const { 
		labels_graph_sk,
		rex_users_graph_sk
	} = useSelector( (state: RootState) => state.getSearchCV );


	return (
		<Modal
			open={visible}
			onCancel={onClose}
			closable={false}
			footer={null}
			width={1000}
		>
			<img
				src={IconClosed}
				alt="Cerrar"
				onClick={onClose}
				style={{
				position: 'absolute',
				top: '35px',
				right: '40px',
				cursor: 'pointer',
				width: '24px',
				height: '24px'
				}}
			/>
			<div className='pb-14 pt-9'>
				<Row>
					<Col span={24} className='flex justify-center mb-3'>
						<div className='text-2xl font-bold font-sans'>Candidatos seleccionados sobre gráfica</div>
					</Col>
					<Col span={24} className='flex justify-center'>
						<div className='text-base font-sans'>Te presentamos la visualización de los candidatos que elegiste:</div>
					</Col>
				</Row>
				<Row className='my-14'>
					<Col xs={24} md={16} className='flex justify-end'>
						<ResponsiveContainer 
							width="80%"
							height={300}
							className='GraphicUsers'
						>
							<RadarChart cx="50%" cy="50%" outerRadius="80%" data={rex_users_graph_sk.data}>
								<PolarGrid />
								<PolarAngleAxis
								dataKey="subject"
								tick={{ fontSize: 12, fill: '#000', fontWeight: 'bold' }}
								/>
								<PolarRadiusAxis angle={30} tickCount={11} domain={[0, 10]} />
								{
									rex_users_graph_sk.users.map((user, index)=> {
										return	<Radar name={user.nombre} dataKey={user.id} stroke={labels_graph_sk[index]['color']} fill="transparent" />

									})
								}
								<Tooltip />
							</RadarChart>
						</ResponsiveContainer>
					</Col>
					<Col xs={24} md={6} className=''>
						<Row className='mt-2'>
							{
								rex_users_graph_sk.users.map((user, index)=> {
									return <Col span={24} key={index} className='h-auto justify-end' style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
										<span className='font-sans' style={{ color: '#1A1A1A', fontSize: 12, marginRight: '6px', fontWeight: '700', opacity: 0.7 }}>{user.nombre}</span>
										<div style={{ width: 12, height: 12, backgroundColor: labels_graph_sk[index]['color'], marginRight: '8px' }}></div>
									</Col>
								})
							}
							
						</Row>
					</Col>
				</Row>

			</div>
		</Modal>
	)
}

export default ModalSoftSkills