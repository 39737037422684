// src/reducers/pages/myPortal/education/addKillerQuestionsReducer.ts

import {
    ADD_KILLER_QUESTIONS_REQUEST,
    ADD_KILLER_QUESTIONS_SUCCESS,
    ADD_KILLER_QUESTIONS_FAILURE,
    AddKillerQuestionsActionTypes,
} from '../../../../../constants/pages/offers/killerQuestions/PostConditionKillersQuestions';
  
  interface AddKillerQuestionsState {
    loading: boolean;
    data: any | null; // Store the added question data
    error: string | null;
  }
  
  const initialState: AddKillerQuestionsState = {
    loading: false,
    data: null,
    error: null,
  };
  
  export const addKillerQuestionsReducer = (
    state = initialState,
    action: AddKillerQuestionsActionTypes
  ): AddKillerQuestionsState => {
    switch (action.type) {
      case ADD_KILLER_QUESTIONS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ADD_KILLER_QUESTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data, // Store the added question data
          error: null,
        };
      case ADD_KILLER_QUESTIONS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload, // Store error message if failure occurs
        };
      default:
        return state;
    }
  };
  
  export default addKillerQuestionsReducer;
  