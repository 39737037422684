import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';

import {
  FETCH_CHECKOUT_REQUEST,
  FETCH_CHECKOUT_SUCCESS,
  FETCH_CHECKOUT_FAILURE,
  CHECKOUT_SESSION_ID,
  CheckoutActionTypes,
  CHECKOUT_PACK_SELECTED
} from '../../../../constants/common/checkout/Checkout';

import FetchWithIP from '../../utils/FetchHeaders';

export const fetchCheckoutRequestReducer = (): CheckoutActionTypes => ({
  type: FETCH_CHECKOUT_REQUEST,
});

export const fetchCheckoutSuccessReducer = (data: { user: {}, pack: {} }): CheckoutActionTypes => ({
  type: FETCH_CHECKOUT_SUCCESS,
  payload: data,
});

export const fetchCheckoutFailureReducer = (error: string): CheckoutActionTypes => ({
  type: FETCH_CHECKOUT_FAILURE,
  payload: error,
});

export const fecthCheckoutSessionIdReducer = (id:string): CheckoutActionTypes => ({
  type: CHECKOUT_SESSION_ID,
  payload: id,
});

export const checkoutPackSelectedReducer = (data:{}): CheckoutActionTypes => ({
  type: CHECKOUT_PACK_SELECTED,
  payload: data,
});

export const CheckoutReducer = (id: string): ThunkAction<Promise<any>, RootState, unknown, CheckoutActionTypes> => async (dispatch) => {

  dispatch(fetchCheckoutRequestReducer());

  try {

    const respuesta = await FetchWithIP(`checkout/${id}`, {
      method: 'GET'
    }).then(res => res.json())

    if (respuesta.data) {
      dispatch(fetchCheckoutSuccessReducer(respuesta.data));
      return { payload: { data: respuesta.data } };
    } else {
      throw new Error('Los datos obtenidos no son un array');
    }

  } catch (error) {
    dispatch(fetchCheckoutFailureReducer('Error al mostrar datos'));
    return { payload: { data: [] } };
  }
};
