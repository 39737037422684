export const FETCH_EMPLOYMENTS_REQUEST = 'FETCH_EMPLOYMENTS_REQUEST';
export const FETCH_EMPLOYMENTS_SUCCESS = 'FETCH_EMPLOYMENTS_SUCCESS';
export const FETCH_EMPLOYMENTS_FAILURE = 'FETCH_EMPLOYMENTS_FAILURE';
export const SET_FILTER_PARAMS_EMPLOYMENTS = 'SET_FILTER_PARAMS_EMPLOYMENTS';

export const FETCH_GET_OFFER_REQUEST = 'FETCH_GET_OFFER_REQUEST';
export const FETCH_GET_OFFER_SUCCESS = 'FETCH_GET_OFFER_SUCCESS';
export const FETCH_GET_OFFER_FAILURE = 'FETCH_GET_OFFER_FAILURE';


export interface FetchEmploymentsRequestAction {
    type: typeof FETCH_EMPLOYMENTS_REQUEST;
  }

export interface FetchEmploymentsSuccessAction {
    type: typeof FETCH_EMPLOYMENTS_SUCCESS;
    payload: {
      data: any[]
      meta: { total: number; limit: number; page: number }; 
    };
  }

export interface FetchEmploymentsFailureAction {
    type: typeof FETCH_EMPLOYMENTS_FAILURE;
    payload: string;
  }

interface SetFilterParamsEmploymentsAction {
    type: typeof SET_FILTER_PARAMS_EMPLOYMENTS;
    payload: any; 
}

export type EmploymentsActionTypes =
  | FetchEmploymentsRequestAction
  | FetchEmploymentsSuccessAction
  | FetchEmploymentsFailureAction
  | SetFilterParamsEmploymentsAction;