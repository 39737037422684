import React, { useEffect } from 'react';
import { Card, Checkbox, Divider, Input, Radio, Slider } from 'antd';
import Header from '../../../components/pages/principalNav/HeaderOffers';
import SideAnswers from '../../../components/pages/offers/offersProfile/SideAnswers';
import { useParams, useNavigate } from 'react-router-dom';
import '../../../styles/pages/offers/answers.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { GetAnswersReducer } from '../../../redux/actions/pages/searchCV/answers/GetAnswers';
import CustomRadio from '../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/CustomRadio';
import CustomInputNumber from '../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/CustomInputNumber';
import icon from "../../../assets/img/offers/ArrowLeft.svg";

const Answers: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { offerId, candidateName, userId, postulationId } = useParams<{ offerId?: string; candidateName?: string; userId?: string, postulationId?: string }>();

  const { rex_answers_killers_questions } = useSelector(({ getAnswers }: any) => getAnswers);

  // Validamos que offerId tenga un valor numérico válido
  const parsedOfferId = offerId ? parseInt(offerId) : 0;

  useEffect(() => {
    if (offerId && userId) {
      dispatch(GetAnswersReducer(+offerId, +userId))
      console.log("postulacionID:", postulationId)
    }
  }, [dispatch, offerId, userId]);

  return (
    <div className="answers-container">
       <button
          onClick={() => navigate(-1)}
          className="flex ml-[20px] border-l-green-700 mt-4 items-center text-[#006497] text-[16px] font-bold"
        >
          <img src={icon} alt="Arrow Left" className="mr-2" />
          Volver al listado
        </button>

      <div className="answers-content">
        {/* Lado izquierdo: SideAnswers */}
        <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md pb-4 mb-4">
        <SideAnswers offerId={parsedOfferId}  postulationId={parseInt(postulationId || "0")}
          />
        </Card>

        {/* Lado derecho: Detalles de las respuestas */}
        <div className="answers-details">
          <h2 className="answers-title">
            Respuestas {candidateName || 'Desconocido'}
          </h2>
          <Divider className="answers-divider" />

          {
            rex_answers_killers_questions.map((question: any, index: number) => {
              // Verifica que todos los datos necesarios existan
              if (!question || !question.tipos_preguntas) {
                return <div key={index}>Datos de la pregunta no disponibles.</div>;
              }

              // Obtén la respuesta de forma segura
              const respuesta = question.respuestas_killer_questions?.[0]?.respuesta || null;

              return (
                <div className="mb-8" key={index}>
                  <p className="font-bold text-heading-x1 ">
                    {index + 1}. {question.pregunta}
                  </p>

                  {
                    question.tipos_preguntas.tipo === 'lineal' ? (
                      <div className="max-w-full mt-[10px]">
                        <div className="flex justify-between mb-2 font-medium text-caption">
                          {question.detalle_killers.map((detalle: any, i: number) => (
                            <span key={i}>{detalle.detalle}</span>
                          ))}
                        </div>
                        <Slider
                          min={0}
                          max={question.detalle_killers.length - 1}
                          step={1}
                          value={respuesta ? +respuesta : 0}
                          tooltip={{ formatter: null }}
                          trackStyle={{ backgroundColor: "#0778b1" }}
                          railStyle={{ backgroundColor: "#d9d9d9" }}
                        />
                      </div>
                    ) : question.tipos_preguntas.tipo === 'radio' ? (
                      <Radio.Group
                        className="mt-4 font-bold text-body-md"
                        value={respuesta ? +respuesta : null}
                      >
                        {question.detalle_killers.map((detalle: any, i: number) => (
                          <CustomRadio key={i} value={detalle.id}>
                            {detalle.detalle}
                          </CustomRadio>
                        ))}
                      </Radio.Group>
                    ) : question.tipos_preguntas.tipo === 'numerico' ? (
                      <div className="flex flex-wrap items-center mt-[10px]">
                        <CustomInputNumber
                          value={respuesta ? +respuesta : 0}
                          onChange={() => {}}
                          onchangeDefault={(value: any) => {}}
                        />
                      </div>
                    ) : question.tipos_preguntas.tipo === 'checkbox' ? ( // Caso para 'checkbox' ajustado
                      <div className="mt-4">
                        <Checkbox.Group
                          value={respuesta ? JSON.parse(respuesta) : []} // Parsear la respuesta que está en formato JSON
                          style={{ display: 'flex', flexWrap: 'wrap' }} // Flex para una fila
                        >
                          {question.detalle_killers.map((detalle: any, i: number) => (
                            <div key={i} className="flex items-center mb-3 mr-6">
                              <Checkbox
                                className="custom-checkbox"
                                value={detalle.id}
                                checked={respuesta ? JSON.parse(respuesta).includes(detalle.id) : false} // Verifica si está marcado
                              />
                              <span className="ml-2 font-bold text-body-md">{detalle.detalle}</span>
                            </div>
                          ))}
                        </Checkbox.Group>
                      </div>
                    ) : question.tipos_preguntas.tipo === 'respuesta' ? ( // Caso para 'respuesta'
                      <p className="mt-4 font-medium text-body-md">
                        {respuesta || 'No hay respuesta'}
                      </p>
                    ) : (
                      <p className="text-gray-500">Tipo de pregunta no soportado.</p>
                    )
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Answers;
