import React, { useState, useEffect } from "react";
import { Modal, Button, Input, notification } from "antd";
import { CopyOutlined } from '@ant-design/icons';
import IconClosed from "../../../assets/icons/IconClosed.svg";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { validateCode } from '../../../redux/actions/auth/Validation'; // Asegúrate de importar la acción de validación

interface ValuationModalProps {
  visible: boolean;
  onClose: () => void;
  email: string;
  onConfirmResend: () => void;
}

const validationSchema = Yup.object({
  codigo: Yup.array()
    .of(Yup.string().required())
    .length(6, "El código debe tener 6 dígitos")
});

const ValuationModal: React.FC<ValuationModalProps> = ({
  visible,
  onClose,
  email,
  onConfirmResend
}) => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(30);
  const [isResendEnabled, setIsResendEnabled] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(timer);
          setIsResendEnabled(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  const handleCopy = (values: { codigo: string[] }) => {
    const codigoCompleto = values.codigo.join('');
    navigator.clipboard.writeText(codigoCompleto);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setFieldValue: Function,
    values: { codigo: string[] }
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      setFieldValue(`codigo[${index}]`, value);
      const nextInput = index + 1;
      if (nextInput < 6) {
        const nextElement = document.getElementsByName(`codigo[${nextInput}]`)[0] as HTMLInputElement;
        if (nextElement) nextElement.focus();
      }
    } else if (value === '') {
      setFieldValue(`codigo[${index}]`, '');
    }
  };

  const handleValidationSubmit = async (values: { codigo: string[] }, { setSubmitting }: FormikHelpers<{ codigo: string[] }>) => {
    try {
      const codigoCompleto = parseInt(values.codigo.join(''), 10); // Convertimos el código a número
      await dispatch(validateCode(codigoCompleto) as any); // Llama a la acción de validación y asegura el tipo con `as any`
      setSubmitting(false);
      notification.success({ message: "Código validado correctamente" });
      onClose(); // Cierra el modal después de la validación
    } catch (error) {
      notification.error({ message: "Error en la validación del código" });
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      closable={false}
      width={677}
      bodyStyle={{ borderRadius: "12px" }}
      style={{ borderRadius: "12px", border: "1px solid #E1E1E2" }}
    >
      <img
        src={IconClosed}
        alt="Cerrar"
        onClick={onClose}
        style={{
          position: "absolute",
          top: "34px",
          right: "34px",
          cursor: "pointer",
          width: "24px",
          height: "24px",
        }}
      />

      <div className="text-center mx-[114px] mb-[36px] mt-[55px]">
        <h3 className="text-heading-md font-bold">
          Por favor, confirma tu correo
        </h3>
        <p className="font-medium px-[25px] text-body-sm mt-[10px] mb-[48px]">
          Confirma tu correo para que tu valoración se aplique correctamente y tú también encuentres tu “trabajo ideal"
        </p>
        <h2 className="text-heading-md font-bold text-blue3">
          ¡Gracias por registrarte en Merēre!
        </h2>

        <Formik
          initialValues={{
            codigo: Array(6).fill(''),
          }}
          validationSchema={validationSchema}
          onSubmit={handleValidationSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="flex justify-center mb-4">
                {Array.from({ length: 6 }).map((_, index) => (
                  <Field
                    key={index}
                    name={`codigo[${index}]`}
                    as={Input}
                    maxLength={1}
                    className="text-center rounded-[4px]"
                    style={{ marginRight: "13px", height: "60px", textAlign: "center", width: "50px" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, index, setFieldValue, values)
                    }
                  />
                ))}
                <Button
                  onClick={() => handleCopy(values)}
                  className="bg-blue3 text-white principal-nav-notify-button2 w-[58px] h-[60px] rounded-[4px]"
                >
                  <CopyOutlined style={{ fontSize: '24px' }} />
                </Button>
              </div>

              <div className="flex justify-center mt-[20px]">
                <Button
                  htmlType="submit"
                  disabled={isSubmitting}
                  className={`bg-blue3 principal-nav-notify-button2 text-white w-[278px] h-[44px] rounded-[4px] ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isSubmitting ? 'Verificando...' : 'Confirmar'}
                </Button>
              </div>

              <h1 className="font-normal text-gray-500 text-[14px] text-center mt-[20px]">
                ¿No te llegó el código?{" "}
                {countdown > 0 ? (
                  <span className="font-semibold">{`Por favor espera ${countdown} s`}</span>
                ) : (
                  <Button
                    type="link"
                    onClick={() => {
                      onConfirmResend();
                      setCountdown(30);
                      setIsResendEnabled(false);
                    }}
                    disabled={!isResendEnabled}
                  >
                    Reenviar
                  </Button>
                )}
              </h1>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ValuationModal;
