import React, { useEffect, useState } from "react";
import { Layout, Button, Skeleton } from "antd";
import RatingBlue from "../../rating/RatingBlue";
import "tailwindcss/tailwind.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/store";
import { GetEmpresasBySectorReducer } from "../../../redux/actions/pages/company/GetCompanySector";
import { GetOfertasByEmpresaIdReducer } from "../../../redux/actions/pages/company/GetCompanyOffers";
import { GetEmpresasSameSectorReducer } from "../../../redux/actions/pages/company/GetCompanySame";

const { Sider } = Layout;

interface CompanySidebarProps {
  empresaId?: string;
  companyName?: string;
}

const CompanySidebar: React.FC<CompanySidebarProps> = ({
  empresaId,
  companyName,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const [empresasLimit, setEmpresasLimit] = useState(5);
  const [loadedEmpresas, setLoadedEmpresas] = useState<any[]>([]); // Empresas cargada
  const [hasMore, setHasMore] = useState(false); // Saber si hay más empresas
  const [loadingMore, setLoadingMore] = useState(false); // Estado para más carga

  const [ofertasLimit, setOfertasLimit] = useState(5);
  const [loadedOfertas, setLoadedOfertas] = useState<any[]>([]);
  const [hasMoreOfertas, setHasMoreOfertas] = useState(false); // Saber si hay más ofertas
  const [loadingMoreOfertas, setLoadingMoreOfertas] = useState(false); // Estado para más carga de ofertas

  const [sameLimit, setSameLimit] = useState(5);
  const [loadedSame, setLoadedSame] = useState<any[]>([]);
  //const [hasMoreSame, setHasMoreSame] = useState(false); // Saber si hay más Same
  const [hasMoreSame, setHasMoreSame] = useState(false)
  const [loadingMoreSame, setLoadingMoreSame] = useState(false); // Estado para más carga de ofertas

  const empresasBySectorState = useSelector(
    (state: RootState) => state.getEmpresasBySector
  );

  const ofertasByEmpresaState = useSelector(
    (state: RootState) => state.getEmpresaOffers
  );

  const similarBySameState = useSelector(
    (state: RootState) => state.getCompanySame
  );

  // Cargar empresas con el límite actual
  const loadEmpresas = async (limit: number, isLoadMore: boolean = false) => {
    if (empresaId) {
      if (isLoadMore) {
        setLoadingMore(true); // Mostrar Skeleton solo cuando estás cargando más datos
      }

      const response = await dispatch(
        GetEmpresasBySectorReducer(empresaId, limit)
      );

      if (response.payload) {
        const empresas = response.payload.data;

        // Evitar duplicados al añadir nuevas empresas
        if (isLoadMore) {
          const uniqueEmpresas = empresas.filter(
            (empresa: any) =>
              !loadedEmpresas.some((loaded) => loaded.id === empresa.id)
          );
          setLoadedEmpresas((prevEmpresas) => [
            ...prevEmpresas,
            ...uniqueEmpresas,
          ]); // Añadir solo las nuevas empresas
        } else {
          setLoadedEmpresas(empresas); // Cargar empresas iniciales
        }

        // Si el número de empresas cargadas es igual al límite, podría haber más empresas
        setHasMore(empresas.length === limit);
        setLoadingMore(false); // Ocultar Skeleton
      }
    }
  };

  const loadOfertas = async (limit: number, isLoadMore: boolean = false) => {
    if (empresaId) {
      if (isLoadMore) {
        setLoadingMoreOfertas(true); // Mostrar Skeleton solo cuando estás cargando más datos
      }

      const response = await dispatch(
        GetOfertasByEmpresaIdReducer(empresaId, limit)
      );

      if (response.payload) {
        const ofertas = response.payload.data;

        // Evitar duplicados al añadir nuevas ofertas
        if (isLoadMore) {
          const uniqueOfertas = ofertas.filter(
            (oferta: any) =>
              !loadedOfertas.some((loaded) => loaded.id === oferta.id)
          );
          setLoadedOfertas((prevOfertas) => [...prevOfertas, ...uniqueOfertas]); // Añadir solo las nuevas ofertas
        } else {
          setLoadedOfertas(ofertas); // Cargar ofertas iniciales
        }

        // Si el número de ofertas cargadas es igual al límite, podría haber más ofertas
        setHasMoreOfertas(ofertas.length === limit);
        setLoadingMoreOfertas(false); // Ocultar Skeleton
      }
    }
  };

  const loadSame = async (limit: number, isLoadMore: boolean = false) => {
    if (empresaId) {
      if (isLoadMore) {
        setLoadingMoreSame(true);
      }

      const response = await dispatch(GetEmpresasSameSectorReducer(empresaId, limit));

      if (response.payload) {
        const sameL = response.payload.data;

        if (isLoadMore) {
          const uniqueSames = sameL.filter(
            (same: any) =>
              !loadedSame.some((loaded) => loaded.id === same.id)
          );
          setLoadedSame((prevSameL) => [...prevSameL, ...uniqueSames]);
        } else {
          setLoadedSame(sameL);
        }

        // Este console.log te ayudará a ver cuántos datos devuelve el API
        console.log("Datos devueltos por el API:", sameL.length, "Límite:", limit);

        // Si la cantidad de empresas devueltas es igual al límite, significa que puede haber más empresas para cargar
        setHasMoreSame(sameL.length === limit); // Si se devuelven exactamente `limit` empresas, puede haber más
        setHasMoreSame(sameL.length >= limit);
        setLoadingMoreSame(false);
      }
    }
  };


  // Efecto inicial para cargar los primeros 3 elementos
  useEffect(() => {
    loadEmpresas(5); // Inicia con 3
    loadOfertas(5);
    loadSame(5);
  }, [empresaId]);

  // Cargar más datos
  const handleLoadMore = async () => {
    const newLimit = empresasLimit + 5;
    setEmpresasLimit(newLimit);
    await loadEmpresas(newLimit, true); // Cargar más empresas con el nuevo límite y activar `loadingMore`
  };

  // Mostrar menos datos
  const handleShowLess = async () => {
    setEmpresasLimit(5);
    await loadEmpresas(5); // Volver a cargar solo 3
  };

  const handleLoadOfertas = async () => {
    const newLimit = ofertasLimit + 5;
    setOfertasLimit(newLimit);
    await loadOfertas(newLimit, true); // Cargar más empresas con el nuevo límite y activar `loadingMore`
  };

  const handleShowLessOfertas = async () => {
    setOfertasLimit(5);
    await loadOfertas(5); // Volver a cargar solo 3
  };


  const handleLoadMoreSame = async () => {
    const newLimit = sameLimit + 5; // Incrementar en 3
    setSameLimit(newLimit);
    await loadSame(newLimit, true); // Cargar más empresas similares
  };

  const handleShowLessSame = async () => {
    setSameLimit(5); // Reiniciar el límite a 3
    await loadSame(5); // Cargar solo 3 de nuevo
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Esto devuelve algo como "10/18/2024" dependiendo del formato regional
  };

  return (
    <Sider
      width={"100%"}
      className="bg-white p-6 rounded-lg shadow-md border border-blue4"
      style={{ boxShadow: "0 4px 6px rgba(0, 0, 255, 0.2)" }}
    >
      <div className="mb-[24px]">
        <h3 className="font-bold text-heading-x1 mb-3 text-gray">
          Empresas mejor valoradas del sector
        </h3>
        <ul className="mb-3">
          {similarBySameState.loading && !loadingMoreSame ? (
            [...Array(5)].map((_, idx) => (
              <li key={idx} className="mb-2">
                <Skeleton active paragraph={{ rows: 1 }} />
              </li>
            ))
          ) : similarBySameState.error ? (
            <li>Error al cargar empresas: {similarBySameState.error}</li>
          ) : loadedSame && loadedSame.length > 0 ? (
            <>
              {loadedSame.slice(0, sameLimit).map((same: any) => (
                <li
                  key={same.id}
                  className="flex justify-between items-center mb-2"
                  onClick={() => {
                    console.log(same);
                  }}
                >
                  <span
                    className="text-body-md font-medium text-gray inline-block max-w-[65%] whitespace-nowrap overflow-hidden text-ellipsis"
                    title={same.empresa}
                  >
                    {same.empresa}
                  </span>
                  <span className="flex justify-end w-auto">
                    <RatingBlue
                      filledStars={same.promedioValoraciones || 0}
                      totalStars={5}
                      filledStarSize={{
                        width: "15px",
                        height: "15px",
                        marginTop: "2px",
                        marginRight: "4px",
                      }}
                      emptyStarSize={{
                        width: "21px",
                        height: "21px",
                        marginLeft: "-2px"
                      }}

                      showRatingValue={false}
                    />
                  </span>
                </li>
              ))}

              {loadingMoreSame && (
                <li className="flex justify-center my-4">
                  <Skeleton active paragraph={{ rows: 1 }} />
                </li>
              )}
            </>
          ) : (
            <li>No hay empresas disponibles</li>
          )}
        </ul>

        {!similarBySameState.loading && loadedSame.length > 0 && (
          <div className="flex justify-center">
            {hasMoreSame && !loadingMoreSame && (
              <Button
                type="link"
                className="text-blue3 underline font-semibold text-body-sm"
                onClick={handleLoadMoreSame}
              >
                Ver más
              </Button>
            )}
            {sameLimit > 5 && (
              <Button
                type="link"
                className="text-blue3 underline font-semibold text-body-sm"
                onClick={handleShowLessSame}
              >
                Ver menos
              </Button>
            )}
          </div>
        )}
      </div>





      {/* Sección de Últimas ofertas */}
      <div className="mb-[24px]">
        <h3 className="font-bold text-heading-x1 mb-3 text-gray">
          Últimas ofertas de {companyName || "la empresa"}
        </h3>
        <ul className="mb-3">
          {ofertasByEmpresaState.loading && !loadingMoreOfertas ? (
            [...Array(5)].map((_, idx) => (
              <li key={idx} className="mb-2">
                <Skeleton active paragraph={{ rows: 1 }} />
              </li>
            ))
          ) : ofertasByEmpresaState.error ? (
            <li>Error al cargar ofertas: {ofertasByEmpresaState.error}</li>
          ) : loadedOfertas && loadedOfertas.length > 0 ? (
            <>
              {loadedOfertas.map((offer: any) => (
                <li key={offer.id} className="mb-4">
                  <div
                    onClick={() => {
                      console.log(offer);
                    }}
                  >
                    <span
                      className="font-bold text-body-md inline-block max-w-[90%] whitespace-nowrap overflow-hidden text-ellipsis"
                      title={offer.cargo}
                    >
                      {offer.cargo}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <p
                      className="text-body-md font-medium inline-block max-w-[60%] whitespace-nowrap overflow-hidden text-ellipsis"
                      title={offer.ubi_poblacion + " | " + offer.ubi_provincia}
                    >
                      {offer.ubi_poblacion} {" | "} {offer.ubi_provincia}
                    </p>
                    <p className="text-body-md font-normal">
                      {formatDate(offer.createdAt)}
                    </p>
                  </div>
                </li>
              ))}

              {loadingMoreOfertas && (
                <li className="flex justify-center my-4">
                  <Skeleton active paragraph={{ rows: 1 }} />
                </li>
              )}
            </>
          ) : (
            <li>No hay ofertas disponibles</li>
          )}
        </ul>

        {!ofertasByEmpresaState.loading && loadedOfertas.length > 0 && (
          <div className="flex justify-center">
            {hasMoreOfertas && !loadingMoreOfertas && (
              <Button
                type="link"
                className="text-blue3 underline font-semibold text-body-sm"
                onClick={handleLoadOfertas}
              >
                Ver más
              </Button>
            )}
            {ofertasLimit > 5 && (
              <Button
                type="link"
                className="text-blue3 underline font-semibold text-body-sm"
                onClick={handleShowLessOfertas}
              >
                Ver menos
              </Button>
            )}
          </div>
        )}
      </div>

      {/* Empresas similares */}
      <div>
        <h3 className="font-bold text-heading-x1 mb-3 text-gray">
          Empresas similares que te pueden interesar
        </h3>
        <ul className="mb-3">
          {empresasBySectorState.loading && !loadingMore ? (
            [...Array(5)].map((_, idx) => (
              <li key={idx} className="mb-2">
                <Skeleton active paragraph={{ rows: 1 }} />
              </li>
            ))
          ) : empresasBySectorState.error ? (
            <li>Error al cargar empresas: {empresasBySectorState.error}</li>
          ) : loadedEmpresas && loadedEmpresas.length > 0 ? (
            <>
              {loadedEmpresas.map((company: any) => (
                <li
                  key={company.id} className="mb-1"
                  onClick={() => {
                    console.log(company);
                  }}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-body-md">
                      {company.empresa}
                    </span>
                    <Button
                      type="link"
                      className="p-0 text-blue3 font-medium text-caption"
                    >
                      {company._count.ofertas} ofertas
                    </Button>
                  </div>
                  <div className="border-t border-gray2 -mt-1"></div>
                </li>
              ))}

              {loadingMore && (
                <li className="flex justify-center my-4">
                  <Skeleton active paragraph={{ rows: 1 }} />
                </li>
              )}
            </>
          ) : (
            <li>No hay empresas disponibles</li>
          )}
        </ul>
        {!empresasBySectorState.loading && loadedEmpresas.length > 0 && (
          <div className="flex justify-center">
            {hasMore && !loadingMore && (
              <Button
                type="link"
                className="text-blue3 underline font-semibold text-body-sm"
                onClick={handleLoadMore}
              >
                Ver más
              </Button>
            )}
            {empresasLimit > 5 && (
              <Button
                type="link"
                className="text-blue3 underline font-semibold text-body-sm"
                onClick={handleShowLess}
              >
                Ver menos
              </Button>
            )}
          </div>
        )}

      </div>
    </Sider >
  );
};

export default CompanySidebar;
