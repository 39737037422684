import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button, Input, Drawer, Card, message } from "antd";
import { PlusOutlined, MenuOutlined, CloseCircleOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import IconSearch from "../../assets/icons/search.svg";
import SidebarSearch from "../../components/pages/searchCV/SidebarSearchCV";
import List from "../../assets/icons/view_list.svg";
import Stream from "../../assets/icons/view_stream.svg";
import SortingOptions from "../../components/pages/searchCV/SortingOptions";
import IconButton from "../../components/pages/searchCV/IconButton";
import TableSearchCV from "../../components/pages/searchCV/TableSearchCV";
import ListSearchCV from "../../components/pages/searchCV/ListSearchCV";
import InfiniteScroll from "react-infinite-scroll-component";
import QuestionModal from "../../components/pages/searchCV/ModalSearch";
import { AppDispatch, RootState } from "../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { GetPaisesReducer } from "../../redux/actions/pages/emplotment/searchCountry";
import SelectBasic from "../../components/pages/offers/CreateOffers/SelectBasic";
import { getCvGeneralReducer } from "../../redux/actions/pages/searchCV/GetSearchCV";
import { DeleteProfileSkillsReducer, FetchProfileSkillReducer, GetProfilesSkillsReducer } from "../../redux/actions/offers/skills/ProfileSkills";
import ModalEliminarProfileSkill from "../../components/modals/ModalEliminarProfileSkill";
import ModalEditarPerfilSoftSkill from "../../components/pages/searchCV/ModalEditarPerfilSoftSkill";
import ModalGuardarBusqueda from "../../components/modals/ModalGuardarBusqueda";
import { SaveSavedSearchesReducer } from "../../redux/actions/common/savedSearches/SavedSearches";

interface SoftSkillFiltro {
  nivel: number,
  soft_skill: string,
  key: string
}

interface FilterParams {
  educations?: string[];
  keywords?: string[];
  ratings?: number[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
  experiences?: number[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  educationsUsers?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  modalidad?: string | null,
  jornada?: string[],
  excluirEmpresa?: string[],
  softSkillsFiltro?: SoftSkillFiltro[]
}

const clearFilters = {
  educations: [],
  keywords: [],
  sectors: [],
  tamanioArray: [],
  experiences: [],
  ratings: [],
  valoraciones_usuariosmax: false,
  paises: [],
  ciudades: [],
  softSkills: [],
  experienciaLaboral: [],
  educationsUsers: [],
  excluirEducationsUsers: [],
  excluirExperienciaLaboral: [],
  companies: [],
  modalidad: null,
  jornada: [],
  excluirEmpresa: []
}

const SearchCV: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    rex_loading,
    rex_paises,
  } = useSelector(({ paises }: any) => paises);

  const {
    rex_profiles_skills,
  } = useSelector(({ profileSkills }: any) => profileSkills);

  const {
    cvGeneral,
    rex_total,
    rex_limit,
  } = useSelector(({ cvgeneral }: any) => cvgeneral)

  const paises = useSelector((state: RootState) => state.paises.rex_paises);

  const [selectedView, setSelectedView] = useState<string>("list");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDeleteProfileSoftSkill, setShowDeleteProfileSoftSkill] = useState(false)
  const [showSaveSearch, setShowSaveSearch] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [savedSearchQuery, setSavedSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false);
  const [countryForTable, setCountryForTable] = useState('');
  const [cityForTable, setCityForTable] = useState('');
  const [sliderValues, setSliderValues] = useState<number[]>([1, 6]);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [selectedprofileSk, setSelectedprofileSk] = useState<number | null>(null)
  const [idDeleteProfileSk, setIdDeleteProfileSk] = useState<number | null>(null)
  const [selectedCountryName, setSelectedCountryName] = useState<string>("");
  const [selectedSortColumn, setSelectSortColumn] = useState<string>("creatAt")
  const [sortOrderColumn, setSortOrderColumn] = useState<string | null>(null)
  const [activeSearch, setActiveSearch] = useState<boolean>(true)
  const [openEditProfileSkill, setOpenEditProfileSkill] = useState<boolean>(false)
  const [selectedIdCity, setSelectedIdCity] = useState(null)
  const [nameSavedSearch, setNameSavedSearch] = useState('')
  const [filterParams, setFilterParams] = useState<FilterParams>(clearFilters);

  const clearCountry = () => {
    setSelectedCountry(null)
    setCountryForTable('');
    // setCityForTable('');
    // setSelectedCity('');
    setSelectedCountryName('')
  };

  const clearCity = () => {
    setCityForTable('')
    setSelectedCity('')
    setSelectedIdCity(null)
  }

  const handleResetFilters = () => {
    setFilterParams(clearFilters)
  }

  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));
    setPage(1);
    setHasMore(true);
    setLoading(true);
  };

  const handleViewSelect = (view: string) => {
    setSelectedView(view);
  };

  const handleSortSelect = (option: string) => {
    setSelectSortColumn(option)
    console.log("Selected sorting option:", option);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (e?: React.KeyboardEvent | React.MouseEvent) => {
    if (e && "key" in e && e.key !== "Enter") {
      console.log('entre aqui 1')
      return
    };

    setSavedSearchQuery(searchQuery);
    searchData()
  }

  const editProfileSoftSkill = (id: number) => {
    setOpenEditProfileSkill(true)
    dispatch(FetchProfileSkillReducer(id))
  }

  const deleteProfileSoftSkill = (id: number) => {
    setIdDeleteProfileSk(id)
    setShowDeleteProfileSoftSkill(true)
  }

  const changeOrderSortColumn = () => {

    setSortOrderColumn((prev) => {
      if (prev === "desc") {
        return "asc"
      }
      if (prev === "asc") {
        handleSortSelect('creatAt')
        return null
      }
      return "desc"
    })
  }

  const deleteProfileSoftSkills = async () => {
    if (idDeleteProfileSk) {
      const respuesta = await dispatch(DeleteProfileSkillsReducer(idDeleteProfileSk))
      if (respuesta) {
        message.success('Perfil eliminado con exito!')
        setIdDeleteProfileSk(null)
        setShowDeleteProfileSoftSkill(false)
        handleFilterChange({ softSkills: undefined })
        return
      }
    }
    message.success('Error, operacion no realizada')
  }

  const saveSavedSearch = async () => {
    if (nameSavedSearch.length > 0) {
      const rpta = await dispatch(SaveSavedSearchesReducer(searchQuery, nameSavedSearch, selectedCountry, selectedIdCity))
      if (rpta.respuesta) {
        message.success('Búsqueda guardada con éxito')
        setShowSaveSearch(false)
      } else {
        message.error('Ha ocurrido un error al guardar la búsqueda')
      }
    } else {
      message.warning('El nombre es necesario')
    }
  }

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);
    setTimeout(() => {
      const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined
      const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
      const selectedExperiences = filterParams.experiences && filterParams.experiences?.length > 0 ? filterParams.experiences : undefined
      const selectedValoracionesMax = filterParams.valoraciones_usuariosmax ? filterParams.valoraciones_usuariosmax : undefined
      const selectedSectors = filterParams.sectors && filterParams.sectors?.length > 0 ? filterParams.sectors : undefined
      const selectedPaises = filterParams.paises && filterParams.paises?.length > 0 ? filterParams.paises : undefined
      const selectedCiudades = filterParams.ciudades && filterParams.ciudades?.length > 0 ? filterParams.ciudades : undefined
      const selectedSoftSkills = filterParams.softSkills && filterParams.softSkills?.length > 0 ? filterParams.softSkills : undefined
      const selectedExperienciaLaboral = filterParams.experienciaLaboral && filterParams.experienciaLaboral?.length > 0 ? filterParams.experienciaLaboral : undefined
      const selectedExcluirExperienciaLaboral = filterParams.excluirExperienciaLaboral && filterParams.excluirExperienciaLaboral?.length > 0 ? filterParams.excluirExperienciaLaboral : undefined
      const selectedCompanies = filterParams.companies && filterParams.companies?.length > 0 ? filterParams.companies : undefined
      const selectedModalidad = filterParams.modalidad ? filterParams.modalidad : undefined
      const selectedJornada = filterParams.jornada && filterParams.jornada?.length > 0 ? filterParams.jornada : undefined
      const countryToUse = countryForTable;
      const cityToUse = cityForTable
      const selectedExcluirEmpresa = filterParams.excluirEmpresa && filterParams.excluirEmpresa?.length > 0 ? filterParams.excluirEmpresa : undefined
      const selectedSoftSkillsFiltro = filterParams.softSkillsFiltro && filterParams.softSkillsFiltro?.length > 0 ? filterParams.softSkillsFiltro : undefined

      dispatch(getCvGeneralReducer(
        false,
        nextPage,
        rex_limit,
        selectedSortColumn,
        selectedEducations,
        savedSearchQuery,
        countryToUse,
        cityToUse,
        selectedExperiences,
        selectedRatings,
        selectedValoracionesMax,
        selectedSectors,
        selectedPaises,
        selectedCiudades,
        selectedSoftSkills,
        selectedExperienciaLaboral,
        selectedExcluirExperienciaLaboral,
        selectedCompanies,
        sortOrderColumn,
        activeSearch,
        selectedModalidad,
        selectedJornada,
        selectedExcluirEmpresa,
        selectedSoftSkillsFiltro
      )).then(() => {
        setPage(nextPage);
        setLoadingMoreData(false);
        if (nextPage >= totalPages) {
          setHasMore(false);
        }
      })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (selectedCountry && rex_paises.length > 0) {
      const country = rex_paises.find((c: any) => c.id === selectedCountry);
      if (country) {
        const citiesCountry = country.ciudades.find((ciu: any) => ciu.ciudad == cityForTable)
        if (!citiesCountry) {
          setSelectedIdCity(null)
          setSelectedCity('')
          setCityForTable('')
        }
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, rex_paises]);

  useEffect(() => {
    dispatch(GetPaisesReducer())
    dispatch(GetProfilesSkillsReducer())
  }, []);

  useEffect(() => {
    if (selectedCity && selectedCountry) {
      console.log('dasdasasddsadasdsaasdasdasdad')
      const selectedCountryData = paises.find((pais: any) => pais.id === selectedCountry)
      const countryName = selectedCountryData?.pais || '';
      setSelectedCountryName(countryName);
    }
  }, [selectedCity, selectedCountry])

  const searchData = () => {

    const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined;
    const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
    const selectedExperiences = filterParams.experiences && filterParams.experiences?.length > 0 ? filterParams.experiences : undefined
    const selectedValoracionesMax = filterParams.valoraciones_usuariosmax ? filterParams.valoraciones_usuariosmax : undefined
    const selectedSectors = filterParams.sectors && filterParams.sectors?.length > 0 ? filterParams.sectors : undefined
    const selectedPaises = filterParams.paises && filterParams.paises?.length > 0 ? filterParams.paises : undefined
    const selectedCiudades = filterParams.ciudades && filterParams.ciudades?.length > 0 ? filterParams.ciudades : undefined
    const selectedSoftSkills = filterParams.softSkills && filterParams.softSkills?.length > 0 ? filterParams.softSkills : undefined
    const selectedExperienciaLaboral = filterParams.experienciaLaboral && filterParams.experienciaLaboral?.length > 0 ? filterParams.experienciaLaboral : undefined
    const selectedExcluirExperienciaLaboral = filterParams.excluirExperienciaLaboral && filterParams.excluirExperienciaLaboral?.length > 0 ? filterParams.excluirExperienciaLaboral : undefined
    const selectedCompanies = filterParams.companies && filterParams.companies?.length > 0 ? filterParams.companies : undefined
    const selectedModalidad = filterParams.modalidad ? filterParams.modalidad : undefined
    const selectedJornada = filterParams.jornada && filterParams.jornada?.length > 0 ? filterParams.jornada : undefined
    const selectedExcluirEmpresa = filterParams.excluirEmpresa && filterParams.excluirEmpresa?.length > 0 ? filterParams.excluirEmpresa : undefined
    const selectedSoftSkillsFiltro = filterParams.softSkillsFiltro && filterParams.softSkillsFiltro?.length > 0 ? filterParams.softSkillsFiltro : undefined

    dispatch(
      getCvGeneralReducer(
        true,
        1,
        rex_limit,
        selectedSortColumn,
        selectedEducations,
        searchQuery,
        countryForTable,
        cityForTable,
        selectedExperiences,
        selectedRatings,
        selectedValoracionesMax,
        selectedSectors,
        selectedPaises,
        selectedCiudades,
        selectedSoftSkills,
        selectedExperienciaLaboral,
        selectedExcluirExperienciaLaboral,
        selectedCompanies,
        sortOrderColumn,
        activeSearch,
        selectedModalidad,
        selectedJornada,
        selectedExcluirEmpresa,
        selectedSoftSkillsFiltro
      )
    ).then(() => setLoading(false));
  }

  useEffect(() => {
    setLoading(true);
    setPage(1);
    setHasMore(true);

    const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined;
    const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
    const selectedExperiences = filterParams.experiences && filterParams.experiences?.length > 0 ? filterParams.experiences : undefined
    const selectedValoracionesMax = filterParams.valoraciones_usuariosmax ? filterParams.valoraciones_usuariosmax : undefined
    const selectedSectors = filterParams.sectors && filterParams.sectors?.length > 0 ? filterParams.sectors : undefined
    const selectedPaises = filterParams.paises && filterParams.paises?.length > 0 ? filterParams.paises : undefined
    const selectedCiudades = filterParams.ciudades && filterParams.ciudades?.length > 0 ? filterParams.ciudades : undefined
    const selectedSoftSkills = filterParams.softSkills && filterParams.softSkills?.length > 0 ? filterParams.softSkills : undefined
    const selectedExperienciaLaboral = filterParams.experienciaLaboral && filterParams.experienciaLaboral?.length > 0 ? filterParams.experienciaLaboral : undefined
    const selectedExcluirExperienciaLaboral = filterParams.excluirExperienciaLaboral && filterParams.excluirExperienciaLaboral?.length > 0 ? filterParams.excluirExperienciaLaboral : undefined
    const selectedCompanies = filterParams.companies && filterParams.companies?.length > 0 ? filterParams.companies : undefined
    const selectedModalidad = filterParams.modalidad ? filterParams.modalidad : undefined
    const selectedJornada = filterParams.jornada && filterParams.jornada?.length > 0 ? filterParams.jornada : undefined
    const selectedExcluirEmpresa = filterParams.excluirEmpresa && filterParams.excluirEmpresa?.length > 0 ? filterParams.excluirEmpresa : undefined
    const selectedSoftSkillsFiltro = filterParams.softSkillsFiltro && filterParams.softSkillsFiltro?.length > 0 ? filterParams.softSkillsFiltro : undefined

    if (selectedCountry && cityForTable) {
      console.log('111111111111111')
      dispatch(
        getCvGeneralReducer(
          true,
          1,
          rex_limit,
          selectedSortColumn,
          selectedEducations,
          savedSearchQuery,
          countryForTable,
          cityForTable,
          selectedExperiences,
          selectedRatings,
          selectedValoracionesMax,
          selectedSectors,
          selectedPaises,
          selectedCiudades,
          selectedSoftSkills,
          selectedExperienciaLaboral,
          selectedExcluirExperienciaLaboral,
          selectedCompanies,
          sortOrderColumn,
          activeSearch,
          selectedModalidad,
          selectedJornada,
          selectedExcluirEmpresa,
          selectedSoftSkillsFiltro
        )
      ).then(() => setLoading(false));
    }
    else if (selectedCountry && selectedCity && savedSearchQuery) {
      console.log('22222222222222')
      const selectedCountryData = paises.find((pais: any) => pais.id === selectedCountry);
      const countryName = selectedCountryData?.pais || '';
      setSelectedCountryName(countryName);
      dispatch(
        getCvGeneralReducer(
          true,
          1,
          rex_limit,
          selectedSortColumn,
          selectedEducations,
          savedSearchQuery,
          countryForTable,
          cityForTable,
          selectedExperiences,
          selectedRatings,
          selectedValoracionesMax,
          selectedSectors,
          selectedPaises,
          selectedCiudades,
          selectedSoftSkills,
          selectedExperienciaLaboral,
          selectedExcluirExperienciaLaboral,
          selectedCompanies,
          sortOrderColumn,
          activeSearch,
          selectedModalidad,
          selectedJornada,
          selectedExcluirEmpresa,
          selectedSoftSkillsFiltro
        )
      ).then(() => setLoading(false));
    }
    else if (savedSearchQuery) {
      console.log('3333333333333333')
      dispatch(
        getCvGeneralReducer(
          true,
          1,
          rex_limit,
          selectedSortColumn,
          selectedEducations,
          savedSearchQuery,
          countryForTable,
          cityForTable,
          selectedExperiences,
          selectedRatings,
          selectedValoracionesMax,
          selectedSectors,
          selectedPaises,
          selectedCiudades,
          selectedSoftSkills,
          selectedExperienciaLaboral,
          selectedExcluirExperienciaLaboral,
          selectedCompanies,
          sortOrderColumn,
          activeSearch,
          selectedModalidad,
          selectedJornada,
          selectedExcluirEmpresa,
          selectedSoftSkillsFiltro
        )
      ).then(() => setLoading(false));
    }
    else {
      console.log('44444444444444444444')
      dispatch(
        getCvGeneralReducer(
          true,
          1,
          rex_limit,
          selectedSortColumn,
          selectedEducations,
          savedSearchQuery,
          countryForTable,
          cityForTable,
          selectedExperiences,
          selectedRatings,
          selectedValoracionesMax,
          selectedSectors,
          selectedPaises,
          selectedCiudades,
          selectedSoftSkills,
          selectedExperienciaLaboral,
          selectedExcluirExperienciaLaboral,
          selectedCompanies,
          sortOrderColumn,
          activeSearch,
          selectedModalidad,
          selectedJornada,
          selectedExcluirEmpresa,
          selectedSoftSkillsFiltro
        )
      ).then(() => setLoading(false));
    }
  }, [filterParams, rex_limit, selectedSortColumn, sortOrderColumn, activeSearch])

  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (cvGeneral?.length == rex_total) {
        setHasMore(false)
      }
    }
  }, [rex_total, cvGeneral])

  useEffect(() => {
    if (selectedView === 'list') {
      setPage(1);
      setHasMore(true);
    } else if (selectedView === 'table') {
      setHasMore(false);
    }
  }, [selectedView]);

  return (
    <div className="flex justify-center">
      <div className="w-full 2x2:w-[1370px]">
        <div className="bg-white px-4 lg:px-8 py-8 lg:py-12">
          <div className="flex flex-col lg:flex-row">

            <Button
              type="primary"
              className="lg:hidden mb-3"
              onClick={() => setDrawerVisible(true)}
              icon={<MenuOutlined />}
            />
            {/* <Button
            onClick={()=>{
              console.log('rex_limit',rex_limit)
              console.log('rex_total',rex_total)
              console.log('page',page)
              console.log('hasmore:',hasMore)
              console.log('selectedCountry:',selectedCountry)
              console.log('selectCity:',selectedCity)
              console.log('selectedCountryName:',selectedCountryName)
              console.log('countryForTable:',countryForTable)
              console.log('cityForTable:',cityForTable)
              console.log('savedSearchQuery:',savedSearchQuery)
            }}>
              AQUI
            </Button> */}
            <div
              style={{ width: "234px", backgroundColor: "white" }}
              className="hidden lg:block p-0"
            >
              <SidebarSearch
                onFilterChange={handleFilterChange}
                currentFilters={filterParams}
                activeSearch={activeSearch}
                setActiveSearch={setActiveSearch}
                resetFilters={handleResetFilters}
              />
            </div>




            <Drawer
              title={null}
              placement="left"
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
              drawerStyle={{ padding: 0 }}
              width={268}
              className="block lg:hidden"
            >
              <SidebarSearch
                inDrawer
                activeSearch={activeSearch}
                setActiveSearch={setActiveSearch}
                resetFilters={handleResetFilters}
              />
            </Drawer>





            <div className="flex-1 pl-0 lg:pl-6">
              <>
                <h2 className="text-heading-md font-bold mb-4">
                  Resultado de la búsqueda de CV
                </h2>

                <Row gutter={[12, 12]}>
                  <Col xs={24} xl={17}>
                    <Row gutter={[12, 12]}>
                      <Col xs={24} lg={10}>
                        <Input
                          prefix={<img src={IconSearch} width={24} height={24} />}
                          value={searchQuery}
                          placeholder="Buscar por trabajo"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={handleSearch}
                          className="Input-Filter-Employment w-full custom-input-company px-2 rounded"
                        />
                      </Col>
                      <Col xs={12} md={8} lg={5}>
                        <SelectBasic
                          placeholder="País"
                          style={{ height: '44px' }}
                          value={selectedCountry || undefined}
                          onChange={(value: any, option: any) => {
                            setSelectedCountry(value)
                            setCountryForTable(option.label)
                          }}
                          loading={rex_loading}
                          options={rex_paises.map((country: any) => ({
                            value: country.id,
                            label: country.pais
                          }))}
                          suffixIcon={
                            selectedCountry ? (
                              <CloseCircleOutlined
                                onClick={clearCountry}
                                style={{ color: "black", cursor: "pointer" }}
                              />
                            ) : undefined
                          }
                        />
                      </Col>
                      <Col xs={12} md={8} lg={5}>
                        <SelectBasic
                          placeholder="Ciudad"
                          style={{ height: '44px' }}
                          disabled={!selectedCountry && !selectedCity}
                          value={selectedCity ? { value: selectedCity, label: selectedCity } : null}
                          onChange={(value, option: any) => {
                            setSelectedIdCity(option.id)
                            setSelectedCity(option.value)
                            setCityForTable(option.value)
                          }}
                          options={availableCities.map((city: any) => ({
                            value: city.ciudad,
                            label: city.ciudad,
                            id: city.id
                          }))}
                          suffixIcon={
                            selectedIdCity ? (
                              <CloseCircleOutlined
                                onClick={clearCity}
                                style={{ color: "black", cursor: "pointer" }}
                              />
                            ) : undefined
                          }
                        />
                      </Col>
                      <Col xs={24} md={8} lg={4}>
                        <Button
                          className="bg-blue3 text-white principal-nav-notify-button2 rounded-[4px] w-full"
                          onClick={() => {
                            handleSearch();
                          }}>
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={0} xl={2} xxl={3}>
                  </Col>

                  <Col xs={24} xl={4} xxl={4} className="flex justify-end">
                    <div className="principal-nav-action-buttons flex md:justify-end space-x-2 w-full">
                      <Button
                        className="principal-nav-notify-button font-semibold text-body-sm rounded-[4px] w-full"
                        onClick={() => {
                          if (searchQuery == '' && !selectedCountry && !selectedIdCity) {
                            message.warning('Al menos un campo es requerido')
                            return
                          }
                          setShowSaveSearch(true)
                        }}
                      >
                        Guardar búsqueda
                      </Button>
                    </div>
                  </Col>
                </Row>


                <Row className="mt-3">
                  <Col xl={24} md={24} className="flex justify-start">
                    <div className="principal-nav-action-buttons flex">
                      <Button
                        className="principal-nav-notify-button text-blue3 shadow-none font-semibold text-body-sm lg:w-[326px] h-[36px] ml-0 rounded-[4px] font-sans"
                        onClick={showModal}
                      >
                        <PlusOutlined style={{ color: '#006497' }} />
                        Crear perfil de soft skill para la búsqueda
                      </Button>
                      <QuestionModal
                        visible={isModalVisible}
                        onClose={closeModal}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="flex gap-2 mt-4 flex-wrap">
                  {
                    rex_profiles_skills.map((pro: any, index: number) => (
                      <>
                        <div
                          key={index}
                          className={`border rounded-full h-6 w-auto py-1 px-2 flex justify-center items-center cursor-pointer font-medium text-sm text-[#1A1A1AB2] ${selectedprofileSk == pro.id ? 'border-none bg-blue3 text-white' : ''}`}
                          onClick={() => {
                            if (selectedprofileSk == pro.id) {
                              setSelectedprofileSk(null)
                              handleFilterChange({ softSkills: undefined })
                            } else {
                              handleFilterChange({ softSkills: [pro.nombre] })
                              setSelectedprofileSk(pro.id)
                            }
                          }}
                        >{pro.nombre}
                          <div className="pl-2 flex gap-1">
                            <EditOutlined onClick={(e) => {
                              e.stopPropagation()
                              editProfileSoftSkill(pro.id)
                            }} className="rounded-sm hover:bg-blue4 hover:text-white" />
                            <CloseOutlined onClick={(e) => {
                              e.stopPropagation()
                              deleteProfileSoftSkill(pro.id)
                            }} className="rounded-sm hover:bg-red-300 hover:text-white" />
                          </div>
                        </div>
                      </>
                    ))
                  }
                </div>
                <Row gutter={16} className="mb-[30px]">
                  <Col xs={24} className="mb-[32px]">
                    <div className="pt-3">
                      <div className="flex items-center">
                        <span 
                          className="font-bold text-green32 text-caption mr-[4px]"
                        >
                          ACCIONES DE PERFIL:
                        </span>
                        <div className="flex space-x-[8px]">
                          <button 
                            // className="text-[#00476D] font-bold text-caption"
                            className="text-green32 font-bold text-caption cursor-not-allowed"
                          >
                            Añadir a favoritos
                          </button>
                          <span className="font-bold text-xs">|</span>
                          <button 
                            // className="text-[#00476D] font-bold text-caption"
                            className="text-green32 font-bold text-caption cursor-not-allowed"
                          >
                            Guardar perfil
                          </button>
                          <span className="font-bold text-xs">|</span>
                          <button 
                            // className="text-[#DC2828] font-bold text-caption"
                            className="text-green32 font-bold text-caption cursor-not-allowed"
                          >
                            Archivar candidato
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} md={24} lg={24} className="">
                    <Row gutter={24}>
                      <Col xs={24} md={20}>
                        <SortingOptions
                          changeOrderSortColumn={changeOrderSortColumn}
                          sortOrderColumn={sortOrderColumn}
                          onSelect={handleSortSelect}
                          setSortOrderColumn={setSortOrderColumn}
                        />
                      </Col>
                      <Col xs={24} md={4} className="flex justify-end">
                        <IconButton
                          icon={Stream}
                          selected={selectedView === "stream"}
                          onClick={() => handleViewSelect("stream")}
                          altText="Icono de flujo"
                        />
                        <IconButton
                          icon={List}
                          selected={selectedView === "list"}
                          onClick={() => handleViewSelect("list")}
                          altText="Icono de lista"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={24} className="mb-[32px]">
                    {selectedView === "list" ? (
                      <TableSearchCV
                        loading={loading}
                        fetchMoreData={fetchMoreData}
                        educations={filterParams.educations}
                        searchTerm={isSearching ? savedSearchQuery : ''}
                        selectedCountry={countryForTable}
                        selectedCity={cityForTable}
                        hasMore={hasMore} />
                    ) : (
                      <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4">
                        <ListSearchCV
                          fetchMoreData={fetchMoreData}
                          educations={filterParams.educations}
                          searchTerm={isSearching ? savedSearchQuery : ''}
                          selectedCountry={countryForTable}
                          selectedCity={cityForTable}
                          killerQuestion={false}
                          hasMore={hasMore}
                        />

                      </Card>
                    )}
                    {/* <Button
                      onClick={() => {
                        console.log('hasmore:', hasMore)
                      }}>
                      hola
                    </Button>
                    <Button
                      onClick={() => {
                        console.log('rextotal:', rex_total)
                      }}>
                      hola2
                    </Button>
                    <Button
                      onClick={() => {
                        console.log('data:', cvGeneral)
                      }}>
                      data
                    </Button> */}
                    {/* </InfiniteScroll> */}

                  </Col>
                </Row>
              </>
            </div>
            <ModalEliminarProfileSkill
              showModal={showDeleteProfileSoftSkill}
              setShowModal={setShowDeleteProfileSoftSkill}
              onOk={deleteProfileSoftSkills}
            />
            <ModalEditarPerfilSoftSkill
              openModal={openEditProfileSkill}
              setOpenModal={setOpenEditProfileSkill}
            />
            <ModalGuardarBusqueda
              showModal={showSaveSearch}
              setNameSearch={setNameSavedSearch}
              nameSearch={nameSavedSearch}
              setShowModal={setShowSaveSearch}
              onOk={saveSavedSearch}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCV;