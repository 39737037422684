// constants/offer/SearchOffersEmpl.ts

export const SEARCH_OFFERS_EMPL_REQUEST = 'SEARCH_OFFERS_EMPL_REQUEST';
export const SEARCH_OFFERS_EMPL_SUCCESS = 'SEARCH_OFFERS_EMPL_SUCCESS';
export const SEARCH_OFFERS_EMPL_FAILURE = 'SEARCH_OFFERS_EMPL_FAILURE';
export const SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL = 'SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL';
export const RESET_LOCATION_FILTER_PARAMS = 'RESET_LOCATION_FILTER_PARAMS';


export interface FetchSearchOffersEmplRequestAction {
    type: typeof SEARCH_OFFERS_EMPL_REQUEST;
  }

export interface FetchSearchOffersEmplSuccessAction {
    type: typeof SEARCH_OFFERS_EMPL_SUCCESS;
    payload: {
      data: any[]
      meta: { total: number; limit: number; page: number }; 
    };
  }


export interface FetchSearchOffersEmplFailureAction {
    type: typeof SEARCH_OFFERS_EMPL_FAILURE;
    payload: string;
  }

interface SetFilterParamsSearchOffersEmplAction {
    type: typeof SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL;
    payload: any; 
}

interface ResetLocationFilterParamsAction {
  type: typeof RESET_LOCATION_FILTER_PARAMS;
  payload: any; 
}

export type SearchOffersEmplActionTypes =
  | FetchSearchOffersEmplRequestAction
  | FetchSearchOffersEmplSuccessAction
  | FetchSearchOffersEmplFailureAction
  | SetFilterParamsSearchOffersEmplAction
  | ResetLocationFilterParamsAction;
