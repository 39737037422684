// actions/GetApplications.ts
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  FETCH_GET_APPLICATIONS_REQUEST,
  FETCH_GET_APPLICATIONS_SUCCESS,
  FETCH_GET_APPLICATIONS_FAILURE,
  SET_FILTER_PARAMS_APLICATIONS,
  SET_FILTER_PARAMS_APLICATIONS_TWO,
  AplicationsActionTypes
} from '../../../constants/applications/GetApplications';
import FetchWithIP from '../utils/FetchHeaders';

export const fetchAplicationsRequest = (): AplicationsActionTypes => ({
  type: FETCH_GET_APPLICATIONS_REQUEST,
});

export const fetchAplicationsSuccess = (data: any[],  meta: { total: number; limit: number; page: number }): AplicationsActionTypes => ({
  type: FETCH_GET_APPLICATIONS_SUCCESS,
  payload: { data, meta },
});

export const fetchAplicationsFailure = (error: string): AplicationsActionTypes => ({
  type: FETCH_GET_APPLICATIONS_FAILURE,
  payload: error,
});


export const setFilterParamsAplications = (filters: any): AplicationsActionTypes => ({
  type: SET_FILTER_PARAMS_APLICATIONS,
  payload: filters,
});

export const setFilterParamsAplicationsTwo = (filters_two: any): AplicationsActionTypes => ({
  type: SET_FILTER_PARAMS_APLICATIONS_TWO,
  payload: filters_two,
});



export const GetApplicationsReducer = (
  page?: number,
  limit?: number,
  fases_postulaciones?: boolean,
  fase_descartado?: boolean,
  cv_Descargado?: boolean,
  visto_Empresa?: boolean,
  pais?: string,
  ciudad?: string,
  sector?: string,
  cargo?: string,
): ThunkAction<Promise<any>,RootState,unknown,AplicationsActionTypes> => async (dispatch) => {
  dispatch(fetchAplicationsRequest());

  const queryParams = new URLSearchParams({
    sortOrder: 'desc',
    sortColumn: 'id',
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
    ...(pais ?  { ubi_provincia: pais.toString() }: {}),
    ...(ciudad ? { ubi_poblacion: ciudad.toString() }: {}),
    ...(sector ?  { sector: sector.toString() }: {}),
    ...(cargo ?   { cargo: cargo.toString() }: {}),
  }).toString();

  try {

    if (fases_postulaciones) {
      console.log('entre1')
      dispatch(setFilterParamsAplicationsTwo(fases_postulaciones));
    }
    if (fase_descartado) {
      console.log('entre2')
      dispatch(setFilterParamsAplicationsTwo(fase_descartado));
    }
    if (cv_Descargado) {
      console.log('entre1')
      dispatch(setFilterParamsAplicationsTwo(cv_Descargado));
    }
    if (visto_Empresa) {
      console.log('entre2')
      dispatch(setFilterParamsAplicationsTwo(visto_Empresa));
    }

    const locationFilter = [pais, ciudad, sector,cargo].filter(Boolean).join(', ');

    if (locationFilter) {
      dispatch(setFilterParamsAplications({ location: locationFilter }));
    } else {
    }

    const response = await FetchWithIP(`postulaciones/getAllByUserToken?${queryParams}`, { 
      method: 'POST' 
    },{
      fases_postulaciones,
      fase_descartado,
      cv_Descargado,
      visto_Empresa
    }
  )
    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      const { total, limit, page } = responseData.meta;
      dispatch(fetchAplicationsSuccess(
        responseData.data, { total, limit, page}));
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }

    return true

    // const applicationData = response.data
    // // const applicationData = response.data.map((application: any) => ({
    // //   id: application.id,
    // //   usuario_id: application.usuario_id,
    // //   oferta_id: application.oferta_id,
    // //   createdAt: application.createdAt,
    // //   updatedAt: application.updatedAt,
    // //   empresa_id: application.ofertas?.empresa_id || application.empresa_id, // Asegúrate de que esto esté presente
    // //   ofertas: {
    // //     id: application.ofertas.id,
    // //     cargo: application.ofertas.cargo, // Asegúrate de que esto esté presente
    // //     descripcion: application.ofertas.descripcion,
    // //     tipo: application.ofertas.tipo,
    // //     ubi_provincia: application.ofertas.ubi_provincia,
    // //     ubi_poblacion: application.ofertas.ubi_poblacion,
    // //     sal_min: application.ofertas.sal_min,
    // //     sal_max: application.ofertas.sal_max,
    // //     anios_experiencia: application.ofertas.anios_experiencia,
    // //     estudios_minimos: application.ofertas.estudios_minimos,
    // //     tipo_contrato: application.ofertas.tipo_contrato,
    // //     jornada_laboral: application.ofertas.jornada_laboral,
    // //     createdAt: application.ofertas.createdAt,
    // //   },
    // // }));

    // dispatch({
    //   type: FETCH_GET_APPLICATIONS_SUCCESS,
    //   payload: {
    //     data: applicationData,
    //     meta: response.meta,
    //   },
    // });
  } catch (error: any) {
    dispatch(fetchAplicationsFailure(error.message || 'Error al cargar '));
    return { error: error.message };
  }
};
