    // Constants para las acciones relacionadas con la eliminación de educación de usuarios
export const DELETE_EDUCATION_REQUEST = 'DELETE_EDUCATION_REQUEST';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAILURE = 'DELETE_EDUCATION_FAILURE';

// Tipos de las acciones
interface DeleteEducationRequestAction {
  type: typeof DELETE_EDUCATION_REQUEST;
}

interface DeleteEducationSuccessAction {
  type: typeof DELETE_EDUCATION_SUCCESS;
  payload: {
    id: number; // ID de la educación eliminada
  };
}

interface DeleteEducationFailureAction {
  type: typeof DELETE_EDUCATION_FAILURE;
  payload: string;
}

// Exportamos los tipos de las acciones
export type DeleteEducationActionTypes =
  | DeleteEducationRequestAction
  | DeleteEducationSuccessAction
  | DeleteEducationFailureAction;
