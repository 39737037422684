// src/reducers/pages/company/PaqueteUsuarios.ts

import {
    ADD_PAQUETE_USUARIO_REQUEST,
    ADD_PAQUETE_USUARIO_SUCCESS,
    ADD_PAQUETE_USUARIO_FAILURE,
    PaqueteUsuarioActionTypes,
} from '../../../../../constants/pages/controlPanel/Packs/PostPackUser';
  
  interface PaqueteUsuarioState {
    rex_loading: boolean;
    rex_data: any | null;
    rex_error: string | null;
  }
  
  const initialState: PaqueteUsuarioState = {
    rex_loading: false,
    rex_data: null,
    rex_error: null,
  };
  
  export const paqueteUsuarioReducer = (
    state = initialState,
    action: PaqueteUsuarioActionTypes
  ): PaqueteUsuarioState => {
    switch (action.type) {
      case ADD_PAQUETE_USUARIO_REQUEST:
        return {
          ...state,
          rex_loading: true,
        };
      case ADD_PAQUETE_USUARIO_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          rex_data: action.payload.data,
          rex_error: null,
        };
      case ADD_PAQUETE_USUARIO_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default paqueteUsuarioReducer;
  