import {
    FETCH_CHECKOUT_REQUEST,
    FETCH_CHECKOUT_SUCCESS,
    FETCH_CHECKOUT_FAILURE,
    CHECKOUT_PACK_SELECTED,
    CHECKOUT_SESSION_ID,
    CheckoutActionTypes,
} from "../../../../constants/common/checkout/Checkout";

interface CheckoutState {
    rex_loading: boolean;
    rex_data: {};
    rex_error: string | null;
    rex_session_id: string;
    rex_data_pack_selected:{} | null
}

const initialState: CheckoutState = {
    rex_loading: false,
    rex_data: {
        user: {
            id : null,
            identificacion_fiscal: "",
            razon_social: "",
            direccion: "",
            email_facturacion: "",
            usuarios: {
                personas: {
                    nombre: "",
                    apellido_paterno: ""
                }
            }
        },
        pack: {
            paquete: "",
            descripcion: "",
            precio: null,
            caracteristicas_paquetes: [],
            idCompra: null
        }
    },
    rex_session_id: "",
    rex_error: null,
    rex_data_pack_selected:null
};

const CheckoutReducer = (state = initialState, action: CheckoutActionTypes): CheckoutState => {
    switch (action.type) {
        case CHECKOUT_PACK_SELECTED:
            return {
                ...state,
                rex_data_pack_selected: true,
            };
        case FETCH_CHECKOUT_REQUEST:
            return {
                ...state,
                rex_loading: true,
            };
        case FETCH_CHECKOUT_SUCCESS:
            return {
                ...state,
                rex_loading: false,
                rex_data: action.payload,
                rex_error: null,
            };

        case FETCH_CHECKOUT_FAILURE:
            return {
                ...state,
                rex_loading: false,
                rex_error: action.payload,
            };
        case CHECKOUT_SESSION_ID:
            return {
                ...state,
                rex_session_id: action.payload,
            };
        default:
            return state;
    }
};

export default CheckoutReducer;