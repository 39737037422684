import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VALIDATE_RESET_CODE_REQUEST,
  VALIDATE_RESET_CODE_SUCCESS,
  VALIDATE_RESET_CODE_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  ForgotPasswordActionTypes,
  ValidateResetCodeActionTypes,
  ResetPasswordActionTypes,
} from '../../../constants/auth/PasswordReset';

interface PasswordResetState {
  rex_loading: boolean;
  rex_message: string | null;
  rex_error: string | null;
}

const initialState: PasswordResetState = {
  rex_loading: false,
  rex_message: null,
  rex_error: null,
};

export const passwordResetReducer = (
  state = initialState,
  action: ForgotPasswordActionTypes | ValidateResetCodeActionTypes | ResetPasswordActionTypes
): PasswordResetState => {
  switch (action.type) {
    // Forgot Password Reducers
    case FORGOT_PASSWORD_REQUEST:
    case VALIDATE_RESET_CODE_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
    case VALIDATE_RESET_CODE_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_message: action.payload.message,
        rex_error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
    case VALIDATE_RESET_CODE_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };
    default:
      return state;
  }
};

export default passwordResetReducer;
