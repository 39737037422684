import { Button, Col, Modal, Row } from 'antd'
import React from 'react'
import IconClosed from "../../assets/icons/IconClosed.svg";
import { useSelector } from 'react-redux';
import './../../styles/components/modals/ModalConfirmarPuntos.css'

const ModalCrearOferta: React.FC<{
  showModal: boolean;
  setShowModal: any;
  onOk: ()=> any
}> = ({
  showModal,
  setShowModal,
  onOk
}) => {
  
  const {
    rex_credits_user
  } = useSelector(({ paquetesUsuarios }: any) => paquetesUsuarios);

    const credits = 1
    const onClose = () => {
      setShowModal(!showModal)
    }

  return (
    <Modal
      visible={showModal}
      closable={false}
      onCancel={onClose}
      width={800}
      footer={null}
      className='modal-puntos'
    >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16,8]} className='text-center mt-7'>
            <Col span={24} className='text-2xl font-bold font-sans mb-1'>¿Deseas publicar esta oferta?</Col>
            <Col span={24} className='text-base font-medium font-sans'>Créditos disponibles: <span className='text-[#34C759]'>{rex_credits_user.total_creditos_oferta}</span></Col>
            <Col span={24} className='text-base font-medium font-sans'>Créditos que gastarás en esta publicacion: <span className='text-[#DC2828]'>{credits}</span></Col>
        </Row>
        <Row className='flex justify-center mb-5'>
              <div className="flex justify-center mx-[114px] mt-[38px]">
                <Button
                onClick={onClose}
                className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
                >
                Cancelar
                </Button>
                <Button
                  disabled={!(rex_credits_user.total_creditos_oferta >= credits)}
                  onClick={onOk}
                  className={`button-accept ${!(rex_credits_user.total_creditos_oferta >= credits) ? '':'active'} bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
                >
                  Aceptar
                </Button>
            </div>
        </Row>
    </Modal>
  )
}

export default ModalCrearOferta