import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import IconAlert from '../../../../assets/icons/alerts.svg';
import '../../../styles/alertFilter/AlertFilter.css';
import IconGPS from '../../../../assets/icons/IconGPS.svg';
import Filter from '../../../../assets/icons/Filter.svg';
import Valor from '../../../../assets/icons/Valoraciones.svg';

const { Option } = Select;

interface FilterParams {
  ratings?: number[];
  solicitudesRapidas?: boolean
}

interface AlertFilterProps {
  newOffersCount: number;
  onFilterClick: () => void;
  onQuickApplyClick: () => void;
  onRatingsClick: (value: number) => void;
  onProvinceClick: (value: string) => void;
  onCreateAlertClick: () => void;
  newOffersText?: string;
  createAlertText?: string;
  filterButtonText?: string;
  quickApplyButtonText?: string;
  ratingsDefaultText?: string;
  provinceDefaultText?: string;
  provinceOptions?: { value: string, label: string }[];
  ratingsOptions?: { value: number, label: string }[];
  onFilterChange?: (filters: Partial<FilterParams>) => void;
  onResetFilters?: () => void;
}

const AlertFilter: React.FC<AlertFilterProps> = ({
  onFilterChange = () => { },
  newOffersCount,
  onFilterClick,
  onQuickApplyClick,
  onRatingsClick,
  onProvinceClick,
  onCreateAlertClick,
  newOffersText = "Nuevas ofertas de que se adaptan a ti",
  createAlertText = "Crear nueva alerta",
  filterButtonText = "Todas las Ofertas",
  quickApplyButtonText = "Solicitudes rápidas",
  ratingsDefaultText = "",
  provinceDefaultText = "Provincia",
  ratingsOptions = [{ value: 0, label: "Valoraciones" }],
  provinceOptions = [{ value: "province", label: "Provincia" }],
  onResetFilters
}) => {

  const [selectedRatings, setSelectedRatings] = useState<number | string>("Valoraciones");
  const [isQuickApply, setIsQuickApply] = useState(false);

  const [filtroOfertas, setFiltroOfertas] = useState(true);
  const [filtroSolicitudes, setFiltroSolicitudes] = useState(false);
  const [filtroValoraciones, setFiltroValoraciones] = useState(false);

  const handleRatingSelect = (value: number | string) => {
    setFiltroOfertas(false)
    setFiltroValoraciones(true)

    setSelectedRatings(value);
    if (typeof value === 'number') {
      onFilterChange?.({ ratings: [value] });
    } else {
      onFilterChange?.({ ratings: [0] });
    }
  };

  const handleQuickApplyClick = () => {
    setFiltroOfertas(false)
    setFiltroSolicitudes(true)
    // setFiltroValoraciones(false)

    setIsQuickApply(prevState => {
      const newState = !prevState; // Alternar el estado
      onFilterChange?.({ solicitudesRapidas: newState });
      return newState;
    });
  };

  const resetFilters = () => {
    setSelectedRatings("Valoraciones");
    setIsQuickApply(false);
    onFilterChange?.({
      ratings: [],
      solicitudesRapidas: false
    });
    onResetFilters?.();
    setFiltroOfertas(true)
    setFiltroSolicitudes(false)
    setFiltroValoraciones(false)
  };


  return (
    <div className="alert-filter-container mb-4 text-center relative">
      <div className="alert-filter-header flex justify-between items-center mb-4">
        <span>
          <span className="text-[#006497] text-heading-sm font-bold font-sans">{newOffersCount}</span>
          <span className="text-black text-heading-sm font-bold font-sans"> {newOffersText}</span>
        </span>

        <button
          className="alert-create-button text-[#006497] font-semibold flex items-center absolute top-0 right-0 -mt-8 mr-0 text-base sm:text-md md:text-md"
          // onClick={onCreateAlertClick}
          style={{ cursor: 'not-allowed' }}
        >
          <img src={IconAlert} className="mr-2 mt-1 W-[24px]" />
          {createAlertText}
        </button>
      </div>

      <Row gutter={[12, 12]}>
        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
          <button
            className={`alert-filter-button bg-[#FCFCFC] py-2 rounded 
              ${filtroOfertas && 'border border-gray-300 '}
              w-full flex justify-center items-center h-[44px] text-[16px] font-semibold text-[#52525B] font-sans hover:!border-2 active:!border-[#81BFEC] alert-filter-button2`
            }
            onClick={resetFilters}
            style={
              !filtroOfertas
                ? { border: '1px solid #E1E1E2' }
                : { border: '1px solid #52525B' }
            }
          >
            <img src={Filter} width={24} height={24} className="mr-2" />
            {filterButtonText}
          </button>
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
          <button
            className="alert-filter-button bg-[#FCFCFC] py-2 rounded w-full flex items-center h-[44px] text-[16px] font-semibold text-[#52525B] justify-center font-sans hover:!bg-[#E6E6E6] hover:!border-0 "
            onClick={handleQuickApplyClick}
            style={
              !filtroSolicitudes
                ? { border: '1px solid #E1E1E2' }
                : { border: '1px solid #52525B' }
            }
          >
            {isQuickApply ? "Todas las solicitudes" : quickApplyButtonText} {/* Cambiar texto según el estado */}
          </button>
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
          <div
            className="alert-filter-select ant-select-selection-item relative inline-block bg-[#FCFCFC] py-2 px-4 rounded border border-gray-300 flex items-center h-[44px] text-[16px]  font-semibold text-[#52525B] w-full"
            style={
              !filtroValoraciones
                ? { border: '1px solid #E1E1E2' }
                : { border: '1px solid #52525B' }
            }
          >
            <img src={Valor} width={24} height={24} className="mr-2" />
            <Select
              className="bg-transparent appearance-none w-full focus:outline-none custom-select-title-tabs font-sans"
              onChange={handleRatingSelect}
              defaultValue={"Valoraciones"}
              value={selectedRatings}
              bordered={false}
            >
              {ratingsOptions.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default AlertFilter;
