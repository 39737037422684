import React, { useCallback } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store/store';
import { addPaqueteUsuario } from '../../redux/actions/pages/controlPanel/Packs/PostPackUser';

const API_KEY_STRIPE: string | undefined = process.env.REACT_APP_API_KEY_STRIPE;

interface Pack {
    id: number;
    paquete: string;
    precio: number
}

const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const CheckoutForm = ({ pack }: { pack: Pack }) => {

    const navigate = useNavigate();
    const { notification } = App.useApp();

    const dispatch = useDispatch<AppDispatch>()

    const plan = {
        id: pack.id,
        nombre: pack.paquete,
        importe: pack.precio*100
    }

    const completePayment = async () => {
        await dispatch(addPaqueteUsuario({ id: plan.id }));
        notification.success({
            message: 'Compra realizada',
            description: 'Ya cuentas con el nuevo paquete',
        })
        navigate('/memberships')
        localStorage.removeItem('sessionIdStripe')
    }

    const fetchClientSecret = useCallback(async () => {
        let rpta
        await fetch(`${API_BASE_URL_EXACT}/stripe/payment/package`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify([{
                id: plan.id,
                nombre: plan.nombre,
                importe: plan.importe
            }]),
        })
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem('sessionIdStripe',data.sessionId)
                rpta = data.clientSecret
            });
        return rpta + ""
    }, []);
    
    const options = { fetchClientSecret };
    if (!API_KEY_STRIPE) return null

    const stripePromise = loadStripe(API_KEY_STRIPE);

    return (
        <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
                ...options,
                onComplete: async ()=>{
                    const sessionId = localStorage.getItem('sessionIdStripe')
                    fetch(`${API_BASE_URL_EXACT}/stripe/session-status?session_id=${sessionId}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if(data.status == "complete" && data.payment_status == "paid"){
                            completePayment()
                        }
                    });
                },
            }}
        >
            <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
    )
}

export default CheckoutForm