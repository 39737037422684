import {
	FETCH_PAQUETES_REQUEST,
    FETCH_PAQUETES_SUCCESS,
    FETCH_PAQUETES_FAILURE,
    PaquetesActionTypes,
	Paquete
} from "../../../../constants/common/paquetes/Paquetes";

interface PaquetesState {
	rex_loading: boolean;
    rex_paquetes : Paquete[]
	rex_error: string | null;
}

const initialState: PaquetesState = {
	rex_loading: false,
    rex_paquetes : [],
	rex_error: null,
};

const PaquetesReducer = (state = initialState, action: PaquetesActionTypes): PaquetesState => {
	switch (action.type) {
		case FETCH_PAQUETES_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_PAQUETES_SUCCESS:
			return {
				...state,
				rex_loading: false,
				rex_paquetes: action.payload,
				rex_error: null,
			};
		case FETCH_PAQUETES_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload,
			};
		default:
			return state;
	}
};

export default PaquetesReducer;


