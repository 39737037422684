// Constants para eliminar experiencias laborales
export const DELETE_EXPERIENCE_REQUEST = 'DELETE_EXPERIENCE_REQUEST';
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS';
export const DELETE_EXPERIENCE_FAILURE = 'DELETE_EXPERIENCE_FAILURE';

// Tipos de las acciones para eliminar
interface DeleteExperienceRequestAction {
  type: typeof DELETE_EXPERIENCE_REQUEST;
}

interface DeleteExperienceSuccessAction {
  type: typeof DELETE_EXPERIENCE_SUCCESS;
  payload: {
    id: number; // ID de la experiencia eliminada
  };
}

interface DeleteExperienceFailureAction {
  type: typeof DELETE_EXPERIENCE_FAILURE;
  payload: string;
}

// Exportamos los tipos de acciones
export type DeleteExperienceActionTypes =
  | DeleteExperienceRequestAction
  | DeleteExperienceSuccessAction
  | DeleteExperienceFailureAction;
