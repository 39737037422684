// Constantes para las acciones relacionadas con "Busquedas guardadas"
export const FETCH_SAVED_SEARCHES_REQUEST = 'FETCH_SAVED_SEARCHES_REQUEST';
export const FETCH_SAVED_SEARCHES_SUCCESS = 'FETCH_SAVED_SEARCHES_SUCCESS';
export const FETCH_SAVED_SEARCHES_FAILURE = 'FETCH_SAVED_SEARCHES_FAILURE';

export const SAVE_SAVED_SEARCHES_REQUEST = 'SAVE_SAVED_SEARCHES_REQUEST';
export const SAVE_SAVED_SEARCHES_SUCCESS = 'SAVE_SAVED_SEARCHES_SUCCESS';
export const SAVE_SAVED_SEARCHES_FAILURE = 'SAVE_SAVED_SEARCHES_FAILURE';

interface Pais {
	pais : string
}
interface Ciudad {
	ciudad : string
}

export interface savedSearchesDto {
	nombre : string
	puesto : string | null
	ultima_busqueda : string
	pais : Pais | null
	ciudad: Ciudad | null
	createdAt: string
}

interface FetchSavedSearchesRequestAction {
	type: typeof FETCH_SAVED_SEARCHES_REQUEST;
}

interface FetchSavedSearchesSuccessAction {
	type: typeof FETCH_SAVED_SEARCHES_SUCCESS;
	payload: {
		data: savedSearchesDto[]
	};
}

interface FetchSavedSearchesFailureAction {
	type: typeof FETCH_SAVED_SEARCHES_FAILURE;
	payload: string;
}

interface SaveSavedSearchesRequestAction {
	type: typeof SAVE_SAVED_SEARCHES_REQUEST;
}

interface SaveSavedSearchesSuccessAction {
	type: typeof SAVE_SAVED_SEARCHES_SUCCESS;
}

interface SaveSavedSearchesFailureAction {
	type: typeof SAVE_SAVED_SEARCHES_FAILURE;
	payload: string;
}

export type SavedSearchesActionTypes =
	| FetchSavedSearchesRequestAction
	| FetchSavedSearchesSuccessAction
	| FetchSavedSearchesFailureAction
	| SaveSavedSearchesRequestAction
	| SaveSavedSearchesSuccessAction
	| SaveSavedSearchesFailureAction;