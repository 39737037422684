import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom'; 
import Check from "../../../assets/icons/check.svg"; 
import ArrowLeft from "../../../assets/icons/arrowLeft.svg"; 
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import CustomButton from "../../../components/dropdownInput/DropdownInput";
import {addPaqueteUsuario} from "../../../redux/actions/pages/controlPanel/Packs/PostPackUser"
import { GetPackagesReducer } from '../../../redux/actions/common/paquetes/Paquetes';
import { Paquete, PaqueteDetalle, PaqueteDetalleCaracteristica } from '../../../constants/common/paquetes/Paquetes';

const Memberships: React.FC = () => {
  const navigate = useNavigate(); // Hook para la navegación

  const {
    rex_paquetes
  } = useSelector(({ paquetes }: any) => paquetes);

    const [isModalVisible, setIsModalVisible] = useState(false); // Estado para controlar la visibilidad del modal
    const [selectedPack, setSelectedPack] = useState<{ id: number, paquete:string } | null>(null); // Guardar el pack seleccionado
    const dispatch = useDispatch<AppDispatch>();
  
    const handleConfirm = async () => {
      if (selectedPack) {
        const result = await dispatch(addPaqueteUsuario({ id: selectedPack.id }));
        console.log(result);
        setIsModalVisible(false);

        navigate('/checkout', { state: { pack: selectedPack } });
         // Cerrar el modal después de la confirmación
        // Aquí puedes realizar alguna acción adicional (como mostrar una notificación de éxito)
      }
    };

  const showModal = (pack: { id: number, paquete: string }) => {
    setSelectedPack(pack);
    setIsModalVisible(true); // Abrir el modal
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Cerrar el modal
  };

  // const handleConfirm = async () => {
  //   if (selectedPack) {
  //     const result = await dispatch(addPaqueteUsuario({ id: selectedPack.id }));
  //     console.log(result);
  //     message.success('Paquete comprado correctamente');
  //     setIsModalVisible(false); // Cerrar el modal después de la confirmación
  //     navigate("/controlPanel")
  //     // Aquí puedes realizar alguna acción adicional (como mostrar una notificación de éxito)
  //   }
  // };

  // Datos para las FAQs
  const faqs = [
    { question: 'Lorem Ipsum', answer: 'Al ser miembro, obtienes acceso a una variedad de beneficios exclusivos, como descuentos y contenido especial.' },
    { question: 'Lorem Ipsum', answer: 'Puedes cancelar tu membresía en cualquier momento desde tu cuenta en la sección de configuración.' },
    { question: 'Lorem Ipsum', answer: 'Sí, puedes cambiar el nivel de tu membresía desde tu cuenta en cualquier momento.' },
    { question: 'Lorem Ipsum', answer: 'Aquí irá la respuesta a la pregunta "¿Puedo guardar ofertas de trabajo para inscribirme más tarde?". Por favor, adapte el contenido a lo que mejor represente la información necesaria.' }
  ];

  // Renderiza las FAQs utilizando CustomButton
  const renderFaqs = () => (
    <div className="faq-section">
      {faqs.map((faq, index) => (
        <CustomButton
          key={index}
          content={faq.question}
          backgroundColor="#FCFCFC"
          color="#5E5E5E"
          fontSize='16px'
          fontWeight='bold'
          borderColor="#006497"
          borderWidth="1px"
          borderRadius="6px"
          iconColor="#006497"
          iconType="down"
        >
          <p className="text-body-md font-medium text-black block p-2">
            {faq.answer}
          </p>
        </CustomButton>
      ))}
    </div>
  );

  useEffect(() => {
    dispatch(GetPackagesReducer())
  }, [])

  return (
    <>
      <Row className='flex justify-center'>
        <Col xs={20} md={16}>
          <div className="pt-8 ">
            <div className="flex items-center mb-4">
              <img
                src={ArrowLeft}
                alt="Regresar"
                className="cursor-pointer mr-2"
                onClick={() => navigate(-1)}
              />
              <h2 className="text-xl font-bold border-b border-[#A1A1AA] w-full pb-1">Membresías disponibles</h2>
            </div>
          </div>
        </Col>
      </Row>

      {
        rex_paquetes.map((paq: Paquete, index: number) => (
          <Row key={index} className='flex justify-center'>
            <Col xs={20} md={16}>
              <h3 className="text-[24px] font-bold text-[#006497] text-center mb-2">
                {paq.tipo}
              </h3>
              <p className="text-center mb-6">{paq.descripcion}</p>
              <p className="text-center text-[#006497] text-[12px] font-bold mb-3">Más vendido</p>

              <div className="flex flex-wrap justify-center 2x2:justify-between gap-6 mb-8">
                {
                  paq.paquetes.map((pad: PaqueteDetalle, idxpaq: number) => (
                    <div
                      key={idxpaq}
                      className="w-[227px] h-[363px] bg-[#FCFCFC] border border-[#81BFEC] rounded-[6px] shadow-md p-6 text-left flex flex-col justify-between pb-10"
                    >
                      <div>
                        <p className="text-[#006497] text-[24px] font-bold mb-2 text-center">{pad.paquete}</p>
                        <p className="text-[#1A1A1A]/70 text-[12px] mb-2">{pad.descripcion}</p>
                        <p className="text-[24px] font-bold text-[#1A1A1A]/70 text-center mb-2">{pad.precio}€ <span className="text-[14px] font-normal">+ IVA</span></p>

                        <p className="text-[#000000] font-bold text-[12px] mb-2">Características</p>
                        <ul className="mb-4">
                          {
                            pad.caracteristicas_paquetes.map((car: PaqueteDetalleCaracteristica, idxcar: number) => (
                              <li key={idxcar} className="flex items-center mb-2">
                                <img src={Check} alt="check" className="mr-2" />{car.descripcion}
                              </li>
                            ))
                          }
                        </ul>
                      </div>

                      {/* Centrar el botón */}
                      <div className="flex justify-center">
                        <Button
                          type="primary"
                          className="w-[120px] h-[36px]"
                          style={{ backgroundColor: '#1C82BC', borderColor: '#006497', borderRadius: '4px' }}
                          onClick={() => {
                            navigate(`/checkout/confirmation/${pad.id}`)
                          }} 
                        >
                          Comprar pack
                        </Button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Col>
          </Row>
        ))
      }

      <Row className='flex justify-center'>
        <Col xs={20} md={16}>
          {/* FAQ Section */}
          <h3 className="text-[18px] font-bold text-black text-left mt-10 mb-10">FAQs</h3>
          {renderFaqs()}
        </Col>
      </Row>

      {/* <MomdalConfirmacion
        visible={isModalVisible}
        onClose={handleCancel} // Cierra el modal cuando se hace clic en cancelar
        onConfirm={handleConfirm} // Acción cuando se confirma la compra
        packId={selectedPack?.id ?? 0} // Pasar el id como número
        packName={selectedPack?.paquete ?? ""}
      /> */}



    </>
  );
};

export default Memberships;