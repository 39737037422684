export const FETCH_CANCEL_APPLICATION = 'FETCH_CANCEL_APPLICATION';
export const FETCH_FAILED_CANCEL_APPLICATION = 'FETCH_FAILED_CANCEL_APPLICATION';
export const FETCH_SUCCESS_CANCEL_APPLICATION = 'FETCH_SUCCESS_CANCEL_APPLICATION';

interface FetchCancelApplicationRequestAction {
	type: typeof FETCH_CANCEL_APPLICATION;
	[key: string]: any;
}

interface FetchCancelApplicationSuccessAction {
	type: typeof FETCH_SUCCESS_CANCEL_APPLICATION;
	[key: string]: any;
}

interface FetchCancelApplicationFailureAction {
	type: typeof FETCH_FAILED_CANCEL_APPLICATION;
	[key: string]: any;
}


export type CancelApplicationActionTypes =
	| FetchCancelApplicationRequestAction
	| FetchCancelApplicationSuccessAction
	| FetchCancelApplicationFailureAction;