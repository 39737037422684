// actions/pages/offers/offer/EditOffer.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  EDIT_OFFER_REQUEST,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  EditOfferActionTypes
} from '../../../../../constants/pages/offers/offer/EditOffer';
import FetchWithIP from '../../../utils/FetchHeaders';
import { AnyAction } from 'redux'; 

export const editOfferRequestReducer = (): EditOfferActionTypes => ({
  type: EDIT_OFFER_REQUEST,
});

export const editOfferSuccessReducer = (data: any): EditOfferActionTypes => ({
  type: EDIT_OFFER_SUCCESS,
  payload: data,
});

export const editOfferFailureReducer = (error: string): EditOfferActionTypes => ({
  type: EDIT_OFFER_FAILURE,
  payload: error,
});

// actions/pages/offers/offer/EditOffer.ts

export const EditOfferReducer = (
  offerId: number,
  offerData: any
): ThunkAction<Promise<any>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(editOfferRequestReducer());

    // Define las propiedades permitidas para la actualización
    const allowedProperties = [
      'sector_id',
      'cargo',
      'descripcion',
      'tipo',
      'ubi_provincia',
      'ubi_poblacion',
      'sal_min',
      'sal_max',
      'abanico_salarial',
      'anios_experiencia',
      'estudios_minimos',
      'tipo_contrato',
      'jornada_laboral',
      'aptitudes_ofertas', // Nueva propiedad para aptitudes
      'palabras_clave_ofertas', // Nueva propiedad para palabras clave
      'soft_skills_oferta'
    ];

    // Filtra `offerData` para que solo incluya las propiedades permitidas
    const filteredOfferData = Object.keys(offerData)
      .filter(key => allowedProperties.includes(key))
      .reduce((obj, key) => {
        obj[key] = offerData[key];
        return obj;
      }, {} as any);
      console.log(":::::fetch data:",filteredOfferData)

    try {
      const response = await FetchWithIP(`ofertas/${offerId}`, {
        method: 'PATCH'},
       (filteredOfferData),
      );
      

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.data) {
        dispatch(editOfferSuccessReducer(responseData.data));
        return responseData; // Devuelve el resultado completo aquí
      } else {
        throw new Error('Error en los datos de la oferta editada');
      }
    } catch (error) {
      dispatch(editOfferFailureReducer('Error al editar la oferta'));
      throw error;
    }
  };
