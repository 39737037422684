import React, { useState, useEffect } from 'react';
import { Card, Avatar, Row, Col, Button, Divider, Skeleton, message, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import IconLocation2 from '../../../../assets/icons/location2.svg';
import IconTypemoney1 from '../../../../assets/icons/typemoney1.svg';
import IconClock from '../../../../assets/icons/clock.svg';
import IconComment from '../../../../assets/icons/comment.svg';
import IconPersons from '../../../../assets/icons/persons.svg';
import IconTag from '../../../../assets/icons/tag.svg';
import IconShield from '../../../../assets/icons/shield.svg';
import IconFile from '../../../../assets/icons/file.svg';
import IconCheck from '../../../../assets/icons/check.svg';
import IconShield2 from '../../../../assets/icons/IconShield2.svg';
import 'tailwindcss/tailwind.css';
import '../../../styles/pages/employment/recommendations/Information.css';
import DiferenciaFecha from '../../../../utils/CalculateDifferentDate';
import { SaveOfferReducer } from '../../../../redux/actions/offers/SaveOffer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store/store';
import CardStats from '../../../cards/CardStats';
import { SignUpOfferReducer } from '../../../../redux/actions/offers/SignUpOffer';
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import { Link } from 'react-router-dom';

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

interface InformationProps {
  id: number;
  title: string;
  company: string;
  location: string;
  employmentType: string;
  salary: string;
  comments: number;
  applicantsCount: string;
  introText: string;
  requirements: any[];
  aptitudes: any[];
  responsibilities: string[];
  extraText: string[];
  extraText2: string[];
  postedTime: string;
  applied: boolean;
  onApply: () => void;
  loading: boolean;
  partialLoading?: boolean;
  saved: boolean;
  avatar: string;
  sector: string;
  getData: () => void;
  setSelectedCardIndex: any;
}

const Information: React.FC<InformationProps> = ({
  id,
  title,
  company,
  location,
  employmentType,
  salary,
  comments,
  applicantsCount,
  introText,
  requirements,
  responsibilities,
  extraText,
  extraText2,
  postedTime,
  applied,
  onApply,
  loading,
  partialLoading = false,
  saved = true,
  sector,
  aptitudes,
  avatar = null,
  getData,
  setSelectedCardIndex
}) => {
  const [isApplied, setIsApplied] = useState(applied);
  const [isSaved, setIsSaved] = useState(true); // Estado para el botón de guardar
  const [showModalCancelApplication, setShowModalCancelApplication] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setIsApplied(applied);
  }, [applied]);

  const handleApplyClick = () => {
    setIsApplied(!isApplied);
    setIsSaved(!isSaved); // Cambiar el estado de guardar al aplicar
    onApply();
  };

  const handleSaveClick = async () => {
    await dispatch(SaveOfferReducer(id))
    setSelectedCardIndex(null)
    await getData()
  };

  const cancelApplication = async () => {
    if (id) {
      const rpta: any = await dispatch(SignUpOfferReducer(id, null, null, null))
      if (rpta.respuesta) {
        setIsApplied(!isApplied);
        message.success('Postulacion eliminada');
        setShowModalCancelApplication(false)
      } else {
        message.error('Error, operacion no realizada');
      }
      return
    }
    message.error('Error, operacion no realizada');
  }

  return (
    <Card className="mx-auto bg-[#FBFDFE] rounded-none border-[#81BFEC] p-2">
      <Skeleton loading={loading && !partialLoading} avatar active className='mb-4'>
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-4">
          <div className="flex items-center mb-4 lg:mb-0">
            <Avatar
              size={40}
              src={
                avatar
                  ? API_BASE_URL_EXACT + avatar
                  : IMAGE_USER_DEFAULT
              }
              shape="circle"
            />
            <div className="ml-3">
              <h3 className="text-base m-0 font-bold font-sans">{title}</h3>
              <h4 className="text-sm font-medium m-0 font-sans">{company}</h4>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center">
            <Button
              className={`btn-guardar border-none text-blue3 mb-2 lg:mb-0 lg:ml-1 font-semibold text-sm font-sans shadow-none`}
              icon={<img src={IconShield2} alt="icono" />}
              onClick={handleSaveClick}
            >
              Guardada
            </Button>
            {
              isApplied
                ? <Button
                  className={`btn-inscribirte rounded-[4px] text-blue font-sans lg:ml-4 font-semibold text-base h-[44px] w-[142px] inscrito`}
                  icon={<img src={IconCheck} />}
                  onClick={() => {
                    setShowModalCancelApplication(true)
                  }}
                >
                  Inscrito
                </Button>
                :
                <Link to={`/employment/recommendations/offerRegistration/${id}`}>
                  <Button
                    className={`btn-inscribirte rounded-[4px] text-blue font-sans lg:ml-4 font-semibold text-base h-[44px] w-[142px]`}
                    icon={<img src={IconFile} />}
                  >
                    Inscribirte
                  </Button>
                </Link>
            }
          </div>
        </div>
      </Skeleton>

      <Skeleton loading={loading && !partialLoading} active paragraph={{ rows: 2 }} className='mb-4'>
        <Row className="mb-2">
          <Col className="flex items-center text-sm font-sans font-medium mr-5">
            <img src={IconLocation2} className="p-1" /> {location}
          </Col>
          <Col className="flex items-center text-sm font-sans font-medium mr-5">
            <img src={IconTypemoney1} className="p-1" /> {salary}
          </Col>
          <Col className="flex items-center text-sm font-sans font-medium mr-5">
            <img src={IconClock} className="p-1" /> {employmentType}
          </Col>
        </Row>
        <Row className="mb-4 flex gap-1">
          <CardStats
            text={comments}
            iconButton={<img src={IconComment} className="text-blue4" />}
          />
          <CardStats
            text={applicantsCount}
            iconButton={<img src={IconPersons} className="text-blue4" />}
          />
          <img src={IconTag} className="text-blue4" />
          <CardStats
            text={sector}
          />
          {
            aptitudes.map((apt) => {
              return (
                <CardStats
                  text={apt.aptitudes.aptitud}
                />
              )
            })
          }
        </Row>
        <div className="text-xs text-black text-right font-medium mb-4 font-sans">
          <DiferenciaFecha fecha={postedTime} />
        </div>
        <Divider className="border-gray-300" />
      </Skeleton>

      {/* Descripción con efecto de carga condicional */}
      <Skeleton loading={loading} active={partialLoading} paragraph={{ rows: 3 }} className='mb-4'>
        <div className="mb-4">
          <p className="text-base font-sans font-medium">{introText}</p>
        </div>
      </Skeleton>

      <Skeleton loading={loading} active={partialLoading} paragraph={{ rows: requirements.length }} className='mb-4'>
        <div className="mb-4">
          <h2 className="text-base font-sans font-medium mb-4">Requisitos:</h2>
          <ul>
            {requirements.map((requirement, index) => (
              <li key={index} className="text-base font-sans font-medium mb-1">
                <b>{requirement.title}: </b>{requirement.requirement}
              </li>
            ))}
          </ul>
        </div>
      </Skeleton>

      <Skeleton loading={loading} active={partialLoading} paragraph={{ rows: responsibilities.length }} className='mb-4'>
        <div className="mb-4">
          <h2 className="text-base font-bold font-sans">Responsabilidades:</h2>
          <ul>
            {responsibilities.map((responsibility, index) => (
              <li
                key={index}
                className="text-base font-sans font-medium pt-5"
                dangerouslySetInnerHTML={{ __html: responsibility }}
              ></li>
            ))}
            {/* {responsibilities.map((responsibility, index) => (
              <li key={index} className="text-base font-sans font-medium pt-5">{responsibility}</li>
            ))} */}
          </ul>
        </div>
      </Skeleton>

      <Skeleton loading={loading} active={partialLoading} paragraph={{ rows: extraText.length + 1 }}>
        <div className="mb-4">
          <ul>
            {extraText.map((extra, index) => (
              <li key={index} className="text-base font-sans font-medium">{extra}</li>
            ))}
            <p className="text-base font-sans font-bold pt-6">{extraText2}</p>
          </ul>
        </div>
      </Skeleton>
      <Modal
        visible={showModalCancelApplication}
        closable={false}
        footer={null}
        width={800}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={() => setShowModalCancelApplication(false)}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16, 24]} className='text-center mt-7'>
          <Col span={24}>
            <p className="font-bold text-2xl font-sans">Anular inscripción</p>
          </Col>
          <Col span={24}>
            <p className="font-sans font-medium text-sm">¿Está seguro de eliminar la postulación?</p>
          </Col>
        </Row>
        <Row className='flex justify-center mb-5'>
          <div className="flex justify-center mx-[114px] mt-[38px]">
            <Button
              onClick={() => setShowModalCancelApplication(false)}
              className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
            >
              Cancelar
            </Button>
            <Button
              onClick={cancelApplication}
              className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
            >
              Aceptar
            </Button>
          </div>
        </Row>
      </Modal>
    </Card>
  );
};

export default Information;
