// src/reducers/pages/offers/editKillerQuestionsReducer.ts

import {
    UPDATE_KILLER_QUESTIONS_REQUEST,
    UPDATE_KILLER_QUESTIONS_SUCCESS,
    UPDATE_KILLER_QUESTIONS_FAILURE,
    UpdateKillerQuestionsActionTypes,
} from '../../../constants/offers/EditKillerQuestions';
  
  interface UpdateKillerQuestionsState {
    rex_loading: boolean;
    rex_success: boolean;
    rex_error: string | null;
    rex_response: any | null;
  }
  
  const initialState: UpdateKillerQuestionsState = {
    rex_loading: false,
    rex_success: false,
    rex_error: null,
    rex_response: null,
  };
  
  export const updateKillerQuestionsReducer = (
    state = initialState,
    action: UpdateKillerQuestionsActionTypes
  ): UpdateKillerQuestionsState => {
    switch (action.type) {
      case UPDATE_KILLER_QUESTIONS_REQUEST:
        return {
          ...state,
          rex_loading: true,
          rex_success: false,
          rex_error: null,
        };
      case UPDATE_KILLER_QUESTIONS_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          rex_success: true,
          rex_error: null,
          rex_response: action.payload,
        };
      case UPDATE_KILLER_QUESTIONS_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_success: false,
          rex_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default updateKillerQuestionsReducer;
  