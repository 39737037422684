import React, { useEffect, useState } from "react";
import { EyeFilled } from "@ant-design/icons";
import { Row, Col, Button, App, Divider, Segmented, message } from "antd";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { EnvironmentOutlined } from "@ant-design/icons";
import "../../../styles/pages/offers/estilos.css";
import InputC from "../../../components/pages/offers/CreateOffers/Input";
import Select from "../../../components/pages/offers/CreateOffers/Select";
import Sliders from "../../../components/pages/offers/CreateOffers/Slider";
import ModalPreview from "../../../components/pages/offers/CreateOffers/modals/ModalPreview";
import CustomButton from "../../../components/pages/offers/CreateOffers/Buttons";
import CustomTag from "../../../components/pages/offers/CreateOffers/CustomTag";
import "../../../components/styles/pages/principalNav/PrincipalNav.css";
import CheckboxC from "../../../components/checkbox/CheckboxProps";
import Ckeditor from "../../../components/pages/offers/CreateOffers/Ckeditor";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/store";
import { CreateOfferReducer, ResetCreateOfferReducer } from "../../../redux/actions/offers/CreateOffers";
import { GetOfferDetailReducer } from "../../../redux/actions/pages/offers/offer/GetOffer";
import { EditOfferReducer } from "../../../redux/actions/pages/offers/offer/EditOffer";
import { GetKeywordsReducer } from "../../../redux/actions/common/keywords/Keywords";
import { GetAptitudesReducer, GetSkillsReducer } from "../../../redux/actions/common/skills/Skills";
import { GetSectorsReducer } from "../../../redux/actions/common/sectors/Sectors";
import * as Yup from 'yup';
import SelectBasic from "../../../components/pages/offers/CreateOffers/SelectBasic";
import { GetPaisesReducer } from "../../../redux/actions/pages/emplotment/searchCountry";
import { deleteOfferReducer } from "../../../redux/actions/pages/company/DeleteOffers";
import OfferDescription from "../../../components/pages/offers/KillerQuestions/SidebarOfferDescription";
import ModalCancelOferta from "../../../components/modals/ModalCancelOferta";
interface offerCreate {
  sector_id: number | null;
  cargo: string;
  descripcion: string;
  tipo: string;
  ubi_provincia: string;
  ubi_poblacion: string;
  sal_min: number;
  sal_max: number;
  abanico_salarial: string;
  anios_experiencia: number;
  estudios_minimos: string;
  tipo_contrato: string;
  jornada_laboral: string;
  palabras_clave_ofertas: [];
  aptitudes_ofertas: [];
  soft_skills_oferta: [];
}

const validationSchema = Yup.object().shape({
  cargo: Yup.string()
    .required('El puesto es requerido'),
  ubi_provincia: Yup.string()
    .required('La provincia es requerida'),
  ubi_poblacion: Yup.string()
    .required('La población es requerida'),
  sal_min: Yup.number()
    .typeError('Debe ser un número')
    .required('El salario mínimo es requerido')
    .min(0, 'El salario mínimo debe ser mayor o igual a 0'),
  sal_max: Yup.number()
    .required('El salario maximo es requerido'),
  descripcion: Yup.string()
    .required('La descripción es requerida'),
  estudios_minimos: Yup.string()
    .required('Los estudios minimos son requeridos'),
  anios_experiencia: Yup.string()
    .required('Los años de experiencia son requeridos'),
  sector_id: Yup.number()
    .required('El sector es requerido'),
  tipo_contrato: Yup.string()
    .required('El tipo de contrato es requerido'),
  jornada_laboral: Yup.string()
    .required('La jornada laboral es requerida'),

});

const CreateOffer: React.FC = () => {
  const { id, relatedTo } = useParams<{ id: string; relatedTo: string }>();
  const isEditMode = !!id;
  const isRelatedOffer = !!relatedTo;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { notification } = App.useApp();

  const {
    rex_loading,
    rex_request,
    rex_failed
  } = useSelector(({ createOffers }: any) => createOffers);

  const loading_paises = useSelector((state: RootState) => state.paises.rex_loading);

  const {
    rex_paises
  } = useSelector(({ paises }: any) => paises);

  const [aptitudes, setAptitudes] = useState<any[]>([]);
  const [selectedAptitudes, setSelectedAptitudes] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<any>([]);
  const [sectores, setSectores] = useState<any[]>([]);
  const [selectedSectores, setSelectedSectores] = useState<string[]>([]);
  const { rex_offer_detail } = useSelector((state: RootState) => state.getOfferID);
  const { rex_aptitudes } = useSelector(({ softskill }: any) => softskill);
  const [offerDetails, setOfferDetails] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [softSkills, setSoftSkills] = useState<Array<{ soft_skill_id: number; porcentaje: number }>>([]);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);

  useEffect(() => {
    if (selectedCountry && rex_paises.length > 0) {
      const country = rex_paises.find((c: any) => c.pais === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, rex_paises]);

  useEffect(() => {
    dispatch(GetPaisesReducer());
  }, []);

  const showModal = (values: any) => {
    setModalData(values);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const [initialValues, setInitialValues] = useState<offerCreate>({
    sector_id: null,
    cargo: "",
    tipo: "inserte tipo",
    anios_experiencia: 0,
    descripcion: "",
    ubi_poblacion: "",
    ubi_provincia: "",
    sal_max: 0,
    sal_min: 0,
    abanico_salarial: "Año",
    estudios_minimos: "",
    tipo_contrato: "",
    jornada_laboral: "",
    palabras_clave_ofertas: [],
    aptitudes_ofertas: [],
    soft_skills_oferta: []
  });

  const handleSliderChange = (skillId: number, value: number) => {
    const percentaje = value * 10;

    setSoftSkills((prevSkills: any) => {
      const newSkills = prevSkills.filter((skill: any) => skill.soft_skill_id !== skillId);
      return [...newSkills, { soft_skill_id: skillId, porcentaje: percentaje }];
    });
  };

  const handleSectorClose = (sector: string) => {
    setSelectedSectores((prev) => prev.filter((item) => item !== sector));
  };

  const handleKeywordRemove = (keyword: number, setFieldValue: any) => {
    setSelectedKeywords((prev: any) => {
      const newRpta = prev.filter((item: any) => item.key !== keyword);
      setFieldValue("palabras_clave_oferta", newRpta)
      return newRpta
    });
  };

  const handleAptitudRemove = (aptitud: number, setFieldValue: any) => {
    setSelectedAptitudes((prev: any) => {
      const newAptitudes = prev.filter((item: any) => item.key !== aptitud);

      setFieldValue("aptitudes_oferta", newAptitudes);
      return newAptitudes;
    });
  };

  useEffect(() => {
    dispatch(GetAptitudesReducer());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  type Align = "start" | "center" | "end";
  const [alignValue, setAlignValue] = React.useState<Align>("center");

  useEffect(() => {
    if (rex_request) {
      const offerId = rex_request.data?.id;
      if (!isEditMode && offerId) {
        dispatch(ResetCreateOfferReducer());
        notification.success({ message: rex_request.mensaje });
        navigate(`/offer/killerQuestions/${offerId}?mode=create`);
      } else if (isEditMode) {
        dispatch(ResetCreateOfferReducer());
        notification.success({ message: rex_request.mensaje });
        navigate(`/offer/killerQuestions/${id}?mode=edit`);
      }
    }

  }, [rex_request, notification, navigate, isEditMode, id]);

  useEffect(() => {
    if (isRelatedOffer && relatedTo) {
      dispatch(GetOfferDetailReducer(parseInt(relatedTo))); // Cargar los datos de la oferta relacionada
    }
  }, [isRelatedOffer, relatedTo]);


  useEffect(() => {
    if (rex_failed) {

    }
  }, [rex_failed]);

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(GetOfferDetailReducer(parseInt(id)));
    }
  }, [isEditMode, id]);



  useEffect(() => {
    if ((isEditMode || isRelatedOffer) && rex_offer_detail) {
      setInitialValues({
        ...rex_offer_detail,
        id: undefined,
        sector_id: rex_offer_detail.sector_id,
        cargo: rex_offer_detail.cargo,
        descripcion: rex_offer_detail.descripcion,
        tipo: rex_offer_detail.tipo,
        ubi_provincia: rex_offer_detail.ubi_provincia,
        ubi_poblacion: rex_offer_detail.ubi_poblacion,
        sal_min: rex_offer_detail.sal_min,
        sal_max: rex_offer_detail.sal_max,
        abanico_salarial: rex_offer_detail.abanico_salarial,
        anios_experiencia: rex_offer_detail.anios_experiencia,
        estudios_minimos: rex_offer_detail.estudios_minimos,
        tipo_contrato: rex_offer_detail.tipo_contrato,
        jornada_laboral: rex_offer_detail.jornada_laboral,
        palabras_clave_ofertas: rex_offer_detail.palabras_clave_oferta?.map((item: any) => ({
          label: item.palabras_claves.palabra,
          key: item.id,
          value: item.palabras_claves.id,
        })) || [],

        aptitudes_ofertas: rex_offer_detail.aptitudes_oferta?.map((item: any) => ({
          label: item.aptitudes.aptitud,
          key: item.id,
          value: item.aptitudes.id,
        })) || [],

        soft_skills_oferta: rex_offer_detail.soft_skills_oferta?.map((skill: any) => ({
          soft_skill_id: skill.soft_skill_id,
          porcentaje: skill.porcentaje || 0,
        })) || [],

      });

      if (rex_offer_detail.sectores && rex_offer_detail.sectores.sector) {
        setSelectedSectores([rex_offer_detail.sectores.sector]);
      }

      if (rex_offer_detail.aptitudes_oferta) {
        setSelectedAptitudes(
          rex_offer_detail.aptitudes_oferta.map((item: any) => ({
            label: item.aptitudes.aptitud,
            key: item.id,
            value: item.aptitudes.id,
          }))
        );
      }

      if (rex_offer_detail.palabras_clave_oferta) {
        setSelectedKeywords(
          rex_offer_detail.palabras_clave_oferta.map((item: any) => ({
            label: item.palabras_claves.palabra,
            key: item.id,
            value: item.palabras_claves.id,
          }))
        );
      }

      if (rex_offer_detail.soft_skills_oferta) {
        setSoftSkills(
          rex_offer_detail.soft_skills_oferta.map((skill: any) => ({
            soft_skill_id: skill.soft_skill_id,
            porcentaje: skill.porcentaje || 0,
          }))
        );
      }
    }
  }, [isEditMode, isRelatedOffer, rex_offer_detail]);

  const handleCancelCreation = () => {
    setIsModalConfirmVisible(true);
  };


  const handleConfirmCancel = () => {
    // Verificar si estamos en modo edición
    if (isEditMode) {
      // Verificar si se tiene un ID de oferta
      const offerId = parseInt(id || relatedTo || "0");

      if (isNaN(offerId)) {
        notification.error({ message: "ID de oferta no disponible o inválido" });
        return;
      }

      // Despachar la acción para eliminar la oferta guardada
      dispatch(deleteOfferReducer(offerId));

      // Redirigir a la página de ofertas abiertas
      navigate("/offers/open-offers");
    } else {
      // Si estamos en modo creación, solo navegamos sin eliminar nada
      navigate("/offers/open-offers");
    }
  };



  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const submitData = {
            ...values,
            id: undefined,
            empresa_id: undefined,
            finalizada: undefined,
            dateApertura: undefined,
            dateFinalizada: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            empresas: undefined,
            aptitudes_oferta: undefined,
            _count: undefined,
            killers_questions: undefined,
            sectores: undefined,
            palabras_clave_oferta: undefined,
            guardada: undefined,
            aptitudes_ofertas: selectedAptitudes.map((aptitude: any) => ({
              id: aptitude.value,
              aptitud: aptitude.label.trim()
            })),
            palabras_clave_ofertas: selectedKeywords.map((keyword: any) => ({
              palabra_clave: keyword.label.trim(),
            })),
            soft_skills_oferta: softSkills.map(skill => ({
              id: skill.soft_skill_id,
              porcentaje: skill.porcentaje,
            })),
          };
          if (isEditMode) {
            try {
              const result = await dispatch(EditOfferReducer(parseInt(id), submitData));
              if (result && result.respuesta) {
                message.success("Oferta editada con éxito");
                navigate(`/offer/killerQuestions/${id}?mode=edit`);
              } else {
                message.error("Error al editar la oferta");
              }
            } catch (error) {
              message.error("Error al editar la oferta");
            }
          }
          else if (isRelatedOffer) {
            // Cuando es una oferta relacionada, se crea como una nueva oferta y se pasa `relatedTo`
            const rpta: any = await dispatch(CreateOfferReducer({ ...submitData, borrador: true }));
            if (rpta.respuesta) {
              message.success("Oferta relacionada creada con éxito");
              navigate(`/offer/killerQuestions/${rpta.data.id}/${relatedTo}?mode=create-offer-related`);
            } else {
              message.error("Error al crear la oferta relacionada");
            }
          }
          else {
            const rpta: any = await dispatch(CreateOfferReducer({ ...submitData, borrador: true }));
            if (rpta.respuesta) {
              // Navegar a otra página si es necesario
              // navigate(`/offer/killerQuestions/${rpta.data.id}`)
            }
          }
          window.scrollTo(0, 0);
        }}
      >
        {({ handleChange, handleBlur, values, setFieldValue }) => (
          <Form>
            <div className="bg-white px-[24px] pt-[8px]">
              <h1 className="font-bold font-sans text-heading-md pt-[16px]">
                {isEditMode
                  ? "Edición de oferta de empleo"
                  : isRelatedOffer
                    ? "Creación de oferta relacionada"
                    : "Creación de nueva oferta de empleo"}
                <Divider className=" bg-grays"></Divider>
              </h1>


              <Row gutter={24} className="mb-[40px]">
                <Col xs={24} md={12} lg={8}>
                  <div>
                    <h3 className="font-medium font-sans text-heading-x1 mb-[8px]">
                      Puesto de trabajo *
                    </h3>
                    <Field
                      name="cargo"
                      as={InputC}
                      placeholder="Escribe las primeras letras del cargo..."
                      customClassName="h-[44px] w-full"
                      className="mb-1"
                    />
                    <ErrorMessage
                      name="cargo"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={24} className="mb-[40px]">
                <Col xs={24} sm={24} md={24} className="mr-[0px] px-[12px]">
                  <div>
                    <h3 className="font-medium font-sans text-heading-x1 mb-[8px]">
                      Lugar del puesto de trabajo
                    </h3>
                    <Row gutter={[24, 24]}>
                      <Col xs={12} md={6} lg={4}>
                        <SelectBasic
                          placeholder="País"
                          style={{ height: '44px' }}
                          onChange={(value) => {
                            if (typeof value === 'string') {
                              setSelectedCountry(value);
                              setFieldValue("ubi_provincia", value);
                            }
                          }}
                          loading={rex_loading}
                          value={
                            values.ubi_provincia
                              ? { value: values.ubi_provincia, label: values.ubi_provincia }
                              : null
                          }
                          options={rex_paises.map((country: any) => ({
                            value: country.pais,
                            label: country.pais
                          }))}
                        />
                        <ErrorMessage
                          name="ubi_provincia"
                          component="div"
                          className="text-red-500 text-sm"
                        />

                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        <SelectBasic
                          placeholder="Ciudad"
                          style={{ height: '44px' }}
                          disabled={!selectedCountry}
                          options={availableCities.map((city: any) => ({
                            value: city.ciudad,
                            label: city.ciudad
                          }))}
                          value={
                            values.ubi_poblacion
                              ? { value: values.ubi_poblacion, label: values.ubi_poblacion }
                              : null
                          }
                          onChange={(value) => {
                            if (typeof value === 'string') {
                              setFieldValue("ubi_poblacion", value);
                            }
                          }}
                        />
                        <ErrorMessage
                          name="ubi_poblacion"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </Col>
                      <Col sm={24} md={12} lg={4}>
                        <div className="h-full w-full flex items-center">
                          <CheckboxC value={0} children={undefined} />
                          <h1 className="font-medium text-body-sm text-gray">
                            En remoto
                          </h1>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row gutter={24} className="">
                <Col sm={24} md={24}>
                  <div>
                    <h3 className="font-medium text-heading-x1 font-sans mb-[8px]">
                      Compromiso de transparecia salarial *
                    </h3>
                    <Row gutter={[24, 24]}>
                      <Col xs={12} md={6} lg={4}>
                        <Field
                          name="sal_min"
                          as={InputC}
                          placeholder="Salario mínimo *"
                          customClassName="h-[44px]"
                          className=""
                          type="number"
                        />
                        <ErrorMessage
                          name="sal_min"
                          component="div"
                          className="text-red-500 text-sm"
                        />

                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        <Field
                          name="sal_max"
                          as={InputC}
                          placeholder="Salario máximo *"
                          customClassName="h-[44px]"
                          className="mb-1"
                          type="number"
                        />
                        <ErrorMessage
                          name="sal_max"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </Col>
                      <Col sm={24} md={12}>
                        <div className="">
                          <Segmented
                            defaultValue="Año"
                            style={{ marginBottom: 30, fontWeight: "600" }}
                            onChange={(value) => {
                              setFieldValue("abanico_salarial", value);
                              setAlignValue(value as Align);
                            }}
                            options={["Año", "Mes", "Hora"]}
                          />
                          <span className="font-medium text-body-sm text-gray pl-[24px]">
                            Informa del abanico salarial *
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row gutter={24} className="mb-[30px] mt-[40px]">
                <Col span={24} className="">
                  <h3 className="font-medium text-heading-x1 font-sans mb-[8px]">
                    Descripción del trabajo *
                  </h3>
                  <Ckeditor
                    setFieldValue={setFieldValue}
                    initialValue={values.descripcion}
                  />

                  <div className="mt-12">
                    <ErrorMessage
                      name="descripcion"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={12}>
                  <div>
                    <h3 className="font-medium text-heading-x1 font-sans mb-[8px]">
                      Información sobre el puesto
                    </h3>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Años de experiencia
                        </h3>
                        <SelectBasic
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          onChange={(selectedOption: any) => {
                            setFieldValue("anios_experiencia", parseInt(selectedOption))
                          }}
                          value={{
                            value: values.anios_experiencia,
                            label: `${values.anios_experiencia} ${values.anios_experiencia === 1 ? 'año' : 'años'}`,
                          }} // Asigna el valor inicial basado en la oferta
                          options={[
                            { value: 0, label: "ROCKIE (0 años)" },
                            { value: 1, label: "1 año" },
                            { value: 2, label: "2 años" },
                            { value: 3, label: "3 años" },
                            { value: 4, label: "4 años" },
                            { value: 5, label: "Más de 5 años" },
                          ]}
                        />

                        <div className="mt-2">
                          <ErrorMessage
                            name="anios_experiencia"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </Col>

                      <Col span={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Estudios mínimos *
                        </h3>
                        <SelectBasic
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          value={{
                            value: values.estudios_minimos,
                            label: values.estudios_minimos
                          }}
                          onChange={(selectedOption: any) => {
                            setFieldValue("estudios_minimos", selectedOption)
                          }}
                          options={[
                            { value: "eso", label: "Educación Secundaria Obligatoria" },
                            { value: "bachillerato", label: "Bachillerato" },
                            { value: "ciclo_medio", label: "Ciclo Formativo Grado Medio" },
                            { value: "ciclo_superior", label: "Ciclo Formativo Grado Superior" },
                            { value: "artisticas", label: "Enseñanzas artísticas (regladas)" },
                            { value: "deportivas", label: "Enseñanzas deportivas (regladas)" },
                            { value: "grado", label: "Grado" },
                            { value: "licenciatura", label: "Licenciatura" },
                            { value: "diplomatura", label: "Diplomatura" },
                            { value: "ingenieria_tecnica", label: "Ingeniería Técnica" },
                            { value: "ingenieria_superior", label: "Ingeniería Superior" },
                            { value: "postgrado", label: "Postgrado" },
                            { value: "master", label: "Máster" },
                            { value: "doctorado", label: "Doctorado" },
                            { value: "otros", label: "Otros títulos, certificaciones y carnés" },
                            { value: "fp_grado_medio", label: "Formación Profesional Grado Medio" },
                            { value: "fp_grado_superior", label: "Formación Profesional Grado Superior" }
                          ]}
                        />
                        <div className="mt-2">
                          <ErrorMessage
                            name="estudios_minimos"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={12}>
                  <div>
                    <Row gutter={24} className="">
                      <Col xs={12} md={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Tipo de contrato *
                        </h3>
                        <SelectBasic
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          value={{ value: values.tipo_contrato, label: values.tipo_contrato }}
                          onChange={(selectedOption: any) => {
                            setFieldValue("tipo_contrato", selectedOption)
                          }}
                          options={[
                            { value: "indefinido", label: "Indefinido" },
                            { value: "duracion_determinada", label: "De duración determinada" },
                            { value: "otros", label: "Otros contratos" },
                            { value: "tiempo_parcial", label: "A tiempo parcial" },
                            { value: "fijo_discontinuo", label: "Fijo discontinuo" },
                            { value: "formativo", label: "Formativo" },
                            { value: "autonomo", label: "Autónomo" },
                            { value: "relevo", label: "De relevo" }
                          ]}
                        />

                        <div className="mt-2">
                          <ErrorMessage
                            name="tipo_contrato"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Jornada laboral *
                        </h3>
                        <SelectBasic
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          value={{ value: values.jornada_laboral, label: values.jornada_laboral }}
                          onChange={(selectedOption: any) => {
                            setFieldValue("jornada_laboral", selectedOption)
                          }}
                          options={[
                            { value: "todas", label: "Todas las modalidades de jornada" },
                            { value: "completa", label: "Jornada completa" },
                            { value: "indiferente", label: "Jornada indiferente" },
                            { value: "parcial_maniana", label: "Jornada parcial - Turno mañana" },
                            { value: "parcial_tarde", label: "Jornada parcial - Turno tarde" },
                            { value: "parcial_noche", label: "Jornada parcial - Turno noche" },
                            { value: "parcial_indiferente", label: "Jornada parcial - Horario indiferente" },
                            { value: "intensiva_maniana", label: "Jornada intensiva - Turno mañana" },
                            { value: "intensiva_tarde", label: "Jornada intensiva - Turno tarde" },
                            { value: "intensiva_noche", label: "Jornada intensiva - Turno noche" },
                            { value: "intensiva_indiferente", label: "Jornada intensiva - Horario indiferente" }
                          ]}
                        />

                        <div className="mt-2">
                          <ErrorMessage
                            name="jornada_laboral"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>


                <Col xs={24} md={12}>
                  <div>
                    <Row gutter={24}>
                      <Col className="hidden md:block">
                        <div className="divider"></div>
                      </Col>
                      <Col xs={12} md={9}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Sector
                        </h3>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          value={
                            values.sector_id
                              ? { value: values.sector_id.toString(), label: selectedSectores[0] || '' }
                              : null
                          }
                          onChange={(selectedOption: any) => {
                            setFieldValue('sector_id', parseInt(selectedOption.value));
                            setSelectedSectores([selectedOption.label]);
                          }}
                          fetchOptions={async (search) => {
                            const response = await dispatch(GetSectorsReducer(search, 1, 5));
                            return response.payload.data.map((sector: any) => ({
                              label: sector.sector,
                              value: sector.id.toString(),
                            }));
                          }}
                          style={{ width: "100%" }}
                        />

                        <ErrorMessage
                          name="sector_id"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </Col>

                      <Col span={14} className="pt-[40px]">
                        <div className="flex flex-wrap">
                          {selectedSectores.map((sector, index) => (
                            <div key={index}>
                              <CustomTag
                                key={index}
                                text={sector}
                                onClose={() => handleSectorClose(sector)}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Divider className="bg-blue3 my-[40px]"></Divider>

              <Row>
                <Col>
                  <h3 className="font-bold text-heading-x1 font-sans mb-[5px]">
                    ¿Cómo sería tu candidato ideal?
                  </h3>
                </Col>
                <Col>
                  <h3 className="ml-2 text-heading-x1 font-sans">
                    Esta información no saldra en la oferta
                  </h3>
                </Col>
              </Row>

              <Row gutter={24} className="my-4">
                <Col xs={24} sm={24} md={14}>
                  <h3 className="font-medium text-heading-x1 font-sans mb-[8px]">Aptitudes / Tecnología</h3>
                  <Row gutter={24} className="mb-[20px]">
                    <Col span={15}>
                      <div className="w-[225px]">
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          value={null}
                          fetchOptions={async (search) => {
                            const response = await dispatch(GetSkillsReducer(search, 1, 5));
                            return response.payload.data.map((aptitud: any) => ({
                              label: aptitud.aptitud,
                              key: aptitud.id,
                              value: aptitud.id,
                              aptitud: aptitud.aptitud,
                            }));
                          }}
                          onChange={(values, newValue: any) => {
                            if (selectedAptitudes.find((aptitudes: any) => aptitudes.label === newValue.label)) return;
                            const selectedItems = Array.isArray(newValue) ? newValue : [newValue];
                            setSelectedAptitudes((prevSelected: any) => {
                              const rpta = Array.from(new Set([...prevSelected, ...selectedItems]));
                              setFieldValue("aptitudes_ofertas", rpta);
                              return rpta;
                            });
                          }}
                          style={{ width: "100%" }}
                        />

                      </div>
                      <div className="mt-2">
                        <ErrorMessage name="aptitudes_ofertas" component="div" className="text-red-500 text-sm" />
                      </div>
                    </Col>

                    <Col span={24}>
                      <div className="flex flex-wrap mt-2">
                        {selectedAptitudes.map((aptitud: any) => (
                          <CustomTag
                            key={aptitud.key}
                            text={aptitud.label}
                            onClose={() => handleAptitudRemove(aptitud.key, setFieldValue)}
                          />
                        ))}
                      </div>
                    </Col>
                  </Row>

                  <h3 className="font-medium text-heading-x1 font-sans mb-[8px]">Palabras clave</h3>
                  <Row gutter={24}>
                    <Col span={15}>
                      <div className="w-[225px]">
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          value={null}
                          fetchOptions={async (search) => {
                            const response = await dispatch(GetKeywordsReducer(search, 1, 5));
                            return response.payload.data.map((keyword: any) => ({
                              label: keyword.palabra,
                              key: keyword.id,
                              value: keyword.id,
                              palabra_clave: keyword.palabra,
                            }));
                          }}
                          onChange={(values, newValue: any) => {
                            if (selectedKeywords.find((keyWords: any) => keyWords.label === newValue.label)) return;
                            const selectedItems = Array.isArray(newValue) ? newValue : [newValue];
                            setSelectedKeywords((prevSelected: any) => {
                              const rpta = Array.from(new Set([...prevSelected, ...selectedItems]));
                              setFieldValue("palabras_clave_ofertas", rpta);
                              return rpta;
                            });
                          }}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mt-2">
                        <ErrorMessage name="palabras_clave_ofertas" component="div" className="text-red-500 text-sm" />
                      </div>
                    </Col>

                    <Col span={24}>
                      <div className="flex flex-wrap mt-2">
                        {selectedKeywords.map((keyword: any) => (
                          <CustomTag
                            key={keyword.key}
                            text={keyword.label}
                            onClose={() => handleKeywordRemove(keyword.key, setFieldValue)}
                          />
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} sm={24} md={10} className="mb-[144px]">
                  <h3 className="font-medium text-heading-x1 mb-2">
                    Soft skills
                  </h3>
                  <div className="h-[362px]">
                    {rex_aptitudes.map((skill: any) => {
                      const matchingSkill = softSkills.find((s) => s.soft_skill_id === skill.id);
                      if ((isEditMode || isRelatedOffer) && !matchingSkill) {
                        return null;
                      }
                      return (
                        <Sliders
                          key={skill.id}
                          skillId={skill.id}
                          minLabel={skill.soft_skill}
                          maxLabel={skill.soft_skill_c}
                          defaultValue={matchingSkill ? matchingSkill.porcentaje / 10 : 0}
                          onChange={(value: number) => handleSliderChange(skill.id, value)}
                        />
                      );
                    })}
                  </div>
                </Col>
              </Row>

              <Row className="justify-center md:justify-end mb-[24px]">
                <Col>
                  <Button className="bg-white text-[#5F5F5F] border border-white w-[310px] mr-[8px] principal-nav-notify-button0"
                    onClick={() => handleCancelCreation()}
                  >
                    Cancelar creación de oferta de empleo
                  </Button>
                </Col>
                <Row gutter={[6, 6]}>
                  <Col>
                    <Button
                      onClick={() => showModal(values)}
                      className=" principal-nav-notify-button md:w-[327px]h-full !border-blue !bg-white text-blue font-sans hover:!text-blue hover:!border-2 font-semibold text-base ease-in-out duration-100"
                    >
                      <EyeFilled /> Vista previa
                    </Button>
                  </Col>

                  <ModalPreview
                    visible={isModalVisible}
                    formValues={modalData}
                    onClose={handleCloseModal}
                    loading={false}
                  />
                  <Col>
                    <Button
                      className="bg-blue3 text-white w-[122px] principal-nav-notify-button2 text-base font-sans"
                      htmlType="submit"
                      loading={rex_loading}
                    >
                      Siguiente
                    </Button>
                  </Col>
                  {/*<Col>
                    <Button
                      className="bg-blue3 text-white w-[122px] principal-nav-notify-button2 pl-1 pr-1 font-sans"
                      htmlType="submit"
                      loading={rex_loading}
                      iconPosition={"end"}
                    >
                      {isEditMode ? "Guardar Oferta" : "Crear Oferta"}
                    </Button>
                  </Col>*/}
                </Row>
              </Row>
              <br></br>
            </div >
          </Form >
        )}
      </Formik >
      <div>
        <ModalCancelOferta
          showModal={isModalConfirmVisible}
          setShowModal={setIsModalConfirmVisible}
          onOk={handleConfirmCancel} // Pasar la función de confirmación al modal
        />
      </div>
    </>
  );
};

export default CreateOffer;
