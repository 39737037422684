// src/constants/pages/myPortal/education/PostKillersQuestions.ts

export const ADD_KILLER_QUESTIONS_REQUEST = 'ADD_KILLER_QUESTIONS_REQUEST';
export const ADD_KILLER_QUESTIONS_SUCCESS = 'ADD_KILLER_QUESTIONS_SUCCESS';
export const ADD_KILLER_QUESTIONS_FAILURE = 'ADD_KILLER_QUESTIONS_FAILURE';

// Action Types
interface AddKillerQuestionsRequestAction {
  type: typeof ADD_KILLER_QUESTIONS_REQUEST;
}

interface AddKillerQuestionsSuccessAction {
  type: typeof ADD_KILLER_QUESTIONS_SUCCESS;
  payload: {
    data: any; // Response data from the API
  };
}

interface AddKillerQuestionsFailureAction {
  type: typeof ADD_KILLER_QUESTIONS_FAILURE;
  payload: string;
}

export type AddKillerQuestionsActionTypes =
  | AddKillerQuestionsRequestAction
  | AddKillerQuestionsSuccessAction
  | AddKillerQuestionsFailureAction;
