import React, { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import ButtonCom from "../../../button/Button";
import DebounceSelect from "../../employment/searchResult/SelectKeyWords"; // Reutilizando tu componente DebounceSelect
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store/store";
import { GetEmpresasReducer } from "../../../../redux/actions/common/company/Company"; // Importa la acción de empresas

interface EmpresasProps {
  initialCompanies?: {}[];
  onCompaniesChange?: (companies: string[]) => void;
  onExcludedCompaniesChange?: (excludedCompanies: string[]) => void,
  ref?:any
}

const Empresas = forwardRef(
  (
    { initialCompanies = [], onCompaniesChange, onExcludedCompaniesChange }: EmpresasProps,
    ref
  ) => {
  const dispatch = useDispatch<AppDispatch>();
  const [companies, setCompanies] = useState<{value:string, excluded:boolean}[]>([]); // Lista de empresas seleccionadas
  const [newCompany, setNewCompany] = useState<{value:string, excluded:boolean, label:string}>({ value: '', excluded: false, label: '' }); // Nueva empresa seleccionada
  const [ clearOptions, setClearOptions ] = useState(false)

  // Función para obtener las opciones de empresas desde la API
  const fetchCompanyOptions = async (search: string) => {

    const searchFormated = search.startsWith("-") ? search.slice(1) : search;

    const response = await dispatch(GetEmpresasReducer(searchFormated, 1, 5)); // Cambia los parámetros si es necesario
    return response.payload.data.map((company: any) => {

      const isExcluded = search.startsWith("-")

      return {
        label: isExcluded ? "-" + company.empresa : company.empresa,
        value: isExcluded ? "-" + company.empresa : company.empresa,
        excluded: isExcluded
      }
    });
  };

  const resetCompanies = () => {
    setCompanies([])
  };

  // Exponemos el método `resetCompanies` para que el padre pueda llamarlo
  useImperativeHandle(ref, () => ({
    resetCompanies,
  }))

  // Agregar la nueva empresa seleccionada
  const addCompany = () => {

    const findCompany = companies.find(com => com.excluded == newCompany.excluded && (newCompany.excluded ? "-"+ com.value == newCompany.value.trim() : newCompany.value.trim()))
    
    if (newCompany?.value?.trim() !== "" && !findCompany) {

      let companiesString = companies.filter((com: any) => com.excluded == false).map((com: any) => com.value)
      let excludedCompaniesString = companies.filter((com: any) => com.excluded == true).map((com: any) => com.value)

      if (newCompany?.excluded) {
        if (onExcludedCompaniesChange) {
          onExcludedCompaniesChange([...excludedCompaniesString, newCompany?.value?.slice(1)])
        }
      } else {
        if (onCompaniesChange) {
          onCompaniesChange([...companiesString, newCompany?.value]); // Notifica los cambios al componente padre
        }
      }

      const updatedCompanies = [...companies, { value: newCompany.excluded ? newCompany?.value?.trim().slice(1) : newCompany?.value?.trim(), excluded: newCompany?.excluded }];
      setCompanies(updatedCompanies)
      setNewCompany({ value: '', excluded: false, label: '' })

      setClearOptions(true)
      setTimeout(() => setClearOptions(false), 100);
    }
  }

  // Eliminar una empresa seleccionada
  const removeCompany = (companyToRemove: string) => {

    const companiesPrev = [...companies]

    let company: any = companiesPrev.find((com: any) => com.value == companyToRemove)

    const updatedCompanies = companies.filter(
      (company: any) => company.value !== companyToRemove
    );
    setCompanies(updatedCompanies)

    let companiesString = updatedCompanies.filter((com: any) => com.excluded == false).map((com: any) => com.value)
    let excludedCompaniesString = updatedCompanies.filter((com: any) => com.excluded == true).map((com: any) => com.value)
    if (company.excluded) {
      if (onExcludedCompaniesChange) {
        onExcludedCompaniesChange(excludedCompaniesString)
      }
    } else {
      if (onCompaniesChange) {
        onCompaniesChange(companiesString); // Notifica los cambios al componente padre
      }
    }

    setClearOptions(true)
    setTimeout(() => setClearOptions(false), 100)
  };

  return (
    <div>
      {/* Input de búsqueda de empresas */}
      <div className="flex mb-[7px] gap-2">
        <DebounceSelect
          showSearch
          placeholder="Escribe aquí..."
          fetchOptions={fetchCompanyOptions} // Función para buscar empresas
          onChange={(value: any, option: any) => {
            setNewCompany(option)
          }}
          value={newCompany.value!= "" ? {label: newCompany.label, value: newCompany.value} : null}
          clearOptions={clearOptions}
          customClassName="custom-input-class" // Añade una clase personalizada si lo deseas
          style={{ width: "109px", height: '36px', marginBottom: "10px" }}
        />

        <ButtonCom
          className="w-[64px] flex items-center font-sans"
          buttons={[
            {
              type: "link",
              label: "Añadir",
              border: "1px solid #006497",
              size: "small",
              textColor: "#006497",
              fontSize: "12px",
              fontWeight: "bold",
              textAlign: "center",
              onClick: addCompany, // Añadir empresa seleccionada
            },
          ]}
        />
      </div>

      {/* Lista de empresas seleccionadas */}
      <div className="flex flex-col">
        {companies.map((company: any, index) => (
          <div
            key={index}
            className="flex justify-between items-center mb-[8px]"
          >
            <span className={`font-medium text-body-sm ${company.excluded ? "text-[#FF6B6B]" : "text-green22"}`}>
              {company.value}
            </span>
            <span
              className="ml-2 cursor-pointer transition-colors font-medium text-body-sm text-green22"
              onClick={() => removeCompany(company.value)} // Eliminar empresa seleccionada
            >
              X
            </span>
          </div>
        ))}
      </div>
    </div>
  )
});

export default Empresas;
