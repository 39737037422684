// constants.ts
export const FETCH_CV_GENERAL_REQUEST = 'FETCH_CV_GENERAL_REQUEST';
export const FETCH_CV_GENERAL_SUCCESS = 'FETCH_CV_GENERAL_SUCCESS';
export const FETCH_CV_GENERAL_FAILURE = 'FETCH_CV_GENERAL_FAILURE';
export const UPDATE_CV_GENERAL = 'UPDATE_CV_GENERAL';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';
export const UPDATE_USER_GRAPH_SOFT_SKILLS = 'UPDATE_USER_GRAPH_SOFT_SKILLS'

export interface CvGeneral {
  checked: boolean;
  id: number;
  usuario: string;
  imagen: string;
  ubicacion: string;
  meses_experiencia: number;
  personas: {
    nombre: string;
    apellido_materno: string;
    apellido_paterno: string;
  };
  soft_skills_usuarios: [
        {
          soft_skills : {
            soft_skills_oferta: any;
            soft_skill: string
          },
          nivel: number,
          porcentaje: number
        }
  ]
  aptitudes_usuarios: Array<{
    aptitudes: {
      id: number;
      aptitud: string;
    };
  }>;
  educacion_usuarios: Array<{
    id: number;
    tipo_educacion_id: number;
    centro_educativo_id: number;
    usuario_id: number;
    carrera_id: number;
    fecha_inicio: string;
    fecha_final: string;
    nombre_centro_educativo: string;
    carrera: string;
    ubicacion: string;
  }>;
  idiomas_usuarios: Array<{
    id: number;
    nivel_idioma_id: number;
    usuario_id: number;
    niveles_idiomas: {
      nivel: string;
      idiomas: {
        idioma: string;
      };
    };
  }>;
  cvs_usuarios: Array<any>; // You may want to define a more specific type here
  experiencias_laborales_usuarios: Array<{
    id: number;
    empresa_id: number;
    usuario_id: number;
    sector_id: number;
    cargo: string;
    descripcion: string;
    nombre_empresa: string;
    fecha_inicio: string;
    fecha_fin: string;
    nombre_sector: string;
  }>;
  valoraciones_usuarios: Array<{
    id: number;
    usuario_id: number;
    usuarios: number;
    valoracion: number;
  }>;
  promedioValoraciones: number;
  valoracionesPromedio: number;
  valoracionesCount: number;
  pais: {
    pais: string;
  }
}

export interface DataSoftSkill {
  [key: string]: string | number | undefined;
  subject: string;
  idxSubject?: number,
  A?: number;
  B?: number;
  C?: number;
  D?: number;
  E?: number;
  F?: number;
  G?: number;
  H?: number;
  I?: number;
  J?: number;
  fullMark: number;
}

export interface SoftSkillsUsersTable {
  data  : DataSoftSkill[],
  users : []
}

export interface DetailSoftSkillsFormat { 
  soft_skills: { 
    soft_skill: any
    soft_skill_c: any 
  }
  nivel: number 
}

export interface DetailSoftSkillsOfferFormat { 
  soft_skills: { 
    soft_skill: any
    soft_skill_c: any 
  }
  porcentaje: number 
}

export interface LabelsGraphSoftSkills {
  id: number,
  nameKey: string,
  color: string
}

export interface FetchCvGeneralRequestAction {
  type: typeof FETCH_CV_GENERAL_REQUEST;
}

export interface FetchCvGeneralSuccessAction {
  type: typeof FETCH_CV_GENERAL_SUCCESS;
  payload: {
    resetUserGraph: boolean;
    data: CvGeneral[];
    meta: { total: number; limit: number; page: number }; 
  };
}

export interface UpdateUsersGraphSoftSkillsAction {
  type: typeof UPDATE_USER_GRAPH_SOFT_SKILLS;
  payload: {
    data: DataSoftSkill[];
    users: []; 
  };
}

export interface FetchCvGeneralFailureAction {
  type: typeof FETCH_CV_GENERAL_FAILURE;
  payload: string;
}

export interface UpdateCvGeneralSuccessAction {
  type: typeof UPDATE_CV_GENERAL;
  payload: [];
}


interface SetFilterParamsAction {
  type: typeof SET_FILTER_PARAMS;
  payload: any; // Suponiendo que tienes un tipo FilterParams definido
}


export type CvGeneralActionTypes =
  | FetchCvGeneralRequestAction
  | UpdateCvGeneralSuccessAction
  | FetchCvGeneralSuccessAction
  | FetchCvGeneralFailureAction
  | UpdateUsersGraphSoftSkillsAction
  | SetFilterParamsAction;