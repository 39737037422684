import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import axios from 'axios';
import {
  SEARCH_OFFERS_EMPL_REQUEST,
  SEARCH_OFFERS_EMPL_SUCCESS,
  SEARCH_OFFERS_EMPL_FAILURE,
  SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL,
  RESET_LOCATION_FILTER_PARAMS,
  SearchOffersEmplActionTypes
} from '../../../constants/offers/SearchOffers';
import FetchWithIP from '../utils/FetchHeaders';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchSearchOffersEmplRequest = (): SearchOffersEmplActionTypes => ({
  type: SEARCH_OFFERS_EMPL_REQUEST,
});

export const fetchSearchOffersEmplSuccess = (data: any[],  meta: { total: number; limit: number; page: number }): SearchOffersEmplActionTypes => ({
  type: SEARCH_OFFERS_EMPL_SUCCESS,
  payload: { data, meta },
});

export const fetchSearchOffersEmplFailure = (error: string): SearchOffersEmplActionTypes => ({
  type: SEARCH_OFFERS_EMPL_FAILURE,
  payload: error,
});


export const setFilterParamsSearchOffersEmpl = (filters: any): SearchOffersEmplActionTypes => ({
  type: SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL,
  payload: filters,
});

export const resetLocationFilterParams = (): SearchOffersEmplActionTypes => ({
  type: SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL,
  payload: { location: null }
});

export const SearchOffersEmplReducer = (
  page?: number ,
  limit?: number ,
  cargo?: string,
  sector?: string,
  pais?: string ,
  ciudad?: string ,
  valoraciones_empresasArray?: number[],
  palabras_claveArray?: string[],
  sectores_Array?: string[],
  tamanioArray?: { desde: number; hasta: number }[]
): ThunkAction<Promise<any>,RootState,unknown,SearchOffersEmplActionTypes> => async (dispatch) => {
  dispatch(fetchSearchOffersEmplRequest());

  if (valoraciones_empresasArray) {
    dispatch(setFilterParamsSearchOffersEmpl(valoraciones_empresasArray));
  }
  if (palabras_claveArray) {
    dispatch(setFilterParamsSearchOffersEmpl(palabras_claveArray));
  }
  if (sectores_Array) {
    dispatch(setFilterParamsSearchOffersEmpl(sectores_Array));
  }
  if (tamanioArray) {
    dispatch(setFilterParamsSearchOffersEmpl(tamanioArray));
  }

  const queryParams = new URLSearchParams({
    sortOrder: 'desc',
    sortColumn: 'id',
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
    ...(cargo !== undefined && { cargo: cargo.toString() }),
    ...(sector !== undefined && { sector: sector.toString() }),
    ...(pais !== undefined && { ubi_provincia: pais.toString() }),
    ...(ciudad !== undefined && { ubi_poblacion: ciudad.toString() }),
  }).toString();

  try {
    const locationFilter = [pais, ciudad, sector,cargo].filter(Boolean).join(', ');

    if (locationFilter) {
      dispatch(setFilterParamsSearchOffersEmpl({ location: locationFilter }));
    } else {
    }

    const response = await FetchWithIP(`ofertas/all?${queryParams}`,{
      method: 'POST'
    }, {
      valoraciones_empresasArray,
      palabras_claveArray, 
      sectores_Array,
      tamanioArray
    }
  )
    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();


    if (responseData && responseData.data) {
      const { total, limit, page } = responseData.meta;
      dispatch(fetchSearchOffersEmplSuccess(
        responseData.data, { total, limit, page}));
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }

    return true
  } catch (error: any) {
    dispatch(fetchSearchOffersEmplFailure(error.message || 'Error al cargar '));
    return { error: error.message };
  }
};
