// Constantes para las acciones relacionadas con "Busquedas de usuarios"
export const FETCH_USER_SEARCHES_REQUEST = 'FETCH_USER_SEARCHES_REQUEST';
export const FETCH_USER_SEARCHES_SUCCESS = 'FETCH_USER_SEARCHES_SUCCESS';
export const FETCH_USER_SEARCHES_FAILURE = 'FETCH_USER_SEARCHES_FAILURE';

interface Pais {
	pais : string
}
interface Ciudad {
	ciudad : string
}

export interface userSearchesDto {
	nombre : string
	puesto : string | null
	ultima_busqueda : string
	pais : Pais | null
	ciudad: Ciudad | null
	createdAt: string
}

interface FetchUserSearchesRequestAction {
	type: typeof FETCH_USER_SEARCHES_REQUEST;
}

interface FetchUserSearchesSuccessAction {
	type: typeof FETCH_USER_SEARCHES_SUCCESS;
	payload: {
		data: userSearchesDto[]
	};
}

interface FetchUserSearchesFailureAction {
	type: typeof FETCH_USER_SEARCHES_FAILURE;
	payload: string;
}

export type UserSearchesActionTypes =
	| FetchUserSearchesRequestAction
	| FetchUserSearchesSuccessAction
	| FetchUserSearchesFailureAction;