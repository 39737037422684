export const FETCH_PAQUETES_REQUEST = 'FETCH_PAQUETES_REQUEST';
export const FETCH_PAQUETES_SUCCESS = 'FETCH_PAQUETES_SUCCESS';
export const FETCH_PAQUETES_FAILURE = 'FETCH_PAQUETES_FAILURE';

interface FetchPaquetesRequestAction {
    type: typeof FETCH_PAQUETES_REQUEST;
}

interface FetchPaquetesSuccessAction {
    type: typeof FETCH_PAQUETES_SUCCESS;
    payload: any;
}

interface FetchPaquetesFailureAction {
    type: typeof FETCH_PAQUETES_FAILURE;
    payload: string;
}

export interface PaqueteDetalleCaracteristica {
    descripcion : string
}


export interface PaqueteDetalle {
    id: number,
    paquete: string,
    precio : number,
    credito_oferta: number,
    credito_perfil : number,
    descripcion: string,
    caracteristicas_paquetes : PaqueteDetalleCaracteristica[]
}

export interface Paquete {
    tipo: string;
    descripcion: string;
    paquetes : PaqueteDetalle[]
    createdAt: string;
    updatedAt: string;
  }

export type PaquetesActionTypes = 
  | FetchPaquetesRequestAction
  | FetchPaquetesSuccessAction
  | FetchPaquetesFailureAction;