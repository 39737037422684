import React, { useState, useEffect } from "react";
import { Row, Col, Card, Divider, Drawer, Button, Menu, Dropdown } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store/store";
import { fetchApplicationPhasesReducer } from "../../../redux/actions/common/fase/GetApplicationPhases";
import OffersProfileInfo from "../../../components/pages/offers/offersProfile/OffersProfileInfo";
import TableProfile from "../../../components/pages/offers/offersProfile/TableOffers";
import SidebarOffers from "../../../components/pages/offers/offersProfile/SidebarOffersProfile";
import Stream from "../../../assets/icons/view_stream.svg";
import List from "../../../assets/icons/view_list.svg";
import IconButton from "../../../components/pages/searchCV/IconButton";
import ModalSoftSkillsC from "../../../components/pages/offers/offersProfile/ModalSoftSkillsC";

interface FilterParams {
  educations?: string[];
  keywords?: string[];
  ratings?: number[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
  experiences?: number[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  educationsUsers?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  experiencia_nula?: number[];
  modalidad_remoto?: string[];
}

const clearFilters = {
  educations: [],
  keywords: [],
  sectors: [],
  tamanioArray: [],
  experiences: [],
  ratings: [],
  valoraciones_usuariosmax: false,
  paises: [],
  ciudades: [],
  softSkills: [],
  experienciaLaboral: [],
  educationsUsers: [],
  excluirEducationsUsers: [],
  excluirExperienciaLaboral: [],
  companies: [],
  experiencia_nula: [],
  modalidad_remoto: [],
  activeSearch: true,
  jornada: [],
  excluirEmpresa: [],
}

const OffersProfile: React.FC = () => {
  const location = useLocation();
  const { nombreOferta } = location.state;

  const { id } = useParams<{ id: string }>();
  const [selectedView, setSelectedView] = useState<string>("stream");
  const [showSoftSkills, setShowSoftSkills] = useState<boolean>(false)
  const [activeSearch, setActiveSearch] = useState<boolean>(true)
  const offerId = parseInt(id || "0", 10);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { rex_phases, rex_loadingp } = useSelector((state: RootState) => state.getApplicationPhases);
  const [selectedUserSoftSkills, setSelectedUserSoftSkills] = useState<any | null>(null);

  useEffect(() => {
    dispatch(fetchApplicationPhasesReducer());
  }, []);

  const handleViewSelect = (view: string) => {
    setSelectedView(view);
  };

  const handlePhaseChange = (phase: string) => {
    console.log(`Changed phase to: ${phase}`);
    // Here you would typically dispatch an action to update the phase
  };

  const phaseMenu = (
    <Menu onClick={({ key }) => handlePhaseChange(key.toString())}>
      {rex_phases.map((phase) => (
        <Menu.Item key={phase.id}>{phase.fase}</Menu.Item>
      ))}
    </Menu>
  );
  const [modalData, setModalData] = useState<any>(null);

  const [filterParams, setFilterParams] = useState<FilterParams>(clearFilters);

  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));
  };

  const handleResetFilters = () => {
    setFilterParams(clearFilters)
  }


  return (
    //BASE
    <div className="flex justify-center">
      <div className="w-full 2x2:w-[1370px]">
        <div className="px-4 lg:px-8 py-8 lg:py-12">

          <div className="flex flex-col lg:flex-row">
            <Button
              type="primary"
              className="lg:hidden mb-4"
              onClick={() => setDrawerVisible(true)}
              icon={<MenuOutlined />}
            />
            <div style={{ width: "234px", backgroundColor: "white" }} className="hidden lg:block p-0">
              <SidebarOffers
                activeSearch={activeSearch}
                setActiveSearch={setActiveSearch}
                onFilterChange={handleFilterChange}
                resetFilters={handleResetFilters}
              />
            </div>

            <Drawer
              title={null}
              drawerStyle={{ padding: 0 }}
              width={268}
              placement="left"
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
              className="block lg:hidden"
            >
              <SidebarOffers
                inDrawer
                activeSearch={activeSearch}
                setActiveSearch={setActiveSearch}
                onFilterChange={handleFilterChange}
                resetFilters={handleResetFilters}
              />
            </Drawer>

            <div className="flex-1 pl-0 md:pl-[24px]">
              <Row gutter={[16, 16]}>
                <Col sm={24} md={24}>
                  <div className="mb-6">
                    <div className="flex justify-between items-center">
                      <div>
                        <h2
                          onClick={() => navigate('/offers')}
                          className="font-bold text-[#006497] text-2xl inline cursor-pointer"
                        >
                          Oferta
                        </h2>
                        <span className="font-bold text-[#1A1A1A70] text-2xl mx-2">&gt;</span>
                        <span className="font-bold text-black text-2xl inline">Listado de inscritos en la oferta</span>
                      </div>
                    </div>
                    <Divider className="my-4 bg-[#A1A1AA]" />
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <h3 className="text-sm font-medium font-sans text-black">Título de la oferta</h3>
                        <p className="text-lg font-medium font-sans text-black">{nombreOferta}</p>
                      </div>
                      {/* <Button
                          className="principal-nav-notify-button w-[150px] h-[36px] font-semibold text-body-sm rounded-[4px]"
                          onClick={() => navigate("")}
                          disabled
                        >
                          Guardar búsqueda
                        </Button>*/}
                    </div>
                    <Row gutter={16} className="mb-[30px]">
                      <Col xs={24} md={24} lg={24} className="">
                        <Row gutter={24} className="flex justify-between">
                          <Col>
                            <span className="font-bold font-sans text-[#5F5F5F] text-xs">Ordenado por:</span>
                            <div className="mt-2">
                              <span className="font-bold text-green32 text-xs cursor-not-allowed">
                                RECOMENDACIÓN IA
                              </span>
                              <span className="font-bold text-black text-xs mx-2">|</span>
                              <span className="font-bold text-[#00476D] text-xs">FECHA DE INSCRIPCIÓN</span>
                            </div>
                          </Col>
                          <Col className="flex justify-end items-center">
                            <IconButton
                              icon={Stream}
                              selected={selectedView === "stream"}
                              onClick={() => handleViewSelect("stream")}
                              altText="Icono de flujo"
                            />
                            <IconButton
                              icon={List}
                              selected={selectedView === "list"}
                              onClick={() => handleViewSelect("list")}
                              altText="Icono de lista"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row >

                    <div className="flex justify-between items-center mt-6 -mb-2">
                      <Row gutter={[0, 6]} className="flex justify-between w-full">
                        <Col lg={19} className="">
                          <Row gutter={[6, 6]} className="h-full flex items-center">
                            <Col className="flex content-center w-full md:w-auto h-min">
                              <span className="text-[#1A1A1A] text-[12px] font-bold opacity-70 flex items-center justify-center">
                                ACCIONES DE PERFIL:
                              </span>
                            </Col>
                            <Col className="flex content-center justify-center w-2/4 md:w-auto border-r-[3px] border-black h-min">
                              <a href="/" className="text-[#00476D] font-bold text-[12px] px-2 flex items-center justify-center">
                                Añadir a favoritos
                              </a>
                            </Col>
                            <Col className="flex content-center justify-center border-none md:border-solid w-2/4 md:w-auto border-r-[3px] md:border-black h-min">
                              <Dropdown overlay={phaseMenu} trigger={['click']} disabled={rex_loadingp}>
                                <a className="text-[#00476D] font-bold text-[12px] cursor-pointer px-2 flex items-center justify-center">
                                  Avanzar en la fase
                                </a>
                              </Dropdown>
                            </Col>
                            <Col className="flex content-center justify-center w-2/4 md:w-auto border-r-[3px] border-black h-min">
                              <a href="/" className="text-[#00476D] font-bold text-[12px] px-2 flex items-center justify-center">
                                Guardar perfil
                              </a>
                            </Col>
                            <Col className="flex content-center justify-center w-2/4 md:w-auto h-min">
                              <a href="/" className="text-[#DC2828] font-bold text-[12px] px-2 flex items-center justify-center">
                                Archivar candidato
                              </a>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} lg={5} className="">
                          <button
                            className="text-[#00476D] font-bold text-xs ml-auto"
                            onClick={() => {
                              setShowSoftSkills(true)
                            }}
                          >
                            Ver candidatos seleccionados sobre gráfica
                          </button>
                        </Col>
                      </Row>
                    </div>

                  </div >
                  {selectedView === "stream" ? (
                    <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4 mb-4">
                      <OffersProfileInfo
                        offerId={offerId}
                        filters={filterParams}
                      />
                    </Card>
                  ) : (
                    <div className="">
                      <TableProfile
                        offerId={offerId}
                        filters={filterParams}
                      />
                    </div>
                  )}
                </Col >
              </Row>
            </div>
          </div>

          {
            showSoftSkills && (
              <ModalSoftSkillsC
                visible={showSoftSkills}
                onClose={() => setShowSoftSkills(false)}
              />
            )
          }

        </div >
      </div>
    </div>
  );
};

export default OffersProfile;