import { ThunkAction } from "redux-thunk";
import { 
    FETCH_PAQUETES_FAILURE, 
    FETCH_PAQUETES_REQUEST, 
    FETCH_PAQUETES_SUCCESS,
    PaquetesActionTypes 
} from "../../../../constants/common/paquetes/Paquetes";
import { RootState } from "../../../store/store";
import FetchWithIP from "../../utils/FetchHeaders";

export const fetchPaquetesRequestAction = (): PaquetesActionTypes => ({
	type: FETCH_PAQUETES_REQUEST,
});

export const fetchPaquetesSuccessAction = (data: []): PaquetesActionTypes => ({
	type: FETCH_PAQUETES_SUCCESS,
	payload: data,
});

export const fetchPaquetesFailureAction = (error: string): PaquetesActionTypes => ({
	type: FETCH_PAQUETES_FAILURE,
	payload: error,
});


export const GetPackagesReducer = ():
	ThunkAction<Promise<any>, RootState, unknown, PaquetesActionTypes> => async (dispatch) => {
		try {

			dispatch(fetchPaquetesRequestAction());

			const response = await FetchWithIP(`paquetes`, {
				method: 'GET'
			});

			if (!response.ok) {
				throw new Error(`Error HTTP: ${response.status}`);
			}

			const responseData = await response.json();

			if (responseData.data) {
				dispatch(fetchPaquetesSuccessAction(responseData.data));
			} else {
				throw new Error('No se encontraron datos de los paquetes')
			}
		} catch (error) {
			dispatch(fetchPaquetesFailureAction('Error al mostrar datos'));
			return { payload: { data: [] } };
		}
	};