import { Button } from 'antd'
import React from 'react'
import './../../../../styles/components/pages/offers/offersProfile/ButtonUnlocked.css'

interface ButtonUnlockedProps {
	text: string;
	onAction: any;
	icon? : JSX.Element;
	customClassName?: string
}


const ButtonUnlocked: React.FC<ButtonUnlockedProps> = ({ text, onAction, icon, customClassName }) => {
	return (
		<Button
			type="primary"
			className={`button-unlocked font-sans bg-[#006497] w-full md:w-auto font-semibold text-[14px] h-9 ${customClassName}`}
			onClick={onAction}
		>
			{icon}{text}
		</Button>
	)
}

export default ButtonUnlocked