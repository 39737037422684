// reducers/offers/DeleteOfferSave.ts
import {
    FETCH_DELETE_OFFER_SAVE_REQUEST,
    FETCH_DELETE_OFFER_SAVE_SUCCESS,
    FETCH_DELETE_OFFER_SAVE_FAILURE,
} from '../../../constants/offers/DeleteOfferSave';

interface EmploymentState {
    rex_loading: boolean;
    rex_data: any[] | null;
    rex_error: string | null;
    rex_meta: {
        total: 0;
    } | null;
}

const initialState: EmploymentState = {
    rex_loading: false,
    rex_data: null,
    rex_error: null,
    rex_meta: null,
};

const GetOfferSave = (state = initialState, action: any): EmploymentState => {
    switch (action.type) {
        case FETCH_DELETE_OFFER_SAVE_REQUEST:
            return {
                ...state,
                rex_loading: true,
                rex_error: null,
            };
        case FETCH_DELETE_OFFER_SAVE_SUCCESS:
            return {
                ...state,
                rex_loading: false,
                // Filtramos el ID que se eliminó de la lista solo si rex_data está definido
                rex_data: state.rex_data ? state.rex_data.filter((offer) => offer.id !== action.payload) : null,
                rex_error: null,
            };
        case FETCH_DELETE_OFFER_SAVE_FAILURE:
            return {
                ...state,
                rex_loading: false,
                rex_error: action.error || 'Error desconocido',
            };
        default:
            return state;
    }
};


export default GetOfferSave;
