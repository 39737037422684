import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  FETCH_POSTULACIONES_REQUEST,
  FETCH_POSTULACIONES_SUCCESS,
  FETCH_POSTULACIONES_FAILURE,
  UPDATE_CV_OR_VE_REQUEST,
  UPDATE_CV_OR_VE_SUCCESS,
  UPDATE_CV_OR_VE_FAILURE,
  PostulacionesActionTypes,
  SET_FILTER_PARAMS,
  Postulacion,
  UPDATE_DATA_POSTULACIONES,
  UPDATE_DATA_SOFT_SKILLS_OFFER
} from '../../../../../constants/pages/offers/offersProfile/GetOffersProfile';
import FetchWithIP from '../../../utils/FetchHeaders';
import { DetailSoftSkillsOfferFormat } from '../../../../../constants/pages/searchCV/GetSearchCV';

export const fetchPostulacionesRequest = (): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_REQUEST,
});

export const fetchPostulacionesSuccess = (data: Postulacion[], meta: { total: number; limit: number; page: number }): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_SUCCESS,
  payload: { data, meta },
});

export const updateDataPostulaciones = (data: Postulacion[]): PostulacionesActionTypes => ({
  type: UPDATE_DATA_POSTULACIONES,
  payload: { data },
});

export const updateDataSoftSkillsOffer = (data: { users: any[], data: any[] }): PostulacionesActionTypes => ({
  type: UPDATE_DATA_SOFT_SKILLS_OFFER,
  payload: {
    users: data.users,
    data: data.data
  },
});

export const updateCvOrVeRequest = ( postulacionId: number): PostulacionesActionTypes => ({
  type: UPDATE_CV_OR_VE_REQUEST,
  payload: {
    postulacionId,
  }
});

export const updateCvOrVeSuccess = (updatedPostulacion: Postulacion): PostulacionesActionTypes => ({
  type: UPDATE_CV_OR_VE_SUCCESS,
  payload: {
    updatedPostulacion,
  }
});

export const updateCvOrVeFailure = (error: string): PostulacionesActionTypes => ({
  type: UPDATE_CV_OR_VE_FAILURE,
  payload: {
    error,
  }
});

export const fetchPostulacionesFailure = (error: string): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_FAILURE,
  payload: error,
});

export const setFilterParams = (filters: any): PostulacionesActionTypes => ({
  type: SET_FILTER_PARAMS,
  payload: filters,
});

export const addOrRemoveUserOffers = (
  postulacion: any,
  addUser: boolean,
): ThunkAction<Promise<any>, RootState, unknown, any> => async (dispatch, getState) => {

  try {

    let { ofertas, usuarios } = postulacion
    let { soft_skills_offer, postulaciones } = getState().getOffersProfile;

    if (soft_skills_offer.data.length == 0) {
      soft_skills_offer.data = ofertas.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index: number) => [
        {
          subject: item.soft_skills.soft_skill,
          A: item.porcentaje > 0 ? item.porcentaje / 10 : 0,
          fullMark: 10,
          index: index
        },
        {
          subject: item.soft_skills.soft_skill_c,
          A: 10 - (item.porcentaje > 0 ? item.porcentaje / 10 : 0),
          fullMark: 10,
          index: index
        }
      ]
      )

      soft_skills_offer.data.sort((a, b) => {
        if (a.subject < b.subject) return -1
        if (a.subject > b.subject) return 1
        return 0
      })
    }

    if (addUser) {
      soft_skills_offer.data.forEach((ofs: any) => {

        const findSubjectUser = usuarios.soft_skills_usuarios.find((usr: any) => usr.soft_skills.soft_skill == ofs.subject)

        if (findSubjectUser) {
          ofs[usuarios.id] = findSubjectUser.nivel
          const findSubjectOpposite = soft_skills_offer.data.find((off: any) => off.index == ofs.index && off.subject != ofs.subject)
          if (findSubjectOpposite) {
            findSubjectOpposite[usuarios.id] = 10 - (findSubjectUser.nivel)
          }
        }
      })

      soft_skills_offer.users.push({
        nombre: `${usuarios.personas.nombre} ${usuarios.personas.apellido_paterno} ${usuarios.personas.apellido_materno}`,
        id: usuarios.id,
        nameKey: usuarios.id.toString()
      })

    } else {

      soft_skills_offer.data.map((ofs: any) => {
        delete ofs[usuarios.id]
        return ofs
      })
      soft_skills_offer.users = soft_skills_offer.users.filter((usr: any) => usr.id != usuarios.id)
    }

    const idxUser: number = postulaciones.findIndex(pos => pos.usuarios.id == usuarios.id)
    postulaciones[idxUser]['checked'] = addUser
    dispatch(updateDataPostulaciones(postulaciones))
    dispatch(updateDataSoftSkillsOffer(soft_skills_offer))


  } catch (error) {
    return { payload: [] };
  }
};

export const GetPostulacionesByOfertaReducer = (
  idOferta?: number,
  page?: number,
  limit?: number,
  valoraciones_empresasArray?: number[],
  experiencia_laboralArray?: string[],
  soft_skillsArray?: string[],
  paisesArray?: string[],
  ciudadesArray?: string[],
  empresasArray?: string[],
  meses_experienciaArray?: number[],
  centrosEducativosArray?: string[],
  sectoresArray?: string[],
  valoraciones_usuariosmax?: boolean,
  experiencia_nula?: number[],
  modalidad_usuariosArray?: string[],
  excluir_experiencia_laboralArray?: string[],
  activeSearch?:boolean,
  jornadaArray?:string[],
  excluir_EmpresaArray?: string[],
  excluir_Exper?:string[]

): ThunkAction<Promise<any>, RootState, unknown, PostulacionesActionTypes> => async (dispatch) => {
  dispatch(fetchPostulacionesRequest());

  if (valoraciones_empresasArray) {
    dispatch(setFilterParams(valoraciones_empresasArray));
  }
  if (experiencia_laboralArray) {
    dispatch(setFilterParams(experiencia_laboralArray));
  }
  if (soft_skillsArray) {
    dispatch(setFilterParams(soft_skillsArray));
  }
  if (paisesArray) {
    dispatch(setFilterParams(paisesArray));
  }
  if (ciudadesArray) {
    dispatch(setFilterParams(ciudadesArray));
  }
  if (empresasArray) {
    dispatch(setFilterParams(empresasArray));
  }
  if (sectoresArray) {
    dispatch(setFilterParams(sectoresArray));
  }
  if (valoraciones_usuariosmax) {
    dispatch(setFilterParams(valoraciones_usuariosmax));
  }
  if (experiencia_nula) {
    dispatch(setFilterParams(experiencia_nula));
  }
  if (modalidad_usuariosArray) {
    dispatch(setFilterParams(modalidad_usuariosArray));
  }
  if (activeSearch) {
    dispatch(setFilterParams(activeSearch));
  }
  if (jornadaArray) {
    dispatch(setFilterParams(jornadaArray));
  }
  if (excluir_EmpresaArray) {
    dispatch(setFilterParams(excluir_EmpresaArray));
  }
  if (excluir_Exper) {
    dispatch(setFilterParams(excluir_Exper));
  }
  if (excluir_experiencia_laboralArray) {
    dispatch(setFilterParams(excluir_experiencia_laboralArray));
  }

  const queryParams = new URLSearchParams({
    sortOrder: 'desc',
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
  }).toString();

  try {
    const response = await FetchWithIP(`postulaciones/findAllPostulacionesByOferta/${idOferta}?${queryParams}`, {
      method: 'GET',
    }, {
      centrosEducativosArray,
      meses_experienciaArray,
      valoraciones_empresasArray,
      valoraciones_usuariosmax,
      sectoresArray,
      paisesArray,
      ciudadesArray,
      soft_skillsArray,
      experiencia_laboralArray,
      excluir_experiencia_laboralArray,
      empresasArray,
      experiencia_nula,
      modalidad_usuariosArray,
      activeSearch,
      jornadaArray,
      excluir_EmpresaArray,
    }
    );


    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      const { total, limit, page } = responseData.meta;
      dispatch(fetchPostulacionesSuccess(
        responseData.data, { total, limit, page }
      ));
      return responseData;
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }
  } catch (error: any) {
    dispatch(fetchPostulacionesFailure(error.message || 'Error al cargar las postulaciones'));
    return { error: error.message };
  }};

export const updateCvOrVe = (
  postulacionId: number, 
  candidatura?: string
): ThunkAction<Promise<any>, RootState, unknown, PostulacionesActionTypes> => 
  async (dispatch) => {
    dispatch(updateCvOrVeRequest(postulacionId));

    try {
      const response = await FetchWithIP(`postulaciones/updateCVorVE/${postulacionId}`, {
        method: 'PATCH',
      },{
        candidatura
      }
    );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      dispatch(updateCvOrVeSuccess(responseData.data));
      return responseData;
    } catch (error: any) {
      dispatch(updateCvOrVeFailure(error.message || 'Error al actualizar el estado de la postulación'));
      return { error: error.message };
    }
};