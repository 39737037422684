import {
	FETCH_SAVE_PROFILE_SKILLS_FAILURE,
	FETCH_SAVE_PROFILE_SKILLS_REQUEST,
	FETCH_SAVE_PROFILE_SKILLS_SUCCESS,
	FETCH_PROFILES_SKILLS_REQUEST,
	FETCH_PROFILES_SKILLS_SUCCESS,
	FETCH_PROFILES_SKILLS_FAILURE,
	DELETE_PROFILE_SKILLS_REQUEST,
	DELETE_PROFILE_SKILLS_SUCCESS,
	DELETE_ROFILE_SKILLS_FAILURE,
	FETCH_PROFILE_SKILL_REQUEST,
	FETCH_PROFILE_SKILL_SUCCESS,
	FETCH_PROFILE_SKILL_FAILURE,
	UPDATE_PROFILE_SKILL_REQUEST,
	UPDATE_PROFILE_SKILL_SUCCESS,
	UPDATE_ROFILE_SKILL_FAILURE,
	ProfileSkillsActionTypes,
	ProfileSoftSkill
} from '../../../../constants/offers/skills/ProfileSkills';

// Estado inicial para las aptitudes
interface ProfileSkillsState {
	rex_loading: boolean;
	rex_error: string | null;
	rex_profiles_skills : [],
	rex_profile_skill : ProfileSoftSkill | null
}

const initialState: ProfileSkillsState = {
	rex_loading: false,
	rex_error: null,
	rex_profiles_skills: [],
	rex_profile_skill : null
};

const skillsReducer = (state = initialState, action: ProfileSkillsActionTypes): ProfileSkillsState => {
	switch (action.type) {
		case FETCH_SAVE_PROFILE_SKILLS_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload
			};
		case FETCH_SAVE_PROFILE_SKILLS_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_SAVE_PROFILE_SKILLS_SUCCESS:
			return {
				...state,
				rex_loading: false,
			};		
		case FETCH_PROFILE_SKILL_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload
			};
		case FETCH_PROFILE_SKILL_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_PROFILE_SKILL_SUCCESS:
			return {
				...state,
				rex_loading: false,
				rex_profile_skill : action.payload
			};		
		case FETCH_PROFILES_SKILLS_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_PROFILES_SKILLS_SUCCESS:
			return {
				...state,
				rex_loading: false,
				rex_profiles_skills: action.payload
			};
		case FETCH_PROFILES_SKILLS_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload
			};
		
		case DELETE_PROFILE_SKILLS_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case DELETE_PROFILE_SKILLS_SUCCESS:
			return {
				...state,
				rex_loading: false,
			};
		case DELETE_ROFILE_SKILLS_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload
			};
		case UPDATE_PROFILE_SKILL_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case UPDATE_PROFILE_SKILL_SUCCESS:
			return {
				...state,
				rex_loading: false,
			};
		case UPDATE_ROFILE_SKILL_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload
			};
		default:
			return state;
	}
};

export default skillsReducer;
