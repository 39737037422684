import React, { useState } from "react";
import {
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';
interface SortingOptionsProps {
  onSelect: (option: string) => void;
  changeOrderSortColumn: any,
  sortOrderColumn: string | null,
  setSortOrderColumn: any
}

const SortingOptions: React.FC<SortingOptionsProps> = ({ onSelect, changeOrderSortColumn, sortOrderColumn, setSortOrderColumn }) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleOptionClick = (option: string) => {

    if(option == selectedOption){
      if(sortOrderColumn == "asc"){
        setSelectedOption("")
        onSelect("creatAt");
      }
    }else{
      setSelectedOption(option);
        const optionMap :any = {
        "VALORACIONES": "valoracionesPromedio",
        "EXPERIENCIA": "meses_experiencia"
      };
  
      const valueToSend = optionMap[option]  || option;
      onSelect(valueToSend)

    }

  };

  const getOptionClass = (option: string) =>
    selectedOption === option ? "text-[#00476D]" : "text-green32";

  return (
    <div className="mb-4">
      <span className="font-bold text-green32 text-caption">Ordenado por:</span>
      <div className="flex items-center">
        <span></span>
        {["RECOMENDACIÓN IA", "VALORACIONES", "EXPERIENCIA"].map(
          (option, index) => (
            <React.Fragment key={option}>
              <span
                className={
                  `font-bold ${index == 0 ? 'cursor-not-allowed' : 'cursor-pointer'} ${getOptionClass(option)} text-caption`
                }
                onClick={() => {
                  if(index != 0){
                    handleOptionClick(option)
                    if(option != selectedOption){
                      setSortOrderColumn('desc')
                    }else{
                      changeOrderSortColumn()
                    }
                  }
                }}
              >
                {option}
              </span>
              {
                index != 0
                ? <div className="ml-2">
                    <ArrowDownOutlined style={{color: sortOrderColumn == 'desc' && selectedOption == option ?'#1677ff' : '#D9D9D9', fontSize:'15px'}} />
                    <ArrowUpOutlined style={{color: sortOrderColumn == 'asc' && selectedOption == option ?'#1677ff' : '#D9D9D9', fontSize:'15px'}}/>
                  </div>
                : null
              }
              {index < 2 && (
                <span className="font-bold text-black text-xs mx-[8px]">|</span>
              )}
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
};

export default SortingOptions;