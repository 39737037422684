import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Row, Col, Drawer, Skeleton } from "antd";
import IconSearch from "../../../assets/icons/search.svg";
import alerts from "../../../assets/icons/alerts.svg";
import "../../../styles/pages/employment/Employment.css";
import "../../../styles/pages/employment/FiltersEmployment.css";
import SidebarFilterComponent from "../../../components/pages/employment/searchResult/SidebarFilter";
import "./Company.css";
import CardEmpresa from "../../../components/cards/CardCompany";
import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetCompanyReducer } from "../../../redux/actions/pages/company/Company";
import { RootState, AppDispatch } from "../../../redux/store/store";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetSectorsReducer } from "../../../redux/actions/common/sectors/Sectors";
import Select from "../../../components/pages/offers/CreateOffers/Select";
import { fetchCompanySuccessReducer, searchCompanyAction } from '../../../redux/actions/pages/company/SearchCompany';

const BANNER_DEFAULT = process.env.REACT_APP_BANNER_EMPRESA_DEFAULT;
const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

interface FilterParams {
  ratings?: number[];
  keywords?: string[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
}

interface Company {
  id: string;
  empresa: string;
  descripcion: string;
  sede_fiscal: string;
  pagina_web: string;
  tamanio: number;
  _count: {
    valoraciones_empresas: number;
    ofertas: number;
    empresa_seguida: number;
  };
  sectores?: {
    sector: string;
  };
  logo: string;
  empresaSeguidaByToken: boolean;
}

const Company: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSector, setSelectedSector] = useState<string | undefined>(undefined); // Sector seleccionado
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    ratings: [],
    keywords: [],
    sectors: [],
    tamanioArray: [],
  });


  const { rex_companies, rex_total, rex_limit } = useSelector((state: RootState) => state.company);

  const { rex_companies: searchCompanies, rex_loading: searchLoading } = useSelector((state: RootState) => state.searchCompany);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [searchSector, setSearchSector] = useState<any>(null);

  const clearSector = () => setSearchSector(null);
  const clearSectors = () => {
    setSelectedSector("")
  }

  useEffect(() => {
    if (rex_companies.length === 0 || page === 1) {
      setLoading(true);
      const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined
      const selectedKeywords = filterParams.keywords && filterParams.keywords.length > 0 ? filterParams.keywords : undefined;
      const selectedSectors = filterParams.sectors && filterParams.sectors.length > 0 ? filterParams.sectors : undefined;
      const selectedTamanio = filterParams.tamanioArray && filterParams.tamanioArray.length > 0 ? filterParams.tamanioArray : undefined;
      dispatch(GetCompanyReducer(1, rex_limit, selectedRatings, selectedKeywords, selectedSectors, selectedTamanio)).then(() => {
        setLoading(false);
      });
    }
  }, [rex_limit, page, filterParams]);

  useEffect(() => {
    if (!searchTerm && !selectedSector) {
      dispatch(GetCompanyReducer(1, rex_limit));
      setPage(1);
      setHasMore(true);
    }
  }, [searchTerm, selectedSector, rex_limit]);

  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));

    setPage(1);
    setHasMore(true);
    setLoading(true);
  };

  //Funcion que se ejecuta para validar el funcionamiento de InfinitiScroll


  const handleCardClick = (id: string) => {
    navigate(`/companyDetail/${id}`);
  };

  const handleSearch = async () => {
    setSearchInitiated(true); // Marcar que la búsqueda ha iniciado
    if (searchTerm.trim() !== '' || selectedSector) {
      // Si searchTerm o selectedSector tienen algún valor, se realiza la búsqueda filtrada
      dispatch(searchCompanyAction(searchTerm, selectedSector, 1));
      navigate(`/company?empresa=${searchTerm}&sector=${selectedSector || ''}`);
    } else {
      await dispatch(fetchCompanySuccessReducer([], { total: 0 }))
      // Si ambos están vacíos, cargar la lista completa de empresas
      await dispatch(GetCompanyReducer(1, rex_limit));
      setPage(1); // Reiniciar la página a 1
      setHasMore(true); // Permitir carga adicional
      setSearchInitiated(false); // Indicar que no es una búsqueda específica
    }
  };


  const handleSectorSelect = (newValue: any) => {
    // Limpiamos las selecciones anteriores de sectores y aplicamos el nuevo sector seleccionado
    const selectedItems = Array.isArray(newValue) ? newValue : [newValue];
    const selectedLabels = selectedItems.map((item: any) => item.label || item.value);
    setSelectedSector(selectedLabels[0] || undefined); // Solo selecciona un sector
  };

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);

    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);

    setTimeout(() => {
      dispatch(GetCompanyReducer(nextPage, rex_limit))
        .then(() => {
          setPage(nextPage);
          setLoadingMoreData(false);
          if (nextPage >= totalPages) {
            setHasMore(false);
          }
        })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (rex_total == 0) setHasMore(false)
    if (rex_companies.length == rex_total) setHasMore(false)
  }, [rex_total, rex_companies])
  // Si hay resultados de búsqueda, muéstralos; de lo contrario, muestra los datos generales
  const companiesToShow = searchCompanies && searchCompanies.length > 0 ? searchCompanies : rex_companies;


  return (
    <div className="flex justify-center">
      <div className="w-full 2x2:w-[1370px]">
        <div className="company-container ml-[24px] mr-[24px]">
          <div className="bg-pink-box"></div>

          <Row gutter={[16, 16]} className="flex justify-between">
            <Col sm={24} md={12} lg={10} xl={8} className="w-full">
              <span className="text-2xl font-bold company-text text-center md:text-left mb-4 md:mb-0">
                Sigue a las empresas que más te
                <br /> interesen del sector
              </span>
            </Col>
            <Col xs={24} md={12} xl={14} xxl={14} className="w-full">
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Row gutter={[12,12]} className="w-full flex items-center">
                  <Col xs={24} md={12} lg={12}>
                    <Input
                      prefix={<img src={IconSearch} width={24} height={24} />}
                      placeholder="Buscar empresa..."
                      value={searchTerm}
                      onChange={handleSearchInput}
                      className="Input-Filter-Employment mr-[8px] w-full custom-input-company px-2 rounded font-sans placeholder:!font-sans placeholder:text-lg"
                      onKeyDown={(e?: React.KeyboardEvent | React.MouseEvent)=>{
                        if (e && "key" in e && e.key !== "Enter") return;
                        handleSearch()
                      }}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={8}>
                    <Select
                      showSearch
                      placeholder="Sector (Opcional)"
                      size="large"
                      value={searchSector || undefined}
                      onChange={(newValue: any) => {
                        setSearchSector(newValue)
                        const selectedItems = Array.isArray(newValue)
                          ? newValue
                          : [newValue];
                        const selectedLabels = selectedItems.map(
                          (item: any) => item.label || item.value
                        );
                        console.log('selectedLabels:',selectedLabels)
                        setSelectedSector(selectedLabels[0])
                      }}
                      fetchOptions={async (search) => {
                        const response = await dispatch(GetSectorsReducer(search, 1, 5));
                        return response.payload.data.map((sector: any) => ({
                          label: sector.sector,
                          value: sector.id,
                        }));
                      }}
                      // onChange={handleSectorSelect} // Manejo de selección de sector
                      suffixIcon={
                        searchSector ? (
                          <CloseCircleOutlined
                            onClick={() => {
                              clearSector();
                              clearSectors();
                            }}
                            style={{ color: "black", cursor: "pointer" }}
                          />
                        ) : undefined
                      }  
                      style={{ width: '100%', height: "44px" }}
                    />
                  </Col>
                  <Col xs={12} md={24} lg={4}>
                    <Button
                      className="Button-Filter-Employment h-[44px] px-4 rounded w-full mt-0 flex items-end"
                      onClick={handleSearch}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <p
            onClick={() => {
              setHasMore(false)
            }}
            className="text-gray-700 font-bold text-lg mb-8 text-center md:text-left mt-[55px] font-sans">
            Busca o filtra entre empresas reclutadoras por "valoraciones",
            "sector", "palabras clave"...
          </p>

          <Row gutter={[16, 16]}>
            <div style={{ width: "234px", backgroundColor: "white" }} className="hidden md:block p-0">
              <SidebarFilterComponent
                onFilterChange={handleFilterChange}
                currentFilters={filterParams}
              />
            </div>

            <Col sm={24} md={16} lg={18} xl={19} xxl={19}>
              <Button
                className="md:hidden mb-4"
                type="primary"
                icon={<MenuOutlined />}
                onClick={() => setDrawerVisible(!drawerVisible)}
              />

              {searchLoading && searchInitiated ? (
                <Skeleton active paragraph={{ rows: 3 }} />
              ) : companiesToShow.length === 0 && searchInitiated ? (
                <p style={{ textAlign: "center" }}>
                  <b>No se encontraron empresas para el término de búsqueda.</b>
                </p>
              ) : (
                <InfiniteScroll
                  dataLength={companiesToShow.length}
                  next={fetchMoreData}
                  hasMore={hasMore && companiesToShow.length >= rex_limit} // Solo permite más carga si hay más de `rex_limit` resultados
                  loader={
                    hasMore && companiesToShow.length >= rex_limit ? ( // Solo muestra el Skeleton si `hasMore` es `true` y hay más resultados para cargar
                      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                        <Skeleton avatar paragraph={{ rows: 3 }} active />
                      </div>
                    ) : null
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Ya no hay más datos</b>
                    </p>
                  }
                  scrollThreshold={0.95}
                >
                  {companiesToShow.map((company: Company) => (
                    <React.Fragment key={company.id}>
                      <CardEmpresa
                        title={company.empresa}
                        description={company.descripcion}
                        location={company.sede_fiscal}
                        link={company.pagina_web}
                        avatarUrl={
                          company && company.logo
                            ? API_BASE_URL_EXACT + company.logo
                            : IMAGE_USER_DEFAULT
                        }
                        reviews={company._count.valoraciones_empresas}
                        activeOffers={company._count.ofertas}
                        followers={company._count.empresa_seguida}
                        workers={company.tamanio}
                        categories={["Categoría desde API"]}
                        onClick={() => navigate(`/companyDetail/${company.id}`)}
                        className="mb-4"
                        sector={company?.sectores?.sector || ''}
                        follow={company?.empresaSeguidaByToken}
                      />
                    </React.Fragment>
                  ))}
                </InfiniteScroll>

              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
};

export default Company;
