import { Button, Col, Modal, Row } from 'antd'
import React from 'react'
import IconClosed from "../../assets/icons/IconClosed.svg";
import { useSelector } from 'react-redux';
import './../../styles/components/modals/ModalConfirmarPuntos.css'

const ModalCrearOferta: React.FC<{
  showModal: boolean;
  setShowModal: any;
  onOk: ()=> any
}> = ({
  showModal,
  setShowModal,
  onOk
}) => {

    const onClose = () => {
      setShowModal(!showModal)
    }

  return (
    <Modal
        visible={showModal}
        closable={false}
        onCancel={onClose}
        footer={null}
        width={800}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16, 24]} className='text-center mt-7'>
          <Col span={24}>
            <p className="font-bold text-2xl font-sans">Confirmar Cancelacion</p>
          </Col>
          <Col span={24}>
            <p className="font-sans font-medium text-sm">  ¿Estás seguro de que deseas cancelar la creación de la oferta de empleo?</p>
          </Col>
        </Row>
        <Row className='flex justify-center mb-5'>
          <div className="flex justify-center mx-[114px] mt-[38px]">
            <Button
                 onClick={onClose}
              className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
            >
              Cancelar
            </Button>
            <Button
              onClick={onOk}
              className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
            >
              Aceptar
            </Button>
          </div>
        </Row>
      </Modal>
  )
}

export default ModalCrearOferta