import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_SAVED_SEARCHES_REQUEST,
  FETCH_SAVED_SEARCHES_SUCCESS,
  FETCH_SAVED_SEARCHES_FAILURE,
  SAVE_SAVED_SEARCHES_REQUEST,
  SAVE_SAVED_SEARCHES_SUCCESS,
  SAVE_SAVED_SEARCHES_FAILURE,
  SavedSearchesActionTypes,
  savedSearchesDto
} from '../../../../constants/common/savedSearches/SavedSearches';
import FetchWithIP from '../../utils/FetchHeaders';

export const fetchSavedSearchesRequestReducer = (): SavedSearchesActionTypes => ({
  type: FETCH_SAVED_SEARCHES_REQUEST,
});

export const fetchSavedSearchesSuccessReducer = (data: savedSearchesDto[]): SavedSearchesActionTypes => ({
  type: FETCH_SAVED_SEARCHES_SUCCESS,
  payload: { data },
});

export const fetchSavedSearchesFailureReducer = (error: string): SavedSearchesActionTypes => ({
  type: FETCH_SAVED_SEARCHES_FAILURE,
  payload: error,
});

export const SaveSavedSearchesRequestReducer = (): SavedSearchesActionTypes => ({
  type: SAVE_SAVED_SEARCHES_REQUEST,
});

export const SaveSavedSearchesSuccessReducer = (): SavedSearchesActionTypes => ({
  type: SAVE_SAVED_SEARCHES_SUCCESS,
});

export const SaveSavedSearchesFailureReducer = (error: string): SavedSearchesActionTypes => ({
  type: SAVE_SAVED_SEARCHES_FAILURE,
  payload: error,
});

export const GetSavedSearches = (): ThunkAction<Promise<any>, RootState, unknown, SavedSearchesActionTypes> => async (dispatch) => {

  dispatch(fetchSavedSearchesRequestReducer());

  try {

    const respuesta = await FetchWithIP('busquedas-guardadas', {
      method: 'GET'
    }).then(res => res.json())

    if (respuesta.data) {
      dispatch(fetchSavedSearchesSuccessReducer(respuesta.data));
    } else {
      throw new Error('Los datos obtenidos no son un array');
    }

  } catch (error) {
    dispatch(fetchSavedSearchesFailureReducer('Error al mostrar datos'));
    return { payload: error };
  }
};

export const SaveSavedSearchesReducer = (
  searchQuery:string,
  nameSavedSearch:string,
  selectedCountry: number | null, 
  selectedIdCity: number | null
): ThunkAction<Promise<any>, RootState, unknown, SavedSearchesActionTypes> => async (dispatch) => {
  dispatch(SaveSavedSearchesRequestReducer());

  try {
    const response = await FetchWithIP('busquedas-guardadas', {
      method: 'POST',
    }, {
      puesto: searchQuery,
      nombre: nameSavedSearch,
      pais_id: selectedCountry,
      ciudad_id:selectedIdCity
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(SaveSavedSearchesSuccessReducer());
    return responseData;
  } catch (error: any) {
    dispatch(SaveSavedSearchesFailureReducer(error.message || 'Error al validar el código'));
    return { error: error.message };
  }
};