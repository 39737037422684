// src/actions/pages/company/PaqueteUsuarios.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  ADD_PAQUETE_USUARIO_REQUEST,
  ADD_PAQUETE_USUARIO_SUCCESS,
  ADD_PAQUETE_USUARIO_FAILURE,
  PaqueteUsuarioActionTypes,
} from '../../../../../constants/pages/controlPanel/Packs/PostPackUser';
import FetchWithIP from '../../../utils/FetchHeaders';

export const addPaqueteUsuarioRequest = (): PaqueteUsuarioActionTypes => ({
  type: ADD_PAQUETE_USUARIO_REQUEST,
});

export const addPaqueteUsuarioSuccess = (data: any): PaqueteUsuarioActionTypes => ({
  type: ADD_PAQUETE_USUARIO_SUCCESS,
  payload: { data },
});

export const addPaqueteUsuarioFailure = (error: string): PaqueteUsuarioActionTypes => ({
  type: ADD_PAQUETE_USUARIO_FAILURE,
  payload: error,
});

export const addPaqueteUsuario = (
  paqueteData: { id: number } // Suponiendo que el paquete es un objeto con al menos un ID
): ThunkAction<Promise<any>, RootState, unknown, PaqueteUsuarioActionTypes> => async (dispatch) => {
  dispatch(addPaqueteUsuarioRequest());

  try {
    const response = await FetchWithIP('paquetes-usuarios', {
      method: 'POST'},
      (paqueteData),
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    dispatch(addPaqueteUsuarioSuccess(responseData.data));
    return responseData; // Retornamos la respuesta para manejarla en el frontend
  } catch (error: any) {
    dispatch(addPaqueteUsuarioFailure(error.message || 'Error al agregar el paquete de usuario'));
    return { error: error.message };
  }
};
