import {
    DELETE_EXPERIENCE_REQUEST,
    DELETE_EXPERIENCE_SUCCESS,
    DELETE_EXPERIENCE_FAILURE,
    DeleteExperienceActionTypes,
  } from '../../../../../constants/pages/myPortal/workExperience/DeleteWorkExperience';
  
  // Estado inicial
  interface DeleteExperienceState {
    rex_deleting: boolean;
    rex_deleted_id: number | null;
    rex_delete_error: string | null;
  }
  
  const initialState: DeleteExperienceState = {
    rex_deleting: false,
    rex_deleted_id: null,
    rex_delete_error: null,
  };
  
  // Reducer
  export const deleteExperienceReducer = (
    state = initialState,
    action: DeleteExperienceActionTypes
  ): DeleteExperienceState => {
    switch (action.type) {
      case DELETE_EXPERIENCE_REQUEST:
        return {
          ...state,
          rex_deleting: true,
          rex_deleted_id: null,
          rex_delete_error: null,
        };
      case DELETE_EXPERIENCE_SUCCESS:
        return {
          ...state,
          rex_deleting: false,
          rex_deleted_id: action.payload.id,
          rex_delete_error: null,
        };
      case DELETE_EXPERIENCE_FAILURE:
        return {
          ...state,
          rex_deleting: false,
          rex_deleted_id: null,
          rex_delete_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default deleteExperienceReducer;
  