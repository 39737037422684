import {
    DELETE_EDUCATION_REQUEST,
    DELETE_EDUCATION_SUCCESS,
    DELETE_EDUCATION_FAILURE,
    DeleteEducationActionTypes,
  } from '../../../../../constants/pages/myPortal/education/DeleteEducation';
  
  interface EducationState {
    rex_loading: boolean;
    rex_education: {
      id: number;
      nombre_centro_educativo: string;
      carrera: string;
      ubicacion: string;
      fecha_inicio: string;
      fecha_final: string;
    }[];
    rex_error: string | null;
  }
  
  const initialState: EducationState = {
    rex_loading: false,
    rex_education: [],
    rex_error: null,
  };
  
  export const educationReducer = (
    state = initialState,
    action: DeleteEducationActionTypes
  ): EducationState => {
    switch (action.type) {
      case DELETE_EDUCATION_REQUEST:
        return {
          ...state,
          rex_loading: true,
        };
      case DELETE_EDUCATION_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          rex_education: state.rex_education.filter((item) => item.id !== action.payload.id),
          rex_error: null,
        };
      case DELETE_EDUCATION_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default educationReducer;
  