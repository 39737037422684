import React, { useEffect, useState } from "react";
import { Button, Row, Col, App } from "antd";
import InputL from "../../components/pages/login/InputL";
import "../../components/styles/pages/login/Login.css";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/store';
import LoginP from "../../assets/img/login/login2.svg";
import IconArrowL from "../../assets/icons/IconArrowL.svg";
import { CopyOutlined } from '@ant-design/icons';
import { validateCode } from '../../redux/actions/auth/Validation';
import { resendCode } from '../../redux/actions/auth/ResendCode';
import ModalConfirmationCode from '../../components/pages/modals/ModalConfirmCode';


interface FormValues {
  codigo: string[];
  email: string;       // Agregar esta línea
  contrasena: string;  // Agregar esta línea
}

const validationSchema = Yup.object({
  codigo: Yup.array()
    .of(Yup.string().required())
    .length(6, "El código debe tener 6 dígitos")
});


const Verification: React.FC = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation(); // Añadir esta línea para obtener la ubicación

  // Obtener el estado de validación del código del store
  const isValidating = useSelector((state: RootState) => state.validation?.isValidating);
  const validationError = useSelector((state: RootState) => state.validation?.validationError);
  const validationSuccess = useSelector((state: RootState) => state.validation?.validationSuccess);
  const { email, contrasena, tipo } = location.state || {}; // Usar location para obtener el estado
  const [countdown, setCountdown] = useState(30); // Estado para la cuenta regresiva
  const [ emailResend, setEmailResend ] = useState("")
  const [isResendEnabled, setIsResendEnabled] = useState(false); // Estado para habilitar el reenvío

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Efectos para manejar el resultado de la validación
  useEffect(() => {
    if (validationSuccess) {
      // notification.success({
      //   message: 'Éxito',
      //   description: 'Código verificado correctamente',
      // });
      // navigate('/dashboard');
    }
  }, [validationSuccess, navigate]);

  useEffect(() => {
    if (location.state?.email) {
      setEmailResend(location.state.email)
    }
  }, [location.state]);

  useEffect(() => {
    if (validationError) {
      notification.error({
        message: 'Error',
        description: validationError || 'Error al validar el código',
      });
    }
  }, [validationError]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(timer); // Limpiar el temporizador
          setIsResendEnabled(true); // Habilitar el botón de reenviar cuando el tiempo llegue a 0
          return 0; // Restablecer a 0
        }
        return prev - 1; // Decrementar el temporizador
      });
    }, 1000);

    return () => {
      clearInterval(timer); // Limpiar el temporizador al desmontar
    };
  }, [countdown]);


  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      const codigoCompleto = parseInt(values.codigo.join(''), 10);
      console.log('Código a enviar:', codigoCompleto);

      // Dispatch de la acción validateCode
      const rpta: any = await dispatch(validateCode(codigoCompleto));

      if (rpta.respuesta) {
        notification.success({
          message: 'Éxito',
          description: 'Código verificado correctamente',
        });
        // Reiniciar la cuenta regresiva al verificar el código
        setCountdown(30);
        setIsResendEnabled(false);

        if (tipo && tipo == "empresa") {
          navigate('/controlPanel/latestSearches');
        } else {
          navigate('/employment/recommendations');
        }
      }
    } catch (error: any) {
      console.error('Error en handleSubmit:', error);
      notification.error({
        message: 'Error',
        description: error.message || 'Error al validar el código',
      });
      return false;
    } finally {
      setSubmitting(false);
    }
  };

  const handleCopy = (values: FormValues) => {
    const codigoCompleto = values.codigo.join('');
    navigator.clipboard.writeText(codigoCompleto);
    notification.success({
      message: 'Código copiado',
      description: 'El código ha sido copiado al portapapeles',
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, setFieldValue: Function, values: FormValues) => {
    const { value } = e.target;
    console.log('Input change - index:', index, 'value:', value);

    if (/^\d$/.test(value)) {
      setFieldValue(`codigo[${index}]`, value);
      const nextInput = index + 1;
      if (nextInput < 6) {
        const nextElement = document.getElementsByName(`codigo[${nextInput}]`)[0] as HTMLInputElement;
        if (nextElement) nextElement.focus();
      } else if (nextInput === 6) {
        const allFilled = values.codigo.every((digit, i) => i === 5 ? value !== '' : digit !== '');
        if (allFilled) {
          console.log('Todos los campos están llenos!');
        }
      }
    } else if (value === '') {
      setFieldValue(`codigo[${index}]`, '');
    }
  };

  const handleResendCode = () => {
    console.log("Email a reenviar:", email); // Añade esto para verificar el email
    setIsModalVisible(true); // Abre el modal de confirmación
  };

  const onConfirmResend = async () => {
    try {
      await dispatch(resendCode(emailResend)); // Llama a la acción para reenviar el código
      notification.success({
        message: 'Éxito',
        description: 'El código de verificación ha sido reenviado a tu correo.',
      });
      setIsModalVisible(false); // Cierra el modal

      // Reinicia la cuenta regresiva y deshabilita el botón de reenviar
      setCountdown(30);
      setIsResendEnabled(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message || 'Error al reenviar el código',
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error al reenviar el código',
        });
      }
      setIsModalVisible(false); // Cierra el modal incluso si hay un error
    }
  };


  return (
    <div style={{ height: "100vh" }}>
      <Row className="lo" style={{ height: "100%" }}>
        <Col
          xl={13}
          className=""
          style={{
            backgroundColor: "#f0f2f5",
            backgroundImage: "url(" + LoginP + ")",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <Col
          xl={11}
          className="p-[50px]"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <Row className="mt-4">
            <Col xl={12}>
              <h1 className="font-semibold text-green32 text-heading-x1">
                <Link
                  className="flex items-center font-bold text-heading-x1"
                  style={{ color: "#006497" }}
                  to="/"
                >
                  <img src={IconArrowL} alt="Arrow Left" className="mr-[15px]" />
                  <span className="font-semibold text-green32 text-heading-x1">
                    Volver
                  </span>
                </Link>
              </h1>
            </Col>
            <Col xs={24} md={12}>
              <h1 className="font-semibold sm:text-start text-gray-500 md:text-end text-heading-x1">
                ¿Ya tienes cuenta?{" "}
                <Link
                  className="font-bold text-heading-x1"
                  style={{ color: "#006497" }}
                  to="/login"
                >
                  Ingresar
                </Link>
              </h1>
            </Col>
          </Row>

          <div style={{ height: '100%', placeSelf: 'center' }}>
            <Formik
              initialValues={{
                codigo: Array(6).fill(''),
                email: email || '',
                contrasena: contrasena || ''
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form className="login-form max-w-[454px] p-4 mt-20">
                  <h1 className="text-heading-md font-bold mb-7 text-center">
                    Verifica tu correo
                  </h1>
                  <h1 className="font-medium text-body-md mb-2 text-center">
                    Se ha enviado un código de verificación de 6 dígitos a tu correo electrónico ({values.email}), copia y pégalo en la siguiente sección:
                  </h1>

                  <div className="flex justify-center mb-4">
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Field
                        key={index}
                        name={`codigo[${index}]`}
                        as={InputL}
                        placeholder=""
                        maxLength={1}
                        className="text-center rounded-[4px]"
                        style={{ marginRight: "13px", height: "60px", textAlign: "center", width: "50px" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputChange(e, index, setFieldValue, values)
                        }
                      />
                    ))}
                    <Button
                      onClick={() => handleCopy(values)}
                      className="bg-blue3 text-white principal-nav-notify-button2 w-[58px] h-[60px] rounded-[4px]"
                    >
                      <CopyOutlined style={{ fontSize: '24px' }} />
                    </Button>
                  </div>

                  <div className="flex justify-center mt-[20px]">
                    <Button
                      htmlType="submit"
                      disabled={isSubmitting || isValidating}
                      className={`bg-blue3 principal-nav-notify-button2 text-white w-[278px] h-[44px] rounded-[4px] ${(isSubmitting || isValidating) ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      onClick={() => {
                        console.log('Valores actuales:', values);
                      }}
                    >
                      {isValidating ? 'Verificando...' : 'Registrarse'}
                    </Button>
                  </div>

                  <h1 className="font-normal text-gray-500 text-[14px] text-center mt-[20px]">
                    ¿No te llegó el código?{" "}
                    {countdown > 0 ? (
                      <span className="font-semibold">{`Por favor espera ${countdown} s`}</span>
                    ) : (
                      <Link
                        className="font-bold text-[14px] "
                        style={{ color: "#006497" }}
                        to=""
                        onClick={handleResendCode}
                      >
                        Reenviar
                      </Link>
                    )}
                  </h1>

                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
      <ModalConfirmationCode
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        email={emailResend}
        onConfirmResend={onConfirmResend}
      />

    </div>
  );
};

export default Verification;