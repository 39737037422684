import React, { useState } from "react";
import { Row, Col, Card, message } from "antd";
import IconEdit from "../../../assets/icons/EditP.svg";
import "tailwindcss/tailwind.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store/store";
import GeneralSetting from "../../../components/pages/myPortal/GeneralSettings/EditGeneralSettings";
import ValidateSttting from "../../../components/pages/myPortal/GeneralSettings/ValidationSettings"; 
import { updateEmailPassword } from "../../../redux/actions/pages/myPortal/generalSettings/updateEmailPasswordActions";

const GeneralSettings: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isValidating, setIsValidating] = useState(false); 
  const dispatch: AppDispatch = useDispatch();
  
  const { rex_user } = useSelector((state: RootState) => state.auth);
  const email = rex_user?.email || "Correo no disponible";

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditSubmit = async (values: { email: string; contrasenaActual: string; contrasenaNueva: string }) => {
    try {
      const response = await dispatch(updateEmailPassword(values.email, values.contrasenaActual, values.contrasenaNueva));
      
      if (response.respuesta) {
        if (values.email !== email) {
          message.success('Se ha enviado un código de verificación a tu nuevo correo.');
          setIsEditing(false);
          setIsValidating(true); // Cambia a modo de validación
        } else {
          message.success('Actualización exitosa');
          setIsEditing(false); 
        }
      } else {
        message.error('Error al actualizar los datos');
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message || 'Error al actualizar los datos');
      }
    }
  };

  const handleValidationSuccess = () => {
    setIsValidating(false); // Regresa a no validando
    setIsEditing(false); // Asegúrate de que no esté en modo edición
  };

  return (
    <div className="flex-1 pr-1">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={24}>
          <Card className="rounded-lg border border-sky-blue0 bg-[#FBFDFE]  ">
            <div>
              {!isEditing && !isValidating ? (
                <div className="pb-[24px]">
                  <h1 className="text-[22px] font-sans font-bold pb-[24px]">Ajustes generales</h1>
                  <div className="pb-1">
                    <h1 className="text-heading-x1 font-bold py-[10px]">
                      Correo y contraseña
                      <img
                        src={IconEdit}
                        alt="Editar"
                        onClick={handleEditClick}
                        className="inline-block text-sky-blue0 pl-3 cursor-pointer"
                      />
                    </h1>
                    <h1 className="text-body-md font-sans font-medium text-green22">
                      <span className="font-bold">Correo Electrónico:</span> {email}
                    </h1>
                    <h1 className="text-body-md font-sans font-medium text-green22">
                      <span className="font-bold">Contraseña:</span> ********
                    </h1>
                  </div>
                </div>
              ) : isValidating ? (
                <ValidateSttting
                  codigo={Array(6).fill('')} // Agrega la propiedad codigo aquí
                  email={email} // Pasa el nuevo correo para la verificación
                  onClose={handleValidationSuccess} // Cambia el estado de validación al completar
                />
              ) : (
                <GeneralSetting
                  email={email}
                  onEdit={handleEditSubmit} 
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralSettings;
