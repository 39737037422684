import React, { useEffect, useRef, useState } from "react";
import { Layout, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "tailwindcss/tailwind.css";
import RatingBlue from "../../../rating/RatingBlue";
import StyledCheckbox from "../../../checkbox/CheckboxProps";
import ToggleSwitch from "../../../toggleSwitch/ToggleSwitch";
import Select from "../../../../components/pages/searchCV/Select";
import Slider from "../../searchCV/Slider";
import IconDrop from "../../../../assets/icons/ArrowDrop.svg";
import IconArrow from "../../../../assets/icons/arrowIconBlue.svg";
import IconDrop2 from "../../../../assets/icons/ArrowDrop2.svg";
import IconLine from '../../../../assets/icons/ArrowA.svg';
import IconLine2 from '../../../../assets/icons/ArrowLine.svg';
import Keywords from "../../employment/searchResult/ComponentsSidebar/KeyWord";
import CompanyC from '../../searchCV/ComponentsSidebar/CompanyC';
import Sector from "../../employment/searchResult/ComponentsSidebar/Sectors";
import Education from "../../searchCV/ComponentsSidebar/Education";
import { AppDispatch } from "../../../../redux/store/store";
import { GetPaisesReducer } from "../../../../redux/actions/pages/emplotment/searchCountry";
import { GetCiudadesReducer } from "../../../../redux/actions/pages/emplotment/searchCities";
import { GetAptitudesReducer } from "../../../../redux/actions/common/skills/Skills";

const { Sider } = Layout;
const { Option } = Select;

interface SidebarOffersProps {
  onFilterChange?: (filters: Partial<FilterParams>) => void;
  inDrawer?: boolean;
  activeSearch?: boolean;
  setActiveSearch?: any;
  resetFilters?: any
}

interface FilterParams {
  educations?: string[];
  ratings?: number[];
  keywords?: string[];
  sectors?: string[];
  experiences?: number[];
  tamanioArray?: { desde: number; hasta: number }[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  experiencia_nula?: number[];
  modalidad_remoto?: string[];
  activeSearch?: boolean;
  jornada?: string[];
  excluirEmpresa?: string[];
  excluirPalabrasClave?: string[]
}

const ratings = [0, 1, 2, 3, 4, 5];

const availability = [
  { value: "todas", label: "Todas las modalidades de jornada" },
  { value: "completa", label: "Jornada completa" },
  { value: "indiferente", label: "Jornada indiferente" },
  { value: "parcial_maniana", label: "Jornada parcial - Turno mañana" },
  { value: "parcial_tarde", label: "Jornada parcial - Turno tarde" },
  { value: "parcial_noche", label: "Jornada parcial - Turno noche" },
  { value: "parcial_indiferente", label: "Jornada parcial - Horario indiferente" },
  { value: "intensiva_maniana", label: "Jornada intensiva - Turno mañana" },
  { value: "intensiva_tarde", label: "Jornada intensiva - Turno tarde" },
  { value: "intensiva_noche", label: "Jornada intensiva - Turno noche" },
  { value: "intensiva_indiferente", label: "Jornada intensiva - Horario indiferente" }
];

const modalidad = ["Remoto"]

const Sidebar: React.FC<SidebarOffersProps> = ({
  onFilterChange = () => { },
  inDrawer = false,
  activeSearch,
  setActiveSearch,
  resetFilters = () => { }
}) => {
  const [openSections, setOpenSections] = useState<{
    valoraciones: boolean,
    palabrasClave: boolean,
    cualificativos: boolean,
    ubicacion: boolean,
    busquedasSugeridas: boolean,
    educacion: boolean,
    disponibilidad: boolean,
    experiencia: boolean,
  }>({
    valoraciones: false,
    palabrasClave: true,
    cualificativos: false,
    ubicacion: false,
    busquedasSugeridas: false,
    educacion: false,
    disponibilidad: false,
    experiencia: false,
  });

  const dispatch = useDispatch<AppDispatch>();

  const [selectedRatings, setSelectedRatings] = useState<number[]>([]);
  const [selectedSoftSkills, setSelectedSoftSkills] = useState<string[]>([]);
  const [searchTermSoftSkills, setSearchTermSoftSkills] = useState<string>('');
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedCitys, setSelectedCitys] = useState<string[]>([]);
  const [searchTermCitys, setSearchTermCitys] = useState<string>('');
  const [searchTermCountries, setSearchTermCountries] = useState<string>('');
  const [moreThan10Ratings, setMoreThan10Ratings] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState<string[]>([])
  const [isCheckedModalidad, setIsCheckedModalidad] = useState(false);
  const [filteredCities, setFilteredCities] = useState<[] | null>([]);
  const [excludedCountries, setExcludedCountries] = useState<string[]>([])
  const [selectedCurrentCountrie, setSelectedCurrentCountrie] = useState<string | null>(null)
  const [resetKeywords, setResetKeywords] = useState(false)
  const [resetSectors, setResetSectors] = useState(false)
  const [resetEducation, setResetEducation] = useState(false)
  const [resetExperience, setResetExperience] = useState(false)
  const [selectedSoftSkillsFilter, setSelectedSoftSkillsFilter] = useState<any[]>([])

  const {
    rex_aptitudes
  } = useSelector(({ softskill }: any) => softskill);

  const {
    rex_paises
  } = useSelector(({ paises }: any) => paises)

  const companyCRef = useRef<any>(null)

  const resetFiltersSelected = () => {
    setSelectedRatings([])
    setSelectedSoftSkills([])
    setSelectedCountries([])
    setSelectedCitys([])
    setSearchTermCitys('')
    setSearchTermCountries('')
    setMoreThan10Ratings(false)
    setSelectedAvailability([])
    setIsCheckedModalidad(false)
    setFilteredCities([])
    setSelectedSoftSkillsFilter([])
    setExcludedCountries([])
    setSelectedCurrentCountrie(null)
    handleSectorsChange([])
    resetCompaniesFilter()
    setResetKeywords(true)
    setResetSectors(true)
    setResetEducation(true)
    setResetExperience(true)
    setActiveSearch(true)
    setTimeout(() => setResetKeywords(false), 100)
    setTimeout(() => setResetSectors(false), 100);
    setTimeout(() => setResetExperience(false), 100);
    setTimeout(() => setResetEducation(false), 100);
  }

  useEffect(() => {
    dispatch(GetPaisesReducer());
    dispatch(GetCiudadesReducer());
    dispatch(GetAptitudesReducer());
  }, []);


  const resetCompaniesFilter = () => {
    if (companyCRef.current) {
      companyCRef.current.resetCompanies()
    }
  }

  const handleRatingSelect = (value: number) => {
    const newRatings = selectedRatings.includes(value)
      ? selectedRatings.filter(r => r !== value)
      : [...selectedRatings, value];

    setSelectedRatings(newRatings);
    onFilterChange?.({ ratings: newRatings });
  };

  const toggleSection = (section: any) => {
    setOpenSections((prevState: any) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const handleKeywordsChange = (newKeywords: string[]) => {
    onFilterChange?.({ experienciaLaboral: newKeywords });
  };

  const handleExcludedKeywords = (newExcludedKeywords: string[]) => {
    onFilterChange?.({ excluirExperienciaLaboral: newExcludedKeywords });
  };
  
  const handleCompaniesChange = (newCompanies: string[]) => {
    onFilterChange?.({ companies: newCompanies });
  };

  const handleSectorsChange = (selectedSectors: string[]) => {
    onFilterChange?.({ sectors: selectedSectors });
  };

  const handleSelectSoftSkillsFilter = (option: any) => {
    const existSoftSkill = selectedSoftSkillsFilter.find((sfs: any) => sfs.key == option.key)
    if (!existSoftSkill) {
      let currentSoftSkills = [...selectedSoftSkillsFilter]
      currentSoftSkills.push({ soft_skill: option.value, nivel: 0, key: option.key })
      setSelectedSoftSkillsFilter(currentSoftSkills)

      onFilterChange?.({ softSkills: currentSoftSkills })
    }
  }

  const handleDownSoftSkills = (id: string) => {

    const existSoftSkill = selectedSoftSkillsFilter.find((sfs: any) => sfs.key == id)
    if (existSoftSkill) {
      const updatedSoftSkills = selectedSoftSkillsFilter.map((sfs: any) => {
        if (sfs.key === id) {
          return {
            ...sfs,
            nivel: (sfs.nivel || 0) - 1
          };
        }
        return sfs;
      });
      setSelectedSoftSkillsFilter(updatedSoftSkills)
      onFilterChange?.({ softSkills: updatedSoftSkills })

    }
  }

  const handleUpSoftSkills = (id:string) => {
    const existSoftSkill = selectedSoftSkillsFilter.find((sfs: any) => sfs.key == id)
    if (existSoftSkill) {
      const updatedSoftSkills = selectedSoftSkillsFilter.map((sfs: any) => {
        if (sfs.key === id) {
          return {
            ...sfs,
            nivel: (sfs.nivel || 0) + 1
          };
        }
        return sfs;
      });
      setSelectedSoftSkillsFilter(updatedSoftSkills)
      onFilterChange?.({ softSkills: updatedSoftSkills })
    }
  }

  const handleRemoveSoftSkillsFilter = (id:string) => {
    let currentSoftSkills = [...selectedSoftSkillsFilter]
    currentSoftSkills = currentSoftSkills.filter(css => css.key != id)
    setSelectedSoftSkillsFilter(currentSoftSkills)
    onFilterChange?.({ softSkills: currentSoftSkills })
  }

  const handleSelect = (value: string) => {

    setSelectedCurrentCountrie(value)

    let updatedExcludedCountries = [...excludedCountries]

    const findCity = filteredCountries.find((cou: any) => cou.pais == value)
    if (findCity) {
      const cities = findCity.ciudades.map((cit: any, index: number) => {
        return {
          id: index,
          ciudad: cit.ciudad
        }
      })
      setFilteredCities(cities)
    }
    if (!selectedCountries.includes(value)) {
      const newSelectedCountries = [...selectedCountries, value];
      setSelectedCountries(newSelectedCountries);
      onFilterChange?.({ paises: newSelectedCountries.filter((cou: string) => !updatedExcludedCountries.includes(cou)) })
    }
  };

  const handleAvailability = (value: string, checked: boolean) => {
    let updatedAvailabilityArray = [...selectedAvailability];
    if (checked) {
      updatedAvailabilityArray.push(value)
    } else {
      updatedAvailabilityArray = updatedAvailabilityArray.filter((ava) => ava !== value);
    }
    setSelectedAvailability(updatedAvailabilityArray)
    onFilterChange?.({ jornada: updatedAvailabilityArray })
  }

  const handleExcludedCompaniesChange = (newExcludedCompanies: string[]) => {
    onFilterChange?.({ excluirEmpresa: newExcludedCompanies });
  };

  const handleRemove = (country: string) => {

    const currentExcludedCountries = [...excludedCountries]
    let newSelectedCountries = selectedCountries.filter(item => item !== country)
    newSelectedCountries = selectedCountries.filter((cou: string) => !currentExcludedCountries.includes(cou))
    setSelectedCountries(newSelectedCountries)
    onFilterChange?.({ paises: newSelectedCountries })
  };

  const handleSelectCity = (value: string) => {

    const findCountry = filteredCountries.find((cou: any) => cou.pais == selectedCurrentCountrie)

    let updatedExcludedCountries = [...excludedCountries]
    if (selectedCurrentCountrie) {
      if (!updatedExcludedCountries.includes(selectedCurrentCountrie)) {
        updatedExcludedCountries.push(findCountry.pais)
      }
      setExcludedCountries(updatedExcludedCountries)
    }

    if (!selectedCitys.includes(value)) {
      const newSelectedCitys = [...selectedCitys, value]
      setSelectedCitys(newSelectedCitys)
      onFilterChange?.({ ciudades: newSelectedCitys, paises: selectedCountries.filter((cou: string) => !updatedExcludedCountries.includes(cou)) });
    }
  };

  const handleSearchCity = (value: string) => {
    setSearchTermCitys(value);
  };

  const handleRemoveCity = (city: string) => {

    const newSelectedCitys = selectedCitys.filter(item => item !== city);
    setSelectedCitys(newSelectedCitys);
    let updatedExcludedCountries = [...excludedCountries]
    selectedCountries.map((cou) => {
      const country = filteredCountries.find((fco: any) => fco.pais == cou)
      const hasCities = country.ciudades.some((cit: any) => newSelectedCitys.includes(cit.ciudad))
      if (!hasCities && excludedCountries.includes(country.pais)) {
        updatedExcludedCountries = updatedExcludedCountries.filter((upd: string) => upd != country.pais)
        setExcludedCountries(updatedExcludedCountries)
      }
    })

    onFilterChange?.({ ciudades: newSelectedCitys, paises: selectedCountries.filter((cou: string) => !updatedExcludedCountries.includes(cou)) })

  };

  const handleValueChange = (values: number[]) => {
    onFilterChange?.({ experiences: values });
  };

  const handleEducationsChange = (newEducations: string[]) => {
    onFilterChange?.({ educations: newEducations });
  };

  const handleMoreThan10RatingsChange = (checked: boolean) => {
    setMoreThan10Ratings(checked);
    onFilterChange?.({ valoraciones_usuariosmax: checked });
  };

  const handleCheckboxChangeModalidad = (checked: boolean, value: any) => {
    console.log('Checkbox changed:', { checked, value });
    setIsCheckedModalidad(checked);

    if (checked) {
      onFilterChange?.({ modalidad_remoto: value });
    } else {
      onFilterChange?.({ modalidad_remoto: undefined });
    }
  };

  const changeActiveSearch = (checked: boolean) => {
    setActiveSearch(checked)
    onFilterChange?.({ activeSearch: checked });
  };

  const filteredSoftSkills = rex_aptitudes.filter((softSkill: any) =>
    softSkill.soft_skill.toLowerCase().includes(searchTermSoftSkills.toLowerCase())
  );

  const filteredCountries = rex_paises.filter((country: any) =>
    country.pais.toLowerCase().includes(searchTermCountries.toLowerCase())
  );

  return (
    <div className="flex flex-col p-4 bg-white shadow-md rounded-lg">
      <Sider
        width={234}
        style={{ backgroundColor: "white" }}
        className={` ${inDrawer ? 'p-[5px] border-none ml-[-15px] mt-[-15px]' : "rounded-lg shadow-md p-5 border border-sky-blue0 shadow-gray-400"}`}
      >
        <h2 className="text-heading-md font-bold text-green42 mb-1">Filtros</h2>
        <h2 className="text-body-md font-bold text-green42">
          Refina tu búsqueda
        </h2>
        <h2
          className="text-caption font-bold text-blue3 mb-6 cursor-pointer"
          onClick={() => {
            resetFilters()
            resetFiltersSelected()
          }}
        >
          Eliminar todos los filtros
        </h2>

        {/* Búsqueda de trabajo activa */}
        <div className="flex justify-between items-center mb-2">
          <div className=" w-[138px]">
            <h2 className="mt-2 mb-3 text-caption font-semibold text-gray-800">
              Búsqueda de trabajo activa
            </h2>
          </div>
          <div className="mt-[-5px]">
            <ToggleSwitch
              checked={activeSearch}
              defaultChecked={false}
              onChange={(value: boolean) => {
                changeActiveSearch(value)
              }}
              size="small"
              customClass="switch-small-1"
            />
          </div>
        </div>

        {/* Valoraciones */}
        <div className="mb-[26px]">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("valoraciones")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Valoraciones
            </h3>
            {openSections.valoraciones ? (
              <img src={IconDrop2} />
            ) : (
              <img src={IconDrop} />
            )}
          </div>
          {openSections.valoraciones && (
            <div className="flex flex-col mb-[26px]">
              {/* Primera opción con estrella vacía y 0 Estrellas */}
              <StyledCheckbox
                checked={selectedRatings.includes(0)}
                onChange={() => handleRatingSelect(0)}
              >
                <div className="flex items-center">
                  <RatingBlue
                    filledStars={0}
                    totalStars={1}
                    showRatingValue={true}
                    emptyStarSize={{ width: '20px', height: '20px', marginLeft: '-3px' }}
                  />
                  <span className="ml-2 text-sm text-black">Estrellas</span>
                </div>
              </StyledCheckbox>

              {/* Otras opciones con estrellas llenas */}
              {ratings.slice(1).map((value, index) => (
                <StyledCheckbox
                  key={index + 1}
                  value={value}
                  onChange={() => handleRatingSelect(value)}
                  checked={selectedRatings.includes(value)}
                >
                  <div className="flex items-center">
                    <RatingBlue
                      filledStars={value}
                      totalStars={value}
                      showRatingValue={false}
                      filledStarSize={{ width: '15px', height: '15px', marginTop: '2px', marginRight: '4px' }}
                    />
                    {/* El contador ha sido eliminado aquí */}
                  </div>
                </StyledCheckbox>
              ))}

              <Divider className="bg-black my-[8px]"></Divider>

              <StyledCheckbox
                className="mt-0"
                value={moreThan10Ratings}
                onChange={(e) => handleMoreThan10RatingsChange(e.target.checked)}
                checked={moreThan10Ratings}
              >
                <h3 className="font-medium text-[14px] text-green42">
                  Solo candidatos con más de 10 valoraciones
                </h3>
              </StyledCheckbox>
            </div>
          )}
        </div>

        {/* Palabras clave */}
        <div className="mb-[26px]">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">
            Palabras clave
          </h3>
          <Keywords
            onKeywordsChange={handleKeywordsChange}
            onExcludedKeywordsChange={handleExcludedKeywords}
            allowExclusion={true}  // Activa la funcionalidad de exclusión
            reset={resetKeywords}
          />

        </div>

        {/* Cualificativos */}
        <div className="mb-[26px]">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("cualificativos")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              {/* Cualificativos */}
              Cualificativos
            </h3>
            {openSections.cualificativos ? (
              <img src={IconLine} />
            ) : (
              <img src={IconLine2} />
            )}
          </div>
          {openSections.cualificativos && (
            <div>
              {/* Select for adding soft skills */}
              <Select
                placeholder="Selecciona soft skills"
                className="w-full"
                onChange={(value, option) => {
                  handleSelectSoftSkillsFilter(option)
                }}
                value={null} // Reset select value after selection
                showSearch
                filterOption={false}
                dropdownRender={(menu) => (
                  <>
                    <div style={{ maxHeight: 300, overflowY: 'auto' }}> {/* Agrega scroll al desplegable */}
                      {menu}
                    </div>
                  </>
                )}
              >
                {filteredSoftSkills.map((skill: any) => (
                  <Option key={skill.id} value={skill.soft_skill}>
                    {skill.soft_skill}
                  </Option>
                ))}
              </Select>

              {/* Display selected skills with remove option */}
              <div className="flex flex-col mt-[8px]">
                {" "}
                {/* Use flex-col for stacking skills */}
                {selectedSoftSkillsFilter.map((skill:any, index:number) => (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center mb-2 gap-[6px]"
                  >
                    {" "}
                    {/* Flex row for skill and remove button */}
                    <div className="flex justify-between w-1/2">
                      <span className="font-medium text-body-sm text-green22 truncate font-sans">
                        {skill.soft_skill}
                      </span>
                      <span
                        className="ml-2 cursor-pointer transition-colors font-medium text-body-sm text-green22"
                        onClick={() => handleRemoveSoftSkillsFilter(skill.key)} // Remove skill on click
                      >
                        X
                      </span>
                    </div>
                    <div className="flex border justify-between items-center border-blue3 rounded-[4px] h-[36px] w-1/2 px-3">
                      <img onClick={() => {
                        if(skill.nivel > 0){
                          handleDownSoftSkills(skill.key)
                        }
                      }} className={`${skill.nivel == 0 ? 'cursor-not-allowed' : 'cursor-pointer'} h-[15px]`} src={IconArrow} />
                      <span className="font-sans text-base text-[#1A1A1AB2]">{skill.nivel}</span>
                      <img onClick={() => {
                        if(skill.nivel < 10){
                          handleUpSoftSkills(skill.key)
                        }
                      }} className={`${skill.nivel == 10? 'cursor-not-allowed': 'cursor-pointer'} rotate-180 h-[15px]`} src={IconArrow} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Ubicación */}
        <div className="mb-[26px]">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("ubicacion")}
          >
            <h3 className="font-bold text-heading-sm mb-[12px] text-green42">
              Ubicación
            </h3>
            {openSections.ubicacion ? (
              <img src={IconLine} />
            ) : (
              <img src={IconLine2} />
            )}
          </div>
          {openSections.ubicacion && (
            <div>
              {/* Provincias */}
              <div className="">
                <h4 className="font-medium text-body-md mb-[12px] text-gray">Pais</h4>
                <Select
                  placeholder="Selecciona un país"
                  onSelect={handleSelect}
                  value={selectedCountries}
                  onChange={(value) => handleSelect(value as string)}
                  showSearch
                  filterOption={false}
                  dropdownRender={(menu) => (
                    <>
                      <div style={{ maxHeight: 300, overflowY: 'auto' }}> {/* Agrega scroll al desplegable */}
                        {menu}
                      </div>
                    </>
                  )}
                >
                  {filteredCountries.map((country: any) => (
                    <Option key={country.id} value={country.pais}>
                      {country.pais}
                    </Option>
                  ))}
                </Select>
                <div className="flex flex-col my-[12px]">
                  {" "}
                  {selectedCountries.map((province, index) => (
                    <div key={index} className="flex items-center mb-2">
                      {" "}
                      <span className="font-medium text-caption text-green22">
                        {province}
                      </span>
                      <span
                        className="ml-1 cursor-pointer transition-colors font-medium text-caption text-[#00476D]" // ml-1 mantiene un pequeño espacio entre la letra y la X
                        onClick={() => handleRemove(province)}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Poblaciones */}
              <div className="mb-[8px]">
                <h4 className="font-medium text-body-md mb-[12px] text-gray">Ciudad</h4>
                <Select
                  placeholder="Seleccionar ciudad"
                  className="w-full"
                  onSelect={handleSelectCity}
                  value={selectedCitys}
                  onChange={(value) => handleSelectCity(value as string)}
                  showSearch
                  onSearch={handleSearchCity}
                  filterOption={false}
                  dropdownRender={(menu) => (
                    <>
                      <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                        {menu}
                      </div>
                    </>
                  )}
                >
                  {filteredCities?.map((city: any) => (
                    <Option key={city.id} value={city.ciudad}>
                      {city.ciudad}
                    </Option>
                  ))}
                </Select>
                <div className="flex flex-col mt-[12px]">
                  {selectedCitys.map((city, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <span className="font-medium text-caption text-green22">
                        {city}
                      </span>
                      <span
                        className="ml-1 cursor-pointer transition-colors font-medium text-caption text-[#00476D]"
                        onClick={() => handleRemoveCity(city)}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <StyledCheckbox
                  value={modalidad}
                  checked={isCheckedModalidad}
                  onChange={(e) => handleCheckboxChangeModalidad(e.target.checked, modalidad)}
                ><h1
                  className='text-gray font-medium text-body-sm'
                >
                    Solo remoto
                  </h1>
                </StyledCheckbox>
              </div>
            </div>
          )}
        </div>

        {/* Empresas */}
        <div className="mb-[26px]">
          <h3 className="font-bold text-heading-sm mb-[7px] text-green42">
            Empresas
          </h3>
          <div className="mb-[7px]">
            <span className="font-normal text-[13px] w-[186px] text-[#5E5E5E] ">
              Añade empresas interesantes para ti o excluye las que no te
              interesen.
            </span>
          </div>
          <CompanyC
            ref={companyCRef}
            onCompaniesChange={handleCompaniesChange}
            onExcludedCompaniesChange={handleExcludedCompaniesChange}
          />

          <div className="mb-[7px]">
            <span className="font-normal text-[13px] w-[186px] text-[#5E5E5E] italic">
              Escribe "-" antes de la palabra para excluirla de la búsqueda
            </span>
          </div>
        </div>

        {/* Experiencia (años) */}
        <div className="mb-[30px]">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">
            Experiencia (años)
          </h3>
          <Slider
            reset={resetExperience}
            onValueChange={handleValueChange}
          />
        </div>

        {/* Educación */}
        <div className="mb-[26px] ">
          <Education
            reset={resetEducation}
            onEducationsChange={handleEducationsChange}
            sectionTitle="Educación"
          />
        </div>

        {/* Disponibilidad */}
        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("disponibilidad")}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Disponibilidad
            </h3>
            {openSections.disponibilidad ? (
              <img src={IconDrop2} />
            ) : (
              <img src={IconDrop} />
            )}
          </div>
          {openSections.disponibilidad && (
            <div className="mt-2">
              {availability.map((option: any, index) => (
                <div className="mt-2">
                  <StyledCheckbox
                    checked={selectedAvailability.includes(option.value)}
                    onChange={(e) => {
                      handleAvailability(option.value, e.target.checked)
                    }}
                    className="mb-[8px]" key={index} value={option.value}
                  >
                    <span className="font-bold !text-base text-green42">{option.label}</span>
                  </StyledCheckbox>
                </div>
              ))}
            </div>
          )}
        </div>



        {/* Áreas de Experiencia */}
        <div className="mb-4">
          <Sector
            sectionTitle="Experiencia en el sector"
            onSectorsChange={handleSectorsChange}
            reset={resetSectors}
          />
        </div>

        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-not-allowed"
            onClick={() => {
              // toggleSection("busquedasSugeridas")
            }}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Búsquedas sugeridas
            </h3>
            {openSections.busquedasSugeridas ? (
              <img src={IconDrop2} />
            ) : (
              <img src={IconDrop} />
            )}
          </div>
          {openSections.busquedasSugeridas && (
            <div className="flex flex-col text-bluec">
              {[
                "Dedicadas a la logística",
                "Mejor valoradas de Ciudad Real",
                "Reclutadores independientes que operan en Ciudad Real",
                "Especializadas en retail",
              ].map((sector) => (
                <div key={sector} className="mb-2 font-bold text-body-md">
                  {sector}
                </div>
              ))}
            </div>
          )}
        </div>
      </Sider>
    </div>
  );
};

export default Sidebar;
