import React from 'react'
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useLocation } from 'react-router-dom';

const API_KEY_STRIPE: string | undefined = process.env.REACT_APP_API_KEY_STRIPE;

const Checkout = () => {

    const location = useLocation();

    const { pack } = location.state || {};

    if (!API_KEY_STRIPE) return null

    const stripePromise = loadStripe(API_KEY_STRIPE);
    return (
        <div className='pt-5'>
            <Elements stripe={stripePromise}>
                <CheckoutForm
                    pack={pack}
                />
            </Elements>
        </div>
    )
}

export default Checkout