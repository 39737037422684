import React, { useState, useEffect } from 'react';
import { Row, Col, Affix, Button, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GetApplicationsReducer } from '../../../redux/actions/applications/GetApplications';
import { AppDispatch, RootState } from '../../../redux/store/store';
import CardEmpleo from '../../../components/cards/CardEmployment';
import InformationNotInterested from '../../../components/pages/myApplications/applications/InformationApplication';
import FilterButtons from '../../../components/pages/myApplications/applications/FilterButtonsApplications';
import { string } from 'yup';
import InfiniteScroll from 'react-infinite-scroll-component';

interface CardEmpleoExampleProps {
  searchInitiated: boolean;
  searchQuery: string;

}

interface FilterParams {
  fases_postulaciones?: boolean;
  fase_descartado?: boolean;
  cv_Descargado?: boolean;
  visto_Empresa?: boolean;
}


const CardEmpleoExample: React.FC<CardEmpleoExampleProps> = ({
  searchInitiated,
  searchQuery
}:any) => {
  const dispatch: AppDispatch = useDispatch();
  const categorias = [
    { valor: 'todas-las-candidaturas', etiqueta: 'Todas las candidaturas' },
    { valor: 'Visto-por-la-empresa', etiqueta: 'Visto por la empresa' },
    { valor: 'cv-descargado', etiqueta: 'CV descargado' },
    { valor: 'avanzas-en-el-proceso', etiqueta: 'Avanzas en el proceso' },
    { valor: 'descartado', etiqueta: 'Descartado' },
  ];

  const { 
    rex_data: applications,
    rex_limit,
    rex_total 
  } = useSelector((state: RootState) => state.applications);
  const { rex_applications: searchResults, rex_loading: searchLoading } = useSelector((state: RootState) => state.searchApplication);

  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>({});
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    fases_postulaciones: false,
    fase_descartado: false,
    cv_Descargado: false,
    visto_Empresa: false
  });

  const selectedCountry = searchQuery.selectedCountry
  const selectedCity = searchQuery.selectedCity
  const selectedSectores = searchQuery.selectedSectores
  const selectedTerm = searchQuery.searchTerm

  const handleCategoriaClick = (newFilters: Partial<FilterParams>) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));
    console.log('newFilters:',newFilters)
    setPage(1);
    setHasMore(true);   
    setLoading(true);
  };

  

  const searchData = () => {
    setLoading(true);
    setPage(1);
    if (selectedCountry && selectedCity && selectedSectores && selectedTerm){
      console.log('entre prmiero')
      const selectedFasesPostulaciones = filterParams.fases_postulaciones ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(GetApplicationsReducer(
        1,
        rex_limit,
        selectedFasesPostulaciones,
        selectedFasesDescartada,
        selectedCv_Descargado,
        selectedVisto_Empresa,
        selectedCountry,
        selectedCity,
        selectedSectores,
        selectedTerm,    
      ))
    }
    else if (selectedCountry && selectedCity && selectedSectores){
      console.log('entre prmiero')
      const selectedFasesPostulaciones = filterParams.fases_postulaciones ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(GetApplicationsReducer(
        1,
        rex_limit,
        selectedFasesPostulaciones,
        selectedFasesDescartada,
        selectedCv_Descargado,
        selectedVisto_Empresa,
        selectedCountry,
        selectedCity,
        selectedSectores,
        ""
      ))
    }
    else if (selectedCountry && selectedCity){
      console.log('selectedCountry:',selectedCountry)
      const selectedFasesPostulaciones = filterParams.fases_postulaciones ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(GetApplicationsReducer(
        1,
        rex_limit,
        selectedFasesPostulaciones,
        selectedFasesDescartada,
        selectedCv_Descargado,
        selectedVisto_Empresa,
        selectedCountry ,
        selectedCity,
        "",
        ""
      )).then(() => setLoading(false));
    }
    else if (selectedSectores) {
      const selectedFasesPostulaciones = filterParams.fases_postulaciones ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(
        GetApplicationsReducer(
          1,
          rex_limit,
          selectedFasesPostulaciones,
          selectedFasesDescartada,
          selectedCv_Descargado,
          selectedVisto_Empresa,
          "",
          "",
          selectedSectores,
          "",
        )
      ).then(() => setLoading(false));
    }
    else if (selectedTerm) {
      const selectedFasesPostulaciones = filterParams.fases_postulaciones ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(
        GetApplicationsReducer(
          1,
          rex_limit,
          selectedFasesPostulaciones,
          selectedFasesDescartada,
          selectedCv_Descargado,
          selectedVisto_Empresa,
          "",
          "",
          "",
          selectedTerm
        )
      ).then(() => setLoading(false));
    }
    else {
      console.log('entre')
      setLoading(true);
      const selectedFasesPostulaciones = filterParams.fases_postulaciones ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(GetApplicationsReducer(
          1,
          rex_limit,
          selectedFasesPostulaciones,
          selectedFasesDescartada,
          selectedCv_Descargado,
          selectedVisto_Empresa,
          "",
          "",
          "",
          "",
        )
      ).then(() => setLoading(false));
    }
  }

  useEffect(() => {
    searchData()
  }, [filterParams, selectedCountry, selectedCity, selectedSectores,rex_total,rex_limit,selectedTerm]);

  useEffect(() => {
    if (!loading && applications.length) {
      const initialLoadingCards = applications.reduce((acc: any, _, index: number) => {
        acc[index] = false;
        return acc;
      }, {});
      setLoadingCards(initialLoadingCards);

      const initialAppliedCards = applications.reduce((acc: any, _, index: number) => {
        acc[index] = true; // Todas las tarjetas están aplicadas
        return acc;
      }, {});
      setAppliedCards(initialAppliedCards);
    }
  }, [applications, loading]);

  const handleCardClick = (index: number) => {
    setLoadingInfo(true);
    setSelectedCardIndex(index);
    setLoadingInfo(false);
    // setTimeout(() => {
    // }, 2000); // Simulación de 2 segundos de carga
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);

    setTimeout(() => {
      const selectedFasesPostulaciones = filterParams.fases_postulaciones  ? filterParams.fases_postulaciones : undefined
      const selectedFasesDescartada = filterParams.fase_descartado ? filterParams.fase_descartado : undefined
      const selectedCv_Descargado = filterParams.cv_Descargado ? filterParams.cv_Descargado : undefined
      const selectedVisto_Empresa = filterParams.visto_Empresa ? filterParams.visto_Empresa : undefined
      dispatch(GetApplicationsReducer(
        nextPage,
        rex_limit,
        selectedFasesPostulaciones, 
        selectedFasesDescartada,
        selectedCv_Descargado,
        selectedVisto_Empresa,
        selectedCountry,
        selectedCity             
      )).then(() => {
        setPage(nextPage);
        setLoadingMoreData(false);
        if (nextPage >= totalPages) {
          setHasMore(false);
        }
      })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };
 
  useEffect(() => {
    if (rex_total > 0 ) {
      if (rex_total == 0) setHasMore(false)
      if (applications?.length == rex_total) {
        setHasMore(false)
      }

    }
  }, [rex_total, applications])
  
  return (
    <div style={{ padding: '10px 0' }}>
      <FilterButtons
        categorias={categorias}
        alClicEnCategoria={handleCategoriaClick}
      />
      {/* <Button
        onClick={()=>{
          console.log('applications:',applications)
          console.log('rex_total:',rex_total)
          console.log('rex_limit:',rex_limit)
          console.log('applications:',applications)
          console.log('hasmore:',hasMore)
          console.log('applications.lenghyt:',applications.length)
          // console.log('filteredApplications:',filteredApplications)
          console.log('searchResults:',searchResults)
          console.log('searchInitiated:',searchInitiated)
          console.log('País seleccionado:', selectedCountry);
          console.log('Ciudad seleccionada:', selectedCity);
          console.log('selectedSectores:', selectedSectores);
          console.log('selectedTerm:', selectedTerm);
        }}
      >
        Aqui
      </Button> */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>

          {/* {(searchLoading || loading) ? (
            <div>Cargando datos...</div>
          ) : filteredApplications.length === 0 ? (
            <div>No se encontraron resultados para el término de búsqueda.</div>
          ) : ( */}
           <InfiniteScroll
            className='w-full pt-[2px] md:pl-[2px] pl-[8px] pr-[8px] box-border'
            dataLength={applications ? applications.length : 0}
            next={fetchMoreData}
            hasMore={hasMore && rex_total >= rex_limit}
            loader={
              <div style={{ marginBottom: '16px' }}>
                <Skeleton
                  active
                  avatar={{ shape: 'square', size: 'large' }}
                  paragraph={{ rows: 3 }}
                />
              </div>
            }
            endMessage={<p>No hay más ofertas para mostrar</p>}
          >
           { applications.map((application, index) => (
              <div
                key={index}
                className={`cursor-pointer mb-5 p-4 rounded-lg transition-shadow duration-300 ${selectedCardIndex === index
                  ? "shadow-lg" // Sombra intensa si la card está seleccionada
                  : "hover:shadow-md" // Sombra leve cuando se hace hover sobre la card
                  }`}
                onClick={() => {
                  handleCardClick(index)
                }}
              >
                {/* <CardEmpleo
                  companyName={application.ofertas.empresas.empresa}
                  jobTitle={application.ofertas?.cargo || `Oferta ID: ${application.oferta_id}`}
                  location={application.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                  salary={application.ofertas?.sal_min !== null ? `${application.ofertas.sal_min}€ - ${application.ofertas.sal_max}€` : 'Salario no disponible'}
                  applied={true}
                  loading={loadingCards[index]}
                  currentStep={application.oferta_id}
                  schedule={application.ofertas.jornada_laboral}
                  ratings={application.ofertas.empresas._count.valoraciones_empresas + " valoraciones"}
                  activeOffers={application.ofertas.empresas._count.ofertas + " ofertas activas"}
                  followers={application.ofertas.empresas._count.empresa_seguida + " seguidores"}
                  description={application.ofertas?.descripcion || 'Descripción no disponible'}
                  postedTime="Subida hace 10 días"
                  createdAt={application?.ofertas?.createdAt}
                /> */}

                <div className='hidden md:flex'>
                  <CardEmpleo
                    idCard={application.oferta_id}
                    companyName={application.ofertas.empresas.empresa}
                    jobTitle={application?.ofertas?.cargo || `Oferta ID: ${application.oferta_id}`}
                    location={application?.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                    salary={application?.ofertas?.sal_min !== null ? `${application.ofertas.sal_min}€ - ${application.ofertas.sal_max}€` : 'Salario no disponible'}
                    applied={true}
                    loading={loadingCards[index]}
                    currentStep={application?.oferta_id}
                    schedule={application?.ofertas?.jornada_laboral}
                    ratings={application?.ofertas?.empresas._count.valoraciones_empresas + " valoraciones"}
                    activeOffers={application?.ofertas?.empresas._count.ofertas + " ofertas activas"}
                    followers={application?.ofertas?.empresas._count.empresa_seguida + " seguidores"}
                    description={application?.ofertas?.descripcion || 'Descripción no disponible'}
                    postedTime=""
                    createdAt={application?.ofertas?.createdAt}
                    styleType="type4"
                    dataComplete={application}
                    avatar={application?.ofertas?.empresas?.logo}
                    setSelectedCardIndex={setSelectedCardIndex}
                  />
                </div>

                <div className='flex md:hidden'>

                  {
                    selectedCardIndex === index
                      ? <InformationNotInterested
                        id={applications[selectedCardIndex]?.ofertas?.id}
                        title={` ${applications[selectedCardIndex]?.ofertas?.cargo || 'Cargo no disponible'}`}
                        company={`${applications[selectedCardIndex]?.ofertas.empresas.empresa || 'ID de empresa no disponible'}`}
                        location={applications[selectedCardIndex]?.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                        employmentType={applications[selectedCardIndex]?.ofertas?.jornada_laboral || 'Tipo de empleo no disponible'}
                        salary={
                          applications[selectedCardIndex]?.ofertas?.sal_min !== null
                            ? `${applications[selectedCardIndex]?.ofertas?.sal_min}€ - ${applications[selectedCardIndex]?.ofertas?.sal_max}€`
                            : 'Salario no disponible'
                        }
                        comments={0}
                        applicantsCount={applications[selectedCardIndex]?.ofertas?._count.postulaciones + " Inscritos"}
                        introText={`${applications[selectedCardIndex]?.ofertas.empresas.descripcion || 'Descripcion no disponible'}`}
                        requirements={[
                          {
                            title: "Estudios mínimos",
                            requirement: applications[selectedCardIndex]?.ofertas.estudios_minimos,
                          },
                          // {
                          //   title: "Jornada laboral",
                          //   requirement: applications[selectedCardIndex]?.ofertas.jornada_laboral,
                          // },
                          // {
                          //   title: "Tipo de contrato",
                          //   requirement: applications[selectedCardIndex]?.ofertas.tipo_contrato,
                          // },
                          {
                            title: "Años de experiencia",
                            requirement: applications[selectedCardIndex]?.ofertas.anios_experiencia == 0 ? "Sin experiencia" : applications[selectedCardIndex]?.ofertas.anios_experiencia,
                          }
                        ]}
                        responsibilities={
                          [
                            applications[selectedCardIndex]?.ofertas.descripcion || 'Descripción no disponible'
                          ]
                        }
                        extraText={[""]}
                        extraText2={[""]}
                        postedTime="Subida hace x días"
                        applied={true}
                        loading={loadingInfo}
                        onApply={() => handleApply(selectedCardIndex)}
                        createdAt={applications[selectedCardIndex]['createdAt']}
                        sector={applications[selectedCardIndex].ofertas.sectores.sector}
                        aptitudes={applications[selectedCardIndex].ofertas.aptitudes_oferta}
                        avatar={applications[selectedCardIndex]?.ofertas?.empresas?.logo}
                        setSelectedCardIndex={setSelectedCardIndex}
                      />
                      : <CardEmpleo
                        idCard={application.oferta_id}
                        companyName={`Empresa ID: ${application.empresa_id}`}
                        jobTitle={application.ofertas?.cargo || `Oferta ID: ${application.oferta_id}`}
                        location={application.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                        salary={application.ofertas?.sal_min !== null ? `${application.ofertas?.sal_min}€ - ${application.ofertas?.sal_max}€` : 'Salario no disponible'}
                        applied={true}
                        loading={loadingCards[index]}
                        currentStep={application.oferta_id}
                        schedule="9:00 am - 5:00 pm"
                        ratings="0 valoraciones"
                        activeOffers="0 ofertas activas"
                        followers="0 seguidores"
                        description={application.ofertas?.descripcion || 'Descripción no disponible'}
                        postedTime="Subida hace 10 días"
                        createdAt={application?.ofertas?.createdAt}
                        dataComplete={application}
                        avatar={application?.ofertas?.empresas?.logo}
                        setSelectedCardIndex={setSelectedCardIndex}
                      // postedTime={`Subida hace ${Math.floor(Math.random() * 30)} días`} // Estático
                      />
                  }
                </div>
              </div>
           ))}
           </InfiniteScroll>
        </Col>
        <Col xs={0} md={12}>
          {selectedCardIndex !== null && applications[selectedCardIndex] &&  (
            <Affix
              offsetTop={140}
            >
              <InformationNotInterested
                id={applications[selectedCardIndex]?.ofertas?.id}
                title={` ${applications[selectedCardIndex]?.ofertas?.cargo || 'Cargo no disponible'}`}
                company={`${applications[selectedCardIndex]?.ofertas.empresas.empresa || 'ID de empresa no disponible'}`}
                location={applications[selectedCardIndex]?.ofertas?.ubi_poblacion || 'Ubicación no disponible'}
                employmentType={applications[selectedCardIndex]?.ofertas?.jornada_laboral || 'Tipo de empleo no disponible'}
                salary={
                  applications[selectedCardIndex]?.ofertas?.sal_min !== null
                    ? `${applications[selectedCardIndex]?.ofertas?.sal_min}€ - ${applications[selectedCardIndex]?.ofertas?.sal_max}€`
                    : 'Salario no disponible'
                }
                comments={0}
                applicantsCount={applications[selectedCardIndex]?.ofertas?._count.postulaciones + " Inscritos"}
                introText={`${applications[selectedCardIndex]?.ofertas.empresas.descripcion || 'Descripcion no disponible'}`}
                requirements={[
                  {
                    title: "Estudios mínimos",
                    requirement: applications[selectedCardIndex]?.ofertas.estudios_minimos,
                  },
                  // {
                  //   title: "Jornada laboral",
                  //   requirement: applications[selectedCardIndex]?.ofertas.jornada_laboral,
                  // },
                  // {
                  //   title: "Tipo de contrato",
                  //   requirement: applications[selectedCardIndex]?.ofertas.tipo_contrato,
                  // },
                  {
                    title: "Años de experiencia",
                    requirement: applications[selectedCardIndex]?.ofertas.anios_experiencia == 0 ? "Sin experiencia" : applications[selectedCardIndex]?.ofertas.anios_experiencia,
                  }
                ]}
                responsibilities={
                  [
                    applications[selectedCardIndex]?.ofertas.descripcion || 'Descripción no disponible'
                  ]
                }
                extraText={[""]}
                extraText2={[""]}
                postedTime="Subida hace x días"
                applied={true}
                loading={loadingInfo}
                onApply={() => handleApply(selectedCardIndex)}
                createdAt={applications[selectedCardIndex]['createdAt']}
                sector={applications[selectedCardIndex].ofertas.sectores.sector}
                aptitudes={applications[selectedCardIndex].ofertas.aptitudes_oferta}
                avatar={applications[selectedCardIndex]?.ofertas?.empresas?.logo}
                setSelectedCardIndex={setSelectedCardIndex}
              />
            </Affix>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardEmpleoExample;
