// Constantes para las acciones relacionadas con "Profile Skills"
export const FETCH_SAVE_PROFILE_SKILLS_REQUEST = 'FETCH_SAVE_PROFILE_SKILLS_REQUEST';
export const FETCH_SAVE_PROFILE_SKILLS_SUCCESS = 'FETCH_SAVE_PROFILE_SKILLS_SUCCESS';
export const FETCH_SAVE_PROFILE_SKILLS_FAILURE = 'FETCH_SAVE_PROFILE_SKILLS_FAILURE';

export const FETCH_PROFILES_SKILLS_REQUEST = 'FETCH_PROFILES_SKILLS_REQUEST';
export const FETCH_PROFILES_SKILLS_SUCCESS = 'FETCH_PROFILES_SKILLS_SUCCESS';
export const FETCH_PROFILES_SKILLS_FAILURE = 'FETCH_PROFILES_SKILLS_FAILURE';

export const FETCH_PROFILE_SKILL_REQUEST = 'FETCH_PROFILE_SKILL_REQUEST';
export const FETCH_PROFILE_SKILL_SUCCESS = 'FETCH_PROFILE_SKILL_SUCCESS';
export const FETCH_PROFILE_SKILL_FAILURE = 'FETCH_PROFILE_SKILL_FAILURE';

export const DELETE_PROFILE_SKILLS_REQUEST = 'DELETE_PROFILE_SKILLS_REQUEST';
export const DELETE_PROFILE_SKILLS_SUCCESS = 'DELETE_PROFILE_SKILLS_SUCCESS';
export const DELETE_ROFILE_SKILLS_FAILURE = 'DELETE_ROFILE_SKILLS_FAILURE';

export const UPDATE_PROFILE_SKILL_REQUEST = 'UPDATE_PROFILE_SKILL_REQUEST';
export const UPDATE_PROFILE_SKILL_SUCCESS = 'UPDATE_PROFILE_SKILL_SUCCESS';
export const UPDATE_ROFILE_SKILL_FAILURE = 'UPDATE_ROFILE_SKILL_FAILURE';

interface SoftSkill {
  id : number;
  soft_skill : string;
  soft_skill_c : string
}

interface DetalleSoftSkill {
  soft_skill : SoftSkill;
  nivel : number;
}

export interface ProfileSoftSkill{
  id : number;
  nombre : string;
  detalles_preferencias_soft_skills : DetalleSoftSkill[]
}

// Tipos de las acciones
interface FetchSaveProfileSkillsRequestAction {
  type: typeof FETCH_SAVE_PROFILE_SKILLS_REQUEST;
}

interface FetchSaveProfileSkillsSuccessAction {
  type: typeof FETCH_SAVE_PROFILE_SKILLS_SUCCESS;
}

interface FetchSaveProfileSkillsFailureAction {
  type: typeof FETCH_SAVE_PROFILE_SKILLS_FAILURE;
  payload: string;
}

interface FetchProfilesSkillsRequestAction {
  type: typeof FETCH_PROFILES_SKILLS_REQUEST;
}

interface FetchProfilesSkillsSuccessAction {
  type: typeof FETCH_PROFILES_SKILLS_SUCCESS;
  payload : []
}

interface FetchProfilesSkillsFailureAction {
  type: typeof FETCH_PROFILES_SKILLS_FAILURE;
  payload: string;
}

interface DeleteProfileSkillsRequestAction {
  type: typeof DELETE_PROFILE_SKILLS_REQUEST;
}

interface DeleteProfileSkillsSuccessAction {
  type: typeof DELETE_PROFILE_SKILLS_SUCCESS;
}

interface DeleteProfileSkillsFailureAction {
  type: typeof DELETE_ROFILE_SKILLS_FAILURE;
  payload: string;
}

interface FetchProfileSkillRequestAction {
  type: typeof FETCH_PROFILE_SKILL_REQUEST;
}

interface FetchProfileSkillSuccessAction {
  type: typeof FETCH_PROFILE_SKILL_SUCCESS;
  payload : ProfileSoftSkill
}

interface FetchProfileSkillFailureAction {
  type: typeof FETCH_PROFILE_SKILL_FAILURE;
  payload: string;
}

interface UpdateProfileSkillRequestAction {
  type: typeof UPDATE_PROFILE_SKILL_REQUEST;
}

interface UpdateProfileSkillSuccessAction {
  type: typeof UPDATE_PROFILE_SKILL_SUCCESS;
}

interface UpdateProfileSkillFailureAction {
  type: typeof UPDATE_ROFILE_SKILL_FAILURE;
  payload: string;
}

// Union Type para todas las posibles acciones de "Profile Skills"
export type ProfileSkillsActionTypes = 
  | FetchSaveProfileSkillsRequestAction
  | FetchSaveProfileSkillsSuccessAction
  | FetchSaveProfileSkillsFailureAction
  | FetchProfilesSkillsRequestAction
  | FetchProfilesSkillsSuccessAction
  | FetchProfilesSkillsFailureAction
  | DeleteProfileSkillsRequestAction
  | DeleteProfileSkillsSuccessAction
  | DeleteProfileSkillsFailureAction
  | FetchProfileSkillRequestAction
  | FetchProfileSkillSuccessAction
  | FetchProfileSkillFailureAction
  | UpdateProfileSkillRequestAction
  | UpdateProfileSkillSuccessAction
  | UpdateProfileSkillFailureAction;
