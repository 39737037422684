export const experienciaTexto = (meses: number | null): string => {

	if (meses === null || meses == 0) {
		return 'ROCKIE'
	}

	if(meses == 1){
		return '1 mes'
	}

	if (meses > 0 && meses < 12) {
		return `${meses} meses`
	} else if (meses === 12) {
		return `1 año`;
	} else {
		const años = Math.floor(meses / 12)

		if (meses === años * 12) {
			return `${años} años`
		}

		return `De ${años} - ${años + 1} años`
	}
}