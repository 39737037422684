import React, { useEffect, useState } from "react";
import { Table, Tooltip, Modal, Button, Divider } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import "../../../components/pages/controlPanel/OpenEstilos.css";
import FinalistInfo from "../../../components/pages/controlPanel/FinalistCandidates";
import { AppDispatch, RootState } from "../../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { GetClosedOffersReducerTable } from "../../../redux/actions/pages/company/ClosedOffers";
import { GetAptitudByIdReducer } from "../../../redux/actions/pages/myPortal/aptitudes/GetAptitud";
import { GetUserRatingsByIdReducer } from '../../../redux/actions/pages/myPortal/ratings/GetRatingPortal';
import ModalCandidate from "../../../components/pages/controlPanel/FinalistCandidates"
import TableComponentDessign from "../../../components/table/TableDessign";
import moment from "moment";
interface FinalistData {
  key: string;
  id: number;
  nombreBusqueda: string;
  inscritos: string;
  finalistas: string;
  fechaApertura: string;
  fechaCierre: string;
  diasActivos: string;
  promedioInscritos: string;
  imageUrl: string;
  nombre: string;
  puesto: string;
  ubicacion: string;
  habilidades: string[];
  descripcion: string;
  radarData: any[];
  isProfileUnlocked: boolean;
  valoracionGeneral: string; // Nueva propiedad
  totalValoraciones: number; // Nueva propiedad
  soft_skills: {}
}

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const CompletedFinalists: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortColumn, setSortColumn] = useState<string>('cargo');
  const [selectedFinalistData, setSelectedFinalistData] = useState<FinalistData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [aptitudes, setAptitudes] = useState<string[]>([]);
  const [valoracionGeneral, setValoracionGeneral] = useState<string>("");
  const [totalValoraciones, setTotalValoraciones] = useState<number>(0);



  const fetchRatings = async (userId: number) => {
    try {
      const response = await dispatch(GetUserRatingsByIdReducer(userId));
      if (response && response.data) {
        return {
          valoracionGeneral: response.data.valoracion,
          totalValoraciones: response.data.valoraciones_usuarios.length,
        };
      }
    } catch (error) {
      console.error('Error fetching ratings:', error);
    }
    return { valoracionGeneral: "0", totalValoraciones: 0 };
  };


  const pageSize = 2;
  const {
    rex_closedoffers: offersData,
    rex_loading: loading,
    rex_error: error,
    rex_meta } = useSelector(({ closedOffers }: any) => closedOffers);

  useEffect(() => {
    dispatch(
      GetClosedOffersReducerTable(1, 10),
    );
  }, [dispatch]);

  const [offers, setOffers] = useState<FinalistData[]>([]);

  const showModal = async (record: any) => {
    const postulaciones = record.postulaciones; // Array de postulaciones

    const finalistasData = await Promise.all(
      postulaciones.map(async (postulacion: any) => {
        const usuarioData = postulacion.usuarios;

        if (usuarioData) {
          let habilidades = usuarioData.habilidades || [];

          const { valoracionGeneral, totalValoraciones } = await fetchRatings(usuarioData.id);

          // Obtener aptitudes
          const aptitudesResponse = await dispatch(GetAptitudByIdReducer(usuarioData.id));
          if (aptitudesResponse && aptitudesResponse.data) {
            const fetchedAptitudes = aptitudesResponse.data.map((item: any) => item.aptitudes.aptitud);
            habilidades = [...habilidades, ...fetchedAptitudes];
          }

          return {
            id: postulacion.id,
            key: postulacion.id.toString(),
            nombreBusqueda: record.cargo || '--',
            inscritos: record._count?.postulaciones?.toString() || '--',
            finalistas: '--',
            fechaApertura: '--',
            fechaCierre: moment(record.dateFinalizada).format("DD/MM/YYYY H:mm"),
            diasActivos: '--',
            promedioInscritos: '--',
            imageUrl: API_BASE_URL_EXACT + usuarioData.imagen || IMAGE_USER_DEFAULT,
            nombre: `${usuarioData.personas?.nombre || 'Nombre no disponible'} ${usuarioData.personas?.apellido_paterno || ''} ${usuarioData.personas?.apellido_materno || ''}`.trim(),
            puesto: usuarioData.cargo || 'Puesto no disponible',
            ubicacion: usuarioData.ubicacion || 'Ubicación no disponible',
            habilidades,
            descripcion: usuarioData.sobreMi || 'Descripción no disponible',
            radarData: [],
            isProfileUnlocked: true,
            valoracionGeneral,  // Valoración específica del usuario
            totalValoraciones, // Total de valoraciones específicas
            soft_skills: {
              soft_skills_oferta: record.soft_skills_oferta,
              soft_skills_usuario: usuarioData.soft_skills_usuarios,
            }
          };
        }

        return null;
      })
    );

    setSelectedFinalistData(finalistasData.filter(Boolean)); // Actualiza el estado con todos los finalistas
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedFinalistData([]); // Arreglo vacío en lugar de null
  };


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order as 'asc' | 'desc');
  };

  useEffect(() => {
    if (offersData) {
      const transformedOffers = offersData.map((offer: any) => ({
        key: offer.id.toString(),
        nombreBusqueda: offer.cargo || '--',
        inscritos: offer._count?.postulaciones?.toString() || '--',
        finalistas: '--',
        fechaApertura: '--',
        fechaCierre: '--',
        diasActivos: '--',
        promedioInscritos: '--',
      }));
      setOffers(transformedOffers);
    }
  }, [offersData]);

  const columns = [
    {
      title: "Cargo",
      key: "cargo",
      ellipsis: true,
      dataIndex: "cargo",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Inscritos",
      key: "_count",
      ellipsis: true,
      render: (data: any) => (
        <span>{data._count.postulaciones}</span>
      ),
    },
    {
      title: "Finalistas",
      dataIndex: "countFinalistas",
      ellipsis: true,
      key: "countFinalistas",
      render: (text: string, record: FinalistData) => (
        <span
          className="text-[#006497] cursor-pointer"
          onClick={() => showModal(record)}  // Llamar a showModal con el registro de finalista
        >
          {text}
        </span>
      ),
    },
    {
      title: "Fecha de apertura",
      key: "dateApertura",
      ellipsis: true,
      render: (data: any) => (
        <span>{moment(data.dateApertura).format("DD/MM/YYYY H:mm")}</span>
      ),
    },
    {
      title: "Fecha de cierre",
      key: "dateFinalizada",
      ellipsis: true,
      render: (data: any) => (
        <span>{moment(data.dateFinalizada).format("DD/MM/YYYY H:mm")}</span>
      ),
    },
    {
      title: "Días activos de búsqueda",
      key: "diasActivos",
      render: (data: any) => {
        const fechaInicio = moment(data.dateApertura);
        const fechaFin = moment(data.dateFinalizada);

        const diferenciaEnDias = fechaFin.diff(fechaInicio, 'days');
        return (
          <span>{diferenciaEnDias}</span>
        )
      },
    },
    {
      title: "Promedio de Inscritos al día",
      key: "promedioInscritos",
      render: (data: any) => (
        <span>{data.promedioDatosPorDia.toFixed(2)}</span>
      ),
    },
    {
      title: "",
      key: "actions",
      render: () => <span>Actions</span>,
    },
  ];

  return (
    <div className="px-[15px]">
      <h3 className="font-bold text-lg pb-[24px]">Finalistas Completados</h3>
      <div style={{ maxHeight: "210px", overflowY: "auto" }}>
        <TableComponentDessign
          customDesign={true}
          columns={columns}
          data={offersData}
          meta={rex_meta}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          getData={(current, order, column) => {
            handleSortChange(column, order);
            setCurrentPage(current);
          }}
        />
      </div>

      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={850}
        closeIcon={<button className="bg-blue3 text-white rounded-full p-2 w-6 h-6 flex items-center justify-center">X</button>}
      >
        <h2 className="text-center font-bold text-[24px] mb-2 mt-2">Candidatos finalistas</h2>
        <p className="text-center text[16px] font-normal text-gray-500 mb-4">Te presentamos a los finalistas de tu proceso de selección:</p>

        {selectedFinalistData.length > 0 ? (
          selectedFinalistData.map((finalista, index) => (
            <div key={index} className="mb-8">
              <FinalistInfo
                imageUrl={finalista.imageUrl || 'default-image.png'}
                nombre={finalista.nombre || 'Nombre no disponible'}
                puesto={finalista.puesto || 'Puesto no disponible'}
                ubicacion={finalista.ubicacion || 'Ubicación no disponible'}
                habilidades={finalista.habilidades || []}
                descripcion={finalista.descripcion || 'Descripción no disponible'}
                radarData={finalista.radarData || []}
                isProfileUnlocked={finalista.isProfileUnlocked || false}
                valoracionGeneral={finalista.valoracionGeneral} // Valoración específica
                totalValoraciones={finalista.totalValoraciones}
                soft_skills={finalista.soft_skills}
              />
              {/* Mostrar Divider solo si no es el último finalista */}
              {index < selectedFinalistData.length - 1 && (
                <Divider className="bg-black my-8" />
              )}
            </div>
          ))
        ) : (
          <p>No se ha seleccionado ningún finalista.</p>
        )}

      </Modal>
    </div>
  );
};

export default CompletedFinalists;