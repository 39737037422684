export const FETCH_GET_OFFER_SAVE_REQUEST = 'FETCH_GET_OFFER_SAVE_REQUEST';
export const FETCH_GET_OFFER_SAVE_SUCCESS = 'FETCH_GET_OFFER_SAVE_SUCCESS';
export const FETCH_GET_OFFER_SAVE_FAILURE = 'FETCH_GET_OFFER_SAVE_FAILURE';
export const SET_FILTER_PARAMS_OFFER_SAVE = 'SET_FILTER_PARAMS_OFFER_SAVE';


export interface FetchOfferSaveRequestAction {
    type: typeof FETCH_GET_OFFER_SAVE_REQUEST;
  }

export interface FetchOfferSaveSuccessAction {
    type: typeof FETCH_GET_OFFER_SAVE_SUCCESS;
    payload: {
      data: any[]
      meta: { total: number; limit: number; page: number }; 
    };
  }

export interface FetchOfferSaveFailureAction {
    type: typeof FETCH_GET_OFFER_SAVE_FAILURE;
    payload: string;
  }

interface SetFilterParamsOfferSaveAction {
    type: typeof SET_FILTER_PARAMS_OFFER_SAVE;
    payload: any; 
}

export type OfferSaveActionTypes =
  | FetchOfferSaveRequestAction
  | FetchOfferSaveSuccessAction
  | FetchOfferSaveFailureAction
  | SetFilterParamsOfferSaveAction;