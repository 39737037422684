// src/constants/pages/myPortal/ratings/GetUserRatings.ts

export const GET_USER_RATINGS_REQUEST = 'GET_USER_RATINGS_REQUEST';
export const GET_USER_RATINGS_SUCCESS = 'GET_USER_RATINGS_SUCCESS';
export const GET_USER_RATINGS_FAILURE = 'GET_USER_RATINGS_FAILURE';

export interface UserRating {
  id: number;
  observacion: string | null;
  anonimo: boolean;
  valoracion: string;
  createdAt: string;
  usuario: {
    imagen: string | null;
    tipo_usuario_id: number;
    cargo: string;
    personas: {
      apellido_materno: string;
      apellido_paterno: string;
      nombre: string;
    };
    empresa: {
      empresa: string; // Nombre de la empresa
      sectores: {
        sector: string; // Sector de la empresa
      };
      logo: string | null; // Logo de la empresa (opcional)
    };
  };
}

interface GetUserRatingsRequestAction {
  type: typeof GET_USER_RATINGS_REQUEST;
}

interface GetUserRatingsSuccessAction {
  type: typeof GET_USER_RATINGS_SUCCESS;
  payload: {
    data: UserRating[];
    meta: any
  }
}

interface GetUserRatingsFailureAction {
  type: typeof GET_USER_RATINGS_FAILURE;
  payload: string;
}

export type GetUserRatingsActionTypes =
  | GetUserRatingsRequestAction
  | GetUserRatingsSuccessAction
  | GetUserRatingsFailureAction;