import React, { useEffect, useState } from "react";
import ButtonCom from "../../../../button/Button";
import DebounceSelect from "../SelectKeyWords"; // Importamos tu componente personalizado
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store/store";
import { GetKeywordsReducer } from "../../../../../redux/actions/common/keywords/Keywords"; 
import { Col, Row } from "antd";

interface KeywordsProps {
  initialKeywords?: {}[];
  onKeywordsChange?: (keywords: string[]) => void;
  allowExclusion?: boolean; 
  reset?: boolean;
  onExcludedKeywordsChange?: (excludedKeywords: string[]) => void,
}

const Keywords: React.FC<KeywordsProps> = ({
  initialKeywords = [],
  onKeywordsChange,
  onExcludedKeywordsChange,
  allowExclusion = false,
  reset = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [keywords, setKeywords] = useState<any[]>(initialKeywords)
  const [newKeyword, setNewKeyword] = useState({ value: '', excluded: false, label: '' })
  const [ clearOptions, setClearOptions ] = useState(false)

  useEffect(() => {
    if (reset) {
      setKeywords([]);
      setNewKeyword({ value: '', excluded: false, label: '' }); 

      if (onKeywordsChange) {
        onKeywordsChange([]); 
      }
      if (onExcludedKeywordsChange) {
        onExcludedKeywordsChange([]); 
      }
    }
  }, [reset])

  const fetchKeywordOptions = async (search: string) => {

    const searchFormated = search.startsWith("-") ? search.slice(1) : search;

    const response = await dispatch(GetKeywordsReducer(searchFormated, 1, 5)); // Cambiar tamaño de página si es necesario

    return response.payload.data.map((keyword: any) => {

      const isExcluded = search.startsWith("-")

      return {
        label: isExcluded ? "-" + keyword.palabra : keyword.palabra,
        value: isExcluded ? "-" + keyword.palabra : keyword.palabra,
        excluded: isExcluded
      }
    });
  }


  // Función para agregar una palabra clave nueva
  const addKeyword = () => {

    const findKeyword = keywords.find(kyw => kyw.excluded == newKeyword.excluded && (newKeyword.excluded ? "-"+kyw.value == newKeyword.value.trim(): kyw.value == newKeyword.value.trim()))

    if (newKeyword.value.trim() !== "" && !findKeyword) {

      let keywordsString = keywords.filter((kyw: any) => kyw.excluded == false).map((kyw: any) => kyw.value)
      let excludedKeywordsString = keywords.filter((kyw: any) => kyw.excluded == true).map((kyw: any) => kyw.value)

      if (newKeyword.excluded) {
        if (onExcludedKeywordsChange) {
          onExcludedKeywordsChange([...excludedKeywordsString, newKeyword.value.slice(1)])
        }
      } else {
        if (onKeywordsChange) {
          onKeywordsChange([...keywordsString, newKeyword.value]); // Notifica los cambios al componente padre
        }
      }

      const updatedKeywords = [...keywords, { value: newKeyword.excluded? newKeyword.value.trim().slice(1) : newKeyword.value.trim(), excluded: newKeyword.excluded }];
      setKeywords(updatedKeywords)
      setNewKeyword({ value: '', excluded: false, label: '' })

      setClearOptions(true)
      setTimeout(() => setClearOptions(false), 100)
    }
  };

  // Función para eliminar una palabra clave
  const removeKeyword = (keywordToRemove: string) => {

    const keywordsPrev = [...keywords]

    let company: any = keywordsPrev.find((kyw: any) => kyw.value == keywordToRemove)

    const updatedKeywords = keywords.filter(
      (company: any) => company.value !== keywordToRemove
    );
    setKeywords(updatedKeywords)

    let keywordsString = updatedKeywords.filter((kyw: any) => kyw.excluded == false).map((kyw: any) => kyw.value)
    let excludedKeywordsString = updatedKeywords.filter((kyw: any) => kyw.excluded == true).map((kyw: any) => kyw.value)
    if (company.excluded) {
      if (onExcludedKeywordsChange) {
        onExcludedKeywordsChange(excludedKeywordsString)
      }
    } else {
      if (onKeywordsChange) {
        onKeywordsChange(keywordsString); // Notifica los cambios al componente padre
      }
    }
    setClearOptions(true)
    setTimeout(() => setClearOptions(false), 100)
  }

  return (
    <div>      
      {/* Usamos DebounceSelect para buscar palabras clave desde la API */}
      <div className="flex justify-between">
        <div className="w-full md:w-[122px]">
          <DebounceSelect
            showSearch
            placeholder="Escribe aquí..."
            fetchOptions={fetchKeywordOptions} // Función para buscar palabras clave
            onChange={(value: any, option: any) => {
              setNewKeyword(option)
            }}
            value={newKeyword.value!= "" ? {label: newKeyword.label, value: newKeyword.value} : null}
            clearOptions={clearOptions}
            customClassName="custom-input-class font-sans font-bold" // Añade una clase personalizada si lo deseas
            style={{ height:'36px', marginBottom: "10px" }}
          />
        </div>
        <div>
          <ButtonCom
            className="font-sans w-[64px]"
            buttons={[
              {
                type: "link",
                label: "Añadir",
                border: "1px solid #006497",
                size: "small",
                textColor: "#006497",
                fontSize: "12px",
                fontWeight: "bold",
                textAlign: "center",
                onClick: addKeyword,
              },
            ]}
          />
        </div>
      </div>

      {/* Muestra las palabras clave seleccionadas */}
      <div className="flex flex-col mt-4">
        {keywords.map((keyword, index) => {
          const isExcluded = allowExclusion && keyword.excluded; // Solo permite exclusión si allowExclusion es true

          return (
            <div
              key={index}
              className="flex justify-between items-center mb-[8px]"
            >
              <span
                className={`font-medium text-body-sm ${isExcluded ? "text-[#FF6B6B]" : "text-green22"}`}
              >
                {keyword.value}
              </span>
              <span
                className="ml-2 cursor-pointer transition-colors font-medium text-body-sm text-green22"
                onClick={() => removeKeyword(keyword.value)}
              >
                X
              </span>
            </div>
          );
        })}
      </div>

      {allowExclusion && ( // Solo muestra la instrucción de exclusión si la funcionalidad está habilitada
        <div className="mb-[7px]">
          <span className="font-normal text-[13px] w-[186px] text-[#5E5E5E] italic">
            Escribe "-" antes de la palabra para excluirla de la búsqueda
          </span>
        </div>
      )}
    </div>
  );
};

export default Keywords;
