import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  DELETE_EXPERIENCE_REQUEST,
  DELETE_EXPERIENCE_SUCCESS,
  DELETE_EXPERIENCE_FAILURE,
  DeleteExperienceActionTypes,
} from '../../../../../constants/pages/myPortal/workExperience/DeleteWorkExperience';
import FetchWithIP from '../../../utils/FetchHeaders';

// Action Creators
export const deleteExperienceRequestReducer = (): DeleteExperienceActionTypes => ({
  type: DELETE_EXPERIENCE_REQUEST,
});

export const deleteExperienceSuccessReducer = (id: number): DeleteExperienceActionTypes => ({
  type: DELETE_EXPERIENCE_SUCCESS,
  payload: { id },
});

export const deleteExperienceFailureReducer = (error: string): DeleteExperienceActionTypes => ({
  type: DELETE_EXPERIENCE_FAILURE,
  payload: error,
});

// Thunk Action para eliminar una experiencia laboral por ID
export const DeleteUserExperienceReducer = (
  id: number
): ThunkAction<Promise<any>, RootState, unknown, DeleteExperienceActionTypes> => async (dispatch) => {
  dispatch(deleteExperienceRequestReducer());

  try {
    const response = await FetchWithIP(`experiencias-laborales-usuarios/${id}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.success) {
      dispatch(deleteExperienceSuccessReducer(id));
      return { payload: { success: true, id } };
    } else {
      throw new Error(responseData.message || 'Error al eliminar la experiencia laboral');
    }
  } catch (error: any) {
    dispatch(deleteExperienceFailureReducer(error.message || 'Error al eliminar la experiencia laboral'));
    return { payload: { success: false } };
  }
};
