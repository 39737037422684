import React, { useEffect, useState } from 'react';
import '../../../styles/pages/notifications/NewValuation.css'
import Avatar from "../../../assets/icons/Avatar.svg";
import { RootState } from "../../../redux/store/store";
import { useSelector, UseDispatch, useDispatch } from "react-redux";
import dayjs from 'dayjs';
import { EllipsisOutlined } from '@ant-design/icons';
import IconDelete from '../../../assets/icons/IconDelete.svg';
import IconLike from '../../../assets/icons/IconUnLike.svg';
import IconTrash from '../../../assets/icons/IconTrash.svg';
import IconTrash3 from '../../../assets/icons/IconTrash3.svg';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AppDispatch } from "../../../redux/store/store";
import 'dayjs/locale/es';
import { createRestrictNotificationReducer, DeleteNotificationReducer, fetchNotificationsReducer } from "../../../redux/actions/common/notificacions/Notifications";
import { Dropdown, Menu } from 'antd';
dayjs.extend(relativeTime);
dayjs.locale('es');

const CardUserExample: React.FC = () => {

	const [activeNotification, setActiveNotification] = useState<number | null>(null);
	const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);

	const dispatch: AppDispatch = useDispatch()

	const {
		rex_notifications,
		rex_loading,
		rex_restricted_notifications
	} = useSelector(({ notifications }: RootState) => notifications)

	const deleteNotification = (id: number) => {
		// setSelectedMenuItem("1")
		dispatch(DeleteNotificationReducer(id))
	}

	const restrictNotification = (type: number) => {
		setSelectedMenuItem("1")
		dispatch(createRestrictNotificationReducer(type))
	}

	useEffect(() => {
		dispatch(fetchNotificationsReducer('updateFasePostulacion'))
	}, [])

	return (
		<div className="space-y-2 mt-[21px] mb-[54px]">

			{rex_notifications?.map((notification) => {

				const menu = (
					<Menu>
						<Menu.Item
							key="1"
							icon={<img src={IconDelete} alt="Eliminar notificación" />}
							onClick={(e) => {
								deleteNotification(notification.id)
							}}
							className={selectedMenuItem === "1" ? "border-2 border-[#0778B1]" : ""}
							style={{ borderRadius: '10px' }}
						>
							<span className="font-medium text-body-sm">Eliminar notificación</span>
						</Menu.Item>
						<Menu.Item
							key="2"
							icon={<img src={IconLike} alt="Mostrar menos así" />}
							onClick={() => setSelectedMenuItem("2")}
							className={selectedMenuItem === "2" ? "border-2 border-[#0778B1] rounded-[18x]" : ""}
							style={{ borderRadius: '10px' }}
						>
							<span className="font-medium text-body-sm">Mostrar menos así</span>
						</Menu.Item>
						<Menu.Item
							key="3"
							icon={<img src={IconTrash} alt="Gestiona las notificaciones de esta empresa" />}
							onClick={() => setSelectedMenuItem("3")}
							className={selectedMenuItem === "3" ? "border-2 border-[#0778B1]" : ""}
							style={{ borderRadius: '10px' }}
						>
							<span className="font-medium text-body-sm">Gestiona las notificaciones de esta empresa</span>
						</Menu.Item>
						<Menu.Item
							key="4"
							icon={<img src={IconTrash3} alt="Desactiva este tipo de notificaciones" />}
							onClick={() => {
								restrictNotification(notification.tipo_notificacion_id)
							}}
							className={selectedMenuItem === "4" ? "border-2 border-[#0778B1]" : ""}
							style={{ borderRadius: '10px' }}
						>
							<span className="font-medium text-body-sm">
								{
									rex_restricted_notifications.some(rnt => rnt.tipo_notificacion_id == notification.tipo_notificacion_id)
										? 'Activa este tipo de notificaciones'
										: 'Desactiva este tipo de notificaciones'
								}
							</span>
						</Menu.Item>
					</Menu>
				);

				return (
					<div
						key={notification.id}
						onClick={() => setActiveNotification(notification.id)}
						className={`notification-item flex items-center h-[44px] p-4 cursor-pointer ${activeNotification === notification.id ? "bg-[#81BFEC]" : "bg-white"
							}`}
					>
						<img
							src={
								notification.usuarioEmisor?.empresas[0]?.logo
									? process.env.REACT_APP_API_BASE_URL_EXACT + notification.usuarioEmisor.empresas[0].logo
									: process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT
							}
							alt="Avatar Empresa"
							className="notification-avatar w-10 h-10 rounded-full md:ml-[24px] mr-[16px]"
						/>
						<div className="flex-1 w-full">
							<span className="font-medium text-body-md overflow-hidden text-ellipsis">{notification.mensaje}</span>
						</div>
						<div className="flex items-center space-x-[16px] mr-[17px]">
							<span className="text-[#5F5F5F] text-body-sm font-medium">{dayjs(notification.createdAt).fromNow()}</span>
							<Dropdown overlay={menu} trigger={['click']}>
								<EllipsisOutlined className="text-heading-md cursor-pointer text-[#0778B1]" />
							</Dropdown>
						</div>
					</div>
				)
			})}
		</div>
	)
};

export default CardUserExample;