import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
	FETCH_CONSUME_CREDIT_REQUEST,
	FETCH_CONSUME_CREDIT_SUCCESS,
	FETCH_CONSUME_CREDIT_FAILURE,
	FETCH_CREDITS_USER_REQUEST,
	FETCH_CREDITS_USER_SUCCESS,
	FETCH_CREDITS_USER_FAILURE,
	ConsumeCredit,
	PaquetesUsuariosActionTypes,
	CreditsUser,
	FETCH_SHOW_PROFILE_REQUEST,
	FETCH_SHOW_PROFILE_SUCCESS,
	FETCH_SHOW_PROFILE_FAILURE,
	FETCH_PUBLISHED_OFFER_REQUEST,
	FETCH_PUBLISHED_OFFER_SUCCESS,
	FETCH_PUBLISHED_OFFER_FAILURE,
	ShowProfile,
	PublishedOffer,
} from '../../../../constants/common/paquetesUsuarios/PaquetesUsuarios';
import FetchWithIP from '../../utils/FetchHeaders';
import { getCvGeneralByIdReducer } from '../../pages/searchCV/GetSearchCV_ID';
import { GetUserPortfolioByIdReducer } from '../../pages/searchCV/portfolio/GetPortfolioUser';
import { GetUserCVByIdReducer } from '../../pages/searchCV/cv/GetCVSearch';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchConsumeCreditRequestAction = (): PaquetesUsuariosActionTypes => ({
	type: FETCH_CONSUME_CREDIT_REQUEST,
});

export const fetchConsumeCreditSuccessAction = (data: ConsumeCredit): PaquetesUsuariosActionTypes => ({
	type: FETCH_CONSUME_CREDIT_SUCCESS,
	payload: data,
});

export const fetchConsumeCreditFailureAction = (error: string): PaquetesUsuariosActionTypes => ({
	type: FETCH_CONSUME_CREDIT_FAILURE,
	payload: error,
});

export const fetchCreditsUserRequestAction = (): PaquetesUsuariosActionTypes => ({
	type: FETCH_CREDITS_USER_REQUEST,
});

export const fetchCreditsUserSuccessAction = (data: CreditsUser): PaquetesUsuariosActionTypes => ({
	type: FETCH_CREDITS_USER_SUCCESS,
	payload: data,
});

export const fetchCreditsUserFailureAction = (error: string): PaquetesUsuariosActionTypes => ({
	type: FETCH_CREDITS_USER_FAILURE,
	payload: error,
});

export const fetchShowProfileRequestAction = (): PaquetesUsuariosActionTypes => ({
	type: FETCH_SHOW_PROFILE_REQUEST,
});

export const fetchShowProfileSuccessAction = (data: ShowProfile): PaquetesUsuariosActionTypes => ({
	type: FETCH_SHOW_PROFILE_SUCCESS,
	payload: data,
});

export const fetchShowProfileFailureAction = (error: string): PaquetesUsuariosActionTypes => ({
	type: FETCH_SHOW_PROFILE_FAILURE,
	payload: error,
});

export const fetchPublishedOfferRequestAction = (): PaquetesUsuariosActionTypes => ({
	type: FETCH_PUBLISHED_OFFER_REQUEST,
});

export const fetchPublishedOfferSuccessAction = (data: PublishedOffer): PaquetesUsuariosActionTypes => ({
	type: FETCH_PUBLISHED_OFFER_SUCCESS,
	payload: data,
});

export const fetchPublishedOfferFailureAction = (error: string): PaquetesUsuariosActionTypes => ({
	type: FETCH_PUBLISHED_OFFER_FAILURE,
	payload: error,
});


export const GetPublishedOfferReducer = (id: number):
	ThunkAction<Promise<any>, RootState, unknown, PaquetesUsuariosActionTypes> => async (dispatch) => {
		try {

			dispatch(fetchPublishedOfferRequestAction());

			const response = await FetchWithIP(`paquetes-usuarios/show-offer/${id}`, {
				method: 'GET'
			});

			if (!response.ok) {
				throw new Error(`Error HTTP: ${response.status}`);
			}

			const responseData = await response.json();

			if (responseData.data) {
				dispatch(fetchPublishedOfferSuccessAction(responseData.data));
			} else {
				throw new Error('No se encontro informacion de la oferta')
			}
		} catch (error) {
			dispatch(fetchPublishedOfferFailureAction('Error al mostrar datos'));
			return { payload: { data: [] } };
		}
	};

export const GetShowProfileReducer = (id: number):
	ThunkAction<Promise<any>, RootState, unknown, PaquetesUsuariosActionTypes> => async (dispatch) => {
		try {

			dispatch(fetchShowProfileRequestAction());

			const response = await FetchWithIP(`paquetes-usuarios/show-profile/${id}`, {
				method: 'GET'
			});

			if (!response.ok) {
				throw new Error(`Error HTTP: ${response.status}`);
			}

			const responseData = await response.json();

			if (responseData.data) {
				dispatch(fetchShowProfileSuccessAction(responseData.data));
			} else {
				throw new Error('No se encontro informacion del perfil')
			}
		} catch (error) {
			dispatch(fetchShowProfileFailureAction('Error al mostrar datos'));
			return { payload: { data: [] } };
		}
	};

export const GetCreditsUserReducer = ():
	ThunkAction<Promise<any>, RootState, unknown, PaquetesUsuariosActionTypes> => async (dispatch) => {
		try {

			dispatch(fetchCreditsUserRequestAction());

			const response = await FetchWithIP(`paquetes-usuarios/creditos-disponibles`, {
				method: 'GET'
			});

			if (!response.ok) {
				throw new Error(`Error HTTP: ${response.status}`);
			}

			const responseData = await response.json();

			if (responseData.data) {
				dispatch(fetchCreditsUserSuccessAction(responseData.data));
			} else {
				throw new Error('No se encontraron datos de la oferta')
			}
		} catch (error) {
			dispatch(fetchCreditsUserFailureAction('Error al mostrar datos'));
			return { payload: { data: [] } };
		}
	};


export const ConsumeCreditReducer = (data: { type: string, id:number}):
	ThunkAction<Promise<any>, RootState, unknown, PaquetesUsuariosActionTypes> => async (dispatch) => {
		try {

			dispatch(fetchConsumeCreditRequestAction());

			const response = await FetchWithIP(`paquetes-usuarios/consume`, {
				method: 'POST'
			}, data);

			if (!response.ok) {
				throw new Error(`Error HTTP: ${response.status}`);
			}

			const responseData = await response.json();

			if (responseData.data) {
				dispatch(fetchConsumeCreditSuccessAction(responseData.data));
				dispatch(GetCreditsUserReducer())
				dispatch(GetShowProfileReducer(+data.id))
				dispatch(getCvGeneralByIdReducer(+data.id));
				dispatch(GetUserPortfolioByIdReducer(+data.id))
				dispatch(GetUserCVByIdReducer(+data.id))
			} else {
				throw new Error('No se encontraron datos de la oferta')
			}
		} catch (error) {
			dispatch(fetchConsumeCreditFailureAction('Error al mostrar datos'));
			return { payload: { data: [] } };
		}
	};
