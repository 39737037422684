import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'antd';
import CardTopHome from '../../components/homes/CardTopHome';
import googleLogo from '../../assets/img/home/Google.svg';
import facebookLogo from '../../assets/img/home/Facebook.svg';
import banner from '../../assets/img/home/Banner.svg';
import valoracion1 from '../../assets/img/home/Valoraciones1.svg';
import valoracion2 from '../../assets/img/home/Valoraciones2.svg';
import valoracion3 from '../../assets/img/home/Valoraciones3.svg';
import '../../styles/pages/home/Home.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModalValorarPerfil from '../../components/pages/modals/ModalValoraPerfil';
import Footer from '../../components/footer/Footer';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const { Meta } = Card;

const Step: React.FC<{ number: number; index: number }> = ({ number, index }) => (
  <div className={`step-home ${index % 2 === 0 ? 'dynamic-left' : ''}`} style={{ left: `${index * 20}%` }}>
    <h3 className="stepNumber-home text-blue3 font-bold">{number}</h3>
    <p className="stepText-home font-sans">Por fin una plataforma que permite que tus referencias te ayuden a encontrar empleo</p>
  </div>
);

const Home: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const { link_valoracion } = useParams<{ link_valoracion: string }>();

  const entityType = location.pathname.includes('valoracion-candidato') ? 'candidate' : 'company';

  const loginGoogle = (event: React.MouseEvent<HTMLButtonElement>) => {
    // window.location.href =`${config.API_URL}auth/google`;
    window.location.href = `${API_URL}auth/google`;

  };

  const loginFacebook = (event: React.MouseEvent<HTMLButtonElement>) => {
    // window.location.href =`${config.API_URL}auth/google`;
    window.location.href = `${API_URL}auth/facebook`;
  };

  const cardsItem = [
    {
      title: "Completa tu perfil con la ayuda de la Inteligencia artificial en tiempo record",
      description: "Gracias a la IA, si utilizas alguna de las plataformas más recurrentes, tu perfil lo puedes crear en un par de clics.",
    },
    {
      title: "Vamos a dar visibilidad a tus méritos",
      description: "Quién mejor que tus propios clientes para mostrar a los reclutadores lo que vales. Pide referencias que acrediten como trabajas.",
    },
    {
      title: "Contacta con los reclutadores que busquen perfiles como tú y sé el primero en enterarte",
      description: "Se el primero en apuntarte a las ofertas de la gente que busca trabajadores como tú.",
    },
  ]

  return (
    <div>
      <CardTopHome />
      <div className="flex justify-center">
        <div className="w-full 2x2:w-[1370px]">
          <Row className='bg-pink p-[50px] md:p-[63px] m-[24px] rounded-md'>
            <Col xs={24} lg={8} >
              <h2 className="heading-home text-blue font-sans font-black text-[28px] leading-[42px] -tracking-[0.019em]">¿Quieres que te contraten por méritos propios?</h2>
              <p className="paragraph-home text-blue font-sans leading-6 text-lg font-medium -tracking-[0.019em]">Por fin una plataforma que permite que tus referencias te ayuden a encontrar empleo</p>
              <div className="buttonContainer-home font-sans">
                <button className="button-home" onClick={loginGoogle}>
                  <img src={googleLogo} alt="Google" className="h-5 w-5 inline-block" />
                  <span className="buttonText-home -tracking-[0.019em]">Continuar con Google</span>
                </button>
                <button className="button-home" onClick={loginFacebook}>
                  <img src={facebookLogo} alt="Facebook" className="h-5 w-5 inline-block" />
                  <span className="buttonText-home -tracking-[0.019em]">Continuar con Facebook</span>
                </button>
                <div className="dividerContainer-home w-[100%]">
                  <div className="font-sans customDivider-home text-lg">O</div>
                </div>
                <input type="email" placeholder="Introducir correo electrónico" className="font-sans inputEmail-home border-solid border-[#5C5C5C] -tracking-[0.019em]" />
                <button
                  className="button-home"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <span className="buttonText-home -tracking-[0.019em]">Continuar con correo electrónico</span>
                </button>
              </div>
            </Col>
            <Col xs={24} lg={16} className='flex justify-center'>
              <img src={banner} alt="Banner" className="h-full" />
            </Col>
          </Row>
          <div className="topSection-home">
            <h1 className="topHeading-home text-[#333333] font-sans font-bold">El portal cargadito de inteligencia artificial para ahorrarte tiempo y objetivos</h1>
            <Row gutter={[16, 16]} className="cardContainer-home">

              {
                cardsItem.map(card => (
                  <Col xs={24} sm={12} md={8}>
                    <Card
                      bordered
                      className="cardInfoHome card-home shadow-sm"
                      cover={<div className="cardCover-home" />}
                      actions={[
                        <div className="cardActions-home cursor-default">
                          <Button className="font-sans cardButton-home font-semibold px-[14px] text-sm w-[86px] h-[36px] rounded-xl bg-white" key="leer-mas">Leer más</Button>
                        </div>,
                      ]}
                    >
                      <Meta className='font-sans h-[100px]'
                        title={<div className='text-base font-bold text-wrap'>{card.title}</div>}
                        description={<div className='text-base font-medium text-[#5C5C5C]'>{card.description}</div>}
                      />
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </div>


          <Row className='bg-pink px-5 py-10 rounded-md'>
            <Col xs={24} xl={12}>
              <h2 className="font-sans font-bold text-blue3 text-2xl mb-5">Tus valoraciones responden por ti</h2>
              <Row className='px-5 md:px-0'>
                {[1, 4, 8].map((number, index) => (
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 24 - number, offset: number }}
                  >
                    <div className={`flex items-end 2x2:w-[1${index + 1}0%]`}>
                      <div className='font-sans font-bold text-9xl text-blue3'>{index + 1}</div>
                      <div className={`font-sans text-lg mb-2 md:text-lg font-medium px-5 md:px-2`}>Por fin una plataforma que permite que tus referencias te ayuden a encontrar empleo</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={24} xl={12} className='relative h-[480px] sm-[800px] md:h-[600px] lg:h-[500px]'>
              <img src={valoracion1} alt="Valoración 1" className="w-3/5 sm:w-1/2 md:w-auto z-[11] absolute top-[20%] md:top-[5%] left-[30%] md:left-1/3" />
              <img src={valoracion2} alt="Valoración 2" className="w-3/5 sm:w-1/2 md:w-auto z-[12] absolute top-[60%] md:top-[57%] left-[43%]" />
              <img src={valoracion3} alt="Valoración 3" className="w-1/2 sm:w-1/3 md:w-auto lg:w-auto z-[10] absolute top-[25%] sm:top-[28%] md:top-[15%] sm:left-[10%] left-[0] lg:left-[15%] xl:left-[15%] 2xl:left-[15%] 2x2:left-[10%]" />
            </Col>
          </Row>

          {
            link_valoracion ? (
              <ModalValorarPerfil
                visible={isModalVisible}
                onClose={() => setIsModalVisible(!isModalVisible)}
                entityName=''
                entityType={entityType}
                linkVal={link_valoracion}
              />
            ) : null
          }

          <Footer />
        </div>
        {/* visible={isModalVisible}
              onClose={() => setIsModalVisible(!isModalVisible)}
              link={link_valoracion} */}
      </div>
    </div>
  );
};

export default Home;
