import { 
    FETCH_CIUDADES_REQUEST,
    FETCH_CIUDADES_SUCCESS,
    FETCH_CIUDADES_FAILURE,
    CiudadesActionTypes  
} from "../../../../constants/pages/emplotment/searchCities";
  
  interface CiudadesState {
    rex_loading: boolean;
    rex_ciudades: any[]; // Aquí se almacenarán todos los sectores
    rex_error: string | null;
  }
  
  const initialState: CiudadesState = {
    rex_loading: false,
    rex_ciudades: [],
    rex_error: null,
  };
  
  const searchCities = (state = initialState, action: CiudadesActionTypes): CiudadesState => {
    switch (action.type) {
      case FETCH_CIUDADES_REQUEST:
        return {
          ...state,
          rex_loading: true,
        };
      case FETCH_CIUDADES_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          rex_ciudades: action.payload.data,
          rex_error: null,
        };
      case FETCH_CIUDADES_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default searchCities;
  