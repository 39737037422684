export const FETCH_CONSUME_CREDIT_REQUEST = 'FETCH_CONSUME_CREDIT_REQUEST';
export const FETCH_CONSUME_CREDIT_SUCCESS = 'FETCH_CONSUME_CREDIT_SUCCESS';
export const FETCH_CONSUME_CREDIT_FAILURE = 'FETCH_CONSUME_CREDIT_FAILURE';

export const FETCH_CREDITS_USER_REQUEST = 'FETCH_CREDITS_USER_REQUEST';
export const FETCH_CREDITS_USER_SUCCESS = 'FETCH_CREDITS_USER_SUCCESS';
export const FETCH_CREDITS_USER_FAILURE = 'FETCH_CREDITS_USER_FAILURE';

export const FETCH_SHOW_PROFILE_REQUEST = 'FETCH_SHOW_PROFILE_REQUEST';
export const FETCH_SHOW_PROFILE_SUCCESS = 'FETCH_SHOW_PROFILE_SUCCESS';
export const FETCH_SHOW_PROFILE_FAILURE = 'FETCH_SHOW_PROFILE_FAILURE';

export const FETCH_PUBLISHED_OFFER_REQUEST = 'FETCH_PUBLISHED_OFFER_REQUEST';
export const FETCH_PUBLISHED_OFFER_SUCCESS = 'FETCH_PUBLISHED_OFFER_SUCCESS';
export const FETCH_PUBLISHED_OFFER_FAILURE = 'FETCH_PUBLISHED_OFFER_FAILURE';

export interface ConsumeCredit {
    message : string;
    status  : boolean;
}

export interface CreditsUser {
    total_creditos_oferta   : number;
    total_creditos_perfil   : number;
    total_creditos_oferta_usados    : number;
    total_creditos_perfil_usados    : number;
}

export interface ShowProfile {
    status      : boolean;
    profileId   : number | null;
}

export interface PublishedOffer {
    status      : boolean;
    offerId     : number | null;
}

interface FetchPublishedOfferRequestAction {
    type: typeof FETCH_PUBLISHED_OFFER_REQUEST;
}

interface FetchPublishedOfferSuccessAction {
    type: typeof FETCH_PUBLISHED_OFFER_SUCCESS;
    payload: PublishedOffer
}
  
interface FetchPublishedOfferFailureAction {
    type: typeof FETCH_PUBLISHED_OFFER_FAILURE;
    payload: string;
}

interface FetchShowProfileRequestAction {
    type: typeof FETCH_SHOW_PROFILE_REQUEST;
}

interface FetchShowProfileSuccessAction {
    type: typeof FETCH_SHOW_PROFILE_SUCCESS;
    payload: ShowProfile
}
  
interface FetchShowProfileFailureAction {
    type: typeof FETCH_SHOW_PROFILE_FAILURE;
    payload: string;
}

interface FetchConsumeCreditRequestAction {
    type: typeof FETCH_CONSUME_CREDIT_REQUEST;
}
  
interface FetchConsumeCreditSuccessAction {
    type: typeof FETCH_CONSUME_CREDIT_SUCCESS;
    payload: ConsumeCredit
}
  
interface FetchConsumeCreditFailureAction {
    type: typeof FETCH_CONSUME_CREDIT_FAILURE;
    payload: string;
}

interface FetchCreditsUserRequestAction {
    type: typeof FETCH_CREDITS_USER_REQUEST;
}
  
interface FetchCreditsUserSuccessAction {
    type: typeof FETCH_CREDITS_USER_SUCCESS;
    payload: CreditsUser
}
  
interface FetchCreditsUserFailureAction {
    type: typeof FETCH_CREDITS_USER_FAILURE;
    payload: string;
}

export type PaquetesUsuariosActionTypes =
    | FetchConsumeCreditRequestAction
    | FetchConsumeCreditSuccessAction
    | FetchConsumeCreditFailureAction
    | FetchCreditsUserRequestAction
    | FetchCreditsUserSuccessAction
    | FetchCreditsUserFailureAction
    | FetchShowProfileRequestAction
    | FetchShowProfileSuccessAction
    | FetchShowProfileFailureAction
    | FetchPublishedOfferRequestAction
    | FetchPublishedOfferSuccessAction
    | FetchPublishedOfferFailureAction;