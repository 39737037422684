import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  SEARCH_COMPANY_REQUEST,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_FAILURE,
  CompanyActionTypes
} from "../../../../constants/company/SearchCompany";
import FetchWithIP from "../../utils/FetchHeaders";

// Acciones para cambiar el estado de búsqueda
export const fetchCompanyRequestReducer = (): CompanyActionTypes => ({
  type: SEARCH_COMPANY_REQUEST,
});

export const fetchCompanySuccessReducer = (data: any[], meta: any): CompanyActionTypes => ({
  type: SEARCH_COMPANY_SUCCESS,
  payload: { 
    data,
    meta
  },
});

export const fetchCompanyFailureReducer = (error: string): CompanyActionTypes => ({
  type: SEARCH_COMPANY_FAILURE,
  payload: error,
});

// Thunk Action para buscar empresas por nombre y sector
export const searchCompanyAction = (
  companyName: string,
  sector?: string, // Añadir el campo sector
  page: number = 1,
  limit: number = 10
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    
    dispatch(fetchCompanyRequestReducer());

    try {
      // Construir la URL con los parámetros necesarios
      const queryParams = new URLSearchParams({
        empresa: companyName,
        page: page.toString(),
        limit: limit.toString(),
        sortOrder: 'desc',
        sortColumn: 'createdAt',
      });

      if (sector) queryParams.append('sector', sector); // Añadir el sector a la consulta si está presente

      const response = await FetchWithIP(
        `empresas/mostrar?${queryParams.toString()}`,
        { method: 'POST' } // Cambia a GET si el backend lo prefiere
      );

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      if (Array.isArray(responseData.data) && responseData.data.length > 0) {
        dispatch(fetchCompanySuccessReducer(responseData.data, responseData.meta));
      } else {
        // Si no hay resultados, enviamos un array vacío
        dispatch(fetchCompanySuccessReducer([], { total: 0 }));
      }

    } catch (error: any) {
      console.error('Error al mostrar las empresas:', error.message || error);
      dispatch(fetchCompanyFailureReducer('Error al mostrar las empresas'));
    }
  };
