import {
	FETCH_CONSUME_CREDIT_REQUEST,
	FETCH_CONSUME_CREDIT_SUCCESS,
	FETCH_CONSUME_CREDIT_FAILURE,
	FETCH_CREDITS_USER_REQUEST,
	FETCH_CREDITS_USER_SUCCESS,
	FETCH_CREDITS_USER_FAILURE,
	FETCH_SHOW_PROFILE_REQUEST,
	FETCH_SHOW_PROFILE_SUCCESS,
	FETCH_SHOW_PROFILE_FAILURE,
	FETCH_PUBLISHED_OFFER_REQUEST,
	FETCH_PUBLISHED_OFFER_SUCCESS,
	FETCH_PUBLISHED_OFFER_FAILURE,
	ConsumeCredit,
	PaquetesUsuariosActionTypes,
	CreditsUser,
	ShowProfile,
	PublishedOffer,
} from "../../../../constants/common/paquetesUsuarios/PaquetesUsuarios";

interface PaquetesUsuariosState {
	rex_loading: boolean;
	rex_consume_credit: ConsumeCredit;
	rex_error: string | null;
	rex_credits_user : CreditsUser;
	rex_show_profile : ShowProfile;
	rex_published_offer : PublishedOffer;
}

const initialState: PaquetesUsuariosState = {
	rex_loading: false,
	rex_consume_credit: {
		message: '',
		status: true
	},
	rex_credits_user: {    
		total_creditos_oferta : 0,
		total_creditos_perfil : 0,
		total_creditos_oferta_usados : 0,
		total_creditos_perfil_usados : 0
	},
	rex_show_profile : {
		status : false,
		profileId : null
	},
	rex_published_offer : {
		status : false,
		offerId : null
	},
	rex_error: null,
};

const PaquetesUsuariosReducer = (state = initialState, action: PaquetesUsuariosActionTypes): PaquetesUsuariosState => {
	switch (action.type) {
		case FETCH_CONSUME_CREDIT_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_CONSUME_CREDIT_SUCCESS:
			return {
				...state,
				rex_loading: false,
				rex_consume_credit: action.payload,
				rex_error: null,
			};
		case FETCH_CONSUME_CREDIT_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload,
			};
		case FETCH_CREDITS_USER_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_CREDITS_USER_SUCCESS:
			return {
				...state,
				rex_loading: false,
				rex_credits_user: action.payload,
				rex_error: null,
			};
		case FETCH_CREDITS_USER_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload,
			};		
		case FETCH_SHOW_PROFILE_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_SHOW_PROFILE_SUCCESS:
			return {
				...state,
				rex_loading		: false,
				rex_show_profile: action.payload,
				rex_error		: null,
			};
		case FETCH_SHOW_PROFILE_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload,
			};
		case FETCH_PUBLISHED_OFFER_REQUEST:
			return {
				...state,
				rex_loading: true,
			};
		case FETCH_PUBLISHED_OFFER_SUCCESS:
			return {
				...state,
				rex_loading		: false,
				rex_published_offer: action.payload,
				rex_error		: null,
			};
		case FETCH_PUBLISHED_OFFER_FAILURE:
			return {
				...state,
				rex_loading: false,
				rex_error: action.payload,
			};
		default:
			return state;
	}
};

export default PaquetesUsuariosReducer;


