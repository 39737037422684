export const FETCH_CHECKOUT_REQUEST = 'FETCH_CHECKOUT_REQUEST';
export const FETCH_CHECKOUT_SUCCESS = 'FETCH_CHECKOUT_SUCCESS';
export const FETCH_CHECKOUT_FAILURE = 'FETCH_CHECKOUT_FAILURE';
export const CHECKOUT_PACK_SELECTED = 'CHECKOUT_PACK_SELECTED'

export const CHECKOUT_SESSION_ID = 'CHECKOUT_SESSION_ID'

export interface Checkout {
  user: {};
  pack: {}
}

interface FetchCheckoutRequestAction {
  type: typeof FETCH_CHECKOUT_REQUEST;
}

interface CheckoutSessionIdAction {
  type: typeof CHECKOUT_SESSION_ID;
  payload: string;
}

interface FetchCheckoutSuccessAction {
  type: typeof FETCH_CHECKOUT_SUCCESS;
  payload: Checkout;
}
interface CheckoutPackSelectedAction {
  type: typeof CHECKOUT_PACK_SELECTED;
  payload: {} | null;
}

interface FetchCheckoutFailureAction {
  type: typeof FETCH_CHECKOUT_FAILURE;
  payload: string;
}

export type CheckoutActionTypes =
  | FetchCheckoutRequestAction
  | FetchCheckoutSuccessAction
  | FetchCheckoutFailureAction
  | CheckoutSessionIdAction
  | CheckoutPackSelectedAction;