import React, { useState, useEffect } from "react";
import { Modal, Button, Col, Row } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalSaved from '../../myPortal/Modals/ModalSavedChanges';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { UpdateProfileCompanyReducer } from "../../../../redux/actions/pages/myPortal/profile/ProfileCompany";
import SelectBasic from "../../offers/CreateOffers/SelectBasic";
import Select from "../../../../components/pages/offers/CreateOffers/Select";
import { GetPaisesReducer } from "../../../../redux/actions/pages/emplotment/searchCountry";
import { GetSectorsReducer } from "../../../../redux/actions/common/sectors/Sectors";

interface ModalEditProfileProps {
  visible: boolean;
  onClose: () => void;
  ID_empresa: string;
}

const ModalEditProfileCompany: React.FC<ModalEditProfileProps> = ({
  visible,
  onClose,
  ID_empresa
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_user } = useSelector(({ auth }: any) => auth);
  const { rex_loading, rex_error } = useSelector(({ profileCompany }: any) => profileCompany);
  const { rex_sectors } = useSelector(({ sectors }: any) => sectors);
  const paises = useSelector((state: RootState) => state.paises.rex_paises);
  const loading = useSelector((state: RootState) => state.paises.rex_loading);

  // Initialize form data with company information
  const [formData, setFormData] = useState({
    empresa: rex_user?.empresa?.empresa || "",
    pagina_web: rex_user?.empresa?.pagina_web || "",
    sede_fiscal: rex_user?.empresa?.sede_fiscal || "",
    tamanio: rex_user?.empresa?.tamanio || "",
    ubicacion: rex_user?.empresa?.ubicacion || "",
    ciudad: rex_user?.empresa?.ciudad || "",
    sector_id: rex_user?.empresa?.sectores?.id || "", 
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);

  const [isAddingNewCountry, setIsAddingNewCountry] = useState(false); // Estado para manejar la opción de agregar nuevo país
  const [newCountry, setNewCountry] = useState(""); // Estado para el nuevo país

  // New states for country/city
  const [selectedCountry, setSelectedCountry] = useState<string | null>("");
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [searchCiudad, setSearchCiudad] = useState<string | null>("");

  const [selectedSector, setSelectedSector] = useState<any | null>({
    value: rex_user?.empresa?.sectores?.id || "", // Asegúrate de que el valor sea el ID del sector
    label: rex_user?.empresa?.sectores?.sector || "",
  });


  // Field configuration for company profile
  const fieldConfigs = [
    { name: 'empresa', label: 'Nombre de la Empresa', type: 'text' },
    { name: 'pagina_web', label: 'Página Web', type: 'text' },
    { name: 'sede_fiscal', label: 'Sede Fiscal', type: 'text' },
    { name: 'tamanio', label: 'Tamaño de la Empresa', type: 'number' },
    // { name: 'descripcion', label: 'Descripción', type: 'text' },
    // { name: 'ubicacion', label: 'Ubicación', type: 'text' },
  ];

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

 
  useEffect(() => {
    if (rex_user?.empresa?.sectores) {
      const sector = rex_user.empresa.sectores[0]; // Asegúrate de que 'sectores' es un arreglo
      if (sector) {
        setSelectedSector({
          value: sector.id,  // ID del sector
          label: sector.sector,  // Nombre del sector
        });
      }
    }
  }, [rex_user]);// Este useEffect depende de los cambios en rex_user

 useEffect(() => {
    // Fetch countries when component mounts
    dispatch(GetPaisesReducer());
    dispatch(GetSectorsReducer());
  }, []);

  useEffect(() => {
    if (selectedCountry && paises.length > 0) {
      const country = paises.find((c: any) => c.pais === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, paises]);

  // Validate form data
  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every(value =>
      value !== "" && value !== undefined && value !== null
    );
    setIsSubmitDisabled(!allFieldsFilled);
  }, [formData]);

  // Handle form submission
  const handleSaveChanges = async () => {
    // if (!isSubmitDisabled) {
    const rpta = await dispatch(UpdateProfileCompanyReducer(formData, ID_empresa));

    console.log("rpta: ----");
    console.log(rpta);

    if (!rex_error) {
      onClose();
      setIsSecondModalVisible(true);
    }
    // }
  };

  const handleSectorChange = (value: any) => {
    const sectorId = value?.value ? Number(value?.value) : "";  // Convertir a número
    setSelectedSector(value);  // Actualiza el sector
    setFormData(prev => ({
      ...prev,
      sector_id: sectorId,  // Guarda el sector_id como un número
    }));
  };



  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <div className="text-center mx-[86px] mb-[36px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar Información de la Empresa
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, actualiza la información de tu empresa
          </p>
        </div>

        <Row gutter={[16, 16]}>
          {fieldConfigs.map((field, index) => (
            <Col
              xxl={index == 4 ? 24 : 12}
              xl={index == 4 ? 24 : 12}
              md={index == 4 ? 24 : 12}
              key={field.name}
            >
              <div >
                <span className="text-body-md font-medium text-[#5F5F5F]">
                  {field.label}
                </span>
                <InputC
                  type={field.type}
                  placeholder={`Ingrese ${field.label.toLowerCase()}`}
                  value={formData[field.name as keyof typeof formData]}
                  onChange={(e) => handleInputChange(e, field.name)}
                  style={{
                    // marginBottom: "23px",
                    borderRadius: "12px",
                    height: "36px",
                    // marginTop: "10px",
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>

        <div className=" mb-[32px] mt-[20px]">
          <Row gutter={[16, 16]} className=" ">
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
            <p className="text-body-md mb-[2px] font-medium text-[#5F5F5F]">País</p>
              <SelectBasic
                placeholder="País"
                style={{ width: "100%", height: '44px', border: "1px solid #E1E1E2",
                  borderRadius: "8px" }}
                value={formData.ubicacion}
                onChange={(value) => {
                  console.log(value);

                  // setFormData({ ...formData, pais_id: val.id });
                  setFormData(prev => ({
                    ...prev,
                    ["ubicacion"]: value,
                  }));
                  setIsAddingNewCountry(false);
                  setSelectedCountry(value)
                }}
                loading={loading}
                options={paises.map((country: any) => ({
                  value: country.pais,
                  label: country.pais,
                  id: country.id
                }))}
                showSearch={false}
              />
            </Col>
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
            <p className="text-body-md mb-[2px] font-medium text-[#5F5F5F]">Ciudad</p>
              <SelectBasic
                placeholder="Ciudad"
                style={{ width: "100%", height: '44px', border: "1px solid #E1E1E2",
                  borderRadius: "8px" }}
                value={formData.ciudad}
                disabled={!selectedCountry}
                options={availableCities.map((city: any) => ({
                  value: city.ciudad,
                  label: city.ciudad
                }))}
                onChange={(citie) => {
                  setFormData(prev => ({
                    ...prev,
                    ["ciudad"]: citie,
                  }));
                  setSearchCiudad(citie)
                }}
                showSearch={false}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="mt-5">
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
            <h1 className="text-body-md font-medium text-[#5F5F5F]">Sector</h1>
              <Select
                showSearch
                placeholder="Seleccionar Sector"
                size="large"
                value={selectedSector}  // Usamos el estado selectedSector que contiene { value, label }
                onChange={handleSectorChange}  // Actualiza el sector
                fetchOptions={async (search) => {
                  const response = await dispatch(GetSectorsReducer(search, 1, 5));  // Cambia esto si necesitas más parámetros
                  return response.payload.data.map((sector: any) => ({
                    label: sector.sector,
                    value: sector.id.toString(),  // Asegúrate de que el "value" sea el ID
                  }));
                }}
                style={{ width: "100%", height: '44px', border: "1px solid #E1E1E2",
                  borderRadius: "8px" }}
              />
            </Col>
          </Row>


          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              // disabled={rex_loading || isSubmitDisabled}
              disabled={rex_loading}
              onClick={handleSaveChanges}
              className={`
                w-[181px] h-[44px] ml-[18px] rounded-[4px] 
                ${rex_loading
                  ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed'
                  : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'
                }
              `}
            >
              {rex_loading ? 'Guardando...' : 'Guardar cambios'}
            </Button>
          </div>
        </div>
      </Modal>

      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />
    </>
  );
};

export default ModalEditProfileCompany;