import {
	FETCH_SAVED_SEARCHES_REQUEST,
	FETCH_SAVED_SEARCHES_SUCCESS,
	FETCH_SAVED_SEARCHES_FAILURE,
  SAVE_SAVED_SEARCHES_REQUEST,
  SAVE_SAVED_SEARCHES_SUCCESS,
  SAVE_SAVED_SEARCHES_FAILURE,
	savedSearchesDto,
	SavedSearchesActionTypes
} from "../../../../constants/common/savedSearches/SavedSearches";

interface SavedSearchesState {
	rex_loading: boolean;
	rex_saved_searches: savedSearchesDto[];
	rex_error: string | null;
}

const initialState: SavedSearchesState = {
	rex_loading: false,
	rex_saved_searches: [],
	rex_error: null,
}

const SavedSearchesReducer = (state = initialState, action: SavedSearchesActionTypes): SavedSearchesState => {
  switch (action.type) {
    case FETCH_SAVED_SEARCHES_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case FETCH_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_saved_searches: action.payload.data,
        rex_error: null,
      };
    case FETCH_SAVED_SEARCHES_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };
    case SAVE_SAVED_SEARCHES_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case SAVE_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_error: null,
      };
    case SAVE_SAVED_SEARCHES_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };
    default:
      return state;
  }
};

export default SavedSearchesReducer;
