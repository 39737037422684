import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import { 
    FETCH_USER_SEARCHES_REQUEST,
    FETCH_USER_SEARCHES_SUCCESS,
    FETCH_USER_SEARCHES_FAILURE,
    UserSearchesActionTypes,
    userSearchesDto 
} from '../../../../constants/common/userSearches/UserSearches';

import FetchWithIP from '../../utils/FetchHeaders';

export const fetchUserSearchesRequestReducer = (): UserSearchesActionTypes => ({
  type: FETCH_USER_SEARCHES_REQUEST,
});

export const fetchUserSearchesSuccessReducer = (data: userSearchesDto[]): UserSearchesActionTypes => ({
  type: FETCH_USER_SEARCHES_SUCCESS,
  payload: { data },
});

export const fetchUserSearchesFailureReducer = (error: string): UserSearchesActionTypes => ({
  type: FETCH_USER_SEARCHES_FAILURE,
  payload: error,
});

export const GetUserSearches = (): ThunkAction<Promise<any>, RootState, unknown, UserSearchesActionTypes> => async (dispatch) => {

    dispatch(fetchUserSearchesRequestReducer());
  
    try {
      const respuesta = await FetchWithIP('busquedas-usuarios', {
        method: 'GET'
      }).then(res => res.json())
  
      if (respuesta.data) {
        console.log("respuesta.data")
        console.log(respuesta.data)
        dispatch(fetchUserSearchesSuccessReducer(respuesta.data));
      } else {
        throw new Error('Los datos obtenidos no son un array');
      }
  
    } catch (error) {
      dispatch(fetchUserSearchesFailureReducer('Error al mostrar datos'));
      return { payload: error };
    }
  };