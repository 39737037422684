import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputL from "../../login/InputL"; // Asegúrate de importar tu InputL aquí
import RedIconX from '../../../../assets/icons/RedIconX.svg';
import GreenIconCheck from '../../../../assets/icons/GreenIconCheck.svg';
import { Button } from "antd";

interface EditGeneralSettingProps {
  email: string;
  onEdit: (values: { email: string; contrasenaActual: string; contrasenaNueva: string }) => Promise<void>;
}

const EditGeneralSetting: React.FC<EditGeneralSettingProps> = ({ email, onEdit }) => {


  const [password, setPassword] = useState(''); // Iniciar vacío para permitir nueva contraseña
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  useEffect(() => {
    setIsLengthValid(password.length >= 8);
    setHasSymbol(/[^A-Za-z0-9]/.test(password));
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
  }, [password]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Correo electrónico inválido")
      .required("Por favor ingresa tu correo electrónico"),
    contrasenaActual: Yup.string()
      .required("Por favor ingresa tu contraseña actual"),
    contrasenaNueva: Yup.string()
      .required("Por favor crea una nueva contraseña")
      .min(8, "La contraseña debe tener al menos 8 caracteres"),
  });

  return (
    <Formik
      initialValues={{ email, contrasenaActual: '', contrasenaNueva: '' }} // Inicializar los campos
      onSubmit={onEdit}
      validationSchema={validationSchema}
    >
      {({ values, handleChange }) => {


        return (
          <Form>
            <h1 className="text-[22px] font-sans font-bold pb-[24px]">Editar Información</h1>
            <div className="mb-4">
              <label className="text-[18px] font-medium text-green22">Email:</label>
              <Field name="email" as={InputL} placeholder="Email" />
              <ErrorMessage name="email" component="div" className="text-red-500" />
            </div>
            <div className="mb-4">
              <label className="text-[18px] font-medium text-green22">Contraseña Actual:</label>
              <Field
                name="contrasenaActual"
                as={InputL}
                isPassword
                placeholder="Contraseña Actual"
              />
              <ErrorMessage name="contrasenaActual" component="div" className="text-red-500" />
            </div>
            <div className="mb-4">
              <label className="text-[18px] font-medium text-green22">Nueva Contraseña:</label>
              <Field
                name="contrasenaNueva"
                as={InputL}
                isPassword
                placeholder="Nueva Contraseña"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                  handleChange(e);
                }}
              />
              <ErrorMessage name="contrasenaNueva" component="div" className="text-red-500" />
            </div>

            {/* Validaciones de contraseña */}
            <div className="mt-2">
              <div className="flex items-center mb-2">
                <img src={isLengthValid ? GreenIconCheck : RedIconX} alt={isLengthValid ? "Valid" : "Invalid"} className="w-[20px] mr-[10px]" />
                <span className="font-sans" style={{ color: isLengthValid ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>8 caracteres</span>
              </div>
              <div className="flex items-center mb-2">
                <img src={hasUppercase ? GreenIconCheck : RedIconX} alt={hasUppercase ? "Valid" : "Invalid"} className="w-[20px] mr-[10px]" />
                <span className="font-sans" style={{ color: hasUppercase ? "#16A249" : "#B3261E",fontSize: "16px", fontWeight: "bold" }}>1 mayúscula</span>
              </div>
              <div className="flex items-center mb-2">
                <img src={hasLowercase ? GreenIconCheck : RedIconX} alt={hasLowercase ? "Valid" : "Invalid"} className="w-[20px] mr-[10px]" />
                <span className="font-sans" style={{ color: hasLowercase ? "#16A249" : "#B3261E",fontSize: "16px", fontWeight: "bold" }}>1 minúscula</span>
              </div>
              <div className="flex items-center mb-2">
                <img src={hasNumber ? GreenIconCheck : RedIconX} alt={hasNumber ? "Valid" : "Invalid"} className="w-[20px] mr-[10px]" />
                <span className="font-sans" style={{ color: hasNumber ? "#16A249" : "#B3261E",fontSize: "16px", fontWeight: "bold" }}>1 número</span>
              </div>
              <div className="flex items-center mb-2">
                <img src={hasSymbol ? GreenIconCheck : RedIconX} alt={hasSymbol ? "Valid" : "Invalid"} className="w-[20px] mr-[10px]" />
                <span className="font-sans" style={{ color: hasSymbol ? "#16A249" : "#B3261E",fontSize: "16px", fontWeight: "bold" }}>1 símbolo</span>
              </div>
            </div>
            <div className="progress-bar-container my-[20px] mt-8 flex">
                      <div
                        className={`progress-bar h-[8px] rounded-full ${isLengthValid || hasUppercase || hasLowercase || hasNumber || hasSymbol
                          ? "bg-[#006497] w-[50px]"
                          : "bg-[#E1E1E1] w-[50px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${(isLengthValid && (hasUppercase || hasLowercase || hasNumber || hasSymbol)) ||
                          (hasUppercase && (hasLowercase || hasNumber || hasSymbol)) ||
                          (hasLowercase && (hasNumber || hasSymbol)) ||
                          (hasNumber && hasSymbol)
                          ? "bg-[#006497] w-[50px]"
                          : "bg-[#E1E1E1] w-[50px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${((isLengthValid && hasUppercase && (hasLowercase || hasNumber || hasSymbol)) ||
                          (hasUppercase && hasLowercase && (hasNumber || hasSymbol)) ||
                          (hasLowercase && hasNumber && hasSymbol))
                          ? "bg-[#006497] w-[50px]"
                          : "bg-[#E1E1E1] w-[50px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${((isLengthValid && hasUppercase && hasLowercase && (hasNumber || hasSymbol)) ||
                          (hasUppercase && hasLowercase && hasNumber && hasSymbol))
                          ? "bg-[#006497] w-[50px]"
                          : "bg-[#E1E1E1] w-[50px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${(isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSymbol)
                          ? "bg-[#006497] w-[50px]"
                          : "bg-[#E1E1E1] w-[50px]"
                          }`}
                      />
                    </div>
            <Button
              className="bg-blue3 ml-0 mb-10 text-white w-[278px] h-[44px] principal-nav-notify-button2 rounded-[4px]"
              htmlType="submit"
            >
              Guardar Cambios
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditGeneralSetting;
