import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/store';
import { Col, Modal, Row } from 'antd';
import IconClosed from '../../../../assets/icons/IconClosed.svg';
import {
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	Radar,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';

interface SoftSkillsModalProps {
	visible: boolean;
	onClose: () => void;
}

const ModalSoftSkills: React.FC<SoftSkillsModalProps> = ({
	visible,
	onClose,
}) => {

	const {
		soft_skills_offer,
		labels_graph_sk
	} = useSelector((state: RootState) => state.getOffersProfile);


	return (
		<Modal
			open={visible}
			onCancel={onClose}
			closable={false}
			footer={null}
			width={1000}
		>
			<img
				src={IconClosed}
				alt="Cerrar"
				onClick={onClose}
				style={{
					position: 'absolute',
					top: '35px',
					right: '40px',
					cursor: 'pointer',
					width: '24px',
					height: '24px'
				}}
			/>
			<div className='pb-14 pt-9'>
				<Row>
					<Col span={24} className='flex justify-center mb-3'>
						<div className='text-2xl font-bold font-sans'>Candidatos seleccionados sobre gráfica</div>
					</Col>
					<Col span={24} className='flex justify-center'>
						<div className='text-base font-sans'>Te presentamos la visualización de los candidatos que elegiste:</div>
					</Col>
				</Row>
				<Row className='my-14'>
					<Col xs={24} md={16} className='flex justify-end'>
						<ResponsiveContainer
							width="80%"
							height={300}
						>
							<RadarChart cx="50%" cy="50%" outerRadius="80%" data={soft_skills_offer.data}>
								<PolarGrid />
								<PolarAngleAxis
									dataKey="subject"
									tick={{ fontSize: 12, fill: '#000', fontWeight: 'bold' }}
								/>
								<PolarRadiusAxis angle={30} tickCount={11} domain={[0, 10]} />
								<Radar name="Perfil del Oferta" dataKey="A" stroke="#FDBCB4" fill="transparent" />
								{
									soft_skills_offer.users.map((user, index) => {
										return <Radar key={index} name={user.nombre} dataKey={user.nameKey} stroke={labels_graph_sk[index]['color']} fill="transparent" />
									})
								}
								<Tooltip />
							</RadarChart>
						</ResponsiveContainer>
					</Col>
					<Col xs={24} md={6} className=''>
						<Row className='mt-2'>
							<Col span={24} className='h-auto justify-end' style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
								<span className='font-sans' style={{ color: '#1A1A1A', fontSize: 12, marginRight: '6px', fontWeight: '700', opacity: 0.7 }}>Perfil de la oferta</span>
								<div style={{ width: 12, height: 12, backgroundColor: '#FDBCB4', marginRight: '8px' }}></div>
							</Col>
							{
								soft_skills_offer.users.map((user, index) => {
									return <Col span={24} key={index} className='h-auto justify-end' style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
										<span className='font-sans' style={{ color: '#1A1A1A', fontSize: 12, marginRight: '6px', fontWeight: '700', opacity: 0.7 }}>{user.nombre}</span>
										<div style={{ width: 12, height: 12, backgroundColor: labels_graph_sk[index]['color'], marginRight: '8px' }}></div>
									</Col>
								})
							}
						</Row>
					</Col>
				</Row>
			</div>
		</Modal>
	)
}

export default ModalSoftSkills