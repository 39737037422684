import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import IconClosed from "../../../../../assets/icons/IconClosed.svg";
import { useDispatch } from "../../../../../redux/store/hooks";
import { AppDispatch } from "../../../../../redux/store/store";
import { SignUpOfferReducer } from "../../../../../redux/actions/offers/SignUpOffer";
import { useNavigate } from "react-router-dom";
import '../../../../../styles/pages/employment/recommendations/ModalSuccessfulRegistration.css'

interface ModalRegistrationProps {
  visible: boolean;
  onClose: () => void;
  offer_id: number;
  cv_usuario_id: number | null;
  portafolioUsuarioIds: number[] | null;
  dataForm: any;
}

const ModalRegistration: React.FC<ModalRegistrationProps> = ({ 
  visible, 
  onClose,
  offer_id,
  cv_usuario_id,
  portafolioUsuarioIds,
  dataForm
 }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);  // Para gestionar el estado de carga

  const handleAcceptClick = async () => {
    setLoading(true);
    onClose(); // Cierra el modal
    setIsSecondModalVisible(true); // Muestra el segundo modal de carga
  
    // Asegúrate de que portafolioUsuarioIds no sea null
    const validPortafolioIds = portafolioUsuarioIds ?? []; // Si es null, lo convierte en un array vacío
  
    try {
      // Realiza la acción de inscripción, pasando valores opcionales
      await dispatch(SignUpOfferReducer(offer_id, cv_usuario_id, validPortafolioIds, dataForm));
      navigate('/myApplications/applications');
    } catch (error) {
      message.error("Ocurrió un error al intentar registrarte. Por favor, intenta nuevamente.");
    } finally {
      setLoading(false); // Detiene el estado de carga
    }
  };
  


  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
      >
        <img
          className="success-registration-image"
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mb-[36px] mt-[20px]">
          <h3 className="mt-[16px] text-heading-md font-bold">
            ¡Te has inscrito con éxito!
          </h3>

          <div className="flex justify-center mx-[114px] mt-[36px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleAcceptClick}
              className="bg-blue3 text-white w-[110px] h-[44px] ml-[18px] principal-nav-notify-button2 rounded-[4px]"
              loading={loading}  // Muestra un indicador de carga mientras se procesa
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalRegistration;
