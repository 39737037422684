import {
  FETCH_EMPLOYMENTS_REQUEST,
  FETCH_EMPLOYMENTS_SUCCESS,
  FETCH_EMPLOYMENTS_FAILURE,

  FETCH_GET_OFFER_REQUEST,
  FETCH_GET_OFFER_SUCCESS,
  FETCH_GET_OFFER_FAILURE,
  SET_FILTER_PARAMS_EMPLOYMENTS
} from "../../../constants/pages/Employment";

interface EmploymentState {
  rex_loading: boolean;
  rex_data: any[];
  rex_error: string | null;
  rex_meta: object | null;
  rex_limit: number;
  rex_page: number;
  rex_total: number;
  filterParams: any;
  rex_loading_offer: boolean;
  rex_data_offer: any[];
  rex_error_offer: string | null;
}

const initialState: EmploymentState = {
  rex_loading: false,
  rex_data: [],
  rex_error: null,
  rex_meta: null,
  rex_limit: 10,
  rex_page: 1,
  rex_total: 0,
  filterParams: {},
  rex_loading_offer: false,
  rex_data_offer:[],
  rex_error_offer: null

};

const GetOffers = (state = initialState, action: any): EmploymentState => {
  switch (action.type) {
    case FETCH_EMPLOYMENTS_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null,
      };
    case FETCH_EMPLOYMENTS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_data: action.payload.meta.page == 1 ? [...action.payload.data] : [...state.rex_data, ...action.payload.data],
        rex_meta: action.payload.meta,
        rex_total: action.payload.meta.total,
        rex_limit: action.payload.meta.limit,
        rex_page: action.payload.meta.page
      };
    case FETCH_EMPLOYMENTS_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.error?.message || "Error desconocido",
      };

    case FETCH_GET_OFFER_REQUEST:
      return {
        ...state,
        rex_loading_offer: true,
        rex_error_offer: null,
      };
    case FETCH_GET_OFFER_SUCCESS:
      return {
        ...state,
        rex_data_offer: action.payload || null, // Ensure rex_data_offer is not undefined
        rex_loading_offer: false
      };
    case FETCH_GET_OFFER_FAILURE:
      return {
        ...state,
        rex_loading_offer: false,
        rex_error_offer: action.error?.message || "Error desconocido",
      };
    case SET_FILTER_PARAMS_EMPLOYMENTS:       
        // Compara si el nuevo filtro es igual al actual
        console.log('Entre a Action filters')
        if (JSON.stringify(action.payload) === JSON.stringify(state.filterParams)) {
          return state; // Si son iguales, no haces cambios en el estado
        }
        
        // Si son diferentes, actualiza el estado
        return {
          ...state,
          filterParams: action.payload,
          rex_data: [] // Reinicia los datos si los filtros cambian
        };
    default:
      return state;
  }
};

export default GetOffers;
