import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Skeleton, Dropdown, Menu, Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetClosedOffersReducer } from "../../../redux/actions/pages/company/ClosedOffers";
import { deleteOfferReducer } from "../../../redux/actions/pages/company/DeleteOffers";
import { draftOfferReducer } from "../../../redux/actions/pages/company/DraftOffer";
import { RootState, AppDispatch } from "../../../redux/store/store";
import CardOffers from "../../../components/pages/offers/CardOffers";
import { useLocation } from 'react-router-dom';
import { searchClosedOffersAction } from "../../../redux/actions/pages/company/SearchFinishedOffers";
import IconClosed from "../../../assets/icons/IconClosed.svg";

interface FinishedOffersProps {
  searchInitiated: boolean;
}

const FinishedOffers: React.FC<FinishedOffersProps> = ({ searchInitiated }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const searchTxt = queryParams.get('cargo')

  const { rex_closedoffers: offers, rex_loading: loading, rex_error: error } = useSelector(
    (state: RootState) => state.closedOffers
  );

  const { closedOffers: searchResults, loading: searchLoading } = useSelector(
    (state: RootState) => state.searchFinishedOffers
  );

  const empresaId = 1;

  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState<string | null>(null);

  useEffect(() => {
    dispatch(searchClosedOffersAction(searchTxt ? searchTxt : '', 1, 10));
  }, [dispatch]);

  const showModal = (type: string, id: number) => {
    setSelectedOfferId(id);
    setModalType(type);
    setIsModalVisible(true); // Muestra el modal
  };

  const handleDelete = async () => {
    if (selectedOfferId !== null) {
      await dispatch(deleteOfferReducer(selectedOfferId));
      setIsDeleteModalVisible(false);
      console.log("ewlimina")
      dispatch(searchClosedOffersAction(searchTxt ? searchTxt : '', 1, 10)); // Actualiza la lista
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const showDeleteModal = (id: number) => {
    setSelectedOfferId(id);
    setIsDeleteModalVisible(true);
  };

  const getModalTitle = () => {
    switch (modalType) {
      case "edit":
        return "Confirmar edición";
      case "create-related":
        return "Confirmar creación de oferta relacionada";
      case "delete":
        return "Confirmar eliminación";
      case "draft":
        return "Confirmar mover a borrador";
      default:
        return "Confirmar acción";
    }
  };

  const getModalContent = () => {
    switch (modalType) {
      case "create-related":
        return "¿Estás seguro de que deseas crear una oferta relacionada?";
      case "delete":
        return "¿Estás seguro de que deseas eliminar esta oferta?";
      default:
        return "¿Estás seguro de que deseas realizar esta acción?";
    }
  };

  const handleModalOk = async () => {
    if (selectedOfferId !== null) {
      switch (modalType) {
        case "delete":
          await dispatch(deleteOfferReducer(selectedOfferId));
          break;
        case "create-related":
          console.log(`Crear oferta relacionada con ${selectedOfferId}`);
          break;
      }
      setIsModalVisible(false);
      dispatch(GetClosedOffersReducer(1, 10)); // Actualiza la lista después de realizar una acción
      dispatch(searchClosedOffersAction(searchTxt ? searchTxt : '', 1, 10));
    }
  };

  const handleMenuClick = (key: string, offerId: number) => {
    showModal(key, offerId);
  };

  const menu = (offerId: number) => (
    <Menu
      onClick={({ key }) => handleMenuClick(key, offerId)}
      items={[
        { label: "Editar oferta", key: "edit" },
        { label: "Crear oferta relacionada", key: "create-related" },
        { label: "Eliminar oferta", key: "delete" },
        { label: "Mover a borrador", key: "draft" }
      ]}
    />
  );

  const ofertasAMostrar = searchInitiated ? searchResults : offers;

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24}>
          <div className="pt-3">
            <h2 className="text-heading-md font-bold mb-4">Listado de ofertas cerradas</h2>
            {error && <p>Error al cargar las ofertas: {error}</p>}
            {loading ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : ofertasAMostrar?.length === 0 ? (
              <p>No hay ofertas cerradas disponibles.</p>
            ) : (
              ofertasAMostrar.map((offer: any, index: number) => (
                <div className="pb-[8px]" key={index} style={{ position: "relative" }}>
                  <CardOffers
                    id={offer.id}
                    title={offer.cargo}
                    subtitle={offer.descripcion}
                    location={`${offer.ubi_provincia}, ${offer.ubi_poblacion}`}
                    activeDate={offer.createdAt}
                    inscritos={offer.inscritos || 0}
                    seleccionados={offer.seleccionados || 0}
                    descartados={offer.descartados || 0}
                    loading={loading}
                    onClick={() => navigate(`/offer/${offer.id}`)}
                    onDelete={() => showDeleteModal(offer.id)}
                  />
                  {/* Dropdown Menu */}
                  <div style={{ position: "absolute", right: 20, top: 20 }}>
                    <Dropdown overlay={menu(offer.id)} trigger={["click"]}>
                      <Button
                        type="text"
                        style={{ padding: 0, color: "#006497", fontSize: "20px", fontWeight: "bold", lineHeight: "1" }}
                        icon={<MoreOutlined rotate={90} />}
                      />
                    </Dropdown>
                  </div>
                </div>
              ))
            )}
          </div>
        </Col>
      </Row>

      {/* Delete Modal */}
      {isDeleteModalVisible && (
        <Modal
          title="Confirmar eliminación"
          visible={isDeleteModalVisible}
          onOk={handleDelete}
          onCancel={handleCancelDelete}
          okText="Eliminar"
          cancelText="Cancelar"
        >
          <p>¿Estás seguro de que deseas eliminar esta oferta?</p>
        </Modal>
      )}

      {/* Generic Modal */}
      <Modal
        visible={isModalVisible}
        closable={false}
        footer={null}
        width={800}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={handleModalCancel}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16, 24]} className='text-center mt-7'>
          <Col span={24}>
            <p className="font-bold text-2xl font-sans">{getModalTitle()}</p>
          </Col>
          <Col span={24}>
            <p className="font-sans font-medium text-sm">{getModalContent()}</p>
          </Col>
        </Row>
        <Row className='flex justify-center mb-5'>
          <div className="flex justify-center mx-[114px] mt-[38px]">
            <Button
              onClick={handleModalCancel}
              className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleModalOk}
              className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
            >
              Aceptar
            </Button>
          </div>
        </Row>
      </Modal>
    </div>
  );
};

export default FinishedOffers;