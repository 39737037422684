// reducers/searchApplicationsReducer.ts
import {
    FETCH_SEARCH_APPLICATIONS_REQUEST,
    FETCH_SEARCH_APPLICATIONS_SUCCESS,
    FETCH_SEARCH_APPLICATIONS_FAILURE,
  } from '../../../constants/applications/SearchApllications';
  
  interface ApplicationState {
    rex_applications: any[];
    rex_meta: any;
    rex_loading: boolean;
    rex_error: string | null;
  }
  
  const initialState: ApplicationState = {
    rex_applications: [],
    rex_meta: {},
    rex_loading: false,
    rex_error: null,
  };
  
const searchApplicationsReducer = (state = initialState, action: any): ApplicationState => {
    switch (action.type) {
      case FETCH_SEARCH_APPLICATIONS_REQUEST:
        return {
          ...state,
          rex_loading: true,
          rex_error: null,
        };
      case FETCH_SEARCH_APPLICATIONS_SUCCESS:
        console.log('entre a los filtros de arriba')
        console.log(action.payload)
        return {
          ...state,
          rex_loading: false,
          rex_applications: action.payload.data,
          rex_meta: action.payload.meta,
        };
      case FETCH_SEARCH_APPLICATIONS_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_error: action.error,
        };
      default:
        return state;
    }
  };
  export default searchApplicationsReducer;
  