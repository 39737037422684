import {
  GET_USER_RATINGS_BY_TOKEN_REQUEST,
  GET_USER_RATINGS_BY_TOKEN_SUCCESS,
  GET_USER_RATINGS_BY_TOKEN_FAILURE,
  GET_USER_RATINGS_BY_ID_REQUEST,
  GET_USER_RATINGS_BY_ID_SUCCESS,
  GET_USER_RATINGS_BY_ID_FAILURE,
  GetUserRatingsByTokenActionTypes,
  GetUserRatingsByIdActionTypes,
  UserRatingByToken
} from '../../../../../constants/pages/myPortal/ratings/GetRatingPortal';

interface GetUserRatingsState {
  rex_loading: boolean;
  rex_userRatingsByToken: UserRatingByToken[] | null;
  rex_userRatingsById: UserRatingByToken[] | null;
  rex_error: string | null;
}

const initialState: GetUserRatingsState = {
  rex_loading: false,
  rex_userRatingsByToken: null,
  rex_userRatingsById: null,
  rex_error: null
};

type CombinedActionTypes = GetUserRatingsByTokenActionTypes | GetUserRatingsByIdActionTypes;

const getUserRatingsReducer = (
  state = initialState,
  action: CombinedActionTypes
): GetUserRatingsState => {
  switch (action.type) {
    case GET_USER_RATINGS_BY_TOKEN_REQUEST:
    case GET_USER_RATINGS_BY_ID_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };
    case GET_USER_RATINGS_BY_TOKEN_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_userRatingsByToken: action.payload,
        rex_error: null
      };
    case GET_USER_RATINGS_BY_ID_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_userRatingsById: action.payload,
        rex_error: null
      };
    case GET_USER_RATINGS_BY_TOKEN_FAILURE:
    case GET_USER_RATINGS_BY_ID_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
        rex_userRatingsByToken: null,
        rex_userRatingsById: null
      };
    default:
      return state;
  }
};

export default getUserRatingsReducer;
