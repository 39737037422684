import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store'; 

import { 
    FETCH_CIUDADES_REQUEST,
    FETCH_CIUDADES_SUCCESS,
    FETCH_CIUDADES_FAILURE,
    CiudadesActionTypes
} from '../../../../constants/pages/emplotment/searchCities';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCiudadesRequestReducer = (): CiudadesActionTypes => ({
  type: FETCH_CIUDADES_REQUEST,
});

export const fetchCiudadesSuccessReducer = (data: any[]): CiudadesActionTypes => ({
  type: FETCH_CIUDADES_SUCCESS,
  payload: {data}, // Almacena los datos recibidos de la API
});

export const fetchCiudadesFailureReducer = (error: string): CiudadesActionTypes => ({
  type: FETCH_CIUDADES_FAILURE,
  payload: error,
});

export const GetCiudadesReducer = (
): ThunkAction<Promise<any>, RootState, unknown, CiudadesActionTypes> => async (dispatch) => {
  dispatch(fetchCiudadesRequestReducer());
  
  try {
    const respuesta = await fetch(`${API_URL}ciudades`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    if (!respuesta.ok) {
      throw new Error(`Error HTTP: ${respuesta.status}`);
    }
    const data = await respuesta.json();
    if (Array.isArray(data.data)) {
      dispatch(fetchCiudadesSuccessReducer(data.data));  
      return { payload: { data:data.data } };
    } else {
      throw new Error('Los datos obtenidos no son un array');
    }

  } catch (error) {
    dispatch(fetchCiudadesFailureReducer('Error al mostrar datos paginados'));
    return { payload: [] };
  }
};