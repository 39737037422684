import React, { useState, useEffect } from "react";
import { Button, Row, Col, App } from "antd";
import InputL from "../../components/pages/login/InputL";
import "../../components/styles/pages/login/Login.css";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import LoginP from "../../assets/img/login/login2.svg";
import IconArrowL from "../../assets/icons/IconArrowL.svg";
import { CopyOutlined } from "@ant-design/icons";
import RedIconX from '../../assets/icons/RedIconX.svg';
import GreenIconCheck from '../../assets/icons/GreenIconCheck.svg';
import { ResetPasswordReducer, ValidateResetCodeReducer } from "../../redux/actions/auth/PasswordReset";

interface FormValues {
  codigo: string[];
  nueva_contrasena?: string; // Solo se usará para la segunda vista
}

// Validación del código de verificación
const verificationSchema = Yup.object({
  codigo: Yup.array()
    .of(Yup.string().required())
    .length(6, "El código debe tener 6 dígitos"),
});

// Validación de la nueva contraseña
const passwordSchema = Yup.object({
  nueva_contrasena: Yup.string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .required("Por favor, ingresa tu nueva contraseña"),
});

const Verification: React.FC = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { email } = location.state || {}; // Obtener correo desde el estado
  const [showPasswordForm, setShowPasswordForm] = useState(false); // Alternar entre vistas
  const [codigoVerificacion, setCodigoVerificacion] = useState<number | null>(null); // Guardar código válido
  const [password, setPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  useEffect(() => {
    setIsLengthValid(password.length >= 8);
    setHasSymbol(/[^A-Za-z0-9]/.test(password));
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
  }, [password]);

  // Manejo del input de código
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setFieldValue: Function,
    values: FormValues
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      setFieldValue(`codigo[${index}]`, value);
      const nextInput = index + 1;
      if (nextInput < 6) {
        const nextElement = document.getElementsByName(`codigo[${nextInput}]`)[0] as HTMLInputElement;
        if (nextElement) nextElement.focus();
      }
    } else if (value === "") {
      setFieldValue(`codigo[${index}]`, "");
    }
  };

  // Copiar código al portapapeles
  const handleCopy = (values: FormValues) => {
    const codigoCompleto = values.codigo.join("");
    navigator.clipboard.writeText(codigoCompleto);
    notification.success({
      message: "Código copiado",
      description: "El código ha sido copiado al portapapeles",
    });
  };

  // Validar el código de verificación
  const handleVerificationSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const codigoCompleto = parseInt(values.codigo.join(""), 10);
    try {
      const rpta: any = await dispatch(ValidateResetCodeReducer(codigoCompleto));
      if (rpta.respuesta) {
        notification.success({ message: "Código verificado correctamente" });
        setCodigoVerificacion(codigoCompleto); // Guardar código validado
        setShowPasswordForm(true); // Cambiar a vista de contraseña
      } else {
        notification.error({ message: "Código incorrecto", description: rpta.error || "Inténtalo de nuevo" });
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message || "Error al validar el código",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Cambiar la contraseña
  const handlePasswordSubmit = async (
    values: { nueva_contrasena: string },
    { setSubmitting }: FormikHelpers<{ nueva_contrasena: string }>
  ) => {
    try {
      const rpta: any = await dispatch(
        ResetPasswordReducer(codigoVerificacion!, values.nueva_contrasena)
      );
      if (rpta.respuesta) {
        notification.success({ message: "Contraseña actualizada correctamente" });
        navigate("/");
      } else {
        notification.error({
          message: "Error al actualizar la contraseña",
          description: rpta.error || "Inténtalo de nuevo",
        });
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message || "Error al actualizar la contraseña",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Row className="lo" style={{ height: "100%" }}>
        <Col
          xl={13}
          style={{
            backgroundColor: "#f0f2f5",
            backgroundImage: `url(${LoginP})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Col
          xl={11}
          className="p-[50px]"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <Row className="mt-4">
            <Col xl={12}>
              <h1 className="font-semibold text-green32 text-heading-x1">
                <Link className="flex items-center font-bold text-heading-x1" to="/">
                  <img src={IconArrowL} alt="Arrow Left" className="mr-[15px]" />
                  Volver
                </Link>
              </h1>
            </Col>
            <Col xs={24} md={12}>
              <h1 className="font-semibold sm:text-start text-gray-500 md:text-end text-heading-x1">
                ¿Ya tienes cuenta?{" "}
                <Link
                  className="font-bold text-heading-x1"
                  style={{ color: "#006497" }}
                  to="/login"
                >
                  Ingresar
                </Link>
              </h1>
            </Col>
          </Row>

          <div style={{ height: "100%", placeSelf: "center" }}>
            {showPasswordForm ? (
              <Formik
                initialValues={{ nueva_contrasena: "" }}
                validationSchema={passwordSchema}
                onSubmit={handlePasswordSubmit}
              >
                {({ isSubmitting, handleChange }) => (
                  <Form className="login-form max-w-[454px] p-4 mt-20">
                    <h1 className="text-heading-md font-bold mb-7 text-center">Nueva Contraseña</h1>
                    <h1 className="font-medium text-body-md mb-2 text-center">
                    Por favor ingresa una nueva contraseña para su cuenta:
                  </h1>
                    <div className="mb-5">
                    <Field
                      name="nueva_contrasena"
                      as={InputL}
                      type="password"
                      isPassword
                      placeholder="Ingresa tu nueva contraseña"
                      className="rounded-[4px]"
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(e.target.value);
                        handleChange(e);
                      }}
                    />
                    </div>
                    <div className="mb-4">
                    <div className="flex items-center mb-2">
                      <img
                        src={isLengthValid ? GreenIconCheck : RedIconX}
                        alt={isLengthValid ? "Valid" : "Invalid"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: isLengthValid ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold"}}>
                        8 caracteres
                      </span>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        src={hasUppercase ? GreenIconCheck : RedIconX}
                        alt={hasUppercase ? "Valid" : "Invalid"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasUppercase ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 mayúscula
                      </span>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        src={hasLowercase ? GreenIconCheck : RedIconX}
                        alt={hasLowercase ? "Valid" : "Invalid"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasLowercase ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 minúscula
                      </span>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        src={hasNumber ? GreenIconCheck : RedIconX}
                        alt={hasNumber ? "Valid" : "Invalid"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasNumber ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 número
                      </span>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={hasSymbol ? GreenIconCheck : RedIconX}
                        alt={hasSymbol ? "Valid" : "Invalid"}
                        className="w-[20px] mr-[10px]"
                      />
                      <span style={{ color: hasSymbol ? "#16A249" : "#B3261E", fontSize: "16px", fontWeight: "bold" }}>
                        1 símbolo
                      </span>
                    </div>
                  </div>
                   {/* Barra de progreso */}
                   <div className="progress-bar-container my-[20px] mt-8 flex">
                      <div
                        className={`progress-bar h-[8px] rounded-full ${isLengthValid || hasUppercase || hasLowercase || hasNumber || hasSymbol
                          ? "bg-[#006497] w-[100px]"
                          : "bg-[#E1E1E1] w-[100px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${(isLengthValid && (hasUppercase || hasLowercase || hasNumber || hasSymbol)) ||
                          (hasUppercase && (hasLowercase || hasNumber || hasSymbol)) ||
                          (hasLowercase && (hasNumber || hasSymbol)) ||
                          (hasNumber && hasSymbol)
                          ? "bg-[#006497] w-[100px]"
                          : "bg-[#E1E1E1] w-[100px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${((isLengthValid && hasUppercase && (hasLowercase || hasNumber || hasSymbol)) ||
                          (hasUppercase && hasLowercase && (hasNumber || hasSymbol)) ||
                          (hasLowercase && hasNumber && hasSymbol))
                          ? "bg-[#006497] w-[100px]"
                          : "bg-[#E1E1E1] w-[100px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${((isLengthValid && hasUppercase && hasLowercase && (hasNumber || hasSymbol)) ||
                          (hasUppercase && hasLowercase && hasNumber && hasSymbol))
                          ? "bg-[#006497] w-[100px]"
                          : "bg-[#E1E1E1] w-[100px]"
                          }`}
                      />
                      <div
                        className={`progress-bar h-[8px] rounded-full ml-[10px] ${(isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSymbol)
                          ? "bg-[#006497] w-[100px]"
                          : "bg-[#E1E1E1] w-[100px]"
                          }`}
                      />
                    </div>
                    <div className="flex justify-center mt-[20px]">
                      <Button
                        htmlType="submit"
                        disabled={isSubmitting}
                        className="bg-blue3 text-white principal-nav-notify-button2 w-[278px] h-[44px] rounded-[4px]"
                      >
                        {isSubmitting ? "Guardando..." : "Guardar Contraseña"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{ codigo: Array(6).fill("") }}
                validationSchema={verificationSchema}
                onSubmit={handleVerificationSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form className="login-form max-w-[454px] p-4 mt-20">
                    <h1 className="text-heading-md font-bold mb-7 text-center">Verifica tu correo</h1>
                    <h1 className="font-medium text-body-md mb-2 text-center">
                    Se ha enviado un código de verificación de 6 dígitos a tu correo electrónico (email), copia y pégalo en la siguiente sección:
                  </h1>
                    <div className="flex justify-center mb-4">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <Field
                          key={index}
                          name={`codigo[${index}]`}
                          as={InputL}
                          maxLength={1}
                          className="text-center rounded-[4px]"
                          style={{ marginRight: "13px", height: "60px", textAlign: "center", width: "50px" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e, index, setFieldValue, values)
                          }
                        />
                      ))}
                      <Button
                        onClick={() => handleCopy(values)}
                        className="bg-blue3 text-white principal-nav-notify-button2 w-[58px] h-[60px] rounded-[4px]"
                      >
                        <CopyOutlined style={{ fontSize: "24px" }} />
                      </Button>
                    </div>
                    <div className="flex justify-center mt-[20px]">
                      <Button
                        htmlType="submit"
                        className="bg-blue3 text-white principal-nav-notify-button2 w-[278px] h-[44px] rounded-[4px]"
                      >
                        Verificar Código
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Verification;
