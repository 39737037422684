import React from "react";
import { Button, Row, Col, App } from "antd";
import LoginP from "../../assets/img/login/login2.svg";
import IconArrowL from "../../assets/icons/IconArrowL.svg";
import InputL from "../../components/pages/login/InputL";
import "../../components/styles/pages/login/Login.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store/store';
import { ForgotPasswordReducer } from '../../redux/actions/auth/PasswordReset';

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Por favor ingresa un correo electrónico válido")
    .required("Por favor ingresa tu correo electrónico"),
});

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = async (
    values: { email: string },
    { setSubmitting }: { setSubmitting: any }
  ) => {
    try {
      const response: any = await dispatch(ForgotPasswordReducer(values.email));
      if (!response.error) {
        notification.success({ message: "Código enviado correctamente. Revisa tu correo electrónico." });
        navigate('/validation-password'); // Redirige a la vista de validación
      } else {
        notification.error({ message: response.error || "Error al enviar el código." });
      }
    } catch (error) {
      notification.error({ message: "Error inesperado al enviar el código." });
    }
    setSubmitting(false);
  };

  return (
    <div style={{ height: "100vh" }}>
      <Row className="lo" style={{ height: "100%" }}>
        <Col
          xxl={13} xl={13} md={13}
          className="sm:hidden sm:bg-red-400 lg:flex"
          style={{
            backgroundColor: "#f0f2f5",
            backgroundImage: `url(${LoginP})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
        </Col>
        <Col
          xxl={11} xl={11} md={11}
          className="px-[20px] md:px-[50px] py-[50px] w-full"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {/* Botón Volver */}
          <Row className="mt-3 mr-8">
            <Col xs={24} md={8} className="w-full">
              <h1 className="font-semibold text-green32 text-heading-x1">
                <Link
                  className="flex items-center font-bold text-heading-x1"
                  style={{ color: "#006497" }}
                  to="/"
                >
                  <img src={IconArrowL} alt="Arrow Left" className="mr-[15px]" />
                  <span className="font-semibold text-green32 text-heading-x1">
                    Volver
                  </span>
                </Link>
              </h1>
            </Col>

            <Col xs={24} md={16}>
              <h1 className="font-semibold sm:text-start text-[#808080] md:text-end text-heading-x1">
                ¿No tienes cuenta?{" "}
                <Link
                  className="font-bold text-heading-x1"
                  style={{ color: "#006497" }}
                  to="/register"
                >
                  Regístrate
                </Link>
              </h1>
            </Col>
          </Row>

          <div
            style={{
              height: '100%',
              alignContent: 'center',
              placeSelf: 'center',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form
                  className="login-form w-[464px] p-4"
                  method="post"
                  role="form"
                >
                  <h1 className="text-heading-md font-bold mb-2 text-center">
                    Recuperación de la cuenta
                  </h1>
                  <h1 className="text-[20px] font-semibold mb-8 text-center">
                    De esta forma sabremos que esta cuenta te pertenece
                  </h1>
                  <h1 className="font-medium text-body-md mb-8 text-center">
                    Introduce tu correo electrónico para enviarte un código de verificación para recuperar tu contraseña.
                  </h1>

                  <div className="mb-5">
                    <Field
                      name="email"
                      as={InputL}
                      placeholder="Escribe tu correo electrónico"
                      customClassName="h-[58px]"
                      className="mb-1"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-10 flex justify-center">
                    <Button
                      htmlType="submit"
                      disabled={isSubmitting}
                      className="bg-blue3 principal-nav-notify-button2 text-white w-[278px] h-[44px] rounded-[4px] ml-0"
                    >
                      Enviar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordReset;
