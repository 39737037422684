// reducers/pages/offers/offer/EditOfferReducer.ts

import {
    EDIT_OFFER_REQUEST,
    EDIT_OFFER_SUCCESS,
    EDIT_OFFER_FAILURE,
    EditOfferActionTypes,
  } from '../../../../../constants/pages/offers/offer/EditOffer';
  
  interface EditOfferState {
    rex_loading: boolean;
    rex_offer_edit: any | null;
    rex_error: string | null;
  }
  
  const initialState: EditOfferState = {
    rex_loading: false,
    rex_offer_edit: null,
    rex_error: null,
  };
  
  const editOfferReducer = (
    state = initialState,
    action: EditOfferActionTypes
  ): EditOfferState => {
    switch (action.type) {
      case EDIT_OFFER_REQUEST:
        return {
          ...state,
          rex_loading: true,
        };
      case EDIT_OFFER_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          rex_offer_edit: action.payload,
          rex_error: null,
        };
      case EDIT_OFFER_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_offer_edit: null,
          rex_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default editOfferReducer;
  