import React, { useState } from 'react';
import { Modal, Button, Skeleton, Col, Row } from 'antd';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { DetailSoftSkillsOfferFormat } from '../../../../constants/pages/searchCV/GetSearchCV';
import IconClosed from '../../../../assets/icons/IconClosed.svg';
import CustomLegend from './Legend';
interface ModalSoftSkillsProps {
	visible: boolean;
	onClose: () => void;
	data: { ofertas: any, usuarios: any, candidateName?: string };
	selectedUserSoftSkills: UserDto;
	candidateName?: string; 
}

interface UserSoftSkillsDto {
	nivel: any;
	soft_skills: {
		soft_skill: string
	}
}

interface OfferSoftSkillsDto {
	nivel: any;
	soft_skills_oferta: {
		porcentaje: number
		soft_skills: {
			soft_skill: string
			soft_skill_c: string
		}
	}[]
}

interface UserDto {
	soft_skills_usuarios: UserSoftSkillsDto[]
}

const ModalSoftSkills: React.FC<ModalSoftSkillsProps> = ({ visible, onClose, data,  candidateName   }) => {
	const [loading, setLoading] = useState(false);
	const defaultSoftSkills = [
		{ subject: 'Adaptable', A: 0, fullMark: 10 },
		{ subject: 'Consistente', A: 0, fullMark: 10 },
		{ subject: 'Detallista', A: 0, fullMark: 10 },
		{ subject: 'Innovador', A: 0, fullMark: 10 },
		{ subject: 'Metódico', A: 0, fullMark: 10 },
		{ subject: 'Proactivo', A: 0, fullMark: 10 },
		{ subject: 'Resiliente', A: 0, fullMark: 10 },
		{ subject: 'Visionario', A: 0, fullMark: 10 },
	];
	// Función para formatear los datos que se pasan a la gráfica
	const formatDataOffferSoftsKill = (oferta: OfferSoftSkillsDto, usuario: UserDto) => {

		if (oferta.soft_skills_oferta.length > 0) {
			const dataFormated = oferta.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index: number) => {

				const findSubjectUser = usuario.soft_skills_usuarios.find((usr: UserSoftSkillsDto) => usr.soft_skills.soft_skill == item.soft_skills.soft_skill)

				return [
					{
						subject: item.soft_skills.soft_skill,
						A: item.porcentaje > 0 ? item.porcentaje / 10 : 0,
						B: findSubjectUser ? findSubjectUser.nivel : 0,
						fullMark: 10,
						index: index
					},
					{
						subject: item.soft_skills.soft_skill_c,
						A: 10 - (item.porcentaje > 0 ? item.porcentaje / 10 : 0),
						B: findSubjectUser ? 10 - (findSubjectUser.nivel) : 0,
						fullMark: 10,
						index: index
					}
				]
			})
			return dataFormated.sort((a, b) => {
				if (a.subject < b.subject) return -1
				if (a.subject > b.subject) return 1
				return 0
			})
		} else {
			return defaultSoftSkills
		}
	}

	const getRadarChartData = (data: { ofertas: any, usuarios: any }) => {

		const softSkillOffer = formatDataOffferSoftsKill(data.ofertas, data.usuarios)

		return softSkillOffer ? softSkillOffer : []
	};


	return (
        <Modal
            open={visible}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={1000}  // Aumentado para dar más espacio
        >
            <img
                src={IconClosed}
                alt="Cerrar"
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: '35px',
                    right: '40px',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px'
                }}
            />
            {loading ? (
                <Skeleton active />
            ) : (
                <div className='pb-14 pt-9'>
                    <Row>
                        <Col span={24} className='flex justify-center mb-3'>
                            <div className='text-2xl font-bold font-sans'>Candidatos seleccionados sobre gráfica</div>
                        </Col>
                        <Col span={24} className='flex justify-center'>
                            <div className='text-base font-sans'>Te presentamos la visualización del candidato  {candidateName && <span className='font-bold ml-1'>{candidateName}</span>}</div>
                        </Col>
                    </Row>
                    <Row className='my-14'>
                        <Col xs={24} md={16} className='flex items-centerm'>
                            <ResponsiveContainer width="100%" height={400}>
                                <RadarChart
                                    cx="50%"
                                    cy="50%"
                                    outerRadius="90%"
                                    data={getRadarChartData(data)}
                                >
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject"
									 tick={{ fontSize: 11, fill: '#000', fontWeight: 'Bold' }}
									 />
                                    <PolarRadiusAxis angle={30} tickCount={11} domain={[0, 10]} />
                                    <Radar
                                        name="Perfil del Candidato"
                                        dataKey="B"
                                        stroke="#006497"
                                        fill="transparent"
                                    />
                                    <Radar
                                        name="Perfil de la oferta"
                                        dataKey="A"
                                        stroke="#FDBCB4"
                                        fill="transparent"
                                    />
                                    <Tooltip />
                                </RadarChart>
                            </ResponsiveContainer>
                        </Col>
                        <Col xs={24} md={8} className='flex items-center pl-6'>
                            <div>
							<Legend content={<CustomLegend showOffer={true} />} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </Modal>
    );
};

export default ModalSoftSkills;
