// src/reducers/UpdateSearchStatusReducer.ts
import {
    UPDATE_SEARCH_STATUS_REQUEST,
    UPDATE_SEARCH_STATUS_SUCCESS,
    UPDATE_SEARCH_STATUS_FAILURE,
    UpdateSearchStatusActionTypes,
} from '../../../../../constants/pages/myPortal/searchStatus/UpdateSearchStatus';
  interface UpdateSearchStatusState {
    rex_loading: boolean;
    busquedaActiva: boolean | null; // Aquí podrías definir un tipo para el estado de búsqueda
    rex_error: string | null;
  }
  
  const initialState: UpdateSearchStatusState = {
    rex_loading: false,
    busquedaActiva: null,
    rex_error: null,
  };
  
  export const updateSearchStatusReducer = (
    state = initialState,
    action: UpdateSearchStatusActionTypes
  ): UpdateSearchStatusState => {
    switch (action.type) {
      case UPDATE_SEARCH_STATUS_REQUEST:
        return {
          ...state,
          rex_loading: true,
        };
      case UPDATE_SEARCH_STATUS_SUCCESS:
        return {
          ...state,
          rex_loading: false,
          busquedaActiva: action.payload.data,
          rex_error: null,
        };
      case UPDATE_SEARCH_STATUS_FAILURE:
        return {
          ...state,
          rex_loading: false,
          rex_error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default updateSearchStatusReducer;
  