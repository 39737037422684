import React, { useState, useEffect } from "react";
import { Row, Col, Button, Drawer, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { MenuOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import CardEmpleo from "../../../components/cards/CardEmployment";
import Information from "../../../components/pages/employment/recommendations/Information";
import FilterComponent from "../../../components/pages/employment/searchResult/SidebarFilter";
import AlertFilter from "../../../components/pages/employment/searchResult/InitialInfo";
import { AppDispatch, RootState } from '../../../redux/store/store';
import { resetLocationFilterParams, SearchOffersEmplReducer } from "../../../redux/actions/offers/SearchOffers";
import { SaveOfferReducer } from "../../../redux/actions/offers/SaveOffer";
import { GetOfferSaveReducer } from "../../../redux/actions/offers/GetOfferSave"; // Importa la acción thunk correcta
import { FetchEmploymentsReducer } from "../../../redux/actions/offers/GetOffers";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetPaisesReducer } from "../../../redux/actions/pages/emplotment/searchCountry";


interface FilterParams {
  ratings?: number[];
  keywords?: string[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];

}

interface SearchResultProps {
  searchQuery: string;
}

const SearchResult: React.FC<SearchResultProps> = ({ searchQuery }: any) => {

  const {
    rex_offersEmpl,
    rex_limit,
    rex_total,
    filterParams,
    rex_loading
  } = useSelector(({ searchOffersEmpl }: any) => searchOffersEmpl);

  const location2 = filterParams.location;

  const selectedCountry = searchQuery.selectedCountry
  const selectedCity = searchQuery.searchCiudad
  const selectedSectores = searchQuery.selectedSectores
  const selectedTerm = searchQuery.searchTerm

  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [savedOffers, setSavedOffers] = useState<{ [key: number]: boolean }>({});
  const [searchTerm2, setSearchTerm2] = useState<string>("cargo3");
  const location = useLocation();
  const [hasMore, setHasMore] = useState(true);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedCountryName, setSelectedCountryName] = useState<string>("");
  const paises = useSelector((state: RootState) => state.paises.rex_paises);

  const [filters, setFilters] = useState<FilterParams>({
    ratings: [],
    keywords: [],
    sectors: [],
    tamanioArray: [],
  });

  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    setFilters(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));

    setPage(1);
    setHasMore(true);
    setLoading(true);
  };

  useEffect(() => {
    dispatch(GetPaisesReducer())
  }, [])




  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const searchData = () => {
    setLoading(true);
    setPage(1);
    setHasMore(true);
  
    const selectedRatings = filters.ratings && filters.ratings.length > 0 ? filters.ratings : undefined;
    const selectedKeywords = filters.keywords && filters.keywords.length > 0 ? filters.keywords : undefined;
    const selectedSectors = filters.sectors && filters.sectors.length > 0 ? filters.sectors : undefined;
    const selectedTamanio = filters.tamanioArray && filters.tamanioArray.length > 0 ? filters.tamanioArray : undefined;
  
    if (selectedCountry && selectedCity && selectedSectores && selectedTerm) {
      const selectedCountryData = paises.find((pais: any) => pais.id === selectedCountry);
      const countryName = selectedCountryData?.pais || '';
      setSelectedCountryName(countryName);
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          selectedTerm,
          selectedSectores,
          countryName,
          selectedCity,
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
    else if (selectedCountry && selectedCity && selectedTerm) {
      const selectedCountryData = paises.find((pais: any) => pais.id === selectedCountry);
      const countryName = selectedCountryData?.pais || '';
      setSelectedCountryName(countryName);
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          selectedTerm,
          "",
          countryName,
          selectedCity,
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
    else if (selectedCountry && selectedCity && selectedSectores) {
      const selectedCountryData = paises.find((pais: any) => pais.id === selectedCountry);
      const countryName = selectedCountryData?.pais || '';
      setSelectedCountryName(countryName);
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          undefined,
          selectedSectores,
          countryName,
          selectedCity,
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
    else if (selectedCountry && selectedCity) {
      const selectedCountryData = paises.find((pais: any) => pais.id === selectedCountry);
      const countryName = selectedCountryData?.pais || '';
      setSelectedCountryName(countryName);
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          undefined,
          "",
          selectedCountryName,
          selectedCity,
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
    else if (selectedSectores) {
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          undefined,
          selectedSectores,
          "",
          "",
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
    else if (selectedTerm) {
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          selectedTerm,
          "",
          "",
          "",
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
    else {
      dispatch(
        SearchOffersEmplReducer(
          1,
          rex_limit,
          undefined,
          "",
          "",
          "",
          selectedRatings,
          selectedKeywords,
          selectedSectors,
          selectedTamanio
        )
      ).then(() => setLoading(false));
    }
  }

  useEffect(() => {
    searchData()
  }, [filters, selectedCountry, selectedCity, selectedSectores, paises, rex_limit, selectedCountryName, selectedTerm]);

  useEffect(() => {
    if (!selectedCountry) {
      setSelectedCountryName("");
    }
  }, [selectedCountry, selectedCity])

  const handleCardClick = (index: number) => {
    // setLoadingInfo(true);
    if (index == selectedCardIndex) {
      setSelectedCardIndex(null);
    } else {
      setSelectedCardIndex(index);
    }

    // setTimeout(() => {
    //   setLoadingInfo(false);
    // }, 2000);
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const handleSave = async (offerId: number,) => {
    await dispatch(SaveOfferReducer(offerId)).then(() => {
      setSavedOffers((prev) => ({
        ...prev,
        [offerId]: !prev[offerId],
      }));
    });

    searchData()
    // if(searchTerm){
    //   // await dispatch(SearchOffersEmplReducer(searchTerm, 1, 10));
    // }
  };

  const handleCreateAlertClick = () => {
    alert("Nueva alerta creada");
  };

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);

    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);

    setTimeout(() => {
      const countryToUse = selectedCountryName;
      const cityToUse = selectedCity;
      const sectorToUse = selectedSectores;
      dispatch(SearchOffersEmplReducer(
        nextPage,
        rex_limit,
        undefined,
        sectorToUse || "",
        countryToUse,
        cityToUse || ""
      ))
        .then(() => {
          setPage(nextPage);
          setLoadingMoreData(false);
          if (nextPage >= totalPages) {
            setHasMore(false);
          }
        })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (rex_offersEmpl?.length == rex_total) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
    }
  }, [rex_total, rex_offersEmpl])

  const defaultProps = {
    companyName: "Supermercados Carrefour",
    jobTitle: "Reponedor de supermercado",
    location: "Campo de Criptana",
    salary: "18.000€",
    schedule: "Parcial",
    // ratings: "20 valoraciones",
    // activeOffers: "90 ofertas activas",
    // followers: "450 Seguidores",
    description: "Importante cadena de alimentación busca incorporar un/a dependiente/a reponedor/a en Fuerteventura.<br>Requisitos: Experiencia en la caja y reposicion, Manejo transpaleta manual...",
    postedTime: "Subida hace 23h",
    applied: false,
    // employmentType: "Tiempo completo",
    comments: 0,
    contractType: "Indefinido",
    currentStep: 1,
  };

  const defaultInformationProps = {
    // applicantsCount: "10",
    // introText: "Auxilium comparo odit. Vado vomica laboriosam similique cattus teres dolorum video nostrum derideo. Cresco solum coniuratio pectus ventito cohibeo spiculum animi debitis.",
    benefits: ["Beneficio 1", "Beneficio 2"],
    extraText: [""],
    extraText2: [""],
  };

  const mergeProps = (offer: any, index: number) => {
    return {
      ...defaultProps,
      companyName: `${offer.empresas.empresa}`,
      jobTitle: offer.cargo,
      location: `${offer.ubi_provincia}, ${offer.ubi_poblacion}`,
      salary: `${offer.sal_min}€ - ${offer.sal_max}€`,
      schedule: offer.jornada_laboral,
      description: offer.descripcion,
      applied: !!appliedCards[index],
      comments: Number(offer.comments),
      ...defaultInformationProps,
      introText: `${offer.empresas.descripcion}`,
      // Nuevas propiedades para `Information`
      company: `${offer.empresas.empresa}`,
      title: offer.cargo,
      id: offer.id,
      createdAt: offer.createdAt,
      ratings: offer.empresas._count.valoraciones_empresas + " valoraciones",
      activeOffers: offer.empresas._count.ofertas + " ofertas activas",
      followers: offer.empresas._count.empresa_seguida + " Seguidores",
      employmentType: offer.jornada_laboral,
      applicantsCount: offer._count.postulaciones + " Inscritos",
      sector: offer.sectores.sector,
      requirements: [
        {
          title: "Estudios mínimos",
          requirement: offer.estudios_minimos,
        },
        // {
        //   title: "Jornada laboral",
        //   requirement: offer.jornada_laboral,
        // },
        // {
        //   title: "Tipo de contrato",
        //   requirement: offer.tipo_contrato,
        // },
        {
          title: "Años de experiencia",
          requirement: offer.anios_experiencia == 0 ? "Sin experiencia" : offer.anios_experiencia,
        }
      ],
      responsibilities: [
        offer.descripcion || 'Descripción no disponible'
      ],
      allProperties: offer
    };
  };

  return (
    <div className="bg-white">
      <div className="flex flex-col md:flex-row">
        <Button
          type="primary"
          className="mx-5 md:hidden mb-3"
          onClick={() => setDrawerVisible(true)}
          icon={<MenuOutlined />}
        />

        <div style={{ width: "234px", backgroundColor: "white" }} className="hidden md:block p-0">
          <FilterComponent
            onFilterChange={handleFilterChange} />
        </div>

        <Drawer
          title={null}
          placement="left"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          drawerStyle={{ padding: 0 }}
          width={268}
          className="block md:hidden"
        >
          <FilterComponent inDrawer />
        </Drawer>

        <div className="flex-1 px-4">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24}>
              <InfiniteScroll
                dataLength={rex_offersEmpl.length}
                next={fetchMoreData}
                hasMore={hasMore && rex_offersEmpl.length >= rex_limit} // Solo permite más carga si hay más de `rex_limit` resultados
                loader={
                  hasMore && rex_offersEmpl.length >= rex_limit ? ( // Solo muestra el Skeleton si `hasMore` es `true` y hay más resultados para cargar
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                      <Skeleton avatar paragraph={{ rows: 3 }} active />
                    </div>
                  ) : null
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    {
                      rex_loading
                      ? <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                          <Skeleton avatar paragraph={{ rows: 3 }} active />
                        </div>
                      : <b>Ya no hay más datos</b>
                    }
                  </p>
                }
                scrollThreshold={0.95}
              >
                {
                  rex_offersEmpl.map((offer: any, index: number) => (
                    <div
                      key={offer.id}
                      onClick={() => {
                        handleCardClick(index)
                      }}
                      className={`cursor-pointer mb-5 p-4 rounded-lg transition-shadow duration-300 ${selectedCardIndex === index ? "shadow-lg" : "hover:shadow-md"
                        }`}
                    >
                      {selectedCardIndex === index ? (
                        <Information
                          {...mergeProps(offer, index)}
                          onApply={() => handleApply(index)}
                          onSave={() => handleSave(offer.id)}
                          loading={loadingInfo}
                          partialLoading={true}
                          aptitudes={[]}
                          applied={offer.inscritoOfertaByUser}
                          saved={offer?.ofertaGuardadaByUser}
                          avatar={offer?.empresas?.logo}
                          isSavedOffer={offer?.ofertaGuardadaByUser}
                          blueBorder={true}
                        />
                      ) : (
                        <CardEmpleo
                          {...mergeProps(offer, index)}
                          loading={loadingInfo}
                          styleType={offer?.ofertaGuardadaByUser ? "type3" : "default"}
                          applied={offer.inscritoOfertaByUser}
                          saveOffer={false}
                          dataComplete={offer}
                          avatar={offer?.empresas?.logo}
                        />
                      )}
                    </div>
                  ))}
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
