import { 
  SEARCH_OFFERS_EMPL_REQUEST, 
  SEARCH_OFFERS_EMPL_SUCCESS, 
  SEARCH_OFFERS_EMPL_FAILURE,
  SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL
} from '../../../constants/offers/SearchOffers';

interface OfferEmplState {
  rex_loading: boolean;
  rex_offersEmpl: any[];
  rex_error?: string | null;
  rex_limit: number;
  rex_page: number;
  rex_total: number;
  filterParams: any;
}

const initialState: OfferEmplState = {
  rex_loading: false,
  rex_offersEmpl: [],
  rex_page: 1,
  rex_limit: 10,
  rex_total: 0,
  filterParams: {},
};

const searchOffersEmplReducer = (state = initialState, action: any): OfferEmplState => {
  switch (action.type) {
    case SEARCH_OFFERS_EMPL_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null,
      };
      
    case SEARCH_OFFERS_EMPL_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_offersEmpl: action.payload.meta.page == 1 ? [...action.payload.data] : [...state.rex_offersEmpl, ...action.payload.data],
        rex_page: action.payload.page,
        rex_total: action.payload.meta.total,
        rex_limit: action.payload.meta.limit,
      };

    case SEARCH_OFFERS_EMPL_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.error?.message || "Error desconocido",
      };
    case SET_FILTER_PARAMS_SEARCH_OFFERS_EMPL:
        console.log('Entre a los filtros:',action.payload);
        console.log('Entre a los filtros2222222222222:',state.filterParams);
        if (JSON.stringify(action.payload) === JSON.stringify(state.filterParams)) {
          console.log('entreeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
          return state; 
        }
        
        return {
          ...state,
          filterParams: action.payload,
          rex_offersEmpl: [] 
        };
    default:
      return state;
  }
};

export default searchOffersEmplReducer;
