import React, { useState, useEffect } from 'react';
import { Avatar, Tag, Select, Button, Divider, Skeleton, Modal, notification, Row, Col, Menu, Dropdown } from 'antd';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { useNavigate } from 'react-router-dom';
import '../../../styles/pages/offers/OffersProfileInfo.css';
import StyledCheckbox from '../../../checkbox/CheckboxProps';
import CustomLegend from './Legend';
import RatingBlue from '../../../rating/RatingBlue'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { addOrRemoveUserOffers, GetPostulacionesByOfertaReducer, updateCvOrVe, updateCvOrVeRequest } from '../../../../redux/actions/pages/offers/offersProfile/GetOffersProfile';
import { updateFasePostulacion } from '../../../../redux/actions/common/fase/PatchApplicationPhases';
import dayjs from 'dayjs';
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import ModalVerCv from '../../../modals/ModalVerCv';
import { DetailSoftSkillsOfferFormat } from '../../../../constants/pages/searchCV/GetSearchCV';
import InfiniteScroll from 'react-infinite-scroll-component';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface FilterParams {
  educations?: string[];
  keywords?: string[];
  ratings?: number[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
  experiences?: number[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  educationsUsers?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  experiencia_nula?: number[];
  modalidad_remoto?: string[];
  activeSearch?: boolean;
  jornada?: string[];
  excluirEmpresa?: string[];
  excluirPalabrasClave?: string[]
}

const { Option } = Select;
interface OffersProfileInfosProps {
  offerId: number;
  filters: FilterParams;
}
interface UserSoftSkillsDto {
  nivel: any;
  soft_skills: {
    soft_skill: string
  }
}

interface OfferSoftSkillsDto {
  nivel: any;
  soft_skills_oferta: {
    porcentaje: number
    soft_skills: {
      soft_skill: string
      soft_skill_c: string
    }
  }[]
}

interface UserDto {
  soft_skills_usuarios: UserSoftSkillsDto[]
}

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const defaultSoftSkills = [
  { subject: 'Adaptable', A: 0, fullMark: 10 },
  { subject: 'Consistente', A: 0, fullMark: 10 },
  { subject: 'Detallista', A: 0, fullMark: 10 },
  { subject: 'Innovador', A: 0, fullMark: 10 },
  { subject: 'Metódico', A: 0, fullMark: 10 },
  { subject: 'Proactivo', A: 0, fullMark: 10 },
  { subject: 'Resiliente', A: 0, fullMark: 10 },
  { subject: 'Visionario', A: 0, fullMark: 10 },
];

const PostulationSkeleton = () => (
  <div className="p-6 bg-gray-100 rounded-lg relative mb-8">
    <div className="flex flex-wrap -mx-3 relative">
      <div className="w-full md:w-3/4 px-3 relative">
        <div className="flex items-center mb-4">
          <Skeleton.Avatar active size={64} shape="square" />
          <div className="ml-4 flex-grow">
            <Skeleton.Input style={{ width: '60%' }} active size="small" />
            <Skeleton.Input style={{ width: '40%' }} active size="small" />
          </div>
        </div>
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative">
        <div style={{ height: '200px' }}></div>
      </div>
    </div>
  </div>
);

const OffersProfileInfos: React.FC<OffersProfileInfosProps> = ({ offerId, filters }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { soft_skills_offer, postulaciones, error, rex_limit, rex_total } = useSelector((state: RootState) => state.getOffersProfile);

  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFase, setNewFase] = useState('');
  const [selectedPostulacionId, setSelectedPostulacionId] = useState<number | null>(null);
  const [showModalCv, setShowModalCv] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cvSeleccionado, setCvSeleccionado] = useState<{
    usuarios: {
      cvs_usuarios: { cv: string }[];
      nombre: string;
      personas: {
        nombre: string;
        apellido_paterno: string;
      }
    };
  } | null>(null);

  useEffect(() => {
    setPage(1);
    setHasMore(true);

  }, [filters])


  useEffect(() => {
    if (postulaciones.length === 0 || page === 1) {
      setLoading(true);
      const selectedRatings = filters.ratings && filters.ratings.length > 0 ? filters.ratings : undefined
      const selectedExperienciaLaboral = filters.experienciaLaboral && filters.experienciaLaboral?.length > 0 ? filters.experienciaLaboral : undefined
      const selectedSoftSkills = filters.softSkills && filters.softSkills?.length > 0 ? filters.softSkills : undefined
      const selectedPaises = filters.paises && filters.paises?.length > 0 ? filters.paises : undefined
      const selectedCiudades = filters.ciudades && filters.ciudades?.length > 0 ? filters.ciudades : undefined
      const selectedCompanies = filters.companies && filters.companies?.length > 0 ? filters.companies : undefined
      const selectedExperiences = filters.experiences && filters.experiences?.length > 0 ? filters.experiences : undefined
      const selectedEducations = filters.educations && filters.educations?.length > 0 ? filters.educations : undefined
      const selectedSectors = filters.sectors && filters.sectors?.length > 0 ? filters.sectors : undefined
      const selectedValoracionesMax = filters.valoraciones_usuariosmax ? filters.valoraciones_usuariosmax : undefined
      const selectedExperienciaNula = filters.experiencia_nula && filters.experiencia_nula.length > 0 ? filters.experiencia_nula : undefined
      const selectedModalidadRemoto = filters.modalidad_remoto && filters.modalidad_remoto?.length > 0 ? filters.modalidad_remoto : undefined
      const selectedExcluirExperienciaLaboral = filters.excluirExperienciaLaboral && filters.excluirExperienciaLaboral?.length > 0 ? filters.excluirExperienciaLaboral : undefined
      const activeSearch = filters.activeSearch
      const selectedJornada = filters.jornada && filters.jornada?.length > 0 ? filters.jornada : undefined
      const selectedExcluirEmpresa = filters.excluirEmpresa && filters.excluirEmpresa?.length > 0 ? filters.excluirEmpresa : undefined

      dispatch(GetPostulacionesByOfertaReducer(
        offerId,
        1,
        10,
        selectedRatings,
        selectedExperienciaLaboral,
        selectedSoftSkills,
        selectedPaises,
        selectedCiudades,
        selectedCompanies,
        selectedExperiences,
        selectedEducations,
        selectedSectors,
        selectedValoracionesMax,
        selectedExperienciaNula,
        selectedModalidadRemoto,
        selectedExcluirExperienciaLaboral,
        activeSearch,
        selectedJornada,
        selectedExcluirEmpresa,
      )).then(() => {
        setLoading(false)
      });
    }
  }, [offerId, page, rex_limit, filters]);

  const handleOpenModal = (fase: string, postulacionId: number) => {
    setNewFase(fase);
    setSelectedPostulacionId(postulacionId);
    setIsModalVisible(true);
  };

  const handleConfirmChange = () => {
    if (selectedPostulacionId) {
      handleChangeFase(newFase, selectedPostulacionId);
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelChange = () => {
    setIsModalVisible(false);
  };

  const handleVerCvClick = (postulacionId: number) => {
    const body = "CVDescargado"
    dispatch(updateCvOrVe(postulacionId, body));
  };

  const handleVerEmpresaClick = (postulacionId: number) => {
    const body = "VistoEmpresa"
    dispatch(updateCvOrVe(postulacionId, body));
  };

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);
    setTimeout(() => {
      const selectedRatings = filters.ratings && filters.ratings.length > 0 ? filters.ratings : undefined
      const selectedExperienciaLaboral = filters.experienciaLaboral && filters.experienciaLaboral?.length > 0 ? filters.experienciaLaboral : undefined
      const selectedSoftSkills = filters.softSkills && filters.softSkills?.length > 0 ? filters.softSkills : undefined
      const selectedPaises = filters.paises && filters.paises?.length > 0 ? filters.paises : undefined
      const selectedCiudades = filters.ciudades && filters.ciudades?.length > 0 ? filters.ciudades : undefined
      const selectedCompanies = filters.companies && filters.companies?.length > 0 ? filters.companies : undefined
      const selectedExperiences = filters.experiences && filters.experiences?.length > 0 ? filters.experiences : undefined
      const selectedEducations = filters.educations && filters.educations?.length > 0 ? filters.educations : undefined
      const selectedSectors = filters.sectors && filters.sectors?.length > 0 ? filters.sectors : undefined
      const selectedValoracionesMax = filters.valoraciones_usuariosmax ? filters.valoraciones_usuariosmax : undefined
      const selectedExperienciaNula = filters.experiencia_nula && filters.experiencia_nula.length > 0 ? filters.experiencia_nula : undefined
      const selectedModalidadRemoto = filters.modalidad_remoto && filters.modalidad_remoto?.length > 0 ? filters.modalidad_remoto : undefined
      const activeSearch = filters.activeSearch
      const selectedJornada = filters.jornada && filters.jornada?.length > 0 ? filters.jornada : undefined
      const selectedExcluirExperienciaLaboral = filters.excluirExperienciaLaboral && filters.excluirExperienciaLaboral?.length > 0 ? filters.excluirExperienciaLaboral : undefined
      const selectedExcluirEmpresa = filters.excluirEmpresa && filters.excluirEmpresa?.length > 0 ? filters.excluirEmpresa : undefined

      dispatch(GetPostulacionesByOfertaReducer(
        offerId,
        nextPage,
        rex_limit,
        selectedRatings,
        selectedExperienciaLaboral,
        selectedSoftSkills,
        selectedPaises,
        selectedCiudades,
        selectedCompanies,
        selectedExperiences,
        selectedEducations,
        selectedSectors,
        selectedValoracionesMax,
        selectedExperienciaNula,
        selectedModalidadRemoto,
        selectedExcluirExperienciaLaboral,
        activeSearch,
        selectedJornada,
        selectedExcluirEmpresa,
      )).then(() => {
        setPage(nextPage);
        setLoadingMoreData(false);
        if (nextPage >= totalPages) {
          setHasMore(false);
        }
      })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (postulaciones?.length == rex_total) {
        setHasMore(false)
      }
    }
  }, [rex_total, postulaciones])


  const handleChangeFase = (newFase: string, postulacionId: number) => {
    const faseMapping = {
      "No Revisado": 1,
      Descartado: 2,
      Preseleccionado: 3,
      Entrevista: 4,
      Seleccionado: 5,
    } as const;

    const fasePostulacionId = faseMapping[newFase as keyof typeof faseMapping];

    if (postulacionId && fasePostulacionId) {
      dispatch(updateFasePostulacion(postulacionId, fasePostulacionId))
        .then(() => {
          dispatch(GetPostulacionesByOfertaReducer(offerId));
        })
        .catch((error) => {
          console.error("Error al actualizar la fase:", error);
        });
    }

    if (postulacionId && fasePostulacionId) {
      dispatch(updateFasePostulacion(postulacionId, fasePostulacionId));
    } else {
      console.error('Fase o ID de postulación inválido');
    }
  };

  const handleFaseChange = (value: string, postulacionId: number) => {
    handleOpenModal(value, postulacionId);
  };

  const renderMenu = (postulacion: any) => (
    <Menu
      className="custom-menu"
      selectedKeys={[postulacion.fases_postulaciones.fase]}
      onClick={(e) => handleFaseChange(e.key, postulacion.id)}
    >
      <Menu.Item key="No Revisado">No Revisado</Menu.Item>
      <Menu.Item key="Descartado" className="">Descartado</Menu.Item>
      <Menu.Item key="Preseleccionado" className="">Preseleccionado</Menu.Item>
      <Menu.Item key="Entrevista" className="">Entrevista</Menu.Item>
      <Menu.Item key="Seleccionado">Seleccionado</Menu.Item>
    </Menu>
  );

  const renderModal = () => (
    <Modal
      visible={isModalVisible}
      closable={false}
      footer={null}
      width={800}
    >
      <img
        src={IconClosed}
        alt="Cerrar"
        onClick={handleModalCancel}
        style={{
          position: "absolute",
          top: "34px",
          right: "34px",
          cursor: "pointer",
          width: "24px",
          height: "24px",
        }}
      />
      <Row gutter={[16, 24]} className='text-center mt-7'>
        <Col span={24}>
          <p className="font-bold text-2xl font-sans">Confirmación</p>
        </Col>
        <Col span={24}>
          <p className="font-sans font-medium text-sm">¿Estás seguro de que deseas cambiar a la fase "{newFase}"?</p>
        </Col>
      </Row>

      <Row className='flex justify-center mb-5'>
        <div className="flex justify-center mx-[114px] mt-[38px]">
          <Button
            onClick={handleCancelChange}
            className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmChange}
            className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
          >
            Aceptar
          </Button>
        </div>
      </Row>

    </Modal>
  );

  const handleCandidateClick = (postulacionId: number, candidateId: number) => {
    if (offerId && postulacionId && candidateId) {
      navigate(`/offer/${offerId}/postulacion/${postulacionId}/candidate/${candidateId}`);
    } else {
      console.error("ID de la oferta, postulación o candidato no definidos");
    }
  };


  const handleViewAnswers = (offerId: number, candidateName: string, userId: string, postulationId: number) => {
    console.log("Navigating to answers...");
    console.log("Offer ID:", offerId);
    console.log("Candidate Name:", candidateName);
    console.log("Postuacion ID:", postulationId);

    // Verifica si offerId y candidateName son válidos
    if (offerId && candidateName) {
      navigate(`/offer/answers/${offerId}/${candidateName}/${userId}/${postulationId}`);
    } else {
      console.error("Error: Invalid offerId or candidateName");
    }
  };

  const getRadarChartData = (data: { ofertas: any, usuarios: any }) => {

    const softSkillOffer = formatDataOffferSoftsKill(data.ofertas, data.usuarios)

    return softSkillOffer ? softSkillOffer : []
  };

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Alerta',
      description:
        'Solo puedes seleccionar a 10 usuarios como máximo',
    });
  };

  const formatDataOffferSoftsKill = (oferta: OfferSoftSkillsDto, usuario: UserDto) => {

    if (oferta.soft_skills_oferta.length > 0) {
      const dataFormated = oferta.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index: number) => {

        const findSubjectUser = usuario.soft_skills_usuarios.find((usr: UserSoftSkillsDto) => usr.soft_skills.soft_skill == item.soft_skills.soft_skill)

        return [
          {
            subject: item.soft_skills.soft_skill,
            A: item.porcentaje > 0 ? item.porcentaje / 10 : 0,
            B: findSubjectUser ? findSubjectUser.nivel : 0,
            fullMark: 10,
            index: index
          },
          {
            subject: item.soft_skills.soft_skill_c,
            A: 10 - (item.porcentaje > 0 ? item.porcentaje / 10 : 0),
            B: findSubjectUser ? 10 - (findSubjectUser.nivel) : 0,
            fullMark: 10,
            index: index
          }
        ]
      })
      return dataFormated.sort((a, b) => {
        if (a.subject < b.subject) return -1
        if (a.subject > b.subject) return 1
        return 0
      })
    } else {
      return defaultSoftSkills
    }
  }

  const calculateDuration = (startDate: string, endDate: string | null) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : dayjs();
    const months = end.diff(start, 'month');
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return `${years} años, ${remainingMonths} meses`;
  };

  if (loading) {
    return (
      <div>
        <PostulationSkeleton />
        <PostulationSkeleton />
        <PostulationSkeleton />
      </div>
    );
  }

  if (error) return <div>Error al cargar las postulaciones: {error}</div>;

  return (

    <div className="p-6 pt-0 pr-3 bg-gray-100 rounded-lg relative">

      {contextHolder}
      <InfiniteScroll
        dataLength={postulaciones.length}
        next={fetchMoreData}
        hasMore={hasMore && postulaciones.length >= rex_limit}
        loader={
          hasMore && postulaciones.length >= rex_limit ? (
            <div >
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            </div>
          ) : null
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            {/* <b>Ya no hay más datos</b> */}
          </p>
        }
        style={{ overflow: 'visible' }}
      >
        {postulaciones.map((postulacion: any, index) => (

          <React.Fragment
            key={postulacion.usuarios.usuario}>
            <Row gutter={[16, 16]} className="flex flex-wrap">
              <Col xs={24} md={24} xl={15}>
                <div className="w-full px-3 relative">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      onClick={() => {
                        handleCandidateClick(postulacion.id, postulacion.usuarios.id)
                        handleVerEmpresaClick(postulacion.id)
                      }}
                      style={{ position: 'relative', zIndex: 1 }}
                      className="mx-auto p-1 -ml-5 bg-gray-100 rounded-lg cursor-pointer"
                    >
                      <div className="flex items-center ml-[-8px] cursor-pointer">
                        <StyledCheckbox
                          checked={postulacion.checked}
                          children={undefined}
                          onChange={({ target: { checked } }) => {
                            if (soft_skills_offer.users.length < 10) {
                              dispatch(addOrRemoveUserOffers(postulacion, checked))
                            } else {
                              openNotificationWithIcon('warning')
                            }
                          }}
                        />

                        <div
                          className="flex items-center"
                        >
                          <Avatar
                            size={64}
                            src={
                              postulacion.usuarios.imagen
                                ? API_BASE_URL_EXACT + postulacion.usuarios.imagen
                                : IMAGE_USER_DEFAULT
                            }
                            shape="square"
                            className="!rounded-none bg-white"
                          />
                          <div className="ml-4 relative">
                            <h2 className="text-[18px] font-medium text-[#1A1A1A] opacity-70">
                              {`${postulacion.usuarios.personas.nombre} ${postulacion.usuarios.personas.apellido_paterno} ${postulacion.usuarios.personas.apellido_materno}`}
                            </h2>
                            <p className="text-[16px] font-bold text-[#1A1A1A] opacity-70">{postulacion.usuarios.cargo}</p>
                            <p className="text-[12px] font-medium text-[#1A1A1A] opacity-70">{postulacion.usuarios.ubicacion}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                    // className='absolute right-[60px]'
                    >
                      <div key={postulacion.id}>
                        <Dropdown className='text-black'
                          overlay={renderMenu(postulacion)}
                          trigger={['click']}
                        >
                          <Button
                            className={`-top-2 text-center rounded-full px-3 text-black font-bold text-[12px] h-[22px] w-[127px] appearance-none ${postulacion.fases_postulaciones.fase === "Descartado"
                              ? "bg-[#FF3B30] text-white"
                              : postulacion.fases_postulaciones.fase === "Preseleccionado"
                                ? "bg-[#1C82BC] text-white"
                                : postulacion.fases_postulaciones.fase === "Entrevista"
                                  ? "bg-[#FF9500] text-white"
                                  : postulacion.fases_postulaciones.fase === "Seleccionado"
                                    ? "bg-[#34C759] text-white"
                                    : "text-black"
                              }`}
                          >
                            {postulacion.fases_postulaciones.fase}
                          </Button>
                        </Dropdown>
                      </div>

                      {renderModal()}
                    </div>
                  </div>
                  {/* <p className="text-[16px] font-bold text-[#1A1A1A] opacity-70">{postulacion.usuarios.cargo}</p>
                        <p className="text-[12px] font-medium text-[#1A1A1A] opacity-70">{postulacion.usuarios.ubicacion}</p>
                    
                       <p className="ml-auto text-[12px] font-medium -mt-10 text-[#1A1A1A] opacity-70">Cualitativos</p> */}

                  <div className="flex mt-4 ml-[-10px]">
                    <h3 className="font-bold font-sans text-[12px]">Valoraciones</h3>
                    <div className="flex items-center ml-[16px] space-x-2">
                      <RatingBlue totalStars={5} filledStars={postulacion.usuarios.promedioValoraciones} />
                      <Tag className="text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Ver valoraciones
                      </Tag>
                    </div>
                  </div>

                  <div className="mt-4 ml-[-3px]  flex">
                    <h3 className="font-bold mr-4 font-sans text-[12px]">Experiencia</h3>
                    <div className="text-[14px] font-medium ml-[1px] font-sans text-[#5E5E5E] opacity-70">
                      {postulacion.usuarios.experiencias_laborales_usuarios.map((exp: any, index: number) => (
                        index < 2
                          ? <p key={index}>
                            {`${exp.cargo} | ${exp.nombre_empresa} | ${exp.nombre_sector} | ${dayjs(exp.fecha_inicio).format('MM/YYYY')} - ${exp.fecha_fin ? dayjs(exp.fecha_fin).format('MM/YYYY') : 'Actual'} (${calculateDuration(exp.fecha_inicio, exp.fecha_fin)})`}
                          </p>
                          : null
                      ))}
                    </div>
                  </div>

                  <div className="mt-3 mb-1 ml-[9px] flex">
                    <h3 className="font-bold mr-[21px] text-[12px]">Educación</h3>
                    <div className="text-[14px] font-medium ml-[-3px]  text-[#5E5E5E] opacity-70">
                      {postulacion.usuarios.educacion_usuarios.map((edu: any, index: number) => (
                        index < 2
                          ? <p key={index}>
                            {`${edu.carrera} | ${edu.nombre_centro_educativo} | ${dayjs(edu.fecha_inicio).format('MM/YYYY')} - ${edu.fecha_final ? dayjs(edu.fecha_final).format('MM/YYYY') : 'Actual'} (${calculateDuration(edu.fecha_inicio, edu.fecha_final)})`}
                          </p>
                          : null
                      ))}
                    </div>
                  </div>

                  <div className="-mt-2 ml-[83px]">
                    <span className="font-bold text-[12px] text-[#5E5E5E] opacity-80">Búsqueda activa | Remoto admitido | Jornada completa</span>
                    <Button
                      type="link"
                      className="font-bold text-[12px]"
                      style={{ color: '#00476D' }}
                      // href{postulacion.usuarios.cvs_usuarios.length > 0 ? postulacion.usuarios.cvs_usuarios[0].nombre : "#"}
                      onClick={() => {
                        setShowModalCv(true);
                        setCvSeleccionado(postulacion)
                        handleVerCvClick(postulacion.id)
                      }}
                    >
                      Ver CV en pdf
                    </Button>

                  </div>
                  <div className="flex mt-1 items-center">
                    <div className="flex-1 ml-[9px]">
                      <span className="font-bold text-[12px] font-sans mr-[14px]">Aptitudes</span>
                      {postulacion.usuarios.aptitudes_usuarios.map((apt: any, index: number) => (
                        <Tag key={index} className="ml-1 my-1 text-[12px]  font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
                          {apt.aptitudes.aptitud}
                        </Tag>
                      ))}
                    </div>
                    <Button
                      type="link"
                      className="text-[12px] font-bold text-blue3 underline bg-transparent border-none cursor-pointer relative z-10" // Añadido 'relative' y 'z-10'
                      onClick={() => {
                        const name = postulacion.usuarios.personas.nombre; // Nombre del candidato
                        const candidateId = postulacion.usuarios.id; // ID del candidato
                        const postulationId = postulacion.id
                        handleViewAnswers(offerId, name, candidateId, postulationId);
                      }}
                    >
                      Ver respuestas
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={24} xl={9} className="flex justify-end items-center">
                <ResponsiveContainer width="100%" height={"100%"}>
                  <RadarChart cx="50%" cy="50%" outerRadius="72%" data={getRadarChartData(postulacion)}  >
                    <PolarGrid />
                    <PolarAngleAxis
                      dataKey="subject"
                      tick={{ fontSize: 11, fill: '#000', fontWeight: 'Bold' }}
                    />
                    <PolarRadiusAxis angle={30} tickCount={11} domain={[0, 10]} />
                    <Radar name="Perfil del Candidato" dataKey="B" stroke="#006497" fill="transparent" />
                    <Radar name="Perfil de la oferta" dataKey="A" stroke="#FDBCB4" fill="transparent" />
                    <Legend content={<CustomLegend showOffer={true} />} />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </Col>

            </Row>
            {index < postulaciones.length - 1 && (
              <Divider className="bg-black my-8 mb-[45px]" />
            )}
          </React.Fragment>

        ))}
      </InfiniteScroll>

      <ModalVerCv
        nombre={cvSeleccionado?.usuarios.personas.nombre + " " + cvSeleccionado?.usuarios.personas.apellido_paterno}
        showModal={showModalCv}
        setShowModal={setShowModalCv}
        cv={cvSeleccionado?.usuarios?.cvs_usuarios[0]?.cv || ""}
      />
    </div>
  );
};

export default OffersProfileInfos;
