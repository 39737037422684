import React, { useState } from "react";
import { Typography, Slider, Radio, Input, Checkbox, Button, message } from "antd";
import CustomInputNumber from "../../../../pages/offers/KillerQuestions/ComponentsKillersQ/CustomInputNumber";
import CustomRadio from "../../../../pages/offers/KillerQuestions/ComponentsKillersQ/CustomRadio";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
const { Title } = Typography;

const KillerQuestions: React.FC<{ sendForm: any }> = ({
  sendForm
}) => {
  const [numValue1, setNumValue1] = useState(0);
  const [numValue2, setNumValue2] = useState(2);

  const {
    rex_request
  } = useSelector(({ getKillerQuestions }: any) => getKillerQuestions);

  // Validation function to check if all questions are answered
  const validateQuestions = (values: any) => {
    const unansweredQuestions: number[] = [];

    rex_request.data.forEach((question: any, index: number) => {
      const value = values[`val${index}`];

      // Check if the value exists and is not empty
      if (!value ||
        (question.tipos_preguntas.tipo === 'checkbox' && value.respuestas.length === 0) ||
        (question.tipos_preguntas.tipo === 'respuesta' && !value.respuesta.trim()) ||
        (question.tipos_preguntas.tipo !== 'checkbox' && !value.respuesta)
      ) {
        unansweredQuestions.push(index + 1);
      }
    });

    return unansweredQuestions;
  };

  return (
    <div>
      <Formik
        initialValues={{}}
        validate={(values) => {
          const unansweredQuestions = validateQuestions(values);

          if (unansweredQuestions.length > 0) {
            return {
              form: `Por favor, responda todas las preguntas. Preguntas pendientes: ${unansweredQuestions.join(', ')}`
            };
          }
          return {};
        }}
        onSubmit={async (values: any, { setSubmitting }: any) => {
          const unansweredQuestions = validateQuestions(values);
        
          if (unansweredQuestions.length > 0) {
            message.error(`Por favor, responda todas las preguntas. Preguntas pendientes: ${unansweredQuestions.join(', ')}`);
            setSubmitting(false);
            return;
          }
        
          // Format the responses before sending
          const formattedDataForm = rex_request.data.reduce((acc: any, question: any, index: number) => {
            const val = values[`val${index}`];
            if (val) {
              acc[question.id] = {
                id: question.id,
                respuesta: question.tipos_preguntas.tipo === 'checkbox'
                  ? val.respuestas.map((r: any) => r.id)  // Extract the IDs of the selected responses
                  : val.respuesta
              };
            }
            return acc;
          }, {});
        
          sendForm(formattedDataForm);  // Send the formatted data to the backend
          setSubmitting(false);
        }}
                      
      >
        {({ isSubmitting, values, setFieldValue, errors, handleSubmit }: any) => (
          <Form method="post" role="form">
            {
              rex_request.data.map((question: any, index: number) => {
                let questionElement;
                switch (question.tipos_preguntas.tipo) {
                  case 'lineal':
                    questionElement = (
                      <div className="max-w-full mt-[10px]">
                        <div className="flex justify-between mb-2 font-medium text-caption">
                          {
                            question.detalle_killers.map((detalle: any) => (
                              <span key={detalle.id}>{detalle.detalle}</span>
                            ))
                          }
                        </div>
                        <Slider
                          min={0}
                          max={question.detalle_killers.length - 1}
                          step={1}
                          value={values[`val${index}`]?.respuesta}
                          onChange={(e) => {
                            const result = {
                              id: question.id,
                              "respuesta": e,
                              "type": 'lineal'
                            };
                            setFieldValue(`val${index}`, result);
                          }}
                          tooltip={{ formatter: null }}
                          trackStyle={{ backgroundColor: "#0778b1" }}
                          railStyle={{ backgroundColor: "#d9d9d9" }}
                        />
                      </div>
                    );
                    break;
                  case 'radio':
                    questionElement = (
                      <Radio.Group
                        className="mt-4 font-bold text-body-md"
                        onChange={(e) => {
                          const result = {
                            id: question.id,
                            "respuesta": e.target.value,
                            "type": 'radio'
                          };
                          setFieldValue(`val${index}`, result);
                        }}
                      >
                        {
                          question.detalle_killers.map((detalle: any) => (
                            <CustomRadio key={detalle.id} value={detalle.id}>
                              {detalle.detalle}
                            </CustomRadio>
                          ))
                        }
                      </Radio.Group>
                    );
                    break;
                  case 'numerico':
                    questionElement = (
                      <div className="flex flex-wrap items-center mt-[10px]">
                        <CustomInputNumber
                          value={numValue1}
                          onChange={setNumValue1}
                          onchangeDefault={(value: any) => {
                            const result = {
                              id: question.id,
                              "respuesta": value,
                              "type": 'numerico'
                            };
                            setFieldValue(`val${index}`, result);
                          }}
                        />
                      </div>
                    );
                    break;
                    case 'checkbox':
                      questionElement = (
                        <div className="flex flex-wrap">
                          {question.detalle_killers.map((detalle: any, index_detalle: number) => (
                            <div key={index_detalle} className="flex items-center mb-3 mr-5">
                              <Checkbox
                                className="custom-checkbox"
                                checked={values[`val${index}`]?.respuestas?.some((respuesta: any) => respuesta.id === detalle.id) || false}
                                onChange={(e) => {
                                  let newResponses = values[`val${index}`]?.respuestas || [];
                                  if (e.target.checked) {
                                    // Add the checked option to the responses array
                                    newResponses = [...newResponses, detalle];
                                  } else {
                                    // Remove the unchecked option from the responses array
                                    newResponses = newResponses.filter((rp: any) => rp.id !== detalle.id);
                                  }
                    
                                  const result = {
                                    id: question.id,
                                    respuestas: newResponses,
                                    type: 'checkbox',
                                  };
                    
                                  setFieldValue(`val${index}`, result);  // Update the form field with the new responses
                                }}
                              />
                              <span className="ml-1 font-bold text-body-md mr-[15px]">
                                {detalle.detalle}
                              </span>
                            </div>
                          ))}
                        </div>
                      );
                      break;
                    
                  case 'respuesta':
                    questionElement = (
                      <Input.TextArea
                        rows={5}
                        className="w-full border my-[8px] border-[#D9D9D9] placeholder:text-green32 focus:placeholder:text-grays hover:placeholder:text-black hover:bg-gray3 hover:border-2 hover:border-[#D9D9D9] hover:text-black focus:border-4 focus:border-[#91c3fd] focus:text-[#757575] rounded-[8px] transition-all duration-200 text-[#757575] font-normal text-body-md"
                        style={{ height: 80 }}
                        onChange={(e) => {
                          const result = {
                            id: question.id,
                            "respuesta": e.target.value,
                            "type": 'respuesta'
                          };
                          setFieldValue(`val${index}`, result);
                        }}
                      />
                    );
                    break;
                  default:
                    questionElement = null;
                }

                return (
                  <div className="mb-8" key={question.id}>
                    <p className="font-bold text-heading-x1">
                      {index + 1}. {question.pregunta}
                    </p>
                    {questionElement}
                  </div>
                );
              })
            }
            {errors.form && <div className="text-red-500 text-center mb-4">{errors.form}</div>}
            <div style={{ textAlign: "center" }} className="my-[38px]">
              <Button
                className="bg-blue3 text-white w-[186px] ml-[0px] principal-nav-notify-button2 rounded-[4px]"
                htmlType="submit"
                disabled={isSubmitting}
              >
                Enviar inscripción
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default KillerQuestions;
