import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReviewCard from '../myPortal/CardProfile';
import { Row, Col } from 'antd';
import IconShare from '../../../assets/icons/shareP.svg';
import "tailwindcss/tailwind.css";
import { AppDispatch, RootState } from '../../../redux/store/store';
import { GetUserRatingsByTokenReducer } from '../../../redux/actions/pages/myPortal/ratings/GetRatingPortal';
import CarouselReviews from '../../carousels/CarouselReviews';
import ValuationModal from '../../../components/pages/modals/ModalQR';

const ReviewSection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { rex_user } = useSelector(({ auth }: any) => auth);

  const {
    rex_userRatingsByToken,
    rex_loading,
    rex_error
  } = useSelector(({ getRatingsPortal }: any) => getRatingsPortal);

  useEffect(() => {
    dispatch(GetUserRatingsByTokenReducer())
      .then(() => {
        // Aseguramos que las valoraciones estén ordenadas después de obtenerlas
        const sorted = rex_userRatingsByToken?.valoraciones_usuarios?.sort((a: any, b: any) => {
          return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
        });
        // Luego actualizamos el estado con las valoraciones ordenadas si es necesario
      })
  }, []);
  

  const closeModal = () => {
    setIsModalVisible(false);
  }
  const showModal = () => { 
    setIsModalVisible(true);
  };

  const sortedRatings = rex_userRatingsByToken?.valoraciones_usuarios?.sort((a: any, b: any) => {
    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
  });
  

  if (rex_loading) return <div>Cargando valoraciones...</div>;
  if (rex_error) return <div>Error: {rex_error}</div>;

  return (
    <div className="pb-[24px]">
      <h1 className="text-heading-md font-bold py-[10px] flex items-center">
        Valoraciones
        <img
          src={IconShare}
          alt="Compartir"
          onClick={showModal}
          className="inline-block text-sky-blue0 pl-3 cursor-pointer"
        />
      </h1>
      <Row align="top">
        <CarouselReviews
          border={true}
          data_reviews={rex_userRatingsByToken?.valoraciones_usuarios?.map((rating: any) => ({
            ...rating,
            anonimo: rating.anonimo,  // Asegúrate de pasar el campo anonimo
          }))}
          loading={false}
        />
      </Row>

      <ValuationModal
        visible={isModalVisible}
        onClose={closeModal}
        link={rex_user?.link_valoracion}
        tipoUsuarioId={rex_user?.tipo_usuario_id} // Ajusta esto según tu lógica
      />
    </div>
  );
};

export default ReviewSection;