import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, Divider, message, Input } from 'antd';
import Sliders from "../../../components/pages/offers/CreateOffers/Slider";
import "../../../components/styles/pages/principalNav/PrincipalNav.css";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { GetProfilesSkillsReducer, SaveProfileSkillsReducer } from '../../../redux/actions/offers/skills/ProfileSkills';

interface DataSoftSkill{
  id: number;
  soft_skill: string;
  soft_skill_c: string;
  aprobado: boolean;
  createdAt: string;
  updatedAt: string
}

const styleModal = {
  border: '2px solid #006497',
  borderRadius: '8px',
  overflow: 'hidden',
  padding: '0px',
}

const QuestionModal: React.FC<any> = ({ visible, onClose }) => {

  const {
    rex_aptitudes,
  } = useSelector(({ softskill }: any) => softskill);

  const {
    rex_loading,
  } = useSelector(({ profileSkills }: any) => profileSkills);

  const [ dataSoftSkills, setDataSoftSkills ] = useState<DataSoftSkill[]>()
  const [ showModalInput, setShowModalInput ] = useState<boolean>(false)
  const [ nameProfileSoftSkills, setNameProfileSoftSkills] = useState<string>('')

  const dispatch = useDispatch<AppDispatch>()

  const initializeSoftSkills = () => {
    const updatedAptitudes = rex_aptitudes.map((item:any) => ({
      ...item,
      value: 5,
    }))
    
    setDataSoftSkills(updatedAptitudes);  
  }

  useEffect(()=>{
    initializeSoftSkills()
  },[visible])

  const onChangeValue = (value:number, index:number) => {
    setDataSoftSkills((prev:any) =>
      prev.map((item:any, i:number) =>
        i === index ? { ...item, value } : item
      )
    )
  }

  const onSaveProfileSoftSkills = async () => {
    if(nameProfileSoftSkills == ""){
      message.warning('El nombre es necesario')
      return
    }
    const data = dataSoftSkills?.map((dat:any) => {
      return {
        id: dat.id,
        soft_skill: dat.soft_skill,
        value: dat.value
      }
    })
    if(Array.isArray(data)){
      const respuesta = await dispatch(SaveProfileSkillsReducer({nombre:nameProfileSoftSkills, data}))
      if(respuesta){
        message.success('Perfil creado con exito')
        setShowModalInput(false)
        onClose()
        dispatch(GetProfilesSkillsReducer())
      }else{
        message.error('Error, operacion no realizada')
      }
    }
  }

  return (
    <div>
      <Modal 
        open={showModalInput}
        closable={false}
        width={550}
        footer={null}
        style={styleModal}
      >
        <div className='mb-5'>
          <h2 className='text-body-md font-sans mt-2 mb-1'>Nombre de perfil Soft Skills:</h2>
          <Input
            className='font-sans border border-blue3 rounded-[4px]'
            value={nameProfileSoftSkills}
            onChange={(e)=> {
              setNameProfileSoftSkills(e.target.value)
            }}
          />
        </div>
        <Row className='flex justify-center'>
          <Col>
            <Button onClick={()=>setShowModalInput(false)} className="mr-2 principal-nav-notify-button h-[36px] w-[68px] border rounded-[4px] font-sans font-semibold text-sm">
              Cerrar
            </Button>
          </Col>
          <Col>
            <Button 
              className='bg-blue3 text-white h-[36px] border rounded-[4px] principal-nav-notify-button2 font-sans font-semibold text-sm' 
              onClick={onSaveProfileSoftSkills}
              loading={rex_loading}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={null}
        open={visible}
        onCancel={onClose}
        width={584}
        footer={null}
        centered={true} 
        maskClosable={false}
        closable={false} 
        style={styleModal}
      >
        <div className='pt-[35px] px-[24px]'>
          <h2 className="text-heading-md font-bold font-sans text-black2">
            Creación de perfil de soft skill
          </h2>
          <p className="text-body-md font-sans mt-2">
            Crea tu perfil ideal para esta oferta
          </p>
          <Divider className="bg-black mb-8" />

          <div className="w-full h-auto pb-4">
            {
              dataSoftSkills?.map((apt:any, index:number) => (
                <Sliders
                  key={index}
                  minLabel={apt.soft_skill}
                  maxLabel={apt.soft_skill_c}
                  defaultValue={apt.value}
                  skillId={apt.id} 
                  onChange={(value)=>{
                    onChangeValue(value, index)
                  }}
                  hasValue={true}
                />  
              ))
            }
          </div>

          <Row gutter={[12,12]} className='flex justify-center md:justify-between mt-8 mb-3'>
            <Col>
                <Button className="text-blue3 border border-white h-[36px] principal-nav-notify-buttonS ml-0 shadow-none font-sans font-semibold text-sm" onClick={initializeSoftSkills}>
                  Resetear todos los parámetros
              </Button>
            </Col>
            <Col className='w-full md:w-auto'>
              <Row className='flex justify-center'>
                <Col>
                  <Button onClick={onClose} className="mr-2 principal-nav-notify-button h-[36px] w-[68px] border rounded-[4px] font-sans font-semibold text-sm">
                    Cerrar
                  </Button>
                </Col>
                <Col>
                  <Button 
                    className='bg-blue3 text-white h-[36px] border rounded-[4px] principal-nav-notify-button2 font-sans font-semibold text-sm' 
                    onClick={()=> {
                      setNameProfileSoftSkills('')
                      setShowModalInput(true)
                    }}
                    loading={rex_loading}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default QuestionModal;
