import React, { useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import IconEmployment from '../../../assets/icons/IconEmployment.svg';
import IconEmploymentWhite from '../../../assets/icons/IconEmploymentWhite.svg';
import IconArrow from '../../../assets/icons/IconArrow.svg';
import IconArrowWhite from '../../../assets/icons/IconArrowWhite.svg';
import '../../../styles/pages/login/CardLoginCandidate.css'

const CardEmployment: React.FC = () => {
  const navigate = useNavigate();

  const [hoverCard, setHoverCard] = useState(false)

  const handleClick = () => {
    navigate("/registerEmployment"); // Redirige a la vista de la empresa
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <Card
        className={`shadow-md rounded-[12px] py-[1px] px-[20px] h-[127px] bg-white border-none card-register-candidate`}
        onMouseEnter={() => setHoverCard(true)}
        onMouseLeave={() => setHoverCard(false)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img 
              src={hoverCard ? IconEmploymentWhite : IconEmployment}
              alt="Employment Icon" className="mr-4 w-[26px]" 
            />
            <div className="max-w-[360px]">
              <h1 className={`font-sans font-semibold text-heading-sm text-black2`}>
                Soy Empresa
              </h1>
              <p className={`font-sans text-body-sm font-medium text-black2`}>
                Conoce a tus candidatos como nunca antes se había hecho.
              </p>
            </div>
          </div>
          <img
            src={hoverCard ? IconArrowWhite : IconArrow}
            alt="Arrow Icon" className={`text-gray-500 hover:text-white`}
          />
        </div>
      </Card>
    </div>
  );
};

export default CardEmployment;
