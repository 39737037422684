// reducer/GetApplicationsReducer.ts
import {
    FETCH_GET_APPLICATIONS_REQUEST,
    FETCH_GET_APPLICATIONS_SUCCESS,
    FETCH_GET_APPLICATIONS_FAILURE,
    SET_FILTER_PARAMS_APLICATIONS,
    SET_FILTER_PARAMS_APLICATIONS_TWO
  } from '../../../constants/applications/GetApplications';
  
  interface ApplicationState {
    rex_loading: boolean;
    rex_data: any[];
    rex_error: string | null;
    rex_meta: object | null;
    rex_limit: number;
    rex_page: number;
    rex_total: number;
    filterParams: any;
    filterParams_two: any;
  }
  
  const initialState: ApplicationState = {
    rex_loading: false,
    rex_data: [],
    rex_error: null,
    rex_meta: null,
    rex_limit: 10,
    rex_page: 1,
    rex_total: 0,
    filterParams: {},
    filterParams_two: {}
  };
  
  const GetApplicationsReducer = (state = initialState, action: any): ApplicationState => {
    switch (action.type) {
      case FETCH_GET_APPLICATIONS_REQUEST:
        return { 
          ...state, 
          rex_loading: true, 
          rex_error: null 
        };
      case FETCH_GET_APPLICATIONS_SUCCESS:
        return { 
          ...state, 
          rex_loading: false, 
          rex_data: action.payload.meta.page == 1 ? [...action.payload.data] : [...state.rex_data, ...action.payload.data],
          rex_meta: action.payload.meta,
          rex_total: action.payload.meta.total,
          rex_limit: action.payload.meta.limit,
          rex_page: action.payload.meta.page
        };
      case FETCH_GET_APPLICATIONS_FAILURE:
        return { 
          ...state, 
          rex_loading: false, 
          rex_error: action.error 
        };
      case SET_FILTER_PARAMS_APLICATIONS:       
        // Compara si el nuevo filtro es igual al actual
        console.log('Entre a Action filters')
        if (JSON.stringify(action.payload) === JSON.stringify(state.filterParams)) {
          console.log('entre 2222222222222222222222')
          return state; // Si son iguales, no haces cambios en el estado
        }
        
        // Si son diferentes, actualiza el estado
        return {
          ...state,
          filterParams: action.payload,
          rex_data: [] // Reinicia los datos si los filtros cambian
        };
      case SET_FILTER_PARAMS_APLICATIONS_TWO:       
        // Compara si el nuevo filtro es igual al actual
        console.log('Entre a Action filters222222222222222222222222')
        // Si son diferentes, actualiza el estado
        return {
          ...state,
          filterParams_two: action.payload,
          rex_data: [] // Reinicia los datos si los filtros cambian
        };
      default:
        return state;
    }
  };
  
  export default GetApplicationsReducer;
  