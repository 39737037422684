import React, { useState, useEffect } from "react";
import ToggleSwitch from "../../toggleSwitch/ToggleSwitch";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "../../../components/pages/myPortal/Date";

interface SwitchOptionsProps {
  visible: boolean;
  onClose: () => void;
  defaultWorking?: boolean;
  initialStartDate?: string | null;
  initialEndDate?: string | null;
  onStartDateChange: (date: string | null) => void;
  onEndDateChange: (date: string | null) => void;
  onCurrentlyWorkingChange: (isWorking: boolean) => void;
  titleText?: string;
}

const SwitchOptions: React.FC<SwitchOptionsProps> = ({
  visible,
  onClose,
  defaultWorking = false,
  initialStartDate = null,
  initialEndDate = null,
  onStartDateChange,
  onEndDateChange,
  onCurrentlyWorkingChange,
  titleText = "¿Sigues trabajando aquí?",
}) => {
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(defaultWorking);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    initialStartDate ? dayjs(initialStartDate) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    initialEndDate ? dayjs(initialEndDate) : null
  );
  const [totalDuration, setTotalDuration] = useState<string>("0 años, 0 meses");

  useEffect(() => {
    setStartDate(initialStartDate ? dayjs(initialStartDate) : null);
    setEndDate(initialEndDate ? dayjs(initialEndDate) : null);
    setIsCurrentlyWorking(defaultWorking);
  }, [initialStartDate, initialEndDate, defaultWorking]);


  // Función de cálculo de duración
  const calculateDuration = (start: Dayjs | null, end: Dayjs | null) => {
    if (!start) {
      setTotalDuration("0 años, 0 meses");
      return;
    }

    const effectiveEnd = isCurrentlyWorking ? dayjs() : end;

    // Verifica si effectiveEnd es null
    if (!effectiveEnd) {
      setTotalDuration("0 años, 0 meses");
      return;
    }

    if (start.isAfter(effectiveEnd)) {
      setTotalDuration("0 años, 0 meses");
      return;
    }

    const diffInYears = effectiveEnd.diff(start, "year");
    const diffInMonths = effectiveEnd.diff(start, "month") % 12;

    setTotalDuration(`${diffInYears} años, ${diffInMonths} meses`);
  };


  // Calcula la duración cuando las fechas cambian
  useEffect(() => {
    calculateDuration(startDate, endDate);
  }, [startDate, endDate, isCurrentlyWorking]);

  useEffect(() => {
    if (isCurrentlyWorking) {
      // Limpia endDate automáticamente al activar el switch
      setEndDate(null);
    }
  }, [isCurrentlyWorking]);


  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setStartDate(date);
      onStartDateChange(formattedDate);
      if (isCurrentlyWorking) {
        setEndDate(date);
        onEndDateChange(formattedDate);
      }
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (!isCurrentlyWorking && date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setEndDate(date);
      onEndDateChange(formattedDate);
    } else if (!date) {
      setEndDate(null);
      onEndDateChange(null);
    }
  };


  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h2 className="mb-3 text-body-md font-normal text-gray-800">
          {titleText}
        </h2>
        <ToggleSwitch
          defaultChecked={isCurrentlyWorking}
          size="small"
          customClass="switch-small-1"
          onChange={() => {
            const newWorkingStatus = !isCurrentlyWorking;
            setIsCurrentlyWorking(newWorkingStatus);

            if (newWorkingStatus) {
              // Switch activado (sigue trabajando): limpia la fecha de fin
              setEndDate(null);
              onEndDateChange(null);
            } else {
              // Switch desactivado (deja de trabajar): no se hace nada, pero asegura que la fecha de fin esté limpia
              setTimeout(() => {
                setEndDate(null); // Asegura que el estado de endDate esté vacío inmediatamente
                onEndDateChange(null); // Notifica al padre
              }, 0);
            }

            // Llama a la función del padre para notificar el cambio
            onCurrentlyWorkingChange(newWorkingStatus);
          }}
        />
      </div>

      {isCurrentlyWorking ? (
        <div className="flex items-center justify-between space-x-[80px]">
          <CustomDatePicker
            useInput={true}
            onChange={(date) => handleStartDateChange(date)}
            value={startDate ? dayjs(startDate) : null}
            placeholder="Desde"
          />
        </div>
      ) : (
        <div className="flex items-center justify-between space-x-[10px]">
          <CustomDatePicker
            useInput={false}
            onChange={(date) => handleStartDateChange(date)}
            value={startDate ? dayjs(startDate) : null}
            placeholder="Fecha de inicio"
          />
          <CustomDatePicker
            useInput={false}
            onChange={(date) => handleEndDateChange(date)}
            value={endDate ? dayjs(endDate) : null} // Si endDate es null, el campo queda vacío
            placeholder="Fecha de fin"
          />

        </div>
      )}

      <h3 className="mt-3 text-[#5F5F5F] text-body-md font-medium text-gray-800">
        Tiempo total: {totalDuration}
      </h3>
    </div>
  );
};

export default SwitchOptions;
