import React, { useState } from 'react';
import { Col, Row, Select } from 'antd';
import '../../../styles/alertFilter/AlertFilter.css';

const { Option } = Select;

interface FilterParams {
  fases_postulaciones?: boolean;
  fase_descartado?: boolean;
  cv_Descargado?: boolean;
  visto_Empresa?: boolean;
}

interface FilterButtonsApplicationProps {
  categorias: { valor: string, etiqueta: string }[];
  alClicEnCategoria?: (filters: Partial<FilterParams>) => void;
}

const FilterButtonsApplication: React.FC<FilterButtonsApplicationProps> = ({
  categorias,
  alClicEnCategoria = () => { },
}) => {
  const [isQuickApply, setIsQuickApply] = useState(false);


  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState<string | null>("todas-las-candidaturas");

  const handleCategoryClick = (valor: string, indice: number) => {
    const isSelected = categoriaSeleccionada === valor;
    const nuevoValor = !isSelected;
    setCategoriaSeleccionada(nuevoValor ? valor : null);

    if (indice === 4) {
      alClicEnCategoria?.({
        fase_descartado: nuevoValor,
        visto_Empresa: false,
        cv_Descargado: false,
        fases_postulaciones: false,
      });
    } else if (indice === 3) {

      alClicEnCategoria?.({
        fases_postulaciones: nuevoValor,
        visto_Empresa: false,
        cv_Descargado: false,
        fase_descartado: false
      });
    } else if (indice === 2) {

      alClicEnCategoria?.({
        cv_Descargado: nuevoValor,
        visto_Empresa: false,
        fases_postulaciones: false,
        fase_descartado: false
      });
    } else if (indice === 1) {

      alClicEnCategoria?.({
        visto_Empresa: nuevoValor,
        cv_Descargado: false,
        fases_postulaciones: false,
        fase_descartado: false
      });
    } else {

      alClicEnCategoria?.({
        visto_Empresa: false,
        cv_Descargado: false,
        fases_postulaciones: false,
        fase_descartado: false
      });
      console.log('Otro botón seleccionado');
    }
  };


  return (
    <div className="alert-filter-container mb-4 text-center">
      <h2 className="text-left font-bold text-[20px] mb-4 -mt-4">Candidaturas activas</h2>
      {/* <div className="alert-filter-buttons"> */}
      <Row gutter={[12, 12]}>
        {categorias.map((categoria, indice) => (
          <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <button
              key={indice}
              className={`
                alert-filter-button w-full mr-2 flex justify-center text-[16px] font-sans font-semibold ${categoriaSeleccionada === categoria.valor ? '!border-[#006497] text-[#006497] alert-filter-button2' : ''
                }`}
              onClick={() => handleCategoryClick(categoria.valor, indice)}
            >
              {categoria.etiqueta}
            </button>
          </Col>
        ))}
      </Row>
      {/* </div> */}
    </div>
  );
};

export default FilterButtonsApplication;
