import React, { useEffect, useState } from 'react';
import { Slider } from 'antd';
import CheckboxC from "../../../components/checkbox/CheckboxProps";

interface AppProps {
  onValueChange?: (values: number[]) => void;
  reset?:boolean
}

const App: React.FC<AppProps> = ({ onValueChange, reset }) => {
  const [value, setValue] = useState<number[]>([1, 10])
  const [ rockieSelected, setRockieSelected ] = useState<boolean>(false)

  const onChange = (newValue: number[], isRockie:boolean) => {
    setValue(isRockie? [0,0] : rockieSelected? [1,10] : newValue)
    setRockieSelected(isRockie)
    if (onValueChange) {
      onValueChange((rockieSelected? [1,10] : newValue).map(mnt => mnt * 12))
    }
  }

  useEffect(() => {
    if (reset) {
      setRockieSelected(false)
      setValue([1,10])
    }
  }, [reset])

  return (
    <div>
      <Slider
        range={{ draggableTrack: true }}
        min={0}
        value={value}
        max={10}
        marks={{
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '+10',
        }}
        onChange={(value:any)=> onChange(value, false)}  // Asocia el evento onChange
      />
      <CheckboxC
        checked={rockieSelected}
        onChange={(e)=> {
          onChange([0,0], e.target.checked)
        }}
        
        children={<span className='font-sans text-sm text-gray -ml-[4px]'>Rockies</span>}
      />
    </div>
  );
};

export default App;
