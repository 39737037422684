import React from 'react'
import ImgLogo from '../../assets/logo/logo.png';

const Footer: React.FC = () => {
  return (
    <div className='p-5 flex justify-center'>
        <img src={ImgLogo} alt="Merere Logo" className="" />
    </div>
  )
}

export default Footer