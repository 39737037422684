import React, { useState, useEffect } from 'react';
import { Row, Col, Skeleton, Button, Affix } from 'antd';
import CardEmpleo from '../../../components/cards/CardEmployment';
import InformationSaved from '../../../components/pages/myApplications/saved/InformationSaved';
import FilterButtons from '../../../components/pages/myApplications/saved/FilterButtons';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/store';
import { GetOfferSaveReducer } from '../../../redux/actions/offers/GetOfferSave';
import { useSearchParams, useLocation } from 'react-router-dom';
import { fetchCancelApplicationRequestReducer, searchSavedOffers } from '../../../redux/actions/applications/SearchSavedApplications';
import { searchApplicationsByCargo } from '../../../redux/actions/applications/SearchApllications';
import InfiniteScroll from 'react-infinite-scroll-component';

interface SavedPageProps {
  searchInitiated: boolean;
  searchQuery: string;
}

const SavedPage: React.FC<SavedPageProps> = ({ 
  searchInitiated,
  searchQuery
}:any) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    rex_data: rawCardsData,
    rex_limit,
    rex_total
  } = useSelector(({ getOfferSave }: any) => getOfferSave);

  const {
    rex_savedOffers: searchResults, rex_loading: searchLoading
  } = useSelector((state: RootState) => state.searchSaveApplication);

  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [appliedCards, setAppliedCards] = useState<{ [key: number]: boolean }>({});
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>({ 0: true, 1: true, 2: true });
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
   const [loading, setLoading] = useState(false);

  const selectedCountry = searchQuery.selectedCountry
  const selectedCity = searchQuery.selectedCity
  const selectedSectores = searchQuery.selectedSectores
  const selectedTerm = searchQuery.searchTerm

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoadingCards({ 0: false, 1: false, 2: false });
  //   }, 500);

  //   return () => clearTimeout(timer);
  // }, []);

  
  
  // const getData = async () => {
  //   setSelectedCardIndex(null)
  //   const cargo = searchParams.get('cargo') || '';
  //   const sector = searchParams.get('sector') || '';
  //   const ubiPoblacion = searchParams.get('ubi_poblacion') || '';
  //   const ubiProvincia = searchParams.get('ubi_provincia') || '';
  //   if (location.pathname === '/myApplications/applications') {
  //     await dispatch(searchApplicationsByCargo(cargo, sector, ubiPoblacion, ubiProvincia, 1, 10));
  //   } else if (location.pathname === '/myApplications/saved') {
  //     await dispatch(searchSavedOffers(cargo, sector, ubiPoblacion, ubiProvincia, 1, 10))
  //   }
  // }
  const searchData = () => {
      setLoading(true);
          setPage(1);
          if (selectedCountry && selectedCity && selectedSectores && selectedTerm){
            console.log('entre prmiero')
            dispatch(GetOfferSaveReducer(
              1,
              rex_limit,
              selectedCountry,
              selectedCity,
              selectedSectores,
              selectedTerm,    
            ))
          }
          else if (selectedCountry && selectedCity && selectedSectores){
            console.log('entre prmiero')
            dispatch(GetOfferSaveReducer(
              1,
              rex_limit,
              selectedCountry,
              selectedCity,
              selectedSectores,
              ""
            ))
          }
          else if (selectedCountry && selectedCity){
            console.log('selectedCountry:',selectedCountry)
            dispatch(GetOfferSaveReducer(
              1,
              rex_limit,
              selectedCountry ,
              selectedCity,
              "",
              ""
            )).then(() => setLoading(false));
          }
          else if (selectedSectores) {
            dispatch(
              GetOfferSaveReducer(
                1,
                rex_limit,
                "",
                "",
                selectedSectores,
                "",
              )
            ).then(() => setLoading(false));
          }
          else if (selectedTerm) {
            dispatch(
              GetOfferSaveReducer(
                1,
                rex_limit,
                "",
                "",
                "",
                selectedTerm
              )
            ).then(() => setLoading(false));
          }
          else {
            console.log('entre')
            setLoading(true);
            dispatch(GetOfferSaveReducer(
                1,
                rex_limit,
                "",
                "",
                "",
                "",
              )
            ).then(() => setLoading(false));
          }

  }

  useEffect(() => {
      searchData()
  }, [selectedCountry, selectedCity, selectedSectores,rex_total,rex_limit,selectedTerm]);

  // useEffect(() => {
  //   dispatch(fetchCancelApplicationRequestReducer());
  //   dispatch(GetOfferSaveReducer() as any);
  // }, [])

  

  // Formatear los datos obtenidos desde el estado de Redux
  const formattedData = rawCardsData?.map((job: any) => ({
    exampleProps: {
      companyName: job.empresa_id ? job.empresa : 'Empresa desconocida',
      jobTitle: job.cargo || 'Título desconocido',
      location: job.ubi_poblacion || 'Ubicación no disponible',
      salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
      schedule: job.jornada_laboral || 'Horario no especificado',
      description: job.descripcion || 'Descripción no disponible',
      postedTime: job.updatedAt && !isNaN(new Date(job.updatedAt).getTime())
        ? `Subida hace ${Math.floor((Date.now() - new Date(job.updatedAt).getTime()) / (1000 * 60 * 60))}h`
        : 'Fecha no disponible',
      applied: job.inscrito,
      createdAt: job.createdAt,
      sector: job.sector || '',
      aptitudes: job.aptitudes,
      avatar: job.logo,
      ratings: job.rating,
      activeOffers: job.ofertas_activas,
      followers: job.seguidores,
    },
    informationProps: {
      id: job.id,
      title: job.cargo || 'Título desconocido',
      company: job.empresa_id ? job.empresa : 'Empresa desconocida',
      location: job.ubi_poblacion || 'Ubicación no disponible',
      employmentType: job.jornada_laboral || 'Tipo de jornada no disponible',
      salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
      comments: 0, // Este es estático
      applicantsCount: job.num_inscritos + ' Inscritos',
      introText: job.descripcion_empresa || 'Descripción no disponible',
      sector: job.sector || '',
      aptitudes: job.aptitudes,
      applied: job.inscrito,
      ratings: job.rating,
      activeOffers: job.ofertas_activas,
      followers: job.seguidores,
      requirements: [
        {
          title: "Estudios mínimos",
          requirement: job.estudios_minimos,
        },
        {
          title: "Años de experiencia",
          requirement: job.anios_experiencia == 0 ? "Sin experiencia" : job.anios_experiencia,
        }
      ],
      responsibilities: [
        job.descripcion || 'Descripción no disponible'
      ],
      extraText: [
        '' // Estático
      ],
      extraText2: [
        ''
      ],
      avatar: job.logo,
      postedTime: job.updatedAt
    },
    postulaciones_guardadas: job.postulaciones_guardadas
  }));

  const formattedSearchResults = searchResults?.map((job: any) => ({
    exampleProps: {
      companyName: job.empresa_id ? job.empresa : 'Empresa desconocida',
      jobTitle: job.cargo || 'Título desconocido',
      location: job.ubi_poblacion || 'Ubicación no disponible',
      salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
      schedule: job.jornada_laboral || 'Horario no especificado',
      ratings: job.rating,
      activeOffers: job.ofertas_activas,
      aptitudes: job.aptitudes,
      followers: job.seguidores,
      sector: job.sector || '',
      description: job.descripcion || 'Descripción no disponible',
      postedTime: job.updatedAt,
      applied: job.inscrito,
      createdAt: job.createdAt
    },
    informationProps: {
      id: job.id,
      title: job.cargo || 'Título desconocido',
      company: job.empresa_id ? job.empresa : 'Empresa desconocida',
      location: job.ubi_poblacion || 'Ubicación no disponible',
      employmentType: job.jornada_laboral || 'Tipo de jornada no disponible',
      salary: job.sal_max ? `${job.sal_min} - ${job.sal_max}€` : 'Salario no disponible',
      comments: 0,
      aptitudes: job.aptitudes,
      applied: job.inscrito,
      sector: job.sector || '',
      applicantsCount: job.num_inscritos + ' Inscritos',
      introText: job.descripcion_empresa || 'Descripción no disponible',
      requirements: [
        {
          title: "Estudios mínimos",
          requirement: job.estudios_minimos,
        },
        {
          title: "Años de experiencia",
          requirement: job.anios_experiencia == 0 ? "Sin experiencia" : job.anios_experiencia,
        }
      ],
      responsibilities: [
        job.descripcion || 'Descripción no disponible'
      ],
      extraText: [
        '' // Estático
      ],
      extraText2: [
        ''
      ],
      postedTime: job.updatedAt
    },
    postulaciones_guardadas: job.postulaciones_guardadas
  }));

  const fetchMoreData = () => {
      if (loadingMoreData || !hasMore) {
        return;
      }
      setLoadingMoreData(true);
      const nextPage = page + 1;
      const totalPages = Math.ceil(rex_total / rex_limit);
  
      setTimeout(() => {
        dispatch(GetOfferSaveReducer(
          nextPage,
          rex_limit,
          selectedCountry,
          selectedCity             
        )).then(() => {
          setPage(nextPage);
          setLoadingMoreData(false);
          if (nextPage >= totalPages) {
            setHasMore(false);
          }
        })
          .catch(() => {
            setLoadingMoreData(false);
          });
      }, 1000);
    };

  const handleCardClick = (index: number) => {
    setLoadingInfo(true);
    setSelectedCardIndex(index);

    setTimeout(() => {
      setLoadingInfo(false);
    }, 500);
  };

  const handleApply = (index: number | null) => {
    if (index !== null) {
      setAppliedCards((prev) => ({
        ...prev,
        [index]: !prev[index]
      }));
    }
  };

  const handleCategoriaClick = (valor: string) => {
    alert(`Categoría seleccionada: ${valor}`);
  }

  const offersToShow = searchInitiated && formattedSearchResults.length > 0 ? (formattedSearchResults) : (formattedData || []);
  const categorias = [
    { valor: 'todas', etiqueta: 'Todas', conteo: offersToShow.length },
    // { valor: 'campo-de-criptana', etiqueta: 'Reponedor en Campo de Criptana', conteo: 0 },
    // { valor: 'disenador-ux', etiqueta: 'Diseñador UX en Toda España', conteo: 0 },
    // { valor: 'generico', etiqueta: 'Genérico', conteo: 0 },
  ];



 useEffect(() => {
    if (rex_total > 0 ) {
      if (rex_total == 0) setHasMore(false)
      if (rawCardsData?.length == rex_total) {
        setHasMore(false)
      }

    }
  }, [rex_total, rawCardsData])

  return (
    <div style={{ padding: '10px' }}>
      <FilterButtons
        categorias={categorias}
        alClicEnCategoria={handleCategoriaClick}
      />
      {/* <Button
        onClick={()=>{
          console.log('applications:',rawCardsData)
          console.log('rex_total:',rex_total)
          console.log('rex_limit:',rex_limit)
          console.log('applications:',rawCardsData)
          console.log('hasmore:',hasMore)
          console.log('applications.lenghyt:',rawCardsData.length)
          // console.log('filteredApplications:',filteredApplications)
          console.log('searchResults:',searchResults)
          console.log('searchInitiated:',searchInitiated)
          console.log('País seleccionado:', selectedCountry);
          console.log('Ciudad seleccionada:', selectedCity);
          console.log('selectedSectores:', selectedSectores);
          console.log('selectedTerm:', selectedTerm);
        }}
      >
        Aqui
      </Button> */}
     
      <Row gutter={[16, 16]}>
        <Col xs={24} md={10}>
          {/* {(searchLoading || rex_loading) ? (
            <div>Cargando datos...</div>
          ) : offersToShow.length === 0 ? (
            <div>No se encontraron resultados para el término de búsqueda.</div>
          ) : ( */}
           <InfiniteScroll
            className='w-full pt-[2px] md:pl-[2px] pl-[8px] pr-[8px] box-border'
            dataLength={offersToShow ? offersToShow.length : 0}
            next={fetchMoreData}
            hasMore={hasMore && rex_total >= rex_limit}
            loader={
              <div style={{ marginBottom: '16px' }}>
                <Skeleton
                  active
                  avatar={{ shape: 'square', size: 'large' }}
                  paragraph={{ rows: 3 }}
                />
              </div>
            }
            endMessage={<p>No hay más ofertas para mostrar</p>}
          >
          { offersToShow.map((card: any, index: number) => (
              <div
                key={index}
                onClick={() => handleCardClick(index)}
                className={`cursor-pointer mb-5 p-4 rounded-lg ${selectedCardIndex === index ? "shadow-lg" : "hover:shadow-md"}`}
              >
                <CardEmpleo
                  {...card.exampleProps}
                  applied={card.exampleProps.applied}
                  loading={loading} styleType={"type3"}
                />
              </div>
            ))}
          </InfiniteScroll>
          
         
        </Col>
        <Col xs={24} md={14}>
          {selectedCardIndex !== null && offersToShow[selectedCardIndex] && (
            <Affix
            offsetTop={140}
          >
            <InformationSaved
              {...offersToShow[selectedCardIndex].informationProps}
              loading={loadingInfo}
              onApply={() => handleApply(selectedCardIndex)}
              getData={rawCardsData}
              setSelectedCardIndex={setSelectedCardIndex}
            />
             </Affix>
          )}
          
        </Col>
      </Row>
    </div>
  );
};



export default SavedPage;
