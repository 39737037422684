import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  SEARCH_SAVED_OFFERS_REQUEST,
  SEARCH_SAVED_OFFERS_SUCCESS,
  SEARCH_SAVED_OFFERS_FAILURE,
  SavedOfferActionTypes,
} from '../../../../constants/company/SearchSaveOffers';
import FetchWithIP from '../../utils/FetchHeaders';
import { fetchOffersSuccessReducer } from './Offers';

// Action Creators para búsqueda
export const searchSavedOffersRequest = (): SavedOfferActionTypes => ({
  type: SEARCH_SAVED_OFFERS_REQUEST,
});

export const searchSavedOffersSuccess = (
  data: any[],
  meta: any): SavedOfferActionTypes => ({
  type: SEARCH_SAVED_OFFERS_SUCCESS,
  payload: { data, meta },
});

export const searchSavedOffersFailure = (error: string): SavedOfferActionTypes => ({
  type: SEARCH_SAVED_OFFERS_FAILURE,
  payload: error,
});

// Thunk para buscar ofertas guardadas por cargo
export const searchSavedOffersAction = (
  cargo: string,
  page: number = 1,
  limit: number = 10
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(searchSavedOffersRequest());

    try {
      const response = await FetchWithIP(
        `ofertas/findAllStateBorradorToken?cargo=${cargo}&page=${page}&limit=${limit}&sortOrder=desc&sortColumn=id`,
        { method: 'GET' }
      );

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      if (Array.isArray(responseData.data)) {
        const { total, limit, page } = responseData.meta;
        // dispatch(searchSavedOffersSuccess(responseData.data, { total, limit, page }));
        dispatch(fetchOffersSuccessReducer(
          responseData.data,
          responseData.meta
        ));
      } else {
        throw new Error('Los datos obtenidos no son un array');
      }
    } catch (error) {
      dispatch(searchSavedOffersFailure('Error al buscar ofertas guardadas'));
    }
  };
