import {
  FETCH_POSTULACIONES_REQUEST,
  FETCH_POSTULACIONES_SUCCESS,
  FETCH_POSTULACIONES_FAILURE,
  SET_FILTER_PARAMS,
  UPDATE_DATA_POSTULACIONES,
  UPDATE_DATA_SOFT_SKILLS_OFFER,
  PostulacionesActionTypes,
  Postulacion,
  LabelsGraphSoftSkills
} from '../../../../../constants/pages/offers/offersProfile/GetOffersProfile';

interface PostulacionesState {
  loading: boolean;
  postulaciones: Postulacion[];
  error: string | null;
  soft_skills_offer : {
    users:any[],
    data:any[]
  },
  labels_graph_sk : LabelsGraphSoftSkills[]
  filterParams: any;
  rex_limit: number;
  rex_page: number;
  rex_total: number;
}

const initialState: PostulacionesState = {
  loading: false,
  postulaciones: [],
  error: null,
  soft_skills_offer : {
    users : [],
    data  : []
  },
  rex_limit: 10,
  rex_page: 1,
  rex_total: 0,
  filterParams: {},
  labels_graph_sk : [
    { id : 1, nameKey: '', color: '#9DDEF4' },
    { id : 2, nameKey: '', color: '#C2A5F9' },
    { id : 3, nameKey: '', color: '#FFB3DA' },
    { id : 4, nameKey: '', color: '#73FF70' },
    { id : 5, nameKey: '', color: '#FFE870' },
    { id : 6, nameKey: '', color: '#FF8A70' },
    { id : 7, nameKey: '', color: '#E2D1DA' },
    { id : 8, nameKey: '', color: '#85EFAC' },
    { id : 9, nameKey: '', color: '#B58392' },
    { id : 10, nameKey: '', color: '#FDBA72' },
  ], 
};

export const postulacionesByOfertaReducer = (
  state = initialState,
  action: PostulacionesActionTypes
): PostulacionesState => {
  switch (action.type) {
    case FETCH_POSTULACIONES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_POSTULACIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        postulaciones: action.payload.meta.page == 1 ? [...action.payload.data] : [...state.postulaciones, ...action.payload.data],
        rex_total: action.payload.meta.total,
        rex_limit: action.payload.meta.limit,
        rex_page: action.payload.meta.page,
        error: null,
      };
    case FETCH_POSTULACIONES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        postulaciones: [],
      };
    case UPDATE_DATA_POSTULACIONES:
      return {
        ...state,
        postulaciones: action.payload.data,
      };
    case UPDATE_DATA_SOFT_SKILLS_OFFER:
      return {
        ...state,
        soft_skills_offer: {
          users : action.payload.users,
          data  : action.payload.data
        },
      };
    case SET_FILTER_PARAMS: // Agrega el case para manejar los filtros
      console.log('Entre a los filtros')
        return {
          ...state,
          filterParams: action.payload, 
          postulaciones: action.payload.meta && action.payload.meta.page === 1 ? [] : state.postulaciones,
        };
    default:
      return state;
  }
};

export default postulacionesByOfertaReducer;