import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import IconCalendar from '../../assets/icons/IconCalendar.svg';
import IconGraphic from '../../assets/icons/IconGraphic.svg';
import IconVal from '../../assets/icons/IconVal.svg';
import IconBag from '../../assets/icons/IconBag.svg';
import IconLeft from '../../assets/icons/arrowLeft.svg';

import NewJob from '../notifications/newJob/NewJob';
import NewValuation from '../notifications/newValuation/NewValuation';
import Visibility from '../notifications/profileVisibility/ProfileVisibility';
import ChangesCandidature from '../notifications/chagesCandidature/ChangesCandidature';
import { Col, Row } from 'antd';
import { AppDispatch } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInfoNotificationsReducer } from '../../redux/actions/common/notificacions/Notifications';
import NewApplicants from './newApplicants/NewApplicants';
import NotificationsA from '../../components/pages/notifications/Notifications';

const NotificationsRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  // Obtener información de las notificaciones
  const getInfoNotificationsRestricted = () => {
    dispatch(fetchInfoNotificationsReducer());
  };

  // Obtener el usuario desde el store (asumo que rex_user tiene toda la info del usuario)
  const { rex_user } = useSelector(({ auth }: any) => auth);

  const isCompany = () => {
    return rex_user.tipos_usuarios.tipo_usuario === 'Empresa';
  };

  useEffect(() => {
    getInfoNotificationsRestricted();
  }, []);

  return (
    <>
      <div className="mx-[32px] mt-[10px]">
        <div className="flex items-center mb-5">
          <img
            src={IconLeft}
            alt="Empleo"
            className="mr-2 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-body-xl font-normal">Notificaciones</span>
        </div>

        <Row gutter={[12, 12]} className="w-full">
          <Col className="w-full md:w-1/5">
            <button
              className={`tab-link-merere2 w-full ${
                location.pathname === '/notifications/all' ? 'active-merere' : ''
              }`}
              onClick={() => navigate('/notifications/all')}
            >
              <span className="inline">Todas mis notificaciones</span>
            </button>
          </Col>
          {isCompany() ? (
            <Col className="w-full md:w-1/5">
              <button
                className={`w-full tab-link-merere2 ${
                  location.pathname === '/notifications/new-applicants' ? 'active-merere' : ''
                }`}
                onClick={() => navigate('/notifications/new-applicants')}
              >
                <img src={IconBag} alt="Empleo" className="mr-2 inline" />
                <span className="inline">Nuevos postulantes</span>
              </button>
            </Col>
          ) : (
            <Col className="w-full md:w-1/5">
              <button
                className={`w-full tab-link-merere2 ${
                  location.pathname === '/notifications/job' ? 'active-merere' : ''
                }`}
                onClick={() => navigate('/notifications/job')}
              >
                <img src={IconBag} alt="Empleo" className="mr-2 inline" />
                <span className="inline">Un empleo nuevo para ti</span>
              </button>
            </Col>
          )}

          <Col className="w-full md:w-1/5">
            <button
              className={`w-full tab-link-merere2 ${
                location.pathname === '/notifications/valuation' ? 'active-merere' : ''
              }`}
              onClick={() => navigate('/notifications/valuation')}
            >
              <img src={IconVal} alt="Valoración" className="mr-2 inline" />
              <span className="inline">Tienes una nueva valoración</span>
            </button>
          </Col>
          <Col className="w-full md:w-1/5">
            <button
              className={`w-full tab-link-merere2 ${
                location.pathname === '/notifications/visibility' ? 'active-merere' : ''
              }`}
              onClick={() => navigate('/notifications/visibility')}
            >
              <img src={IconGraphic} alt="Visibilidad" className="mr-2 inline" />
              <span className="inline">Da más visibilidad de tu perfil</span>
            </button>
          </Col>
          {rex_user.tipo_usuario_id !== 3 && (
            <Col className="w-full md:w-1/5">
              <button
                className={`w-full tab-link-merere2 ${
                  location.pathname === '/notifications/applications' ? 'active-merere' : ''
                }`}
                onClick={() => navigate('/notifications/applications')}
              >
                <img src={IconCalendar} alt="Candidaturas" className="mr-2 inline" />
                <span className="inline">Cambios en la candidaturas</span>
              </button>
            </Col>
          )}
        </Row>

        <Row>
          <Col sm={24} md={6}>
            <div className="">
              <h3 className="text-body-md font-bold mb-2 mt-[42px]">Gestiona tus notificaciones</h3>
              <a href="/myPortal/generalSettings" className="text-blue3 text-body-sm font-semibold relative">
                Ajustes generales
                <span className="absolute left-0 right-0 bottom-[-2px] h-[1px] bg-blue3"></span>
              </a>
            </div>
          </Col>
          <Col sm={24} md={18}>
            <Routes>
              <Route path="all" element={<NotificationsA />} />
              <Route
                path="valuation"
                element={<NewValuation userId={rex_user.tipo_usuario_id} />} // Pasar tipo_usuario_id
              />
              <Route path="job" element={<NewJob />} />
              <Route path="visibility" element={<Visibility />} />
              <Route path="applications" element={<ChangesCandidature />} />
              <Route path="new-applicants" element={<NewApplicants />} />
            </Routes>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotificationsRoute;
