// constants/pages/offers/offer/EditOffer.ts

export const EDIT_OFFER_REQUEST = 'EDIT_OFFER_REQUEST';
export const EDIT_OFFER_SUCCESS = 'EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAILURE = 'EDIT_OFFER_FAILURE';

interface EditOfferRequestAction {
  type: typeof EDIT_OFFER_REQUEST;
}

interface EditOfferSuccessAction {
  type: typeof EDIT_OFFER_SUCCESS;
  payload: any; // Tipo de los datos de respuesta de la oferta editada
}

interface EditOfferFailureAction {
  type: typeof EDIT_OFFER_FAILURE;
  payload: string;
}

export type EditOfferActionTypes =
  | EditOfferRequestAction
  | EditOfferSuccessAction
  | EditOfferFailureAction;
